import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Modal from "../../../../common/modal";
import RegisterNoticeModal from "./modal/registerNoticeModal";
import BoardSearch from "../../../../common/board/boardSearch";
import BoardTable from "../../../../common/board/boardTable";
import CustomPagination from "../../../../common/customPagination";
import { toast } from "react-toastify";

export default function RegisterNoticeForm () {
  const navigater = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [noticeModal, setNoticeModal] = useState();
  const [notices, setNotices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const selectList = ["제목", "내용"];
  const [selected, setSelected] = useState("제목");

  const location = useLocation();
  const isUpload = location?.state?.noticeUpload;
  const isDeleted = location?.state?.noticeDelete;
  const isEdit = location?.state?.noticeEdit;
  let count = 0;

  useEffect(() => {
    getToast();
    getNotice();
  }, [])

  const getToast = () => {
    count++;
    if(count === 1 && isUpload){
      toast.success("공지사항이 등록 되었습니다.");
    }
    if(count === 1 && isDeleted){
      toast.success("공지사항이 삭제 되었습니다.");
    }
    if(count === 1 && isEdit){
      toast.success("공지사항이 수정 되었습니다.");
    }
  }

  const getNotice = async (page = 0) => {
    setCurrentPage(page);
    const req = {
      gbn: "N",
      delYn: "N",
      cmpnyNo: user?.cmpnyNo,
      offset: 10 * page,
      pageNumber: page,
      pageSize: 10,
      paged: true,
    }

    if(keyword !== ''){
      switch(selected) {
        case "제목":
          req.noticeSj = keyword;
          break;
        case "내용":
          req.noticeCn = keyword;
          break;
        default:
          break;
      }
    }

    const response = await axios.post('/api/noticeList', req);
    const res = response.data;
    setNotices(res.content);
    setTotalPage(res.totalPages);
  }

  const handleNoticeModal = () => {
    setNoticeModal(!noticeModal)
  }

  return(
    <>
      <div className="hp_step_content">
        {/* <div className="hp_step_setting">
            <span className="font_16 font300 font_color_gray">※ 권한(관리자쓰기, 사용자쓰기)을 설정할 수 있습니다.</span>
            <button onClick={handleNoticeModal} className="hp_step_setting_btn" title="클릭시 카테고리를 추가 할 수 있습니다."><i className="fa fa-cog" aria-hidden="true" /> 권한 설정</button>
        </div> */}
      </div> 
      <div className="hp_step_gall_wrap">
        <BoardSearch 
          selectList={selectList} 
          setSelected={setSelected}
          setKeyword={setKeyword}
          getData={getNotice}
        />
        <BoardTable notices={notices}/>
        <div className="board_paging">
          <CustomPagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} getData={getNotice}/>
          <button type="button" className="btn_write" onClick={() => navigater("/mypage/registerNoticeWrite")}>
            등록하기
          </button>
        </div>

        <div className="btnarea_center pdt_40 hp_step_btn">
          <Link to={"/mypage/product"} className="btn_normal_save"><span>다음 단계</span></Link>
        </div>
      </div>
      <Modal
        modalState={noticeModal} 
        handleModalState={handleNoticeModal} 
        html={<RegisterNoticeModal handleNoticeModal={handleNoticeModal}/>}
      />
    </>
  )
}