import React, { useEffect, useState } from "react";
import { EditPickupModal } from "../styled/pickUp";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal, ModalCloseButton } from "../styled/common";
import StorageDetails from "./editStatusModal/storageDetails";
import AddPayment from "./editStatusModal/addPayment";
import AddServiceTime from "./editStatusModal/addServiceTime";
import { TbArrowBackUp } from "react-icons/tb";
import { isValidStatus } from "../function/kiosk";

const EditStatusModal = ({ selected, handleModalClose }) => {
  const [action, setAction] = useState("completed");
  const [storage, setStorage] = useState({});
  const [details, setDetails] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [extraCharges, setExtraCharges] = useState(0);
  const [viewType, setViewType] = useState(0);
  const [payType, setPayType] = useState(1);

  const transformValue = viewType * -100;

  useEffect(() => {
    getEnableStorageDetail(selected);
  }, [selected]);

  // 상세보기
  const getEnableStorageDetail = async (user) => {
    const id = user.id;

    const url = "/api/reserve-user-detail";
    const body = { id };
    const res = await axios.post(url, body);

    if (res.status === 200 && res.data.length > 0) {
      setDetails(res.data);

      const categorizedData = res.data.reduce(
        (acc, item) => {
          const type = item.type;
          if (type >= 0 && type <= 2) {
            acc[type].items.push(item);
          }
          return acc;
        },
        { 0: { items: [] }, 1: { items: [] }, 2: { items: [] } },
      );

      setStorage(categorizedData);
    } else {
      setDetails([]);
      setStorage({ 0: { items: [] }, 1: { items: [] }, 2: { items: [] } });
    }
  };

  // 뒤로가기
  const handleBackButton = async () => {
    setChecked([]);
    setIsAllSelected(false);
    setExtraCharges(0);
    setViewType(0);
    await getEnableStorageDetail(selected);
  };

  // 전체 선택 및 해제
  const toggleSelectAll = () => {
    if (isAllSelected) {
      setChecked([]);
    } else {
      const allValidItems = handleAllItem();
      setChecked(allValidItems);
    }

    setIsAllSelected((prev) => !prev);
  };

  // 체크박스 변경
  const toggleCheckbox = (e) => {
    const id = parseInt(e.target.name, 10);
    const selectedItem = details.find((item) => item.id === id);

    if (!selectedItem || !isValidStatus(selectedItem.reservationStatus)) {
      return;
    }

    setChecked((prevChecked) =>
      prevChecked.some((item) => item.id === id)
        ? prevChecked.filter((item) => item.id !== id)
        : [...prevChecked, selectedItem],
    );
  };

  // 픽업하기
  const handlePickUp = async (data) => {
    const historyIds = data.map((item) => item.id);

    if (!data || !data.length) {
      return;
    }

    const url = "/api/pickup";
    const body = { historyIds, reserveId: data[0].reserveId };
    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success("픽업이 완료되었습니다.");
        handleModalClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 확인 클릭 시
  const handleCheckRadio = async () => {
    if (!action) {
      toast.error("픽업상태를 선택해주세요.");
      return;
    }
    if (action === "completed" && (!checked || !checked.length)) {
      toast.error("픽업상태를 변경할 보관함을 선택해주세요.");
      return;
    }

    await actions[action].function();
  };

  // 추가금 결제
  const handleAddPay = async () => {
    const historyIds = checked.map((item) => item.id);

    const url = "/api/add-pay";
    const body = {
      historyIds,
      amount: checked.reduce((acc, item) => {
        if (item.addPay && item.addPay > 0) {
          return acc + item.addPay;
        }
        return acc;
      }, 0),
      reserveId: checked[0].reserveId,
      addPayType: payType,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        handlePickUp(checked);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAllItem = () => {
    const allItems = Object.values(storage)
      .map((storageGroup) => storageGroup.items)
      .flat();
    return allItems.filter((item) => isValidStatus(item.reservationStatus));
  };

  // 서비스 시간 추가
  const handleService = async () => {
    const allValidItems = handleAllItem();
    const historyIds = allValidItems.map((item) => item.id);

    const url = "/api/service-time";
    const body = { historyIds, reserveId: allValidItems[0].reserveId };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        toast.success("서비스 시간이 추가되었습니다.");
        handleBackButton();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 서비스 버튼 클릭 시
  const handleServiceTime = () => {
    const allValidItems = handleAllItem();
    if (!allValidItems.length) {
      toast.error("서비스 시간을 추가할 보관함이 존재하지 않습니다.");
      return;
    }
    setViewType(2);
  };

  // 확인 버튼 누를 시
  const isStorageStatus = async () => {
    const checkItems = details.filter((item) =>
      checked.some((checkedItem) => checkedItem.id === item.id),
    );

    const completed = checkItems.filter((item) => item.reservationStatus === "COMPLETED");
    let data = checkItems.filter((item) => item.reservationStatus === "READY");

    if (completed.length) {
      const ready = await handlePickUpRequest(completed);
      data = [...data, ...ready];
    }

    checkAddPay(data);
  };

  // 픽업요청
  const handlePickUpRequest = async (data) => {
    const historyIds = data.map((item) => item.id);

    const url = "/api/pickup-request";
    const body = { historyIds, reserveId: data[0].reserveId };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        return res.data;
      }
    } catch (error) {
      console.error(error);
    }
    return;
  };

  // 추가금 여부 확인
  const checkAddPay = (data) => {
    const hasAddPay = data.some((item) => !item.addPayTof && item.addPay);

    if (hasAddPay) {
      const total = data.reduce((total, item) => {
        return total + (item.addPay || 0);
      }, 0);
      setExtraCharges(total);
      setViewType(1);
    } else {
      setExtraCharges(0);
      handlePickUp(data);
    }
  };

  // 취소하기
  const handleReserveCancel = async () => {
    const url = "/api/reserve-cancel";
    const body = { reserveId: selected.id };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setViewType(5);
        await getReserveUser();
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || "알 수 없는 이유로 예약을 취소할 수 없습니다.";
      toast.error(message);
    }
  };

  const actions = {
    completed: {
      label: "픽업완료",
      key: "completed",
      function: isStorageStatus,
    },
    cancelled: {
      label: "결제취소",
      key: "cancelled",
      function: handleReserveCancel,
    },
  };

  return (
    <EditPickupModal>
      <Modal>
        <div className="modalContent">
          <div className="slideWrapper">
            <StorageDetails
              selected={selected}
              details={details}
              status={actions}
              current={action}
              setCurrent={setAction}
              storage={storage}
              handleCheckRadio={handleCheckRadio}
              toggleSelectAll={toggleSelectAll}
              isAllSelected={isAllSelected}
              checkStorage={checked}
              toggleCheckbox={toggleCheckbox}
              transformValue={transformValue}
              statusType="COMPLETED"
              handleServiceTime={handleServiceTime}
            />
            <AddPayment
              transformValue={transformValue}
              extraCharges={extraCharges}
              setViewType={setViewType}
              handleAddPay={handleAddPay}
              payType={payType}
              setPayType={setPayType}
            />
            <AddServiceTime
              transformValue={transformValue}
              handleService={handleService}
              handleBackButton={handleBackButton}
            />
          </div>
          <ModalCloseButton
            onClick={() => (viewType === 0 ? handleModalClose() : handleBackButton())}>
            {viewType ? <TbArrowBackUp /> : <IoClose />}
          </ModalCloseButton>
        </div>
      </Modal>
    </EditPickupModal>
  );
};

export default EditStatusModal;
