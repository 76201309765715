import React from "react";
import { GroupInviteContainer } from "../../../group/component/styled/group";
import { IoMdClose } from "react-icons/io";
import { GroupSearchBox } from "../../../group/component/styled/common";

const CompanyInvite = ({
  setMoblphon,
  moblphon,
  handleEnterKey,
  handleSearch,
  isSearchUser,
  searchUser,
  sendKakaoMessage,
  setIsInvite,
  handleInvite,
}) => {
  return (
    <GroupInviteContainer className="invite">
      <div className="inviteContent">
        <div className="closeBtnArea">
          <button onClick={() => setIsInvite(false)}>
            <IoMdClose />
          </button>
        </div>
        <GroupSearchBox>
          <div className="search_box">
            <div className="basic">
              <input
                type="text"
                id="keyword"
                name="keyword"
                placeholder="휴대폰 번호를 입력하세요."
                onChange={(e) => setMoblphon(e.target.value)}
                value={moblphon}
                onKeyDown={(e) => handleEnterKey(e, handleSearch)}
                style={{ width: "60%" }}
              />
              <button onClick={handleSearch}>
                <span className="font_20 font300 font_color_white">검색</span>
              </button>
            </div>
          </div>
          {isSearchUser && (
            <div className="searched">
              {searchUser.length > 0 ? (
                <ul>
                  <li>
                    <div>이름</div>
                    <div>전화번호</div>
                    <div>초대</div>
                  </li>
                  {searchUser.map((user) => (
                    <li key={user.mberNo}>
                      <div>
                        {`${user.mberNm[0]}${
                          user.mberNm.length > 1 ? "*" : ""
                        }${user.mberNm.substring(2)}`}
                      </div>
                      <div>{`${user.moblphonNo.substring(0, 3)}****${user.moblphonNo.substring(
                        7,
                      )}`}</div>
                      <div>
                        <button onClick={() => handleInvite(user.mberNo)}>회사초대</button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="msg">
                  <button onClick={sendKakaoMessage}>카카오톡 공유</button>
                </div>
              )}
            </div>
          )}
        </GroupSearchBox>
      </div>
    </GroupInviteContainer>
  );
};

export default CompanyInvite;
