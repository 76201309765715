import React, { useState } from "react";
import Orders from "./desktop/orders";
import OrderDetails from "./desktop/orderDetails";
import { ChoiceModal } from "../common/modal";

const DesktopOrderView = () => {
  const [active, setActive] = useState("orders");
  const [viewType, setViewType] = useState("orders");
  const tabs = [
    { key: "orders", label: "신규 주문" },
    { key: "completedOrders", label: "완료" },
  ];
  const [selected, setSelected] = useState(null);
  const [modalType, setModalType] = useState("");
  const [orderRejected, setOrderRejected] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const data = Array.from({ length: 10 }, (_, index) => ({
    mberNm: "홍길동",
    mberNo: 0,
    orderDetails: [
      {
        options: [1, 2],
        prdCode: "P0000001",
        price: 50,
        quantity: 2,
      },
    ],
    orderId: 2024071100001 + index,
    payType: "0",
    preparationTime: 30,
    price: 100,
    remark: "케찹 뿌리지마세요",
    tel: "010-1234-5678",
    type: Math.random() < 0.5 ? "0" : "1",
  }));

  const handleModalOpen = (status) => {
    setModalType(status);
    setModalOpen(true);
  };

  const handleChangeOption = (item) => {
    setOrderRejected(item);
  };

  const handleConfirm = () => {
    console.log(orderRejected);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setModalType("");
  };

  const getModalContent = () => {
    switch (modalType) {
      case "orderRejected":
        return (
          <ChoiceModal
            title="주문 거부 발송"
            message="주문거부 사유를 선택해주세요"
            active={orderRejected}
            options={[
              "메뉴 및 가게정보 다름",
              "재료소진",
              "조리지연",
              "가게사정 취소",
              "요청사항 불가",
              "기타",
            ]}
            handleChangeOption={handleChangeOption}
            handleConfirm={handleConfirm}
            handleCancel={handleCancel}
          />
        );
      default:
        return null;
    }
  };

  const changeOrderView = (item, flag) => {};

  return (
    <>
      <Orders
        tabs={tabs}
        active={active}
        setActive={setActive}
        data={data}
        setSelected={setSelected}
        changeOrderView={changeOrderView}
      />
      <OrderDetails viewType={viewType} selected={selected} handleModalOpen={handleModalOpen} />

      {modalOpen && getModalContent()}
    </>
  );
};

export default DesktopOrderView;
