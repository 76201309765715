import Feature from "./component/feature";
import Member from "./component/member";
import Video from "./component/video";
import SectionSwiper from "./component/sectionSwiper";


//메인페이지
const Main = () => {
  
  return (
    <div id="fullpage">
      <SectionSwiper />
      <Video />
      <Feature />
      <Member />
    </div>
  );
};

export default Main;