import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";

const Portfolio = ({ cmpnyNo }) => {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getPortfolio = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 3,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      setPortfolio(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="portfolio">
      <div className="info">
        Portfolio
        <div className="more">
          <span>여기는 작품 소개입니다.</span>
          <span>더 많은 작품은 더보기에 있습니다.</span>
          <button>
            전체 제품 보기<div className="line"></div>
          </button>
        </div>
      </div>
      <div className="content">
        {portfolio.map((item) => (
          <div className="box">
            <div className="view">
              <img src={imgHandler(item.imgUrl)} onError={onErrorImg} />
            </div>
            <div className="contentInfo">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
