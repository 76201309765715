import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import
{ setSyncBussinessCode,
  setSyncLargeCode,
  setSyncMiddleCode,
  setSyncSmallCode,
  setSyncIsClick,
  setSyncLargeCategory,
  setSyncMiddleCategory,
  setSyncSmallCategory,
  setSyncDetailCategory,
  resetSyncBussiness,
  resetSyncLarge,
  resetSyncMiddle } from "../../../../store/productSyncCode";
import { setProductSyncPage } from '../../../../store/productSync';
import ProductCategory from "../productCategory";
import axios from "axios";

export default function ProductSyncTop ({setProductSync}) {
  useEffect(() => {
    getBussiness();
  }, []);

  const dispatch = useDispatch();
  const [business, setBusiness] = useState([]);

  const selected = useSelector((state) => state.productSync.selected);
  const bussinessCode = useSelector((state) => state.productSyncCode.syncBussinessCode);
  const largeCode = useSelector((state) => state.productSyncCode.syncLargeCode);
  const middleCode = useSelector((state) => state.productSyncCode.syncMiddleCode);
  const largeCategory = useSelector((state) => state.productSyncCode.syncLargeCategory);
  const middleCategory = useSelector((state) => state.productSyncCode.syncMiddleCategory);
  const smallCategory = useSelector((state) => state.productSyncCode.syncSmallCategory);
  const detailCategory = useSelector((state) => state.productSyncCode.syncDetailCategory);
  const isClick = useSelector((state) => state.productSyncCode.syncIsClick);

  const getProductSync = async ({
    page,
    selected,
    brandSort,
    prductCl1NmSort,
    prductNm,
    bsnsTy,
    prductCl1,
    prductCl2
  }) => {
    const url = '/api/prdSynth';
    const body = {
      offset : (page - 1) * selected,
      pageNumber : page - 1,
      pageSize : selected,
      paged : true,
      bsnsTy,
      prductCl1,
      prductCl2,
      delYn:'N'
    };

    const response = await axios.post(url, body);
    setProductSync(response.data);
  };

  const getBussiness = async () => {
    const url = '/api/code';
    const body = {
      clCode: "BTY"
    };

    const response = await axios.post(url, body);
    setBusiness(response.data); 
  };

  const getLargeCategory = async (e) => {
    if(e.target.value === "업종") {
      await getProductSync({page : 1, selected});
      dispatch(setProductSyncPage(1));
      dispatch(resetSyncBussiness());
      return;
    };

    const url = '/api/clCode';
    const body = {
      upperClCode : "PCL"
    };
    await getProductSync({page : 1, selected, bsnsTy : e.target.value});
    const response = await axios.post(url, body);

    if(e.target.value === "BTY005") {
      dispatch(setSyncLargeCategory(response.data));
    } else {
      dispatch(setSyncLargeCategory([]));
      dispatch(resetSyncLarge());
    };

    dispatch(setProductSyncPage(1));
    dispatch(setSyncBussinessCode(e.target.value));
    dispatch(setSyncIsClick([true, false, false, false]));
    dispatch(setSyncLargeCode(""));
    dispatch(setSyncMiddleCode(""));
    dispatch(setSyncSmallCode(""));
  };

  const getMiddleCategory = async (e) => {
    if(e.target.value === "대분류") {
      await getProductSync({page : 1, selected, bsnsTy : bussinessCode}); 
      dispatch(setProductSyncPage(1));
      dispatch(resetSyncLarge());
      return;
    };

    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    await getProductSync({
      page: 1,
      selected,
      bsnsTy: bussinessCode,
      prductCl1: e.target.value
    });
    const response = await axios.post(url, body);

    dispatch(setProductSyncPage(1));
    dispatch(setSyncLargeCode(e.target.value));
    dispatch(setSyncMiddleCategory(response.data));
    dispatch(setSyncIsClick([true, true, false, false]));
    dispatch(setSyncMiddleCode(""));
    dispatch(setSyncSmallCode(""));
  };

  const getSmallCategory = async (e) => {
    if(e.target.value === "중분류") {
      await getProductSync({
        page: 1,
        selected,
        bsnsTy: bussinessCode,
        prductCl1: largeCode
      }); 
      dispatch(setProductSyncPage(1));
      dispatch(resetSyncMiddle());
      return;
    };

    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    await getProductSync({
      page: 1,
      selected,
      bsnsTy: bussinessCode,
      prductCl1: largeCode,
      prductCl2: e.target.value
    });
    const response = await axios.post(url, body);

    dispatch(setProductSyncPage(1));
    dispatch(setSyncMiddleCode(e.target.value));
    dispatch(setSyncSmallCategory(response.data));
    dispatch(setSyncIsClick([true, true, true, false]));
  };

  const getDetailCategory = async (e) => {
    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    const response = await axios.post(url, body);
    dispatch(setSyncDetailCategory(response.data));
    dispatch(setSyncIsClick([true, true, true, true]));
  };

  return (
    <>
      <ProductCategory
        getLargeCategory={getLargeCategory}
        getMiddleCategory={getMiddleCategory}
        getSmallCategory={getSmallCategory}
        getDetailCategory={getDetailCategory}
        bussinessCode={bussinessCode}
        largeCode={largeCode}
        middleCode={middleCode}
        bussinessCategory={business}
        largeCategory={largeCategory}
        middleCategory={middleCategory}
        smallCategory={smallCategory}
        detailCategory={detailCategory}
        isClick={isClick}
      />
    </>
  )
}