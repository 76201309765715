import BoardList from "../../../common/boardList"
import QnaTitle from "./qnaTitle"

export default function QnaForm() {
  const BoardListProps = {
    button: true,
    search: true,   
    title: "자주하는 질문",
  }
  
  return(
    <div>
      <div className="inner_content">
        <QnaTitle />
        <BoardList BoardListProps={BoardListProps} />,
      </div>
    </div>
  )
}
