import MypageBanner from "../mypageBanner";
import PromotionForm from "./component/promotionForm";
import HomePageSettingMenu from "./homepageSettingMenu";

export default function Promotion() {
  return(
    <div>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
        <PromotionForm />
      </div>
    </div>
  )
}