import React from "react";

const ProgressPointer = ({ time, status, className }) => {
  return (
    <div className={`pointer ${className}`}>
      <span>{time}</span>
      <div className="checkpoint"></div>
      <span>{status}</span>
    </div>
  );
};

export default ProgressPointer;
