import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { GroupInner, GroupSection } from "./component/styled/common";

import { handleLogin } from "../../store/user";
import { getSetting } from "../../store/scheduler";

const GroupLogin = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({ id: "", pwd: "" });

  useEffect(() => {
    if (user) {
      navigate("/group/myGroup");
    }
    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };
    initKakao();
  }, []);

  const loginWithKakao = () => {
    Kakao.Auth.login({
      success: function (authObj) {
        const token = authObj.access_token;
        axios
          .request({
            method: "POST",
            url: "https://kapi.kakao.com/v2/user/me",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
          .then(async (response) => {
            const { data, status } = response;
            if (status === 200) {
              await kakaoSignIn(data);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      },
      fail: function (err) {
        alert("error!!!!" + JSON.stringify(err));
      },
    });
  };

  const kakaoSignIn = async (user) => {
    const url = "/api/kakaoSignIn";
    const req = {
      email: user.kakao_account.email,
      id: user.id,
      name: user.kakao_account.name,
      phoneNumber: user.kakao_account.phone_number,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      return false;
    } else {
      dispatch(handleLogin(res.data));
      if (sessionStorage.getItem("params")) {
        await handleInvite(res.data.mberNo);
      }
      getSchedulerSetting(res.data.mberNo);
    }
  };

  const changeLoginInfo = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const signIn = async () => {
    if (!loginInfo.id || !loginInfo.pwd) {
      toast.error("아이디 또는 비밀번호를 입력해주세요");
      return;
    }
    const url = "/api/signIn";
    const req = {
      id: loginInfo.id,
      pwd: loginInfo.pwd,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      toast.error("아이디 또는 비밀번호가 일치하지 않습니다");
      return false;
    } else {
      dispatch(handleLogin(res.data));
      if (sessionStorage.getItem("params")) {
        await handleInvite(res.data.mberNo);
      }
      getSchedulerSetting(res.data.mberNo);
    }
  };

  const handleInvite = async (mberNo) => {
    const params = JSON.parse(sessionStorage.getItem("params"));

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: params.gbn,
        mberNoList: [mberNo],
        orgNo: params.orgNo,
      },
    };

    for (const key in body.invite) {
      if (body.invite.hasOwnProperty(key) && !body.invite[key]) {
        console.log("object contains empty values");
        return;
      }
    }

    const res = await axios.post(url, body);
    if (res.data.body.success) {
      sessionStorage.removeItem("params");
    }
  };

  const getSchedulerSetting = async (mberNo) => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
      navigate("/group/myGroup");
    }
  };

  return (
    <GroupSection>
      <GroupInner>
        <div
          className="inner_content"
          style={{
            marginBottom: "100px",
          }}>
          <div className="inner_title">
            <span className="font_30 font300 font_color_black">로그인</span>
          </div>

          <div className="inner_content_wrap">
            <div className="change_pw">
              <input
                type="text"
                placeholder="ID"
                name="id"
                value={loginInfo.id}
                onChange={changeLoginInfo}
                onKeyDown={(e) => handleEnterKey(e, signIn)}
              />
              <input
                type="password"
                placeholder="Password"
                name="pwd"
                value={loginInfo.pwd}
                onChange={changeLoginInfo}
                onKeyDown={(e) => handleEnterKey(e, signIn)}
              />
              <button type="submit" className="pw_confirm pw_100" onClick={signIn}>
                <span>로그인</span>
              </button>
              <button
                className="pw_confirm pw_100"
                style={{
                  backgroundColor: "#FEE500",
                  margin: "10px 0",
                }}
                onClick={loginWithKakao}>
                <span style={{ color: "black" }}>카카오톡 로그인</span>
              </button>
              <Link to="/memberSearch" id="leave" className="member_find">
                회원정보찾기
              </Link>
              <Link to="/type" id="leave" className="member_apply" type="button">
                회원가입
              </Link>
            </div>
          </div>
        </div>
      </GroupInner>
    </GroupSection>
  );
};

export default GroupLogin;
