import { useState } from "react";
import uuid from "react-uuid";
import Modal from "../../../common/modal";

export default function ProductCategory (props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState("");

  const showModal = (e) => {
    if(e.currentTarget.id === "대분류" && !props.isClick[0]) {
      setError("업종을 선택해 주세요.");
      setModalOpen(true);
    };

    if(e.currentTarget.id === "중분류" && !props.isClick[1]) {
      setError("대분류를 선택해주세요.");
      setModalOpen(true);
    };

    if(e.currentTarget.id === "소분류" && !props.isClick[2]) {
      setError("중분류를 선택해주세요.");
      setModalOpen(true);
    };

    if(e.currentTarget.id === "세분류" && !props.isClick[3]) {
      setError("소분류를 선택해주세요.");
      setModalOpen(true);
    };
  };

  const ModalForm = (error) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">상품연동</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          {error}
        </span>
        <div style={{display : "flex", justifyContent : "center", gap : "20px", marginTop : "20px"}}>
          <button onClick={() => setModalOpen(false)}  className="hp_step_setting_cate_save" style={{padding : "10px 20px", margin: "0px" }}>확인</button>
        </div>
      </div>
    )
  };

  return (
  <>
    <table className="table_sm_list work_place hp_step">
      <colgroup>
        <col style={{width: '20%'}} />
        <col style={{width: '20%'}} />
        <col style={{width: '20%'}} />
        <col style={{width: '20%'}} />
        <col style={{width: '20%'}} />
      </colgroup>
      <thead>
        <tr>
          <th scope="col">
            <div onClick={showModal} style={{display : "inline"}}>
              <select key={uuid()} defaultValue={props.bussinessCode} onChange={props.getLargeCategory}>
                <option>업종</option>
                {props.bussinessCategory?.map((item) => 
                  <option value={item.code}>{item.codeNm}</option>
                )}
              </select>
            </div>
          </th>

          <th scope="col">
            <div id="대분류" onClick={showModal} style={{display : "inline"}}>
              <select key={uuid()} defaultValue={props.largeCode} onChange={props.getMiddleCategory}>
                <option >대분류</option>
                {props.largeCategory.map((item) => 
                  <option value={item.clCode}>{item.clCodeNm}</option>
                )}
              </select>
            </div>
          </th>

          <th scope="col">
            <div id="중분류" onClick={showModal} style={{display : "inline"}}>
              <select key={uuid()} defaultValue={props.middleCode} onChange={props.getSmallCategory}>
                <option>중분류</option>
                {props.middleCategory.map((item) => 
                  <option value={item.clCode}>{item.clCodeNm}</option>
                )}
              </select>
            </div>
          </th>

          <th scope="col">
            <div id="소분류" onClick={showModal} style={{display : "inline"}}>
              <select key={uuid()} onChange={props.getDetailCategory}>
                <option>소분류</option>
                {props.smallCategory.map((item) => 
                  <option value={item.clCode}>{item.clCodeNm}</option>
                )}
              </select>
            </div>
          </th>

          <th scope="col">
            <div id="세분류" onClick={showModal} style={{display : "inline"}}>
              <select>
                <option>세분류</option>
                {props.detailCategory.map((item) => 
                  <option value={item.clCode}>{item.clCodeNm}</option>
                )}
              </select>
            </div>
          </th>
        </tr>
      </thead>
    </table>
    <Modal
      modalState={modalOpen} 
      handleModalState={showModal} 
      html={ModalForm(error)}
      w={"16%"}
      h="auto"
    />
  </>
  )
}