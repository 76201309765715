import React from "react";

const Introduce = () => {
  return (
    <div className="introduce">
      <div className="info">Introduce</div>
      <div className="introduceContent">
        <img src="/img/shop_main_bg3.jpg" alt="" />
        <div className="content">
          <span className="tit">여기는 회사 설명입니다</span>
          <span className="con">
            회사 부가적 설명이에요 회사 부가적 설명이에요회사 부가적 설명
            <br />
            이에요회사 부가적 설명이에요회사 부가적 설명이에요
          </span>
        </div>
      </div>
      <div className="introduceContent">
        <img src="/img/shop_main_bg3.jpg" alt="" />
        <div className="content">
          <span className="tit">여기는 회사 설명입니다</span>
          <span className="con">
            회사 부가적 설명이에요 회사 부가적 설명이에요회사 부가적 설명
            <br />
            이에요회사 부가적 설명이에요회사 부가적 설명이에요
          </span>
        </div>
      </div>
    </div>
  );
};

export default Introduce;
