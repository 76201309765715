import styled from "styled-components";

export const MemberSwiperContainer = styled.div`
  .member_swiper .swiper-slide-active {
    min-height: 560px;
    .member_location {
      right: 55px;
    }
    .member_description {
      padding: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .member_swiper .swiper-slide-active {
      min-height: 600px;
    }
  }
`;
