import BoardView from '../../../../common/board/boardView';

const ShoppingNoticeView = () => {

  return (
    <main id="noticeView" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01"></div>
      <article className="boundary pdt_70 pdb_70">
        <BoardView isShop={true}/>
      </article>
    </main>
  );
};

export default ShoppingNoticeView;