import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import { useRegex } from "../../../../../../hooks/useRegex";
import { findMembersByCompanyNo, isGrpMberGbnValue } from "../../../function/group";
import { BlogItemContainer, StockItemContainer } from "../../../styled/group";
import { useMediaQuery } from "react-responsive";

// 블로그
export const BlogItem = ({ item, cmpnys }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  const openShopPage = async (item) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: item.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}/portfolioView/${item.portNo}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      toast.error("홈페이지가 존재하지 않습니다.");
    }
  };

  return (
    <BlogItemContainer onClick={() => openShopPage(item)}>
      <div className="image">
        <img
          src={
            item.imgUrl && item.imgUrl !== "string" ? imgHandler(item.imgUrl) : "/img/gall_0101.png"
          }
          alt=""
        />
      </div>
      <div className="info">
        <span className="title">{item.title}</span>
        <span className="cmpnyNm">{item.cmpnyNm}</span>
        <div className="mbers">{findMembersByCompanyNo(item.cmpnyNo, cmpnys)}</div>
      </div>
    </BlogItemContainer>
  );
};

// e층창고
export const ProductItem = ({ item, cmpnys, imageHeight, updateImageSize }) => {
  const { MoneyFormat } = useRegex();
  const user = useSelector((state) => state?.user?.data[0]);

  const openCmpnyPage = async (item = null) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: item.cmpnyNo,
    };

    const res = await axios.post(url, req);

    if (res.status === 200 && res.data) {
      user && localStorage.setItem("user", JSON.stringify(user));

      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}/productsView/${item.prdNo}`);
      } else {
        toast.error("홈페이지가 존재하지 않습니다.");
      }
    }
  };

  // 상품 매칭
  const productMatching = (cmpnyNo) => {
    const cmpnyDetails = cmpnys[cmpnyNo];

    if (cmpnyDetails && cmpnyDetails.length > 0) {
      const item = cmpnyDetails[0];

      if (cmpnyDetails.length === 1) {
        return isGrpMberGbnValue(item);
      } else {
        const additionalCount = cmpnyDetails.length - 1;

        if (item.nickNm && item.gbn && item.gbnValue) {
          return (
            <span>{`${item.gbnValue}${item.gbn} ${item.nickNm} 외 ${additionalCount}명`}</span>
          );
        } else {
          return (
            <span>
              {item.nickNm
                ? `${item.nickNm} 외 ${additionalCount}명 `
                : `닉네임 미설정 외 ${additionalCount}명`}
            </span>
          );
        }
      }
    } else {
      return null;
    }
  };

  return (
    <li>
      <div className="preview">
        <img
          src={item.prdImgFlpth ? imgHandler(item.prdImgFlpth) : "/img/not_prdct.png"}
          alt={item.prductNm}
          style={{ height: `${imageHeight}px`, cursor: "pointer" }}
          onLoad={updateImageSize}
          onClick={() => openCmpnyPage(item)}
        />
      </div>
      <div className="product">
        <span className="prductNm">{item.prductNm}</span>
        <span className="cnsmrPc">
          {item.cnsmrPc ? `${MoneyFormat(item.cnsmrPc)}원` : "가격 문의"}
        </span>
        <span>{item.cmpnyNm}</span>
        <div className="cmpnyMbers">{productMatching(item.cmpnyNo)}</div>
      </div>
    </li>
  );
};

// e층창고
export const StockItem = ({ item, updateImageSize, imageHeight }) => {
  const { MoneyFormat } = useRegex();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const openCmpnyPage = async (item = null) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: item.cmpnyNo,
    };

    const res = await axios.post(url, req);

    if (res.status === 200) {
      user && localStorage.setItem("user", JSON.stringify(user));

      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}/productsView/${item.prdNo}`);
      } else {
        toast.error("홈페이지가 존재하지 않습니다.");
      }
    }
  };

  const isPrdImg = (item) => {
    if (item.prdImgFlpth) return imgHandler(item.prdImgFlpth);
    if (item.extrlImgUrl) return imgHandler(item.extrlImgUrl);
    return "/img/not_prdct.png";
  };

  return (
    <StockItemContainer onClick={() => openCmpnyPage(item)}>
      <div className="preview">
        <img
          src={isPrdImg(item)}
          alt={item.prductNm}
          style={{ height: `${imageHeight}px` }}
          onLoad={updateImageSize}
        />
      </div>
      <div
        className="product"
        style={{ height: isMobile ? "100%" : `calc(100% - ${imageHeight}px)` }}>
        <div className="stock">
          <span className="prductNm">{item.prductNm}</span>
          <span className="cnsmrPc">
            {item.cnsmrPc ? `${MoneyFormat(item.cnsmrPc)}원` : "가격 문의"}
          </span>
        </div>
        <span className="cmpnyNm">{item.cmpnyNm}</span>
      </div>
    </StockItemContainer>
  );
};
