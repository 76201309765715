import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from "react-toastify";
import { setRegisterIndex } from "../../../../store/compoIndex";
import "../component/styled/intro.css";
import ImageUploadAdapterPlugin from "./utils/ImageUploadAdapter";
import Modal from "../../../../common/modal";
import CustomModal from "../../../../common/customModal";
import uuid from "react-uuid";

export default function RegisterIntroForm() {
  const dispatch = useDispatch();
  const ckeditorInstanceRef = useRef(null);
  const user = useSelector((state) => state?.user?.data[0]);
  const [modalOpen, setModalOpen] = useState(false);

  const [content, setContent] = useState("");

  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setContent(newContent);
  };

  const saveAndNext = async () => {
    const images = extractImages(content);
    await uploadImage(images);
    dispatch(setRegisterIndex(2));
  };

  const extractImages = (content) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = content.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  const uploadImage = async (images) => {
    console.log(images);
    let formData = new FormData();
    images.forEach((image, index) => {
      let blob = dataURItoBlob(image);
      let extension = blob.type.split("/")[1];
      formData.append("files", blob, `${uuid()}.${extension}`);
    });
    formData.append("ids", "N");
    formData.append("mberNo", user.mberNo);

    fetch("/api/uploadFiles", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return data.responseData;
      })
      .then((imageDatas) => {
        let imageUrls = [];
        imageDatas.map((item) => imageUrls.push(item.fileName));
        const reqContent = replaceImagesWithUrls(content, imageUrls);
        setContent(reqContent);
        return reqContent;
      })
      .then((reqContent) => lastSubmit(reqContent))
      .catch((error) => {
        console.error("업로드 중 오류 발생:", error);
      });
  };

  const replaceImagesWithUrls = (content, imageUrls) => {
    let updatedContent = content;
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);
    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });
    setContent(updatedContent);
    return updatedContent;
  };

  const lastSubmit = async (reqContent) => {
    const url = "/api/infoSettingSave";
    const request = {
      cmpnyNo: user.cmpnyNo,
      createMberId: user.userName,
      updateMberId: user.userName,
      cmpnyIntroduce: reqContent,
    };
    const res = await axios.put(url, request);
    if (res.data.success) {
      toast.success("회사 소개가 등록 되었습니다.");
    }
  };

  const openModal = () => {
    if (!content) {
      toast.error("내용을 입력해 주세요.");
      return;
    }
    setModalOpen(true);
  };

  return (
    <>
      <div className="hp_step_content">
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={handleEditorChange}
          config={{
            extraPlugins: [ImageUploadAdapterPlugin],
            toolbar: ["bold", "italic", "|", "link", "imageUpload", "mediaEmbed"],
            uiSettings: {
              height: "500px",
            },
          }}
          ref={ckeditorInstanceRef}
        />
      </div>
      <div className="btnarea_center pdt_40 hp_step_btn">
        <button onClick={openModal} className="btn_normal_save" style={{ border: "none" }}>
          <span>저장하고 다음 단계</span>
        </button>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={
          <CustomModal
            title={`회사소개`}
            content={`회사소개를 등록 하시겠습니까?`}
            handleConfirm={saveAndNext}
            handleCancel={() => setModalOpen(false)}
          />
        }
        w="300px"
        h="auto"
      />
    </>
  );
}
