import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarContainer } from "../../../styled/salesCheck";

const SalesCheckCalendar = ({ currentRect, setOpenCalendar }) => {
  return (
    <CalendarContainer onClick={() => setOpenCalendar(false)}>
      <div
        className="calendar"
        style={{
          position: "absolute",
          top: currentRect.bottom,
          left: currentRect.left,
          width: currentRect.width,
        }}>
        <FullCalendar
          // ref={calendarRef}
          plugins={[dayGridPlugin, interactionPlugin]}
          // locale={"ko"}
          // contentHeight={"auto"}
          // datesSet={handleDatesSet}
          // eventOrder={customEventOrder}
          // dayCellContent={(info) => displayDateInfo(info)}
        />
      </div>
    </CalendarContainer>
  );
};

export default SalesCheckCalendar;
