import axios from "axios";
import { useEffect, useState } from "react";
import { useRegex } from "../../hooks/useRegex";
import { Link } from "react-router-dom";
import "./shopping.css";

const ShoppingFooter = ({ homepageInfo }) => {
  const { BussinessFormat, TelFormat, TimeAddZero } = useRegex();
  const [cmpnyInfo, setCmpnyInfo] = useState("");
  const [rawCSNm, setRawCSNm] = useState("");
  const [rawTelNm, setRawTelNm] = useState("");

  useEffect(() => {
    if (homepageInfo) {
      getCmpnyInfo(homepageInfo?.cmpnyNo);
    }
  }, [homepageInfo]);

  useEffect(() => {
    if (cmpnyInfo) {
      setRawTelNm(cmpnyInfo?.data?.telno?.replace(/-/g, "") ?? "");
      setRawCSNm(homepageInfo?.csNumber?.replace(/-/g, "") ?? "");
    }
  }, [cmpnyInfo, homepageInfo]);

  const getCmpnyInfo = async (cmpnyNo) => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const res = await axios.post(url, body);
    setCmpnyInfo(res);
  };

  return (
    // <footer className="pdt_70 pdb_70 font_color_white footer_mobile_mt">
    //   <div className="ft_wrap font_16 font300 boundary">
    //     <div
    //       className={`ft_cs shop_footer_text_wrapper_${
    //         cmpnyInfo?.data?.adres || rawTelNm ? "h_210" : "h_170"
    //       }`}>
    //       <h4 className="font_18 font300 pdb_30">CS CENTER</h4>
    //       {rawCSNm && (
    //         <p className="pdb_30">
    //           <Link href={`${TelFormat(rawCSNm)}`} className="font_36 font600 font_color_white">
    //             {`${TelFormat(rawCSNm)}` ?? ""}
    //           </Link>
    //         </p>
    //       )}
    //       <p>
    //         상담시간:
    //         {homepageInfo?.csTimeStart
    //           ? ` ${TimeAddZero(homepageInfo?.csTimeStart)}:00 -`
    //           : " 09:00 -"}
    //         {homepageInfo?.csTimeEnd ? ` ${TimeAddZero(homepageInfo?.csTimeEnd)}:00` : " 18:00"}
    //       </p>
    //       <p className="pdb_30">
    //         점심시간:
    //         {homepageInfo?.breakTimeStart
    //           ? ` ${TimeAddZero(homepageInfo?.breakTimeStart)}:00 -`
    //           : " 12:00 -"}
    //         {homepageInfo?.breakTimeEnd
    //           ? ` ${TimeAddZero(homepageInfo?.breakTimeEnd)}:00`
    //           : " 13:00"}
    //       </p>
    //       {/* <p>토, 일, 공휴일은 휴무입니다.</p> */}
    //       <p className="shop_footer_text_div">문의사항은 Q&A게시판을 이용해주세요.</p>
    //     </div>
    //     <div
    //       // style={{width : isMobile ? "" : "30%",}}
    //       className={`ft_company shop_footer_text_wrapper_${
    //         cmpnyInfo?.data?.adres || rawTelNm ? "h_210" : "h_170"
    //       } shop_address_wrapper`}>
    //       <h4 className="font_18 font300 pdb_30">COMPANY</h4>
    //       {cmpnyInfo?.data?.bizrno && (
    //         <p>사업자등록번호: {`${BussinessFormat(cmpnyInfo?.data?.bizrno)}` ?? ""}</p>
    //       )}
    //       {cmpnyInfo?.data?.rprNm && <p>대표: {cmpnyInfo?.data?.rprNm ?? ""}</p>}
    //       {cmpnyInfo?.data?.adres && <p>주소: {cmpnyInfo?.data?.adres ?? ""} </p>}
    //       {rawTelNm && <p>대표전화: {`${TelFormat(rawTelNm)}` ?? ""}</p>}
    //       {/* <p>팩스: {cmpnyInfo?.data?.fxnum ?? ''}</p> */}
    //       {/* <p
    //         className="pdb_30">대표메일 :
    //         <Link
    //           href="mailto:woodplan1999@naver.com"
    //           className="font_color_white"
    //         >
    //           woodplan1999@naver.com
    //         </Link>
    //       </p> */}
    //       <div className="shop_footer_text_div">
    //         <p>호스팅 업체 : 노타이틀</p>
    //         <p>Copyright © 노타이틀 All Rights Reserved.</p>
    //       </div>
    //     </div>

    //     {(homepageInfo?.facebookChk === "N" && homepageInfo?.facebookUrl) ||
    //     (homepageInfo?.instagramChk === "N" && homepageInfo?.instagramUrl) ? (
    //       <div className="ft_company">
    //         <h4 className="font_18 font300 pdb_30">ETC</h4>
    //         <div>
    //           {homepageInfo?.facebookChk !== "N" || !homepageInfo?.facebookUrl ? (
    //             ""
    //           ) : (
    //             <a href={`${homepageInfo?.facebookUrl}`} target="_blank">
    //               <img
    //                 src="/img/i_facebook.png"
    //                 alt=""
    //                 style={{
    //                   marginRight: "10px",
    //                   cursor: "pointer",
    //                 }}></img>
    //             </a>
    //           )}
    //           {homepageInfo?.instagramChk !== "N" || !homepageInfo?.instagramUrl ? (
    //             ""
    //           ) : (
    //             <a href={`${homepageInfo?.instagramUrl}`} target="_blank">
    //               <img
    //                 src="/img/i_instargram.png"
    //                 alt=""
    //                 style={{
    //                   cursor: "pointer",
    //                 }}></img>
    //             </a>
    //           )}
    //         </div>
    //       </div>
    //     ) : (
    //       ""
    //     )}
    //   </div>
    // </footer>
    <footer>
      <div className="ft_info">
        <div className="ft_info1">
          <span className="font_18 font500 pdb_30">대표전화</span>
          <span className="font_30 font700 font_color_2 pdb_40">{common.call}</span>
          <span className="font_18 font300">
            {common.time}
            <br />
            {common.day}
          </span>
        </div>
        <div className="ft_info2">
          <span className="font_16 font400 pdb_70">
            상호명: 노타이틀&nbsp;&nbsp;&nbsp;&nbsp; 대표자명: 조윤호&nbsp;&nbsp;&nbsp;&nbsp;
            {common.business}
            <br />
            통신판매업: 제 2022-대전서구-1316 호
            <br />
            {common.address}
            &nbsp;&nbsp;&nbsp;&nbsp; {common.fax}
            &nbsp;&nbsp;&nbsp;&nbsp; {common.mail}
          </span>
          <span className="font_14 font300">{common.copyright}</span>
        </div>
      </div>
    </footer>
  );
};

export default ShoppingFooter;
