import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

import { NotDataList } from "../../../styled/common";
import { GroupDetailFeedContainer } from "../../../styled/group";

import GroupDetailFeedItem from "../groupDetailHome/groupDetailFeedItem";
import GroupDetailSearchForm from "./groupDetailSearchForm";
import ImageDetailViewer from "../../../../../../common/imageDetailViewer";
import Modal from "../../../../../../common/modal";

const GroupDetailSearchFeed = ({ userInfo, grp, data, isSearch }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [text, setText] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState();
  const [selected, setSelected] = useState({});
  const [selectValue, setSelectValue] = useState();
  const [isBigImages, setIsBigImages] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      mergeFeed(data);
    } else {
      setFeeds([]);
    }
  }, [data]);

  const mergeFeed = async (data) => {
    const [commentsData, authData, gbnData] = await Promise.all([
      mergeComment(data),
      mergeAuth(data),
      mergeGbnValueName(data),
    ]);

    const updatedFeeds = data.map((feed) => {
      const auth = authData.find((auth) => auth.feedNo === feed.feedNo) || {};
      const gbn = gbnData.find((gbn) => gbn.feedNo === feed.feedNo) || {};
      const comments = commentsData.find((comment) => comment.feedNo === feed.feedNo) || {};

      return {
        ...feed,
        ...auth,
        ...gbn,
        comments: comments.comments || [],
        showComments: 3,
      };
    });

    setFeeds(updatedFeeds);
  };

  // 댓글 조회
  const getCommentList = async (feedNo = null) => {
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      delYn: "N",
      paged: false,
    };

    const res = await axios.post(url, req);
    return res?.data?.content;
  };

  // 그룹별 권한 병합
  const mergeAuth = async (feeds) => {
    return feeds.map((feed) => {
      const authData = userInfo ? { auth: userInfo.auth } : {};
      return { feedNo: feed.feedNo, ...authData };
    });
  };

  // 댓글 병합
  const mergeComment = async (feeds) => {
    const comments = await getCommentList();
    return feeds.map((feed) => ({
      feedNo: feed.feedNo,
      comments: comments.filter((comment) => comment.feedNo === feed.feedNo),
    }));
  };

  // gbn 병합 및 피드 본문 내용 말줄임
  const mergeGbnValueName = async (feeds) => {
    return feeds.map((feed) => {
      const content = feed.content.includes("\n")
        ? feed.content.split("\n")[0] + "..."
        : feed.content.length > 15
        ? feed.content.substring(0, 15) + "..."
        : feed.content;
      return {
        feedNo: feed.feedNo,
        gbnValueName: grp.gbn,
        realContent: feed.content,
        content: content,
        showContent: false,
      };
    });
  };

  // 피드 삭제
  const handleDeleteModal = (target, category) => {
    setModalOpen((modalOpen) => !modalOpen);
    setSelectValue(category);
    setSelected(target);
  };

  // 피드 수정
  const editFeed = (target) => {
    navigate(`/group/group-feed-write/${id ? id : target.gbn}`, {
      state: {
        feed: target,
      },
    });
  };

  // 피드 댓글 저장
  const onSubmit = async (e, feedNo) => {
    e.preventDefault();
    if (!text[feedNo].trim()) {
      return;
    }
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      mberNo: user.mberNo,
      mberNm: user.name,
      createMberId: user.userName,
      updateMberId: user.userName,
      content: text[feedNo],
      delYn: "N",
    };
    await axios.put(url, req);
    await addComment(feedNo);
  };

  // 피드 댓글 삭제
  const deleteComment = async (target) => {
    const url = "/api/feedRecomment";
    const req = {
      feedRecommentNo: target.feedRecommentNo,
      feedNo: target.feedNo,
      content: target.content,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`댓글이 삭제되었습니다.`);
    addComment(target.feedNo);
  };

  // 모달 확인 버튼 눌렀을 때
  const onClickModalCheck = async (category) => {
    if (category === "feed") {
      await deleteFeed(selected);
    }
    if (category === "comment") {
      await deleteComment(selected);
    }
    setModalOpen(false);
    setSelected({});
    setSelectValue("");
  };

  // 댓글 추가
  const addComment = async (feedNo) => {
    if (feedNo) {
      const comment = await getCommentList(feedNo);
      const target = feeds.find((item) => item.feedNo === feedNo);
      if (target) {
        target.comments = Array.isArray(comment) ? [...comment] : [];
        setFeeds([...feeds]);
        setText({});
      }
    }
  };

  // 피드 삭제
  const deleteFeed = async (target) => {
    const url = "/api/feedList";
    const req = {
      feedNo: target.feedNo,
      content: target.content,
      imgUrl: target.imgUrl,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`게시글이 삭제되었습니다.`);
    // getFeedList();
    setFeeds(feeds.filter((item) => item.feedNo !== target.feedNo));
  };

  const deleteModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">삭제</h2>
        <button onClick={handleDeleteModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          삭제하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={() => onClickModalCheck(selectValue)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <GroupDetailFeedContainer>
      {isSearch && (
        <>
          <div
            className="label"
            style={{
              margin: " auto auto 15px",
              maxWidth: 550,
            }}>
            {data.length > 0 && (
              <span>
                검색 결과
                <strong>{data.length.toLocaleString()}</strong>개
              </span>
            )}
          </div>
          {feeds.length > 0 ? (
            <ul className="feeds">
              {feeds.map((feed) => (
                <GroupDetailFeedItem
                  feed={feed}
                  setFeedList={setFeeds}
                  setIsBigImages={setIsBigImages}
                  text={text}
                  setText={setText}
                  active={active}
                  setActive={setActive}
                  handleDeleteModal={handleDeleteModal}
                  editFeed={editFeed}
                  onSubmit={onSubmit}
                />
              ))}
            </ul>
          ) : (
            <NotDataList>피드가 존재하지 않습니다.</NotDataList>
          )}
          {isBigImages.length > 0 && (
            <ImageDetailViewer images={isBigImages} setIsDetail={setIsBigImages} isGroup={true} />
          )}
        </>
      )}
      <Modal
        modalState={modalOpen}
        handleModalState={handleDeleteModal}
        html={deleteModalForm()}
        w="300px"
        h="auto"
      />
    </GroupDetailFeedContainer>
  );
};

export default GroupDetailSearchFeed;
