import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";

import { handleChangeUser, handleLogin } from "../../../store/user";

import Modal from "../../../common/modal";
import NotifyHeader from "./component/notifyPage/notifyHeader";
import NotifyList from "./component/notifyPage/notifyList";
import NotifyReleaseNotes from "./component/notifyPage/notifyReleaseNotes";
import NotifyBenefit from "./component/notifyPage/notifyBenefit";

import { NotifyContainer } from "./component/styled/dashboard";

const NotifyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSetMenuOpen, setIsSetMenuOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [notification, setNotification] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);
  const [selectedNotiNos, setSelectedNotiNos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (user) {
      getNotification();
    } else {
      const localUser = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : null;
      if (localUser) {
        dispatch(handleLogin(localUser));
        getNotification(localUser.mberNo);
      }
    }
  }, []);

  // 알림 히스토리 조회
  const getNotification = async (userNo = null) => {
    const url = "/api/notificationHistory";
    const body = {
      mberNo: userNo ? userNo : user.mberNo,
    };
    const res = await axios.post(url, body);
    setNotification(res.data);
  };

  // 선택 삭제 체크박스
  const toggleNotiSelection = (notiNo) => {
    setSelectedNotiNos((selected) => {
      if (selected.includes(notiNo)) {
        return selected.filter((no) => no !== notiNo);
      } else {
        return [...selected, notiNo];
      }
    });
  };

  // 읽음 처리
  const readNotification = async (item) => {
    if (item.readYn === "N") {
      const url = "/api/notificationHistoryUpdate";
      const body = {
        mberNo: user.mberNo,
        allDelYn: "N",
        allReadYn: "N",
        notiNos: [item.notiNo],
        acceptYn: null,
        gbnValue: null,
        readYn: "Y",
        delYn: "N",
      };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        navigateToPage(item);
      }
    } else {
      navigateToPage(item);
    }
  };

  // 페이지 이동
  const navigateToPage = (item) => {
    const navPath = {
      S: () => navigate(`/mypage/detailEvent/${item.refeNo}`),
      R: {
        C: () => navigate(`/mypage/company`),
        G: () => navigate(`/group/${item.refeNo}`),
      },
      F: () => navigate(`/group/feed`),
      RA: () => navigate(`/group/${item.refeNo}`),
    };

    const { notiType, gbn } = item;
    const navFunc = navPath[notiType];

    if (typeof navFunc === "function") {
      navFunc();
    } else if (typeof navFunc === "object" && navFunc[gbn]) {
      navFunc[gbn]();
    }
  };

  // 초대 수락, 거절
  const inviteResponse = async (item, acceptYn) => {
    console.log(item);

    if (!item && !acceptYn) return;

    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "N",
      allReadYn: "N",
      notiNos: [item.notiNo],
      gbnValue: null,
      readYn: "Y",
      delYn: "Y",
    };

    if (item.gbn === "C") {
      body.acceptYn = acceptYn;
    }

    const res = await axios.post(url, body);
    console.log(res);

    if (res.status === 200) {
      const action = acceptYn === "Y";
      toast.success(`초대가 ${action ? "수락" : "거절"}되었습니다.`);
      getNotification();
      handleModalClose();

      if (action) {
        if (item.gbn === "G") {
          navigate(`/group/${item.refeNo}`, { state: { display: true } });
        }
        if (item.gbn === "C") {
          dispatch(handleChangeUser({ cmpnyNo: item.refeNo }));
        }
      }
    }
  };

  const settingMenuClose = () => {
    setIsDelete(false);
    setIsSetMenuOpen(false);
  };

  // 선택 삭제
  const deleteSelectedNotis = async () => {
    if (!selectedNotiNos.length) {
      toast.error("선택된 알림이 존재하지 않습니다.");
      return;
    }

    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "N",
      allReadYn: "N",
      notiNos: [...selectedNotiNos],
      acceptYn: null,
      gbnValue: null,
      readYn: "N",
      delYn: "Y",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      toast.success("선택한 알림이 삭제되었습니다.");
      getNotification();
      setSelectedNotiNos([]);
      handleModalClose();
    }
  };

  // 전체 삭제
  const deleteAllNotis = async () => {
    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "Y",
      allReadYn: "N",
      notiNos: [],
      acceptYn: null,
      gbnValue: null,
      readYn: "N",
      delYn: "Y",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      toast.success("전체 알림이 삭제되었습니다.");
      getNotification();
      settingMenuClose();
      handleModalClose();
    }
  };

  // 모두 읽음
  const readAllNotis = async () => {
    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "N",
      allReadYn: "Y",
      notiNos: [],
      acceptYn: null,
      gbnValue: null,
      readYn: "N",
      delYn: "Y",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      getNotification();
      settingMenuClose();
    }
  };

  const handleInviteResponse = (notiItem, acceptYn) => {
    handleModalOpen("inviteRes", { notiItem, acceptYn });
  };

  const ModalForm = ({ type, item }) => {
    const modalDetails = {
      inviteRes: {
        title: item?.acceptYn === "Y" ? "수락" : "거절",
        body: `${item?.acceptYn === "Y" ? "수락" : "거절"}하시겠습니까?`,
        onConfirm: () => inviteResponse(item?.notiItem, item?.acceptYn),
      },
      delConfirm: {
        title: "알림 삭제",
        body: `${item?.deleteType === "selected" ? "선택된" : "모든"} 알림을 삭제하시겠습니까?`,
        onConfirm: item?.deleteType === "selected" ? deleteSelectedNotis : deleteAllNotis,
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => handleModalClose(),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={handleModalClose} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleModalOpen = (type, item) => {
    setModalContent({ type, item });
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const tabs = ["알림", "혜택", "개발자 노트"];

  const tabChange = (idx) => {
    settingMenuClose();
    setActive(idx);
  };

  return (
    <NotifyContainer>
      <div className="notify">
        <NotifyHeader
          isSetMenuOpen={isSetMenuOpen}
          setIsSetMenuOpen={setIsSetMenuOpen}
          settingMenuClose={settingMenuClose}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          readAllNotis={readAllNotis}
          active={active}
        />
        <div className="notifyBody">
          <div className="notifyInner">
            <ul className="notifyTabMenu">
              {tabs.map((item, idx) => (
                <li className={idx === active ? "active" : ""} onClick={() => tabChange(idx)}>
                  {item}
                </li>
              ))}
            </ul>
            {
              [
                <NotifyList
                  notification={notification}
                  readNotification={readNotification}
                  toggleNotiSelection={toggleNotiSelection}
                  selectedNotiNos={selectedNotiNos}
                  isDelete={isDelete}
                  handleInviteResponse={handleInviteResponse}
                />,
                <NotifyBenefit />,
                <NotifyReleaseNotes />,
              ][active]
            }
          </div>
        </div>
      </div>
      {isDelete && (
        <div className="deleteLayer">
          <button onClick={() => handleModalOpen("delConfirm", { deleteType: "selected" })}>
            선택 알림 삭제
          </button>
          <button onClick={() => handleModalOpen("delConfirm", { deleteType: "all" })}>
            전체 알림 삭제
          </button>
        </div>
      )}
      <Modal
        modalState={modalOpen}
        handleModalState={handleModalClose}
        html={<ModalForm type={modalContent?.type} item={modalContent?.item} />}
        w="300px"
        h="auto"
      />
    </NotifyContainer>
  );
};

export default NotifyPage;
