import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";

const Products = ({ cmpnyNo, homepageInfo }) => {
  const swiperRef = useRef(null);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 15,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      setProducts(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const isPrdImg = (item) => {
    if (item.prdImgFlpth) return item.prdImgFlpth;
    if (item.extrlImgUrl) return item.extrlImgUrl;
    return "/img/prdct_02.png";
  };

  return (
    <div className="product">
      <div className="label">
        PRODUCTS
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <div className="products">
        <div className="info">
          상품
          <span>{homepageInfo.cmpnyNm}의 다양한 상품들을 보여드릴게요</span>
        </div>
        <div className="swiperContent">
          <Swiper
            ref={swiperRef}
            className="productSwiper"
            modules={[Pagination]}
            slidesPerView={3}
            spaceBetween={60}
            loop={true}
            speed={2000}
            pagination={{ type: "progressbar" }}>
            {products.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="swiper-wrapper">
                  <div className="productSlide" id={i}>
                    <div className="box">
                      <img
                        src={isPrdImg(item)}
                        onError={(e) => (e.target.src = "/img/prdct_08.png")}
                        alt="Product"
                      />
                      <div className="productInfo">
                        <span>상품1</span>
                        <p>이것은 상품 설명입니다</p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Products;
