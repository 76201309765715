import React from "react";
import { RequestsContainer } from "../../../../styled/pickup";

const Requests = ({ remark }) => {
  return (
    <RequestsContainer>
      <span>{remark}</span>
    </RequestsContainer>
  );
};

export default Requests;
