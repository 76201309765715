import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AiOutlineCheck } from "react-icons/ai";
import { useMediaQuery } from "react-responsive";
import { useRegex } from "../../../../hooks/useRegex";
import { resetSyncBussiness } from "../../../../store/productSyncCode";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrductNmSync,
  setProductSyncPage,
  setProductSyncSelected,
} from "../../../../store/productSync";
import React, { useEffect, useState } from "react";
import axios from "axios";
import uuid from "react-uuid";
import Pagination from "react-js-pagination";
import Modal from "../../../../common/modal";

function ProductSyncBottom({ productSync, setProductSync }) {
  console.log(productSync);

  useEffect(() => {
    getProductSync({ page: 1, selected: 10 });
    dispatch(setProductSyncPage(1));
    dispatch(setProductSyncSelected(10));
    dispatch(resetSyncBussiness());

    return () => {
      dispatch(setPrductNmSync(""));
    };
  }, []);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { MoneyFormat } = useRegex();

  const [openModal, setOpenModal] = useState();
  const [message, setMessage] = useState("");
  const [isSynced, setIsSynced] = useState(false);
  const [completeSync, setCompleteSync] = useState(false);
  const [toggleBtn, setToggleBtn] = useState(true);
  const [brandSort, setBrandSort] = useState("");
  const [prductCl1NmSort, setPrductCl1NmSort] = useState("");
  const [sortIndex, setSortIndex] = useState([false, false, false]);
  const [isCheckSync, setIsCheckSync] = useState(new Array(10).fill(0));
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [imageYn, setImageYn] = useState(false);

  const page = useSelector((state) => state.productSync.page);
  const selected = useSelector((state) => state.productSync.selected);
  const prductNm = useSelector((state) => state.productSync.prductNm);
  const bsnsTy = useSelector((state) => state.productSyncCode.syncBussinessCode);
  const prductCl1 = useSelector((state) => state.productSyncCode.syncLargeCode);
  const prductCl2 = useSelector((state) => state.productSyncCode.syncMiddleCode);
  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const user = useSelector((state) => state?.user?.data[0]);

  const rows = productSync?.content?.map((item) => ({ item }));
  const numChecked = checkedSet.size;

  useEffect(() => {
    if (productSync.length !== 0 || completeSync) {
      checkSync();
    }
  }, [productSync, completeSync]);

  const getProductSync = async ({
    page,
    selected,
    brandSort,
    prductCl1NmSort,
    prductNm,
    bsnsTy,
    prductCl1,
    prductCl2,
  }) => {
    const url = "/api/prdSynth";
    const body = {
      offset: (page - 1) * selected,
      pageNumber: page - 1,
      pageSize: selected,
      paged: true,
      brandSort,
      prductCl1NmSort,
      bsnsTy,
      prductCl1,
      prductCl2,
      delYn: "N",
    };

    if (prductNm) {
      body.prductNm = prductNm;
    }

    const response = await axios.post(url, body);
    setProductSync(response.data);
  };

  const checkSync = () => {
    const url = "/api/prductStock";
    let arr = [];

    Promise.all(
      productSync?.content?.map(async (item) => {
        return await axios.post(url, {
          cmpnyNo,
          prdNo: item.prdNo,
          notitleYn: "Y",
          offset: 0,
          pageNumber: "0",
          pageSize: 1,
          paged: true,
        });
      }),
    ).then((res) => {
      res.forEach((item) => {
        arr.push(item.data.content.length);
        setIsCheckSync([...arr]);
      });
    });
  };

  const handlePageChange = async (page) => {
    await getProductSync({
      page,
      selected,
      prductNm,
      brandSort,
      prductCl1NmSort,
      bsnsTy,
      prductCl1,
      prductCl2,
    });
    setCheckedSet(new Set());
    dispatch(setProductSyncPage(page));
  };

  const handleSelect = async (e) => {
    await getProductSync({
      page: 1,
      selected: Number(e.target.value),
      prductNm,
      brandSort,
      prductCl1NmSort,
      bsnsTy,
      prductCl1,
      prductCl2,
    });
    dispatch(setProductSyncPage(1));
    dispatch(setProductSyncSelected(Number(e.target.value)));
  };

  const handleSyncModal = () => {
    const checkedItems = Array.from(checkedSet);
    if (checkedItems.length === 0) {
      toast.error("연동할 제품을 선택해 주세요.");
      setOpenModal(false);
    } else if (completeSync) {
      setCompleteSync(false);
      setCheckedSet(new Set());
      setOpenModal(!openModal);
    } else {
      setMessage("제품을 연동 하시겠습니까?");
      setIsSynced(!isSynced);
      setOpenModal(!openModal);
    }
  };

  const updateSet = (set, item) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(item)) {
      updatedSet.delete(item);
    } else {
      updatedSet.add(item);
    }
    return updatedSet;
  };

  const handleCheckChange = (item) => {
    setCheckedSet((prevSet) => updateSet(prevSet, item));
  };

  const toggleAllChecked = ({ target: { checked } }) => {
    if (checked || toggleBtn) {
      const allChecked = new Set(rows.map(({ item }) => item));
      setCheckedSet(allChecked);
    } else {
      setCheckedSet(new Set());
    }
    setToggleBtn(!toggleBtn);
  };

  const putProductSync = async () => {
    const checkedItems = Array.from(checkedSet);
    const url = "/api/put/stockSply";
    let stockSplyList = [];
    checkedItems.forEach((item) => {
      const obj = {
        cmpnyNo,
        pdCmPrice: item.cnsmrPc,
        prdNo: item.prdNo,
        salesStatus: "S01",
        splySe: "SIO002",
        qy: 1,
        createMberId: user.userName,
        updateMberId: user.userName,
        gbn: "notitle",
      };
      stockSplyList.push(obj);
    });

    if (imageYn) {
      try {
        const body = {
          stockSplyList,
        };
        console.log(body);
        const res = await axios.post(url, body);
        toast.success("제품이 연동 되었습니다.");
        setOpenModal(false);
        setCompleteSync(true);
        setCheckedSet(new Set());
        setImageYn(false);
      } catch (error) {
        console.log(error);
        setMessage("제품 연동을 실패 했습니다.");
      }
      dispatch(setPrductNmSync(""));
    } else {
      const checkedWithExtrlImage = checkedItems.filter((item) => item.extrlImgUrl);
      const checkedWithRepImage = checkedItems.filter((item) => item.repImg);
      if (checkedWithExtrlImage.length !== 0 || checkedWithRepImage.length !== 0) {
        stockSplyList = new Array();

        if (checkedWithExtrlImage.length > 0) {
          checkedWithExtrlImage.forEach((item) => {
            const dupObj = {
              cmpnyNo,
              pdCmPrice: item.cnsmrPc,
              prdNo: item.prdNo,
              salesStatus: "S01",
              splySe: "SIO002",
              qy: 1,
              createMberId: user.userName,
              updateMberId: user.userName,
              gbn: "notitle",
            };
            stockSplyList.push(dupObj);
          });
        }

        if (checkedWithRepImage.length > 0) {
          checkedWithRepImage.forEach((item) => {
            const dupObj = {
              cmpnyNo,
              pdCmPrice: item.cnsmrPc,
              prdNo: item.prdNo,
              salesStatus: "S01",
              splySe: "SIO002",
              qy: 1,
              createMberId: user.userName,
              updateMberId: user.userName,
              gbn: "notitle",
            };
            stockSplyList.push(dupObj);
          });
        }

        const body = {
          stockSplyList,
        };

        try {
          await axios.post(url, body);
          toast.success("제품이 연동 되었습니다.");
          setOpenModal(false);
          setCompleteSync(true);
          setCheckedSet(new Set());
          setImageYn(false);
        } catch (error) {
          setMessage("제품 연동을 실패 했습니다.");
        }
      } else {
        toast.error("이미지 없는 제품 연동여부를 확인해주세요.");
      }
      dispatch(setPrductNmSync(""));
    }
  };

  useEffect(() => {
    if (completeSync) {
      setCompleteSync(false);
    }
  }, [completeSync]);

  const onLatestSort = async () => {
    await getProductSync({ page: 1, selected });
    setSortIndex([true, false, false]);
    dispatch(setProductSyncPage(1));
    setBrandSort("");
    setPrductCl1NmSort("");
    dispatch(resetSyncBussiness());
  };

  const onBrandSort = async () => {
    await getProductSync({
      page: 1,
      selected,
      brandSort: "ASC",
      prductCl1NmSort: "",
      prductNm,
      bsnsTy,
      prductCl1,
      prductCl2,
    });
    setSortIndex([false, true, false]);
    dispatch(setProductSyncPage(1));
    setBrandSort("ASC");
    setPrductCl1NmSort("");
  };

  const onCategorySort = async () => {
    await getProductSync({
      page: 1,
      selected,
      brandSort: "",
      prductCl1NmSort: "ASC",
      bsnsTy,
      prductCl1,
      prductCl2,
    });
    setSortIndex([false, false, true]);
    dispatch(setProductSyncPage(1));
    setPrductCl1NmSort("ASC");
    setBrandSort("");
  };

  const onSearch = (e) => {
    e.preventDefault();
    getProductSync({
      page: 1,
      selected,
      brandSort: "",
      prductCl1NmSort: "",
      prductNm: e.target.form[0].value,
      bsnsTy,
      prductCl1,
      prductCl2,
    });
    dispatch(setProductSyncPage(1));
    dispatch(setPrductNmSync(e.target.form[0].value));
    setBrandSort("");
    setPrductCl1NmSort("");
  };

  const handleImageYn = (e) => {
    setImageYn(e.target.checked);
  };

  const syncModalForm = (message) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">상품연동</h2>
        <button onClick={handleSyncModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{message}</span>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          <input type="checkbox" onChange={handleImageYn} name="imgCheck" id="imgCheck" />
          <label for="imgCheck">이미지 없는 제품 연동 여부</label>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          <>
            <button
              onClick={handleSyncModal}
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 15px",
                margin: "0px",
                background: "#6C757D",
                fontSize: "16px",
              }}>
              취소
            </button>
            <button
              onClick={putProductSync}
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 15px",
                margin: "0px",
                fontSize: "16px",
              }}>
              확인
            </button>
          </>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <div className="hp_step_product_list" style={{ padding: "0" }}>
          <div className="hp_step_product_btn_wrap">
            <button
              style={{ margin: "0 10px 0 5px" }}
              onClick={handleSyncModal}
              type="button"
              className="product_btn product_btn_sync">
              연동하기
            </button>
            <button
              onClick={toggleAllChecked}
              style={{ margin: "0", background: "#555" }}
              type="button"
              className="product_btn product_btn_sync">
              전체선택
            </button>
            <ul className="product_btn_ul">
              <li style={{ margin: "0px 5px" }} onClick={onLatestSort}>
                <Link>최신순</Link>
              </li>
              <li style={{ margin: "0px 5px" }} onClick={onBrandSort}>
                <Link>브랜드순</Link>
              </li>
              <li style={{ margin: "0px 5px" }} onClick={onCategorySort}>
                <Link>카테고리순</Link>
              </li>
              <li>
                <select key={uuid()} defaultValue={selected} onChange={handleSelect}>
                  <option value={10}>10개 보기</option>
                  <option value={30}>30개 보기</option>
                  <option value={50}>50개 보기</option>
                </select>
              </li>
            </ul>
          </div>
          <div>
            <form onSubmit="return false">
              <input
                style={{ width: "40%", margin: "10px 10px 10px 5px" }}
                className="input_search"
                defaultValue={prductNm}
                type="text"
              />
              <button onClick={onSearch} className="input_50 button_search">
                조회
              </button>
            </form>
          </div>
          <div className="member_search">
            {productSync?.content?.map((item, i) => (
              <>
                <div
                  className="member_search_wrap product_sync_wrap"
                  style={{ minHeight: "377px" }}
                  key={i}>
                  <div>
                    <div className="product_header">
                      <input
                        type="checkbox"
                        checked={checkedSet.has(item)}
                        onChange={() => handleCheckChange(item)}
                      />
                      {isCheckSync[i] ? (
                        <span className="product_p" style={{ background: "#0074ca" }}>
                          연동완료
                        </span>
                      ) : null}
                    </div>
                    <div style={{ width: "100%", minHeight: "180px" }}>
                      {!item?.repImg && !item?.extrlImgUrl ? (
                        <div style={{ width: "100%", paddingBottom: "100%" }} />
                      ) : (
                        <img
                          className="member_logo pdb_20 product_img"
                          src={
                            item?.repImg
                              ? imgHandler(item?.repImg)
                              : item?.extrlImgUrl
                              ? imgHandler(item?.extrlImgUrl)
                              : "/img/icon_function_3.png"
                          }
                        />
                      )}
                    </div>
                    <div>
                      <div style={{ textAlign: "start", marginBottom: "10px" }}>
                        {item?.bsnsTyNm && <p className="product_p">{item?.bsnsTyNm}</p>}
                        {item?.prductCl1Nm && <p className="product_p">{item?.prductCl1Nm} </p>}
                        {item?.brand && <p className="product_p">{item?.brand}</p>}
                      </div>
                      <span
                        className="font_28 font600 font_color_black pdb_10 text_center"
                        style={{ wordBreak: "unset", textAlign: "start" }}>
                        {item?.prductNm}
                      </span>
                      <span
                        className="font_28 font_color_black pdb_10 text_center"
                        style={{ textAlign: "start" }}>
                        {MoneyFormat(item?.cnsmrPc)}원
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ))}
            <div className="hp_step_product_btn_wrap">
              <button
                onClick={handleSyncModal}
                type="button"
                className="product_btn product_btn_sync"
                style={{ margin: "20px 0 0 0" }}>
                연동하기
              </button>
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="hp_step_product_list">
          <div className="hp_step_product_btn_wrap">
            <button
              onClick={handleSyncModal}
              type="button"
              className="product_btn product_btn_sync">
              연동하기
            </button>
            <form onSubmit="return false" style={{ display: "inline" }}>
              <input
                style={{ width: "220px" }}
                className="input_search"
                type="text"
                defaultValue={prductNm}
              />
              <button onClick={onSearch} className="input_50 button_search">
                조회
              </button>
            </form>
            <ul className="product_btn_ul">
              <li onClick={onLatestSort}>
                <Link style={sortIndex[0] ? { textDecoration: "underline" } : {}}>최신순</Link>
              </li>
              <li onClick={onBrandSort}>
                <Link style={sortIndex[1] ? { textDecoration: "underline" } : {}}>브랜드순</Link>
              </li>
              <li onClick={onCategorySort}>
                <Link style={sortIndex[2] ? { textDecoration: "underline" } : {}}>카테고리순</Link>
              </li>
              <li>
                <select key={uuid()} defaultValue={selected} onChange={handleSelect}>
                  <option value={10}>10개 보기</option>
                  <option value={30}>30개 보기</option>
                  <option value={50}>50개 보기</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="hp_step_product_list_wrap">
            <table className="table_sm_list work_place hp_step">
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      onChange={toggleAllChecked}
                      checked={numChecked === rows?.length}
                    />
                  </th>
                  <th scope="col">
                    <span className="text_center">이미지</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">제품명</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">브랜드</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">가격</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">분류</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">카테고리</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">연동여부</span>
                  </th>
                </tr>
              </thead>
              <tbody id="place_tbody">
                {productSync?.content?.map((item, i) => (
                  <tr key={i}>
                    <td className="input_50">
                      <input
                        type="checkbox"
                        checked={checkedSet.has(item)}
                        onChange={() => handleCheckChange(item)}
                      />
                    </td>
                    <td className="input_50">
                      {(item?.repImg || item?.extrlImgUrl) && (
                        <img
                          src={
                            item?.repImg ? imgHandler(item?.repImg) : imgHandler(item?.extrlImgUrl)
                          }
                        />
                      )}
                      {!item?.repImg && !item?.extrlImgUrl && (
                        <div
                          style={{
                            width: "60px",
                            paddingBottom: "60px",
                          }}></div>
                      )}
                    </td>
                    <td className="input_50">
                      <span>{item?.prductNm}</span>
                    </td>
                    <td className="input_100">
                      <a herf="#" className="font_color_black font500 text_center">
                        {item?.brand}
                      </a>
                    </td>
                    <td className="input_50">
                      <span>{MoneyFormat(item?.cnsmrPc)}원</span>
                    </td>
                    <td className="input_50">
                      <span>{item?.bsnsTyNm}</span>
                    </td>
                    <td className="input_50">
                      <span>{item?.prductCl1Nm}</span>
                    </td>
                    <td className="input_50">
                      <span>
                        {isCheckSync[i] ? <AiOutlineCheck size="20" color="#0074ca" /> : null}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="hp_step_product_btn_wrap">
            <button
              onClick={handleSyncModal}
              type="button"
              className="product_btn product_btn_sync">
              연동하기
            </button>
          </div>
        </div>
      )}

      <Pagination
        activePage={page}
        itemsCountPerPage={selected}
        totalItemsCount={productSync.totalElements}
        pageRangeDisplayed={isMobile ? 5 : 10}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={handlePageChange}
      />

      <Modal
        modalState={openModal}
        handleModalState={handleSyncModal}
        html={syncModalForm(message)}
        w={isMobile ? "60%" : "16%"}
        h="auto"
      />
    </>
  );
}
export default ProductSyncBottom;
