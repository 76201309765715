export default function Video() {
  return (
    <div className="section fp-auto-height-responsive" id="section1">
      <span className="font_32 font_color_1 font300 pdb_30">
        {common.tool} <strong>{common.name}</strong>
        {service.mComment1}
      </span>
      <span className="font_18 font_color_gray font400 pdb_40">
        {service.mComment2}
        <br />
        {service.mComment3}
      </span>
      <video autoPlay loop muted poster="/img/main_video_poster.jpg">
        <source src="/img/main_video.mp4" type="video/mp4" />
        <strong>{service.videoError}</strong>
      </video>
    </div>
  );
}
