import React, { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

import GroupHeader from "./components/group/component/common/groupHeader";
import MobileFooter from "./components/group/component/common/mobileFooter.jsx";
import Footer from "./common/footer.jsx";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AppGroup = () => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isMatch, setIsMatch] = useState(false);

  useEffect(() => {
    document.title = "모노티 그룹";
  }, []);

  const checkIfPathIsNumeric = (pathname) => {
    const match = pathname.match(/\/group\/(\d+)$/);
    return match ? true : false;
  };

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
      changeBodyClassName();

      const isNumeric = checkIfPathIsNumeric(pathname);
      setIsMatch(isNumeric);
    }, [pathname]);

    return null;
  }

  return (
    <Provider store={store}>
      <ScrollToTop />
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      <GroupHeader />
      <Outlet />
      {isMatch ? <></> : isMobile ? <MobileFooter /> : <Footer />}
    </Provider>
  );
};

export default AppGroup;
