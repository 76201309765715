import React from "react";
import styled, { keyframes } from "styled-components";

const createCloudAnimation = (width) => keyframes`
  to { background-position: -${width} 0; }
`;

const Cloud = styled.div`
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 500px;
  pointer-events: none;
  opacity: 0.2;
`;

const Cloud1 = styled(Cloud)`
  background-image: url("/img/clouds_2.png");
  animation: ${createCloudAnimation("1000px")} 60s infinite linear;
`;

const Cloud2 = styled(Cloud)`
  background-image: url("/img/clouds_1.png");
  animation: ${createCloudAnimation("1000px")} 55s infinite linear;
`;

const Cloud3 = styled(Cloud)`
  background-image: url("/img/clouds_3.png");
  animation: ${createCloudAnimation("1579px")} 55s infinite linear;
`;

const CloudsContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`;

const Clouds = () => {
  return (
    <CloudsContainer>
      <Cloud1 />
      <Cloud2 />
      <Cloud3 />
    </CloudsContainer>
  );
};

export default Clouds;
