import React, { useEffect, useState } from "react";
import { OnsitePaymentContainer } from "../../../styled/storage";

const OnsitePayment = ({
  transformValue,
  onsitePayments,
  detail,
  checkStorage,
  handleStorageUpdate,
  handleBackButton,
  payType,
  setPayType,
}) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    console.log(onsitePayments);
    const newTotal = onsitePayments.reduce((acc, curr) => acc + curr.price, 0);
    setTotal(newTotal);
  }, [onsitePayments]);

  return (
    <OnsitePaymentContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="onsitePaymentInfo">
        <span>
          현장결제 예약이 <strong>{onsitePayments.length}</strong>건 있습니다.
        </span>
        <span>합계: {total.toLocaleString()}원</span>
      </div>
      <div className="paymentMethod">
        <div className="con">
          <label htmlFor="card">
            <input
              type="radio"
              name="paymentMethod"
              id="card"
              value="1"
              checked={payType === "1"}
              onChange={(e) => setPayType(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <span>카드</span>
          <label htmlFor="cash">
            <input
              type="radio"
              name="paymentMethod"
              id="cash"
              value="2"
              checked={payType === "2"}
              onChange={(e) => setPayType(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
          <span>현금</span>
        </div>
      </div>
      <div className="info">
        결제 수단을 선택하신 후,
        <br />
        결제가 끝나면 확인 버튼을 눌러주세요.
      </div>
      <div className="buttons">
        <button
          onClick={() =>
            handleStorageUpdate({ historyIds: checkStorage, reserveId: detail[0].reserveId }, true)
          }>
          확인
        </button>
        <button onClick={handleBackButton}>취소</button>
      </div>
    </OnsitePaymentContainer>
  );
};

export default OnsitePayment;
