import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { GroupInner, GroupTitle, GroupSection } from "./component/styled/common";
import { CreateGroupContainer } from "./component/styled/group";
import Modal from "../../common/modal";

const CreateGroup = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    groupName: "",
    gbn: "",
    imgUrl: "",
    displayYn: "Y",
  });
  const [image, setImage] = useState();
  const [isGbnChk, setIsGbnChk] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const [isDisplay, setIsDisplay] = useState(true);
  const [isApprovalUse, setIsApprovalUse] = useState(false);
  const [duplGroupName, setDuplGroupName] = useState(false);

  const changeInput = (e) => {
    const { name, value } = e.target;
    if (name === "groupName" && duplGroupName) {
      setDuplGroupName(false);
    }
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 이미지 추가
  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((data) => ({
        ...data,
        imgUrl: imageUrl,
      }));

      setImage(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  // 빈값, 중복 체크
  const checkSubmitCond = () => {
    if (!formData.groupName) {
      toast.error("그룹명을 설정해주세요.");
      return;
    }

    if (!duplGroupName) {
      toast.error("그룹명 중복 확인을 해주세요.");
      return;
    }

    if (isGbnChk && !formData.gbn) {
      toast.error("명칭을 설정해주세요.");
      return;
    }

    handleModalOpen();
  };

  // 저장
  const onSubmit = async () => {
    const url = "/api/group";
    let body = {
      ...formData,
      createMberId: user.userName,
      updateMberId: user.userName,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "N",
      nickUseYn: "Y",
      displayYn: isDisplay ? "Y" : "N",
      approvalUseYn: !isDisplay || isApprovalUse ? "Y" : null,
    };

    if (image) {
      const uploaded = await uploadLogoImage(image);
      if (!uploaded) {
        setModalOpen(false);
        return;
      }
      body.imgUrl = uploaded;
    }

    const emptyValues = Object.keys(body).reduce((acc, key) => {
      acc[key] = !body[key] ? null : body[key];
      return acc;
    }, {});

    body = { ...emptyValues };

    const responses = await axios.put(url, body);
    const res = responses.data;
    if (res.success) {
      toast.success("그룹이 생성되었습니다.");
      if (res.rtnModel.gbn || res.rtnModel.nickUseYn === "Y") {
        signGrp(res.rtnModel.groupNo, true);
      } else {
        signGrp(res.rtnModel.groupNo);
      }
    }
  };

  // 그룹 가입
  const signGrp = async (groupNo, isGbnValue = false) => {
    const url = "/api/groupMber";
    const body = {
      groupNo,
      mberNo: user.mberNo,
      auth: 0,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "N",
    };
    const res = await axios.put(url, body);

    if (res.status === 200) {
      if (isGbnValue) {
        navigate(`/group/${groupNo}`, {
          state: { auth: 0, display: true },
        });
      } else {
        navigate(`/group/${groupNo}`);
      }
    } else {
      toast.error("잠시후 다시 시도해주세요");
    }
  };

  // 그룹명 중복체크
  const checkDuplGroupName = async () => {
    if (!formData.groupName) {
      toast.error("그룹명이 존재하지 않습니다.");
      return;
    }

    const url = "/api/duplGroupName";
    const body = {
      groupName: formData.groupName,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      if (res.data.success) {
        toast.success("사용 가능한 그룹명입니다.");
        setDuplGroupName(true);
      } else {
        toast.error("이미 사용 중인 그룹명입니다.");
        setDuplGroupName(false);
      }
    }
  };

  const handleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">그룹생성</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          그룹을 생성하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onSubmit}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <GroupSection>
      <GroupInner>
        <GroupTitle className="font_48 font600 font_color_black text_center">그룹 생성</GroupTitle>
        <CreateGroupContainer>
          <ul>
            <li>
              <div className="tit">이미지</div>
              <div className="content">
                <div className="file_import">
                  <label>
                    파일찾기
                    <input
                      type="file"
                      id="wk_file"
                      name="logoImage"
                      accept="image/*"
                      onChange={addImage}
                    />
                  </label>
                  <div className="preview">
                    {formData.imgUrl ? (
                      <>
                        <img src={imgHandler(formData.imgUrl)} alt="" />
                        <button
                          className="deleteBtn"
                          onClick={() => {
                            setFormData((data) => ({ ...data, imgUrl: "" }));
                            setImage(null);
                          }}>
                          <IoCloseSharp />
                        </button>
                      </>
                    ) : (
                      <div className="notImage">
                        <span>이미지가 존재하지 않습니다.</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li className="grpName">
              <div className="tit">그룹 이름</div>
              <div className="content">
                <input
                  type="text"
                  name="groupName"
                  value={formData.groupName}
                  onChange={(e) => changeInput(e)}
                />
                <button onClick={checkDuplGroupName}>중복 확인</button>
              </div>
            </li>
            <li>
              <div>
                <input
                  type="checkbox"
                  id="isGbn"
                  checked={isGbnChk}
                  onChange={(e) => setIsGbnChk(e.target.checked)}
                />
                <label htmlFor="isGbn">명칭 사용 여부</label>
              </div>
            </li>
            {isGbnChk && (
              <li>
                <div className="tit">
                  명칭 <em>ex) 기, 급</em>
                </div>
                <div className="content">
                  <input
                    type="text"
                    name="gbn"
                    value={formData.gbn}
                    onChange={(e) => changeInput(e)}
                  />
                </div>
              </li>
            )}
            <li>
              <div className="tit">그룹 설정</div>
              <div className="radio-inputs content">
                <label className="radio">
                  <input
                    type="radio"
                    name="radio"
                    checked={isDisplay}
                    onChange={(e) => setIsDisplay(true)}
                  />
                  <span className="name">전체공개</span>
                </label>
                <label className="radio">
                  <input
                    type="radio"
                    name="radio"
                    checked={!isDisplay}
                    onChange={(e) => setIsDisplay(false)}
                  />
                  <span className="name">비공개</span>
                </label>
              </div>
            </li>
            <li>
              <div>
                <input
                  type="checkbox"
                  id="isApprovalUse"
                  checked={!isDisplay || isApprovalUse}
                  onChange={(e) => setIsApprovalUse(e.target.checked)}
                  disabled={!isDisplay}
                />
                <label htmlFor="isApprovalUse">가입승인제</label>
              </div>
            </li>
          </ul>
          <div className="btnArea">
            <button onClick={checkSubmitCond}>확인</button>
          </div>
        </CreateGroupContainer>
        <Modal
          modalState={modalOpen}
          handleModalState={handleModalOpen}
          html={ModalForm()}
          w={isMobile ? "70%" : "17%"}
          h="auto"
        />
      </GroupInner>
    </GroupSection>
  );
};

export default CreateGroup;
