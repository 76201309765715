import { useMediaQuery } from 'react-responsive';
import ProductSyncTop from "./productSyncTop";
import ProductSyncBottom from "./productSyncBottom";
import { useRegex } from '../../../../hooks/useRegex';

export default function ProductSyncForm({productSync, setProductSync}) {
  const isMobile = useMediaQuery({query: "(max-width:640px)"});
  const { MoneyFormat } = useRegex();
  
  return(
    <div className="hp_step_content">
      <div className="hp_step_product_step">
        <span className="font_16 font400 text_left font_color_gray" style={isMobile ? {fontSize : "18px"} : {}}>
          <i className="fa fa-cubes" aria-hidden="true" /> 
          조회한 제품 총 <strong className="font_color_1 font400">{MoneyFormat(productSync.totalElements)} 개</strong>
          {/* 조회한 클라우드 제품 총 <strong className="font_color_1 font400">{MoneyFormat(productSync.totalElements)} 개</strong> */}
        </span>
        <form method="post">
          {/*연동제품 시작*/}
          <div className="hp_step_product_wrap">
            <ProductSyncTop setProductSync={setProductSync}/>
            <ProductSyncBottom productSync={productSync} setProductSync={setProductSync}/>
          </div>
          {/*//연동제품 끝*/}
        </form>
      </div>
    </div>
  )
}