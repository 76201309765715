import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HomePageSettingMenu from "../homepageSettingMenu";
import RegisterMenu from "./registerMenu";
import RegisterTitle from "./registerTitle";
import BoardWrite from "../../../../common/board/boardWrite";
import CustomModal from "../../../../common/customModal";
import Modal from "../../../../common/modal";
import { setRegisterIndex } from "../../../../store/compoIndex";

export default function RegisterNoticeWriteForm () {
  const navigater = useNavigate();
  const location = useLocation();
  const index = useSelector((state) => state.compoIndex.register);
  const user = useSelector((state) => state?.user?.data[0]);
  const isEdit = location?.state?.isEdit;
  const notice = location?.state?.notice;

  const titleRef = useRef();
  const contentRef = useRef();
  const selectList = ["공지", "일반", "상품안내", "포트폴리오","결제 안내"];
  const [selected, setSelected] = useState(notice?.noticeCl || "공지");
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    const url = window.location.href
    if(url.includes("register")){
      dispatch(setRegisterIndex(3))
    }
  }, [])

  const saveNotice = async () => {
    const req = {
      cmpnyNo: user?.cmpnyNo,
      noticeSj: titleRef.current.value,
      noticeCn: contentRef.current.value,
      noticeCl: selected,
      delYn: "N",
      createMberId : user?.userName,
      updateMberId : user?.userName,
      gbn: "N"
    }

    if(isEdit){
      req.noticeNo = notice?.noticeNo;
    }

    const response = await axios.put("/api/notice", req);
    if(response.data.success === true){
      if(isEdit){
        navigater("/mypage/register", {state : {noticeEdit : true}});
      }else{
        navigater("/mypage/register", {state : {noticeUpload : true}});
      }
    }
  }

  const openModal = () => {
    if(!checkValidation()){
      return;
    }
    setModalOpen(true);
  }

  const checkValidation = () => {
    if(!titleRef.current.value){
      toast.error(`제목을 입력해 주세요.`);
      return false;
    }
    if(!contentRef.current.value){
      toast.error(`내용을 입력해 주세요.`);
      return false;
    }
    return true;
  }

  return (
    <div className="inner_content">
      <HomePageSettingMenu />
      <RegisterTitle />
      <RegisterMenu index={index}/>
      <div className="inner_content_wrap">
        <BoardWrite 
          selected={selected}
          selectList={selectList}
          titleRef={titleRef}
          contentRef={contentRef}
          setSelected={setSelected}
          notice={notice}
        />
        <div className="btnarea_center pdt_40 port_btn_wrapper">
          <button 
            style={{border : "none"}} 
            onClick={() => navigater("/mypage/register")} 
            className="btn_normal_cancel port_btn"
          >
            <span>취소</span>
          </button>
          <button 
            style={{border : "none"}} 
            className="btn_normal port_btn" 
            onClick={openModal}
          >
            <span>{isEdit ? "수정" : "등록"}</span>
          </button>
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={<CustomModal 
          title={`공지사항 ${isEdit ? "수정" : "등록"}`} 
          content={`공지사항을 ${isEdit? "수정" : "등록"} 하시겠습니까?`} 
          handleConfirm={saveNotice} 
          handleCancel={() => setModalOpen(false)} 
        />}
        w="300px"
        h="auto"
      />
    </div>
  )
}
