import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import 
{ resetBussiness,
  resetLarge,
  setBussinessCategory,
  setBussinessCode,
  setDetailCategory,
  setIsClick,
  setLargeCategory,
  setLargeCode,
  setMiddleCategory,
  setMiddleCode,
  setSmallCategory } from '../../../../store/productCode';
import { setProductPage } from '../../../../store/product';
import ProductCategory from "../productCategory";

export default function ProductTop ({setProduct}) {
  useEffect(() => {
    getBussiness();
  }, []);
  
  const dispatch = useDispatch();
  const bussinessCode = useSelector((state) => state.productCode.bussinessCode);
  const largeCode = useSelector((state) => state.productCode.largeCode);
  const middleCode = useSelector((state) => state.productCode.middleCode);
  const bussinessCategory = useSelector((state) => state.productCode.bussinessCategory);
  const largeCategory = useSelector((state) => state.productCode.largeCategory);
  const middleCategory = useSelector((state) => state.productCode.middleCategory);
  const smallCategory = useSelector((state) => state.productCode.smallCategory);
  const detailCategory = useSelector((state) => state.productCode.detailCategory);
  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const selected = useSelector((state) => state.product.selected);
  const isClick = useSelector((state) => state.productCode.isClick);
  
  const getProduct = async ({page, selected, bsnsTy}) => {
    const url = '/api/prductStock';
    const body = {
      offset : (page - 1) * selected,
      pageNumber : page - 1,
      pageSize : selected,
      paged : true,
      cmpnyNo,
      notitleYn: "Y",
      bsnsTy
    };
  
    const response = await axios.post(url, body);
    setProduct(response.data);
  };

  const getBussiness = async() => {
    const url = '/api/code';
    const body = {
      clCode: "BTY"
    };

    const response = await axios.post(url, body);
    dispatch(setBussinessCategory(response.data));
  };

  const getLargeCategory = async (e) => {
    if(e.target.value === "업종") {
      await getProduct({page : 1, selected});
      dispatch(setProductPage(1));
      dispatch(resetBussiness());
      return;
    };

    const url = '/api/clCode';
    const body = {
      upperClCode : "PCL"
    };

    const response = await axios.post(url, body);
    await getProduct({page : 1, selected, bsnsTy : e.target.value});

    if(e.target.value === "BTY005") {
      dispatch(setLargeCategory(response.data));
    } else {
      dispatch(setLargeCategory([]));
      dispatch(resetLarge());
    };

    dispatch(setProductPage(1));
    dispatch(setBussinessCode(e.target.value));
    dispatch(setIsClick([true, false, false, false]));
  };

  const getMiddleCategory = async (e) => {
    if(e.target.value === "대분류") {
      await getProduct({page : 1, selected, bsnsTy : bussinessCode});
      dispatch(setProductPage(1));
      dispatch(resetLarge());
      return;
    };

    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    const response = await axios.post(url, body);

    dispatch(setProductPage(1));
    dispatch(setLargeCode(e.target.value));
    dispatch(setMiddleCategory(response.data));
    dispatch(setIsClick([true, true, false, false]));
  };

  const getSmallCategory = async (e) => {
    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    const response = await axios.post(url, body);

    dispatch(setMiddleCode(e.target.value));
    dispatch(setSmallCategory(response.data));
    dispatch(setIsClick([true, true, true, false]));
  };

  const getDetailCategory = async (e) => {
    const url = '/api/clCode';
    const body = {
      upperClCode: e.target.value
    };

    const response = await axios.post(url, body);
    dispatch(setDetailCategory(response.data));
    dispatch(setIsClick([true, true, true, true]));
  };
  
  return (
    <ProductCategory 
      getLargeCategory={getLargeCategory} 
      getMiddleCategory={getMiddleCategory}
      getSmallCategory={getSmallCategory}
      getDetailCategory={getDetailCategory}
      bussinessCode={bussinessCode}
      largeCode={largeCode}
      middleCode={middleCode}
      bussinessCategory={bussinessCategory}
      largeCategory={largeCategory}
      middleCategory={middleCategory}
      smallCategory={smallCategory}
      detailCategory={detailCategory}
      isClick={isClick}
    />
  )
}