import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

export default function HomePage({ openNewTab }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const handleNavigate = async (e) => {
    e.preventDefault();

    const url = "/api/cmpny";
    const body = { cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const data = res.data;

      if (data.bizrno) {
        navigate("/mypage/company");
      } else {
        navigate("/mypage/companyInfo");
      }
    } else {
    }
  };
  return (
    <div className="mypage_1">
      <span className="page_title">홈페이지</span>
      <div className="float_wrap">
        <div className="mypage_btn_wrap div_width_50 homepage_wrap">
          <Link onClick={openNewTab}>
            홈페이지 바로가기
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          <Link to="/mypage/homepage">
            홈페이지 관리
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
          <Link onClick={(e) => handleNavigate(e)}>
            기업관리
            <i className="fa fa-angle-right" aria-hidden="true" />
          </Link>
        </div>
        <div className="div_width_50 homepage_wrap2">
          <span className="font_18 font600 font_color_black pdb_20">※ 메뉴 안내</span>
          <span className="font_18 font300 font_color_gray">
            홈페이지 바로가기 : 생성한 홈페이지로 이동
          </span>
          <span className="font_18 font300 font_color_gray">
            홈페이지 관리 : 홈페이지 화면 및 메뉴설정, 제품연동
          </span>
          <span className="font_18 font300 font_color_gray">
            기업정보 : 기업의 기본 정보 확인 및 수정
          </span>
        </div>
      </div>
    </div>
  );
}
