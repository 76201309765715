import styled from "styled-components";

export const CuteContainer = styled.main`
  min-height: 100vh;
  font-family: "Pretendard";
  /* common */
  .dot {
    width: 25px;
    height: 25px;
    background: #ffc96f;
    border-radius: 50%;
  }
  .label {
    align-items: center;
    display: flex;
    font-size: 56px;
    font-weight: 700;
    gap: 20px;
    max-width: 1400px;
    margin: 0 auto 60px;
  }
  .info {
    font-size: 56px;
    font-weight: 700;
    text-align: center;
    span {
      font-size: 24px;
      font-weight: normal;
      display: block;
      margin: 30px 0 60px;
    }
  }
  .banner {
    height: 650px;
    background: #ffc96f;
    .banners {
      height: 750px;
      width: 1300px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  /* common */
  .introduce {
    margin: 270px auto 170px;
    max-width: 1400px;
    .introduceContent {
      height: 700px;
      position: relative;
      .bg {
        z-index: 1;
        width: 1160px;
        height: 100%;
        background: #fff9f9;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .mySwiper {
        width: 100%;
        z-index: 2;
        height: 400px;
        position: absolute;
        left: 0;
        bottom: 90px;
        .box {
          display: flex;
          align-items: center;
          gap: 65px;
          .viewarea {
            width: 550px;
            height: 400px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .content {
            span {
              display: block;
            }
            .tit {
              font-size: 56px;
              font-weight: 700;
              padding-bottom: 42px;
              margin-bottom: 62px;
              position: relative;
              &::after {
                content: "";
                width: 265px;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #ffc96f;
              }
            }
            .con {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .product {
    margin: 120px 0;
    .label {
      justify-content: end;
    }
    .products {
      padding: 130px 0;
      background: #fff9f9;
      .swiperContent {
        position: relative;
        max-width: 1400px;
        margin: auto;
      }
      .productSwiper {
        height: 610px;
        .swiper-slide {
          &:nth-child(even) .productSlide {
            padding-bottom: 90px;
          }
          &:nth-child(odd) .productSlide {
            padding-top: 70px;
            padding-bottom: 20px;
          }
        }
        .productSlide {
          height: 100%;
          .box {
            background: #fff;
            height: 100%;
            width: 100%;
            padding: 30px;
            img {
              width: 100%;
              height: calc(100% - 86px);
              object-fit: cover;
            }
            .productInfo {
              margin-top: 20px;
              height: 66px;
              span {
                font-size: 24px;
              }
              p {
                font-size: 20px;
                color: #555555;
              }
            }
          }
        }
        .swiper-pagination-progressbar {
          background: rgba(0, 0, 0, 0.1);
          height: 4px;
          top: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        .swiper-pagination-progressbar-fill {
          background: #ffc96f;
          height: 100%;
        }
      }
    }
  }
  .portfolio {
    margin: 120px 0;
    .content {
      max-width: 1400px;
      margin: 0 auto;
      width: 100%;
      position: relative;
      .productSwiper {
        height: 503px;
      }
      .box {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 50px;
        border: none;
        z-index: 10;
        background: transparent;
        svg {
          display: block;
          path {
            stroke: #ffc96f;
          }
        }
        &.prev {
          left: -100px;
        }
        &.next {
          right: -100px;
        }
      }
    }
    .more {
      height: 50px;
      display: flex;
      align-items: center;
      max-width: 1400px;
      margin: 70px auto 0;
      .line {
        width: calc(100% - 130px);
        height: 1px;
        background: #ffcf80;
      }
      button {
        width: 130px;
        height: 100%;
        border-radius: 50px;
        font-size: 20px;
        border: 1px solid #ffcf80;
        background: transparent;
        font-weight: 700;
      }
    }
  }
  .news {
    position: relative;
    max-width: 1400px;
    margin: 170px auto;
    .label {
      justify-content: flex-end;
    }
    .newsContent {
      width: 100%;
      .box {
        display: inline-block;
        width: calc((100% - 60px) / 4);
        margin-right: 20px;
        background: #fff9f9;
        &:last-child {
          margin-right: 0;
        }
        .newsInfo {
          width: 100%;
          padding: 20px 0;
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 20px;
            &:nth-child(1) {
              font-size: 24px;
              font-weight: 600;
            }
            &:nth-child(2) {
              padding: 20px;
              font-size: 16px;
              color: #6a6a6a;
              font-weight: 600;
            }
            &:last-child {
              padding-top: 20px;
              border-top: 1px solid #ffcf80;
              font-size: 16px;
              color: #6a6a6a;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
`;
