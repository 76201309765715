import React from "react";
import { DetailsInfoContainer } from "../../../styled/pickup";
import { orderType } from "../../../function/mutualBenefit";

const DetailsInfo = ({ selected, viewType, handleModalOpen }) => {
  console.log(selected);
  const renderActions = (viewType) => {
    switch (viewType) {
      case "completedOrders":
        return null;

      case "inProgress":
        return (
          <div className="actions">
            <button>취소</button>
            <div className="notify">
              <button>음식완료 알림전송</button>
            </div>
            <button>완료</button>
          </div>
        );

      case "orders":
        return (
          <div className="actions">
            <button onClick={() => handleModalOpen("orderRejected")}>거절</button>
            <div className="stepper">
              <button>
                <i className="xi-caret-down"></i>
              </button>
              <span className="current">15~30분</span>
              <button>
                <i className="xi-caret-up"></i>
              </button>
            </div>
            <button>승낙</button>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <DetailsInfoContainer>
      {selected ? (
        <>
          <div className="info">
            <div className="infoImage">
              <img src={`/img/mutualBenefit/${orderType[selected.type].imgPath}.png`} />
            </div>
            <div className="infoText">
              <span>{`${orderType[selected.type].label} ${selected.orderId}`}</span>
              <span>
                메뉴 {selected.orderDetails.length}개 - 총{" "}
                {selected.price ? selected.price.toLocaleString() : 0}원 (결제완료)
              </span>
            </div>
          </div>
          {renderActions(viewType)}
        </>
      ) : (
        <strong>현재 들어온 주문이 없습니다.</strong>
      )}
    </DetailsInfoContainer>
  );
};

export default DetailsInfo;
