import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import moment from "moment";
import Pagination from "react-js-pagination";

import KioskHeader from "./component/common/kioskHeader";
import KioskFooter from "./component/common/kioskFooter";
import KioskTable from "./component/common/kioskTable";
import KioskList from "./component/common/kioskList";

import { KioskResStatus } from "./component/styled/resStatus";

import { formatText, useDisableScroll } from "./component/function/kiosk";
import { toast } from "react-toastify";
import DetailsUseInquiry from "./component/kioskResStatus/detailsUseInquiry";
import { Container } from "./component/styled/common";

const KioskRreservationStatusPage = () => {
  useDisableScroll();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [data, setData] = useState([]);
  const [searched, setSearched] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getEnableStorage(page - 1);
  }, [page]);

  const getEnableStorage = async (current = 0) => {
    const url = "/api/reserve-history";
    const body = {
      page: current,
      size: isTablet ? 6 : 10,
    };
    if (searched) {
      body.searchQuery = searched;
    }
    const res = await axios.post(url, body);

    if (res.status === 200) {
      console.log(res.data);
      setTotal(res.data.totalElements);
      setData(res.data.content);
    }
  };

  const getEnableStorageDetail = async (id) => {
    const url = "/api/reserve-user-detail";
    const body = { id };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      return res.data;
    }
  };

  const handleModalOpen = async (item) => {
    const detail = await getEnableStorageDetail(item.id);
    setSelected(detail);

    if (detail && detail.length) {
      setOpenModal(true);
    }
  };

  return (
    <Container>
      <KioskResStatus>
        <KioskHeader
          title={"이용내역조회"}
          handleSearch={getEnableStorage}
          searched={searched}
          setSearched={setSearched}
        />
        <div
          className="content"
          style={{ height: isTablet ? "calc(100% - 25vh)" : "calc(100% - 9vh - 9vw)" }}>
          {isTablet ? (
            <KioskList data={data} type="resStatus" handleModalOpen={handleModalOpen} />
          ) : (
            <KioskTable data={data} type="resStatus" handleModalOpen={handleModalOpen} />
          )}
        </div>
        <Pagination
          activePage={page}
          itemsCountPerPage={isTablet ? 6 : 10}
          totalItemsCount={total}
          pageRangeDisplayed={isTablet ? 5 : 10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={(activePage) => {
            setPage(activePage);
            getEnableStorage(activePage - 1);
          }}
        />
        <KioskFooter />
        {openModal && <DetailsUseInquiry selected={selected} setOpenModal={setOpenModal} />}
      </KioskResStatus>
    </Container>
  );
};

export default KioskRreservationStatusPage;
