import axios from "axios";
import React, { useEffect, useState } from "react";
import { GoArrowUpRight } from "react-icons/go";

const Portfolio = ({ cmpnyNo }) => {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getPortfolio(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getPortfolio = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 2,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      setPortfolio(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="portfolio">
      <div className="info">
        Portfolio
        <span>더 많은 상품은 화살표를 눌러주세요.</span>
      </div>
      <div className="inner">
        <div className="left">
          <img src={imgHandler(portfolio[0]?.imgUrl)} />
        </div>
        <div className="right">
          <button>
            <GoArrowUpRight />
          </button>
          <div className="portfolioInfo">
            <span className="tit">LED 베이직비솔 네모형 50W 전구색</span>
            <span className="content">
              여기는 상품설명입니다.
              <br />
              여기는 상품설명입니다.
              <br />
              여기는 상품설명입니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
