import styled from "styled-components";

export const SalesCheckContainer = styled.div`
  .sidemenu {
    font-size: 26px;
    .store {
      width: 100%;
      padding: 20px 30px 40px 30px;
      font-weight: 700;
      span {
        margin-bottom: 20px;
        display: block;
      }
      .selected {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 38px;
        text-align: center;
        padding: 0 30px;
        gap: 10px;
        .icon {
          font-size: 25px;
          svg {
            display: block;
          }
        }
        select {
          border: none;
          width: calc(100% - 35px);
          background: url(/img/arrow_down.png) 100% center no-repeat;
          padding-left: 5px;
          &:focus {
            outline: none;
            border: none !important;
            box-shadow: none;
          }
        }
      }
    }
    .tabs {
      ul li {
        height: 108px;
        display: flex;
        align-items: center;
        gap: 30px;
        padding: 0 0 0 33px;
        font-weight: 700;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        margin: 20px;
        border-radius: 20px;
        &.active {
          background: #255dac;
          color: #fff;
        }
        .icon {
          width: 50px;
          height: 50px;
          display: inline-block;
          touch-action: none;
          font-weight: 700;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .viewer {
    height: calc(100% - 100px);
    .sortOptions {
      text-align: right;
      height: 35px;
      line-height: 35px;
      padding: 0 30px;
      button {
        border: none;
        background: transparent;
        font-size: 24px;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .container {
      flex-direction: column;
      .content:not(.pickup) {
        width: 100%;
        height: calc(100% - 173px);
        padding: 0 20px 20px 20px;
        .viewer {
          height: 100%;
          .label {
            font-size: 20px;
            height: 25px;
            span {
              font-size: 20px;
            }
          }
          .viewerContiner {
            height: calc(100% - 200px);
            padding: 15px;
          }
        }
      }
      .sidemenu {
        width: 100%;
        height: 173px;
        .store {
          padding: 20px;
          > span {
            display: none;
          }
          .selected {
            width: calc((100% - 60px) / 4);
            height: 58px;
            font-size: 16px;
            padding: 0 20px;
            select {
              background-size: 10px;
            }
            .icon {
              width: 20px;
              height: 20px;
              font-size: 20px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
        .tabs {
          padding: 0 20px 20px 20px;
          ul {
            display: flex;
            align-items: center;
            gap: 20px;
            li {
              width: calc((100% - 60px) / 4);
              overflow: hidden;
              font-size: 20px;
              margin: 0;
              padding: 0;
              justify-content: center;
              height: 55px;
              gap: 15px;
              .icon {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      .sidemenu {
        background: #fff;
        height: 84px;
        .store {
          padding: 10px 20px;
          .selected {
            padding: 0 10px;
            font-size: 12px;
            height: 34px;
            width: 130px;
            select {
              background-image: none;
            }
          }
        }
        .tabs {
          padding: 0 20px;
          ul {
            gap: 0;
            li {
              box-shadow: none;
              width: 25%;
              height: 30px;
              font-size: 12px;
              border-radius: 0;
              border-bottom: 3px solid transparent;
              .icon {
                display: none;
              }
              &.active {
                background: none;
                color: #000;
                border-bottom: 3px solid #255dac;
              }
            }
          }
        }
      }
      .content:not(.pickup) {
        height: calc(100% - 84px);
        padding: 0;
        .viewer {
          background: transparent;
          box-shadow: none;
          .salesCheckBox {
            height: 25px;
            display: flex;
            width: 100%;
            padding: 0 20px;
            justify-content: space-between;
            align-items: center;
            .search {
              width: calc(100% - 120px);
              height: 100%;
              margin-left: 0;
              font-size: 12px;
              position: relative;
              input[type="text"] {
                width: 100%;
                height: 100%;
                border: 1px solid #ccc;
                padding: 2px 20px 2px 5px;
                border-radius: 10px;
                &:focus {
                  border: 2px solid #d8ad5f !important;
                  box-shadow: 0 0 0 0.2rem #ffcf78;
                }
              }
              button {
                position: absolute;
                top: 50%;
                right: 5px;
                transform: translateY(-50%);
                border: none;
                background: transparent;
                font-size: 15px;
                svg {
                  display: block;
                }
              }
            }
          }
          .sortOptions {
            height: 20px;
            line-height: 20px;
            padding: 0;
            button {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;

export const PendingCountContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  border-radius: 30px;
  transition: 0.3s;
  margin-bottom: 30px;
  &.hasPending {
    background: #f34e5b;
    border: 1px solid #f34e5b;
    color: #fff;
  }
  &.noPending {
    background: #fff;
    border: 1px solid #000;
    color: #000;
  }
  .info {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    .icon {
      font-size: 40px;
      display: flex;
    }
    span {
      display: block;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 70px);
    }
  }
  @media screen and (max-width: 1050px) {
    position: absolute;
    width: calc(75% - 30px);
    height: 50px;
    top: 125px;
    right: 20px;
    font-size: 16px;
    padding: 0 30px;
    .info {
      gap: 10px;
      .icon {
        font-size: 24px;
      }
      span {
        max-width: calc(100% - 34px);
      }
    }
  }

  @media screen and (max-width: 767px) {
    height: 34px;
    top: 60px;
    right: 20px;
    font-size: 12px;
    width: calc(100% - 180px);
    padding: 0 14px;
    .info {
      span {
        font-weight: normal;
        max-width: calc(100% - 24px);
      }
      .icon {
        font-size: 14px;
      }
    }
  }
`;

export const SalesCheckInfoContainer = styled.div`
  .info {
    padding: 0 30px;
    border-bottom: 1px solid #666666;
    height: 70px;
    font-weight: 700;
    position: relative;
    line-height: 70px;
    .total {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 30px;
      line-height: 32px;
      height: 32px;
    }
    .search {
      display: inline-block;
      width: calc(100% - 400px);
      max-width: 400px;
      position: relative;
      margin-left: 40px;
      input[type="text"] {
        width: 100%;
        border: 1px solid #ccc;
        padding: 5px 40px 5px 5px;
        border-radius: 10px;
        &:focus {
          border: 2px solid #d8ad5f !important;
          box-shadow: 0 0 0 0.2rem #ffcf78;
        }
      }
      button {
        display: flex;
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        border: none;
        background: transparent;
      }
    }
  }
  .date {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 85px;
    padding: 0 30px;
    > span {
      width: 15px;
    }
    button {
      border: none;
      display: inline-flex;
      background: transparent;
    }
    .start,
    .end {
      width: calc((100% - 15px) / 2);
      max-width: 400px;
      text-align: center;
      border: 1px solid #666666;
      padding: 5px 0;
      border-radius: 5px;
      position: relative;
      span {
        font-weight: 700;
      }
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .start button {
      right: 30px;
    }
    .end button {
      left: 30px;
    }
  }
  @media screen and (max-width: 1050px) {
    .info {
      font-size: 22px;
      .total {
        height: 27px;
        line-height: 27px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      font-size: 16px;
      height: 40px;
      line-height: 40px;
      margin: 0 20px;
      padding: 0;
      .total {
        right: 0;
      }
    }
    .date {
      padding: 10px 20px;
      height: 45px;
      gap: 10px;
      .start,
      .end {
        font-size: 12px;
        padding: 0;
        height: 25px;
        line-height: 23px;
        button {
          font-size: 16px;
        }
      }
      .start button {
        right: 10px;
      }
      .end button {
        left: 10px;
      }
    }
  }
`;

export const CalendarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  .calendar {
    position: absolute;
    font-size: 16px;
    background: #fff;
    padding: 20px 10px;
    height: 350px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    .fc {
      a {
        color: #ff3b30;
      }
      .fc-toolbar {
        justify-content: space-evenly;
        color: #ff3b30;
      }
      .fc-toolbar-title {
        font-size: 18px;
      }
      .fc-button {
        background: transparent;
        border: none;
        color: #000;
        box-shadow: none;
      }
      .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
        min-height: auto;
      }
      .fc-daygrid-day-top {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .fc-daygrid-day.fc-day-today {
        background: transparent;
        .fc-daygrid-day-top a {
          border-radius: 50%;
          background: #ffe7e6;
          color: #ff3b30;
          padding: 10px;
        }
      }
    }
    .fc-theme-standard .fc-scrollgrid {
      border: none;
    }
    .fc-theme-standard td,
    .fc-theme-standard th {
      border: none;
    }
  }
`;

export const SalesListTable = styled.div`
  height: calc(100% - 190px);
  padding: 20px 30px;
  &.settlementForm {
    height: calc(100% - 240px);
  }
  ul {
    border: 1px solid #ddd;
    max-height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    li {
      &:first-child {
        background: #ddd;
        font-weight: 700;
        font-size: 18px;
      }
      padding: 20px 0;
      font-size: 16px;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: space-between;
      div {
        display: inline-block;
      }
      .date,
      .tel,
      .status {
        width: 15%;
      }
      .details {
        width: 20%;
      }
      .name {
        width: 10%;
      }
      .status {
        span {
          display: inline-block;
          padding: 5px 10px;
          border-radius: 5px;
          font-weight: 700;
          &.pending {
            color: #f1541d;
            background: #fef6f4;
          }
          &.completed {
            color: #429b8c;
            background: #e7f5f2;
          }
        }
      }
      .period,
      .account {
        width: 20%;
      }

      .amount {
        width: 15%;
      }
      .more,
      .check {
        width: 5%;
        min-height: 1px;
        button {
          font-size: 24px;
          border: none;
          background: transparent;
          svg {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 110px);
    padding: 10px 20px;
    &.settlementForm {
      height: calc(100% - 170px);
    }
    ul {
      border: none;
      li {
        font-size: 14px;
        border: 1px solid #666;
        margin-bottom: 10px;
        border-radius: 5px;
        padding: 15px 10px;
        flex-direction: column;
        align-items: baseline;
        position: relative;
        gap: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        &:first-child {
          background: transparent;
          font-weight: normal;
          font-size: 14px;
        }
        .box {
          width: 100%;
          text-align: left;
          span {
            &.tit {
              width: 60px;
              font-weight: 700;
              padding: 0;
              display: inline-block;
            }
            &.con {
              width: calc(100% - 80px);
              padding: 0;
              display: inline-block;
            }
          }
          &.status .con span {
            padding: 0px 10px;
          }
        }
        label {
          position: absolute;
          bottom: 15px;
          right: 10px;
        }
        .more {
          position: absolute;
          top: 15px;
          right: 10px;
        }
      }
    }
  }
`;

export const StatisticsContainer = styled.div`
  height: calc(100% - 195px);
  padding: 30px;
  .selectChart {
    margin-bottom: 30px;
    font-size: 18px;
    span {
      margin-right: 65px;
      font-weight: 700;
    }
    select {
      width: 200px;
      border: none;
      border-bottom: 1px solid #939393;
      color: #9d9d9d;
      padding: 5px;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none !important;
        border-bottom: 1px solid #000 !important;
      }
    }
  }
  .chart {
    width: 100%;
    height: calc(100% - 64px);
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 20px 10px 20px;
    height: calc(100% - 85px);
    .selectChart {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 24px;
      span {
        margin-right: 0;
      }
      select {
        width: 100px;
        font-size: 14px;
        padding: 0 5px 5px;
      }
    }
    .chart {
      height: calc(100% - 34px);
    }
  }
`;

export const PaymentTotalContainer = styled.div`
  height: 50px;
  font-weight: 700;
  text-align: right;
  padding: 0 30px;
  width: 100%;
  @media screen and (max-width: 1050px) {
    font-size: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;
