import axios from "axios";

import { Provider } from "react-redux";
import { store } from "./store/store";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ShoppingHeader from "./components/shopping/header/shoppingHeader";
import ShoppingFooter from "./components/shopping/shoppingFooter";
import MobileFooter from "./components/shopping/mobileFooter";

import styled from "styled-components";
import FooterScorllTopBtn from "./components/shopping/common/FooterScorllTopBtn";
import { useMediaQuery } from "react-responsive";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AppS = () => {
  const { url } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [cmpnyNo, setcmpnyNo] = useState("");
  const [homepageInfo, setHomepageInfo] = useState("");
  const [isMatch, setIsMatch] = useState(false);
  const [theme, setTheme] = useState("A");

  const urls = ["/shop/login", "/shop/login/", "/shop/cart", "/shop/cart/"];
  const mainUrls = ["/shop/", "/shop"];

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      if (!pathname.includes("/company")) {
        window.scrollTo(0, 0);
      }

      changeBodyClassName();

      if (urls.includes(pathname) || mainUrls.includes(pathname)) {
        setIsMatch(true);
      }
    }, [pathname]);

    return null;
  }

  useEffect(() => {
    if (url) {
      checkedDomain();
    }
  }, [url]);

  useEffect(() => {
    if (cmpnyNo) {
      getMySetting();
    }
  }, [cmpnyNo]);

  // 회사번호 조회
  const checkedDomain = async () => {
    const requestUrl = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      domain: url,
    };

    const response = await axios.post(requestUrl, body);
    setcmpnyNo(response.data.cmpnyNo);
  };

  // 홈페이지 정보 조회
  const getMySetting = async () => {
    const url = "/api/infoSettingLoad";
    const request = {
      cmpnyNo,
      delYn: "N",
    };
    const response = await axios.post(url, request);
    if (!response.data) {
      response.data = {};
      response.data.cmpnyNo = cmpnyNo;
    }
    document.title = response.data.logoText ?? response.data.cmpnyNm;
    setHomepageInfo(response.data);
  };

  return (
    <Provider store={store}>
      <ScrollToTop />
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      <div className="sub_wrap">
        <ShoppingHeader
          url={url}
          homepageInfo={isMatch ? null : homepageInfo}
          theme={isMatch ? null : theme}
        />
        <Outlet context={{ cmpnyNo, url, homepageInfo: isMatch ? null : homepageInfo, theme }} />
        <FooterScorllTopBtn />
        {isMobile ? (
          <MobileFooter url={url} />
        ) : (
          <ShoppingFooter homepageInfo={isMatch ? null : homepageInfo} />
        )}
      </div>
    </Provider>
  );
};

export default AppS;
