import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router';
import { Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import * as s from './styled'
import "./shopPort.css";

const PortfolioViewPage = () => {
  const isMobile = useMediaQuery({query: "(max-width:767px)"});
  const [portfolio, setPortfolio] = useState([]);
  const params = useParams();
  const portNo = params.portNo;
  const {url} = useOutletContext();
  const apiUrl = '/api/portMgmtList';
  const location = useLocation();
  const searchQuery = location?.state?.keyword || "";
  const pageQuery = location?.state?.page || 0;
  const moveToUrl = 
  searchQuery ? `/shop/${url}/search?keyword=${searchQuery}` : 
  `/shop/${url}/portfolio?page=${pageQuery}`;

  useEffect(() => {
    getPortfolio();
  }, [])

  const getPortfolio = async () => {
    const body = {
      portNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true
    }
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    const htmlString = res[0]?.contents;
    const contents = document.getElementById("contents");
    contents.innerHTML = htmlString;
    setPortfolio(res[0]);
  }

  return (
    <main id="contProductsList" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01"></div>
    
      <article className={`boundary ${isMobile ? "" : "pdt_70"} pdb_40`}>
        <Row style={{ marginTop: "2rem" }}>
          <div className='shop_port_title_wrapper'>
            <span className='shop_port_title font_24 font600'>{portfolio.title}</span>
          </div>
          <s.MenuWrapper>
            <section
              id="secBrdcrmb" 
              className="font_14 pdb_20 text_right shop_port_menu" 
              style={isMobile ? {width : "100%"} : {}}
            >
              <div style={{display : "flex", gap : "12px"}}>
                <span className="author_name font_14 font_color_gray font300">
                  <span className="sound_only">작성자</span>
                  <i className="fa fa-pencil-square-o" aria-hidden="true" /> {portfolio?.createMberId}
                </span>
                <span className="author_date font_14 font_color_gray font300">
                  <span className="sound_only">작성일</span>
                  <i className="fa fa-clock-o" aria-hidden="true" /> {portfolio?.updateDt || portfolio?.createDt}
                </span>
              </div>
              <Link to={`/shop/${url}/portfolio`}><span style={{cursor : "pointer"}}>전체 포트폴리오 보기</span></Link>
            </section>
          </s.MenuWrapper>

          <s.ContentsWrapper>
            <strong className='shop_port_detail_content' id='contents'></strong>
          </s.ContentsWrapper>

          <s.SecondImgWrapper>
            {
              portfolio?.dtlList?.length !== 0 &&
              <div style={{width : isMobile ? "100%" :"75%"}}>
                {portfolio?.dtlList?.map((item) => (
                  <s.ImgWrap key={item.portDtlNo}>
                    <img style={{width : "100%"}} src={imgHandler(item.imgDtlUrl)} onError={onErrorImg}/>
                  </s.ImgWrap>
                ))}
              </div>
            }
          </s.SecondImgWrapper>
          
          <s.BtnWrapper1 style={{display : "flex", justifyContent : "center"}}>
            <s.BtnWrapper2 style={{width : isMobile ? "100%" : "75%"}}>
              <Link to={moveToUrl}><s.BackBtn>목록</s.BackBtn></Link>
            </s.BtnWrapper2>
          </s.BtnWrapper1>
        </Row>
      </article>
    </main>
  )
}

export default PortfolioViewPage;