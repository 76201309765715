import React from "react";
import { Header } from "../styled/common";
import { useNavigate } from "react-router";
import { IoClose } from "react-icons/io5";

const MutualBenefitHeader = () => {
  const navigate = useNavigate();

  const nav = [
    { label: "홈", path: "home", function: () => console.log("준비중입니다") },
    { label: "영업재개", path: "reopen", function: () => console.log("준비중입니다") },
    { label: "영업종료", path: "close", function: () => console.log("준비중입니다") },
  ];

  return (
    <Header>
      <div className="businessButtons">
        {nav.map((item) => (
          <button onClick={item.function}>
            <div className="icon">
              <img src={`/img/business_${item.path}.png`} alt="business_home" />
            </div>
            <span>{item.label}</span>
          </button>
        ))}
      </div>
      <div className="close">
        <button onClick={() => navigate("/mypage/mutualBenefit")}>
          <IoClose />
        </button>
      </div>
    </Header>
  );
};

export default MutualBenefitHeader;
