import styled from "styled-components";

export const ProductAddFormInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .xlsxButtons {
    display: flex;
    gap: 10px;
    align-items: center;
    button,
    label {
      width: 120px;
      height: 40px;
      font-size: 16px;
      font-weight: 300;
      color: #fff;
      background: #333333;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    input {
      display: none;
    }
  }
`;

export const ProductAddFormSubmitButton = styled.button`
  margin: auto;
  display: block;
  width: 200px;
  height: 54px;
  line-height: 54px;
  font-size: 18px;
  color: #fff;
  background-color: #0074ca;
  border: 1px solid #0074ca;
  border-radius: 0;
`;
