import React from "react";
import { useNavigate } from "react-router";

const CompanyInfo = ({ url }) => {
  const navigate = useNavigate();

  return (
    <div className="homepageInfo">
      <div className="tit">
        <p>{url}</p>
        <span>여기는 회사 설명입니다</span>
      </div>
      <div className="desc">
        회사 부가적 설명이에요 회사 부가적 설명이에요
        <br />
        회사 부가적 설명이에요회사 부가적 설명이에요회사 부가적 설명이에요
      </div>
      <button onClick={() => navigate(`/shop/${url}/shoppingNotice?page=0`)}>소개 보러가기</button>
    </div>
  );
};

export default CompanyInfo;
