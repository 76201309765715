import { RegisterFormContainer } from "../component/styled/homePageSetting";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setRegisterIndex } from "../../../../store/compoIndex";
import { toast } from "react-toastify";
import axios from "axios";

export default function RegisterForm() {
  const user = useSelector((state) => state?.user?.data[0]);
  const [data, setData] = useState({
    logoImage: "",
    logoText: "",
    csTimeStart: 9,
    csTimeEnd: 18,
    breakTimeStart: 12,
    breakTimeEnd: 13,
    csNumber: "",
    facebookUrl: "",
    facebookChk: "Y",
    instagramUrl: "",
    instagramChk: "Y",
    cmpnyIntroduce: "",
  });
  const [logo, setLogo] = useState();
  const [isImage, setIsImage] = useState();
  const [isAllTime, setIsAllTime] = useState(false);
  const dispatch = useDispatch();

  // 데이터 조회
  useEffect(() => {
    getMySettingData();
  }, []);

  const getMySettingData = async () => {
    try {
      const myData = await getMySetting();
      if (myData.logoImage) {
        setIsImage(true);
      }
      const filtered = Object.fromEntries(
        Object.entries(myData).filter(([key, value]) => value !== null),
      );
      setData((info) => ({ ...info, ...filtered }));
    } catch (error) {
      console.log(error);
    }
  };

  const getMySetting = async () => {
    const url = "/api/infoSettingLoad";
    const request = getDataCondition();
    const response = await axios.post(url, request);
    return response.data;
  };

  const getDataCondition = () => {
    return {
      cmpnyNo: user.cmpnyNo,
      delYn: "N",
    };
  };

  // 데이터 변경시
  const changeInput = (e) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checked = e.target.checked ? "Y" : "N";
      setData({ ...data, [name]: checked });
    }

    if (type === "text") {
      setData({ ...data, [name]: value });
    }

    if (type === "select-one") {
      // 시작시간
      if (name.endsWith("Start")) {
        const end = name.slice(0, -5).concat("End");
        const startValue = Number(value);
        const endValue = (startValue + 1) % 24;

        setData((prevData) => ({
          ...prevData,
          [name]: startValue.toString(),
          [end]: endValue.toString(),
        }));
      }
      // 종료시간
      if (name.endsWith("End")) {
        const endValue = Number(value);

        setData((prevData) => ({
          ...prevData,
          [name]: endValue.toString(),
        }));
      }
    }
  };

  // 로고 이미지 추가
  const addLogo = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setData((data) => ({
        ...data,
        logoImage: imageUrl,
      }));

      setLogo(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    if (!item) {
      return data.logoImage;
    }
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  // 저장
  const onSave = async (e) => {
    e.preventDefault();
    if ((isImage && !data.logoImage) || (!isImage && !data.logoText)) {
      toast.error("로고를 설정해주세요.");
      return;
    }
    if (data.facebookChk === "N" && !data.facebookUrl) {
      toast.error("페이스북 URL을 입력해주세요.");
    }
    if (data.instagramChk === "N" && !data.instagramUrl) {
      toast.error("인스타그램 URL을 입력해주세요.");
    }

    const url = "/api/infoSettingSave";
    const uploaded = await uploadLogoImage(logo);

    if (isImage && logo && !uploaded) {
      return;
    }

    const isValue = (key, value) => {
      if (key.includes("Time")) {
        return hours.includes(value.toString()) ? value : null;
      }
      if (key.includes("logoImage")) {
        return isImage ? value : null;
      }
      if (key.includes("logoText")) {
        return !isImage ? value : null;
      }

      return value !== "" && value !== null && value !== undefined ? value : null;
    };

    const updatedData = Object.entries(data).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: isValue(key, value),
      }),
      {},
    );

    const request = {
      cmpnyNo: user.cmpnyNo,
      createMberId: user.userName,
      updateMberId: user.userName,
      ...updatedData,
      logoImage: isImage ? uploaded : null,
    };

    if (isAllTime) {
      request.csTimeStart = 0;
      request.csTimeEnd = 0;
    }

    await axios.put(url, request);
    dispatch(setRegisterIndex(1));
    toast.success("변경사항이 저장되었습니다.");
  };

  const hours = Array.from({ length: 24 }, (_, index) => index.toString());

  return (
    <RegisterFormContainer>
      <div className="inner_content_wrap">
        <form method="post" onSubmit={onSave}>
          <div>
            <div id="step1_html">
              <div className="writing_ty2">
                <ul className="writing_list writingList">
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">1</strong>
                      <strong className="text_center">
                        {isImage ? "로고 설정" : "로고(텍스트 타입)"}
                      </strong>
                    </div>
                    <div className={`writing_cont ${!isImage && "inputxt_normal"}`}>
                      <label className="btn_check font_color_gray font_16 isLogoTypeChk">
                        <input
                          type="checkbox"
                          value={isImage}
                          onChange={() => setIsImage(!isImage)}
                          checked={isImage}
                        />
                        <span className="check_box">로고 이미지 유무</span>
                      </label>
                      {isImage ? (
                        <div className="file_import">
                          <div className="preview">
                            {data?.logoImage && <img src={imgHandler(data?.logoImage)} alt="" />}
                          </div>
                          <label>
                            파일찾기
                            <input
                              type="file"
                              id="wk_file"
                              name="logoImage"
                              accept="image/*"
                              onChange={addLogo}
                            />
                          </label>
                        </div>
                      ) : (
                        <input
                          type="text"
                          placeholder="※ 로고 이미지가 없을 경우 입력한 내용으로 텍스트 로고가 생성됩니다."
                          onChange={(e) => changeInput(e)}
                          value={data?.logoText}
                          name="logoText"
                        />
                      )}
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">2</strong>
                      <strong className="text_center">상담운영시간</strong>
                    </div>
                    <div className="writing_cont inputxt_normal customTime">
                      <div className="timeArea">
                        <div className="startArea">
                          <span className="font300 font_16 font_color_gray">시작시간</span>
                          <select
                            name="csTimeStart"
                            value={isAllTime ? 0 : data?.csTimeStart}
                            onChange={(e) => changeInput(e)}>
                            {hours.map((time) => (
                              <option>{time}</option>
                            ))}
                          </select>
                          <span className="hour font300 font_16 font_color_gray">시</span>
                        </div>
                        <div className="endArea">
                          <span className="font300 font_16 font_color_gray">종료시간</span>
                          <select
                            name="csTimeEnd"
                            value={isAllTime ? 0 : data?.csTimeEnd}
                            onChange={(e) => changeInput(e)}>
                            {hours.map((time) => (
                              <option>{time}</option>
                            ))}
                          </select>
                          <span className="hour font300 font_16 font_color_gray">시</span>
                        </div>
                        <label className="btn_check">
                          <input type="checkbox" onChange={(e) => setIsAllTime(e.target.checked)} />
                          24시간
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">3</strong>
                      <strong className="text_center">브레이크타임</strong>
                    </div>
                    <div className="writing_cont inputxt_normal customTime">
                      <div className="timeArea">
                        <div className="startArea">
                          <span className="font300 font_16 font_color_gray">시작시간</span>
                          <select
                            name="breakTimeStart"
                            value={data?.breakTimeStart}
                            onChange={(e) => changeInput(e)}>
                            {hours.map((time) => (
                              <option>{time}</option>
                            ))}
                          </select>
                          <span className="hour font300 font_16 font_color_gray">시</span>
                        </div>
                        <div className="endArea">
                          <span className="font300 font_16 font_color_gray">종료시간</span>
                          <select
                            name="breakTimeEnd"
                            value={data?.breakTimeEnd}
                            onChange={(e) => changeInput(e)}>
                            {hours.map((time) => (
                              <option>{time}</option>
                            ))}
                          </select>
                          <span className="hour font300 font_16 font_color_gray">시</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">4</strong>
                      <strong className="text_center">CS 번호</strong>
                    </div>
                    <div className="writing_cont inputxt_normal">
                      <input
                        type="text"
                        placeholder="※ 예시) 0505-466-0909"
                        onChange={(e) => changeInput(e)}
                        value={data?.csNumber}
                        name="csNumber"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">5</strong>
                      <strong className="text_center">페이스북</strong>
                    </div>
                    <div className="writing_cont inputxt_normal">
                      <input
                        type="text"
                        placeholder="※ 페이스북 접속 후 주소창의 URL을 복사/붙여넣기 해주세요"
                        onChange={(e) => changeInput(e)}
                        value={data?.facebookUrl}
                        name="facebookUrl"
                      />
                      <div className="inputxt_normal input100 input_checkbox">
                        <p className="font_16 font_color_gray font300 text_left pdt_20">
                          ※ 페이스북 연결을 희망하지 않는 경우, 하단 체크박스를 체크해주세요.
                        </p>
                        <label className="btn_check">
                          <input
                            type="checkbox"
                            name="facebookChk"
                            onChange={(e) => changeInput(e)}
                            value={data?.facebookChk}
                            checked={data?.facebookChk === "Y"}
                          />
                          <span className="check_box">사용 안함</span>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="text_center font_color_1">6</strong>
                      <strong className="text_center">인스타그램</strong>
                    </div>
                    <div className="writing_cont inputxt_normal">
                      <input
                        type="text"
                        placeholder="※ 인스타그램 접속 후 주소창의 URL을 복사/붙여넣기 해주세요"
                        onChange={(e) => changeInput(e)}
                        value={data?.instagramUrl}
                        name="instagramUrl"
                      />
                      <div className="inputxt_normal input100 input_checkbox">
                        <p className="font_16 font_color_gray font300 text_left pdt_20">
                          ※ 인스타그램 연결을 희망하지 않는 경우, 하단 체크박스를 체크해주세요.
                        </p>
                        <label className="btn_check">
                          <input
                            type="checkbox"
                            name="instagramChk"
                            onChange={(e) => changeInput(e)}
                            value={data?.instagramChk}
                            checked={data?.instagramChk === "Y"}
                          />
                          <span className="check_box">사용 안함</span>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="btnArea_center pdt_40 hp_step_btn saveAndNext">
                <button type="submit" className="btn_normal_save">
                  저장하고 다음 단계
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </RegisterFormContainer>
  );
}
