import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: fixed;
  z-index: 99999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  font-size: 70px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const HeaderContainer = styled.header`
  padding: 0;
  h1 {
    display: none;
  }
  .headerInner {
    max-width: 1400px;
    padding: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    nav {
      width: calc(100% - 500px);
      li {
        margin-right: 0;
      }
    }
    .logo {
      height: 40px;
      object-fit: contain;
      margin: 0;
      width: auto;
    }
    .login_menu {
      width: 165px;
    }
    .dropdown-menu {
      width: 150%;
      padding: 20px 0;
      li a {
        padding: 0;
      }
    }
    .headerBtnArea {
      display: flex;
      .notify {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        position: relative;
        background: #0074ca;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(25%, -25%);
          background: #bd1e01;
          font-size: 10px;
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      button {
        width: 40px;
        height: 40px;
        font-size: 32px;
        border: none;
        color: #fff;
        margin-left: 10px;
        svg {
          display: block;
          margin: auto;
        }
        &:first-child {
          margin-left: 20px;
        }
        &.logout {
          background: #333;
          i.icon_logout {
            background: url("/img/icon_logout.png");
            background-position: 50% 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &.login {
          background: #333;
          i.icon_login {
            background: url("/img/icon_login.png");
            background-position: 50% 50%;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        &.sideMenu,
        &.mypage,
        &.search {
          background: #0074ca;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    nav {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .headerInner {
      .login_menu {
        width: auto;
        transform: translateY(0);
      }
      img.logo {
        width: 100px;
        height: 35px;
      }
    }
  }
`;

export const SideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .sideMenuInner {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
  }
  .sideMenuWrapper {
    position: absolute;
    top: 80px;
    right: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000;
  }
  .sideMenuContainer {
    position: relative;
    max-width: 300px;
    .arrowUp {
      position: absolute;
      top: 0;
      transform: translateY(-100%);
      right: 20px;
      width: 0;
      height: 0;
      border-bottom: 15px solid #fff;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
    }
    .sideMenuHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      border-bottom: 2px solid #efefef;
      .logo {
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 30px;
        }
      }
      .close {
        button {
          font-size: 25px;
          border: none;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .sideMenuBody {
      padding: 20px;
      max-height: 450px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 3px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-button:vertical:start:decrement {
        display: block;
        width: 3px;
      }
      &::-webkit-scrollbar-button:vertical:end:decrement {
        display: block;
        width: 3px;
      }
      .tableEditAddBtnArea {
        padding: 20px;
      }
      .tit {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 22px;
        padding: 10px;
        background: #efefef;
        border-radius: 10px 10px 0 0;
        span {
          font-size: 18px;
        }
      }
      .sections {
        margin-bottom: 50px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .sideSection {
        font-size: 16px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        padding: 10px;
        border: 1px solid #efefef;
        border-radius: 0 0 10px 10px;
        li {
          width: calc(50% - 5px);
        }
      }
    }
  }
`;

export const GridSideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .sideMenuInner {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
  }
  .sideMenuWrapper {
    position: absolute;
    top: 80px;
    right: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000;
    .sideMenuContainer {
      position: relative;
      max-width: 300px;
      .arrowUp {
        position: absolute;
        top: 1px;
        transform: translateY(-100%);
        right: 9px;
        width: 0;
        height: 0;
        border-bottom: 15px solid rgb(239, 239, 239);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
      }
      .sideMenuHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background: rgb(239, 239, 239);
        border-bottom: 2px solid #efefef;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .logo {
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 30px;
          }
        }
        .close {
          button {
            font-size: 25px;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .sideMenuBody {
        max-height: 450px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .tableEditAddBtnArea {
          padding: 20px;
        }
        .tit {
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 22px;
          padding: 10px;
          background: #efefef;
          border-radius: 10px 10px 0 0;
          span {
            font-size: 18px;
          }
        }
        .border {
          border-top: 1px solid #efefef;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: 10px;
          li {
            width: calc((100% - 30px) / 4);
            padding: 10px 0;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            .icon {
              display: flex;
              justify-content: center;
              font-size: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1280px) {
      .sideMenuInner {
        width: 96%;
      }
    }
    @media screen and (max-width: 1050px) {
      .sideMenuInner {
        padding: 0;
      }
      .sideMenuWrapper {
        right: 0;
      }
    }
    @media screen and (max-width: 767px) {
      .sideMenuInner {
        width: 90%;
      }
    }
  }
`;

export const AppInstallContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 500px);
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 300px;
    height: 100px;
    font-size: 30px;
    border: none;
    background: #0074ca;
    color: #fff;
    border-radius: 30px;
  }
`;
