import React, { useEffect, useState } from "react";
import { GroupInviteContainer } from "../../styled/group";
import { GroupSearchBox } from "../../styled/common";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const GroupInvite = ({ members, setIsInvite, grp }) => {
  const [searchUser, setSearchUser] = useState([]);
  const [isSearchUser, setIsSearchUser] = useState(false);
  const [moblphon, setMoblphon] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };

    initKakao();
  }, []);

  const handleSearch = async () => {
    const phoneRegex = /^(010\d{8}|011\d{7})$/;

    if (!phoneRegex.test(moblphon)) {
      toast.error("전화번호 양식이 일치하지 않습니다.");
      return;
    }

    const url = "/api/mberList";
    const body = {
      moblphonNo: moblphon,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setSearchUser(res.data.content);
      setIsSearchUser(true);
    }
  };

  const signedUp = (mberNo) => {
    return members.some((user) => parseInt(user.mberNo) === parseInt(mberNo));
  };

  const handleInvite = async (mberNo) => {
    if (signedUp(mberNo)) {
      toast.error("이미 가입된 멤버입니다.");
      return;
    }

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: "G",
        mberNoList: [mberNo],
        orgNo: parseInt(id),
      },
    };
    const res = await axios.post(url, body);

    if (res.data.body.success) {
      toast.success("초대 알림이 발송되었습니다.");
      setIsInvite(false);
    }
  };

  // 카카오 공유
  const sendKakaoMessage = () => {
    window.Kakao.Share.sendCustom({
      templateId: 89801,
      templateArgs: {
        THU: imgHandler(grp.imgUrl),
        unique: grp.groupNo,
        key: grp.groupName,
      },
    });
  };

  return (
    <GroupInviteContainer>
      <div className="inviteContent">
        <div className="closeBtnArea">
          <button onClick={() => setIsInvite(false)}>
            <IoMdClose />
          </button>
        </div>
        <GroupSearchBox>
          <div className="search_box">
            <div className="basic">
              <input
                type="text"
                id="keyword"
                name="keyword"
                placeholder="휴대폰 번호를 입력하세요."
                onChange={(e) => setMoblphon(e.target.value)}
                value={moblphon}
                onKeyDown={(e) => handleEnterKey(e, handleSearch)}
                style={{ width: "60%" }}
              />
              <button onClick={handleSearch}>
                <span className="font_20 font300 font_color_white">검색</span>
              </button>
            </div>
          </div>
          {isSearchUser && (
            <div className="searched">
              {searchUser.length > 0 ? (
                <ul>
                  <li>
                    <div>이름</div>
                    <div>전화번호</div>
                    <div>초대</div>
                  </li>
                  {searchUser.map((mber) => (
                    <li key={mber.mberNo}>
                      <div>
                        {`${mber.mberNm[0]}${
                          mber.mberNm.length > 1 ? "*" : ""
                        }${mber.mberNm.substring(2)}`}
                      </div>
                      <div>{`${mber.moblphonNo.substring(0, 3)}****${mber.moblphonNo.substring(
                        7,
                      )}`}</div>
                      <div>
                        <button
                          onClick={() => handleInvite(mber.mberNo)}
                          className={signedUp(mber.mberNo) ? "signedUp" : ""}>
                          그룹초대
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="msg">
                  <button onClick={sendKakaoMessage}>카카오톡 공유</button>
                </div>
              )}
            </div>
          )}
        </GroupSearchBox>
      </div>
    </GroupInviteContainer>
  );
};

export default GroupInvite;
