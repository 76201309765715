import React from "react";
import ProgressPointer from "./progressPointer";
import { ProgressContainer } from "../../../../styled/pickup";

const Progress = () => {
  return (
    <ProgressContainer>
      <div className="bg">
        <ProgressPointer time="17:00" status="접수완료" className="received" />
        <ProgressPointer time="17:30" status="접수완료" className="cooked" />
        <ProgressPointer time="17:40" status="접수완료" className="pickedUp" />
        <div className="line"></div>
      </div>
    </ProgressContainer>
  );
};

export default Progress;
