import React from "react";
import { SalesListTable } from "../../../styled/salesCheck";
import { IoMdMore } from "react-icons/io";
import { CustomInput } from "../../../styled/common";
import { useMediaQuery } from "react-responsive";

const SalesList = ({ data = [], type }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const headers = {
    salesOverview: [
      { name: "account", label: "입금계좌" },
      { name: "period", label: "결제기간" },
      { name: "status", label: "결제상태" },
      { name: "date", label: "입금일" },
      { name: "amount", label: "입금금액" },
    ],
    orderHistory: [
      { name: "name", label: "이름" },
      { name: "details", label: "주문내역" },
      { name: "tel", label: "전화번호" },
      { name: "status", label: "결제상태" },
      { name: "date", label: "주문날짜" },
      { name: "amount", label: "주문금액" },
    ],
    settlementForm: [
      { name: "check", label: "" },
      { name: "name", label: "이름" },
      { name: "details", label: "주문내역" },
      { name: "tel", label: "전화번호" },
      { name: "status", label: "결제상태" },
      { name: "date", label: "주문날짜" },
      { name: "amount", label: "주문금액" },
    ],
  };

  const header = headers[type] || [];

  const renderCellContent = (item, headerItem) => {
    if (headerItem.name === "status") {
      return <span className="completed">{item?.[headerItem?.name] || "결제취소"}</span>;
    } else if (headerItem.name === "check") {
      return (
        <CustomInput>
          <input type="checkbox" />
          <span className="checkmark"></span>
        </CustomInput>
      );
    } else {
      return item?.[headerItem?.name] || "테스트";
    }
  };

  const renderRow = (item, index) => (
    <li key={index}>
      {header.map((headerItem, subIndex) =>
        headerItem.name === "check" ? (
          <div key={subIndex} className={headerItem.name}>
            {renderCellContent(item, headerItem)}
          </div>
        ) : (
          <div key={subIndex} className={`box${headerItem.name === "status" ? " status" : ""}`}>
            {isMobile ? (
              <>
                <span className="tit">{headerItem.label}</span>
                <span className="con">{renderCellContent(item, headerItem)}</span>
              </>
            ) : (
              renderCellContent(item, headerItem)
            )}
          </div>
        ),
      )}
      <div className="more">
        <button>
          <IoMdMore />
        </button>
      </div>
    </li>
  );

  return (
    <SalesListTable className={type}>
      <ul>
        {!isMobile && (
          <li className="tit">
            {header.map((item, index) => (
              <div key={index} className={item.name}>
                {item.name === "check" ? (
                  <CustomInput>
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                  </CustomInput>
                ) : (
                  item.label || "N/A"
                )}
              </div>
            ))}
            <div className="more"></div>
          </li>
        )}
        {data.length === 0 ? <li>No data available</li> : data.map(renderRow)}
      </ul>
    </SalesListTable>
  );
};

export default SalesList;
