import React from "react";
import FoodItem from "./foodItem";
import { OrderMenuContainer } from "../../../../styled/pickup";

const OrderMenu = () => {
  return (
    <OrderMenuContainer>
      <div className="info">
        <span className="foodItem label">메뉴</span>
        <span className="foodQuantity">수량</span>
        <span className="foodPrice">금액</span>
        <div className="actions">
          <button>주문 전표 출력</button>
        </div>
      </div>
      <ul>
        <FoodItem title="맛있는 떡볶이 세트1번" quantity={1} price={14000} />
        <FoodItem
          title="맛있는 순대볶음"
          quantity={1}
          price={14000}
          options={["맵기 1단계", "순대 내장만", "튀김 골고루"]}
        />
      </ul>
    </OrderMenuContainer>
  );
};

export default OrderMenu;
