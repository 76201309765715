import MypageBanner from "../mypageBanner";
import RegisterNoticeWriteForm from "./component/registerNoticeWriteForm";

export default function RegisterNoticeWrite () {
  return(
    <>
      <MypageBanner />
      <RegisterNoticeWriteForm />
    </>
  )
}