import React, { useState } from "react";

import { ProjectListContainer, ProjectSection } from "./component/common/styled/project";

import ProjectListView from "./component/projectListPage/projectListView";
import ProjectSideMenu from "./component/common/component/projectSideMenu";
import ProjectHeader from "./component/common/component/projectHeader";
import DashboardSideMenu from "../dashboard/component/dashboardPage/dailyAssistant/dashboardSideMenu";

const ProjectList = () => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [clickSideMenu, setClickSideMenu] = useState(false);

  return (
    <ProjectSection>
      <ProjectListContainer>
        <ProjectHeader clickSideMenu={clickSideMenu} setClickSideMenu={setClickSideMenu} />
        <div className="inner">
          <ProjectSideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
          <ProjectListView
            setShowSideMenu={setShowSideMenu}
            showSideMenu={showSideMenu}
            clickSideMenu={clickSideMenu}
            setClickSideMenu={setClickSideMenu}
          />
        </div>
        {clickSideMenu && (
          <DashboardSideMenu
            clickSideMenu={clickSideMenu}
            setClickSideMenu={setClickSideMenu}
            isMargin={false}
          />
        )}
      </ProjectListContainer>
    </ProjectSection>
  );
};

export default ProjectList;
