import { Link } from "react-router-dom";
import { ManagementContainer } from "../styled/dashboard";

export default function Management({ projectCount }) {
  return (
    <ManagementContainer className="mypage_1">
      <span className="page_title">프로젝트 관리</span>
      <Link to={"/mypage/projectList"} className="page_btn">
        더보기 +
      </Link>
      <div className="float_wrap">
        <ul className="mypage_status div_width_50">
          {projectCount.map((item) => (
            <li id={`status_${item.key}`}>
              <Link to={"/mypage/projectList"}>
                {item.name}
                <br />
                <span className="page_number">{item.cnt}</span> 건
              </Link>
            </li>
          ))}
        </ul>
        <div className="mypage_btn_wrap div_width_50">
          {projectMenu.map((item, i) => (
            <a key={i} href={item.link}>
              {item.label}
              <i className="fa fa-angle-right" aria-hidden="true" />
            </a>
          ))}
        </div>
      </div>
    </ManagementContainer>
  );
}
