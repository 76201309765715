import React from "react";
import { Link } from "react-router-dom";
import { FiHome, FiMenu, FiShoppingCart, FiUser, FiFileText, FiSearch } from "react-icons/fi";
import { PiUsersThreeLight } from "react-icons/pi";
import { Row, Col } from "react-bootstrap";

const MobileFooter = ({ url = "" }) => {
  const cmpnyPath = url ? `/${url}/` : "/";

  const footerMenu = [
    { icon: FiFileText, name: "제품소개", path: `/shop${cmpnyPath}productsList` },
    { icon: FiSearch, name: "검색", path: `/shop${cmpnyPath}search?keyword=` },
    { icon: FiHome, name: "홈", path: `/shop/${url}` },
    { icon: FiShoppingCart, name: "장바구니", path: `/shop${cmpnyPath}cart` },
    { icon: FiUser, name: "마이페이지", path: "/mypage" },
  ];

  return (
    <Row
      className="d-lg-none navbar fixed-bottom navbar-light bg-light"
      style={{
        zIndex: "9000",
        position: "fixed",
        display: "flex",
        justifyContent: "space-evenly",
        height: "70px",
        alignItems: "center",
        backgroundColor: "#eee",
        bottom: 0,
        width: "100%",
      }}>
      {footerMenu.map((item) => (
        <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem", width: "20%" }}>
          <Link to={item.path} className="nav-link">
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <item.icon style={{ fontSize: "30px", color: "black", strokeWidth: "1" }} />
              <span>{item.name}</span>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default MobileFooter;
