import { useMediaQuery } from "react-responsive";
import { createFactory, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useRegex } from "../../../../../hooks/useRegex";
import axios from "axios";
import Modal from "../../../../../common/modal";

export default function EditModalForm({
  myProduct,
  setEditModal,
  handleEditModal,
  bussinessCategory,
  getProduct,
  page,
  selected,
}) {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const user = useSelector((state) => state?.user?.data[0]);

  const { register, handleSubmit } = useForm();
  const { MoneyFormat } = useRegex();

  const [res, setRes] = useState({});
  const [opencheckModal, setOpenCheckModal] = useState(false);
  const [editProduct, setEditProduct] = useState("");

  useEffect(() => {
    getChangeHistory();
  }, []);

  const getChangeHistory = async () => {
    const url = "/api/changePdCmHistoryList";
    const request = makeHistoryData();
    const response = await axios.post(url, request);
    customHistory(response);
  };

  const makeHistoryData = () => {
    return {
      cmpnyNo: user.cmpnyNo,
      prdNo: myProduct.prdNo,
      pageNumber: 1,
      pageSize: 100,
      klightingYn: "N",
      paged: true,
      offset: 0,
    };
  };

  const customHistory = (response) => {
    response.data?.content?.forEach((el) => {
      let { NAME_TEXT, PRICE_TEXT } = changeText(el);
      el.nameText = NAME_TEXT;
      el.priceText = PRICE_TEXT;
    });
    setRes(response);
  };

  const changeText = (res) => {
    let NAME_TEXT = "";
    let PRICE_TEXT = "";
    if (res.pdNm) {
      NAME_TEXT += `상품명: '${res.pdNm}' -> `;
    }

    if (res.notitlePdCmNm) {
      NAME_TEXT += `'${res.notitlePdCmNm}'`;
    }

    if (res.pdPrice) {
      PRICE_TEXT += `가격: '${MoneyFormat(res.pdPrice)}'원 -> `;
    } else {
      PRICE_TEXT += `가격: '0'원 -> `;
    }

    if (res.notitlePdCmPrice) {
      PRICE_TEXT += `'${MoneyFormat(res.notitlePdCmPrice)}'원`;
    }

    if (res.pdNm === res.notitlePdCmNm) {
      NAME_TEXT = "";
    }

    if (res.pdPrice === res.notitlePdCmPrice) {
      PRICE_TEXT = "";
    }

    return { NAME_TEXT, PRICE_TEXT };
  };

  const validation = (editProduct) => {
    const isValid = checkValidation(editProduct);
    if (isValid) {
      setEditProduct(editProduct);
      setOpenCheckModal(true);
    }
  };

  const onClickSubmit = async (editProduct) => {
    const url = "/api/changePdCm";
    const request = makeEditData(editProduct);
    await axios.put(url, request);
    toast.success("상품 정보가 수정 되었습니다.");
    getProduct({
      page: page,
      selected: selected,
    });
    setEditModal(false);
  };

  const makeEditData = (editProduct) => {
    return {
      cmpnyNo: user.cmpnyNo,
      prdNo: myProduct.prdNo,
      pdNm: myProduct.notitlePdCmNm ?? myProduct.prductNm,
      pdPrice: myProduct.notitlePdCmPrice ?? myProduct.cnsmrPc,
      notitlePdCmNm: editProduct.notitlePdCmNm,
      notitlePdCmPrice: editProduct.notitlePdCmPrice,
      createMberId: user.userName,
      updateMberId: user.userName,
      salesStatus: myProduct.salesStatus,
    };
  };

  const checkValidation = (editProduct) => {
    // 수정 상품명
    const nowPdCmNm = editProduct.notitlePdCmNm;

    // 이전 상품명
    const prevPdCmNm = myProduct.notitlePdCmNm;

    // 최초 상품명
    const historyPdCmNm = myProduct.prductNm;

    // 수정 상품가격
    const nowPdCmPrice = editProduct.notitlePdCmPrice;

    // 이전 상품가격
    const prevPdCmPrice = myProduct.notitlePdCmPrice;

    // 최초 상품가격
    const historyPdCmPrice = myProduct.cnsmrPc;

    if (!nowPdCmNm) {
      toast.error("변경 상품명을 입력해 주세요.");
      return false;
    }

    if (!nowPdCmPrice) {
      toast.error("변경 판가를 입력해 주세요.");
      return false;
    }

    if (prevPdCmNm && prevPdCmPrice) {
      if (prevPdCmNm === nowPdCmNm && Number(prevPdCmPrice) === Number(nowPdCmPrice)) {
        toast.error("상품명 또는 가격을 수정해 주세요.");
        return false;
      }
    } else {
      if (!prevPdCmNm && prevPdCmPrice) {
        if (historyPdCmNm === nowPdCmNm && Number(prevPdCmPrice) === Number(nowPdCmPrice)) {
          toast.error("상품명 또는 가격을 수정해 주세요.");
          return false;
        }
      } else if (prevPdCmNm && !prevPdCmPrice) {
        if (prevPdCmNm === nowPdCmNm && Number(historyPdCmPrice) === Number(nowPdCmPrice)) {
          toast.error("상품명 또는 가격을 수정해 주세요.");
          return false;
        }
      } else {
        if (historyPdCmNm === nowPdCmNm && Number(historyPdCmPrice) === Number(nowPdCmPrice)) {
          toast.error("상품명 또는 가격을 수정해 주세요.");
          return false;
        }
      }
    }

    return true;
  };

  const checkedEditModal = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">제품수정</h2>
        <button onClick={() => setOpenCheckModal(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          제품을 수정 하시겠습니까?
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          <>
            <button
              onClick={() => setOpenCheckModal(false)}
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 15px",
                margin: "0px",
                background: "#6C757D",
                fontSize: "16px",
              }}>
              취소
            </button>
            <button
              onClick={() => onClickSubmit(editProduct)}
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 15px",
                margin: "0px",
                fontSize: "16px",
              }}>
              확인
            </button>
          </>
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <form onSubmit={handleSubmit(validation)}>
          <h2 className="font_20 font500 font_color_white">제품 수정</h2>
          <button
            onClick={handleEditModal}
            style={{ top: "5px" }}
            className="hp_step_setting_wrap_close">
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <img
              style={{ width: "40%", marginLeft: "25px" }}
              src={
                myProduct?.prdImgFlpth
                  ? imgHandler(myProduct?.prdImgFlpth)
                  : imgHandler(myProduct?.extrlImgUrl)
              }
            />
            <div
              className="font_23 product_edit_content"
              style={{ width: "40%", marginLeft: "35px" }}>
              {myProduct?.bsnsTy ? (
                <p className="font_16 font_color_gray font300">
                  {
                    bussinessCategory?.filter((category) => category?.code === myProduct?.bsnsTy)[0]
                      ?.codeNm
                  }
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
              {myProduct.brand ? (
                <p className="font_16 font_color_gray font300">{myProduct.brand}</p>
              ) : (
                <p>&nbsp;</p>
              )}
              <p>{myProduct.prductNm}</p>
              <p>{MoneyFormat(myProduct.cnsmrPc)}원</p>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div
              className="font_23 product_edit_content"
              style={{ width: "40%", marginLeft: "25px" }}>
              <p>변경 상품명</p>
              <p>변경 판가</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "40%",
                gap: "10px",
              }}>
              <input
                className="input_search"
                type="text"
                defaultValue={myProduct.notitlePdCmNm ?? myProduct.prductNm}
                style={{ width: "130px" }}
                {...register("notitlePdCmNm")}
              />
              <input
                style={{ width: "130px" }}
                className="input_search"
                type="number"
                defaultValue={myProduct.notitlePdCmPrice ?? myProduct.cnsmrPc}
                min="0"
                {...register("notitlePdCmPrice")}
              />
            </div>
          </div>
          {res.data?.content.length !== 0 && (
            <div
              className="font_23 product_edit_content"
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "35px",
              }}>
              <p>
                변경이력
                <span style={{ color: "gray", fontSize: "12px" }}>
                  *변경이력은 최대 100개까지만 보여집니다.
                </span>
              </p>
              <div>
                <table
                  className="table_sm_list work_place hp_step"
                  style={{
                    width: "100%",
                    overflowY: "auto",
                    height: "120px",
                    display: "block",
                  }}>
                  <colgroup style={{ width: "350px" }}>
                    <col style={{ width: "30%" }} />
                    <col style={{ width: "40%" }} />
                    <col style={{ width: "30%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span className="text_center">기간</span>
                      </th>
                      <th scope="col">
                        <span className="text_center">변경내역</span>
                      </th>
                      <th scope="col">
                        <span className="text_center">수정자</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="place_tbody">
                    {res.data?.content?.map((info, i) => (
                      <tr key={i}>
                        <td className="edit_table_td">
                          <a herf="#" className="font_color_black font500 text_center">
                            {info.createDt}
                          </a>
                        </td>
                        <td
                          className="edit_table_td"
                          style={{
                            textAlign: "center",
                          }}>
                          <span>{info.nameText}</span>
                          {info.nameText === "" ? "" : <br />}
                          <span>{info.priceText}</span>
                        </td>
                        <td className="edit_table_td">
                          <span>{info.createMberId}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
            }}>
            <button
              onClick={() => setEditModal(false)}
              className="hp_step_setting_cate_save"
              style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
              취소
            </button>
            <button
              type="submit"
              className="hp_step_setting_cate_save"
              style={{ padding: "10px 20px", margin: "0px" }}>
              확인
            </button>
          </div>
        </form>
      )}

      {!isMobile && (
        <form onSubmit={handleSubmit(validation)}>
          <h2 className="font_20 font500 font_color_white">제품수정</h2>
          <button onClick={handleEditModal} className="hp_step_setting_wrap_close">
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <img
              style={{ width: "20%", marginLeft: "40px" }}
              src={
                myProduct?.prdImgFlpth
                  ? `https://cloud.1472.ai:18443/images/cloud/uploads/${myProduct?.prdImgFlpth}`
                  : myProduct?.extrlImgUrl
              }
            />
            <div className="font_23 product_edit_content" style={{ width: "30%" }}>
              {myProduct?.bsnsTy ? (
                <p className="font_16 font_color_gray font300">
                  {
                    bussinessCategory?.filter((category) => category?.code === myProduct?.bsnsTy)[0]
                      ?.codeNm
                  }
                </p>
              ) : (
                <p>&nbsp;</p>
              )}
              {myProduct.brand ? (
                <p className="font_16 font_color_gray font300">{myProduct.brand}</p>
              ) : (
                <p>&nbsp;</p>
              )}
              <p>{myProduct.prductNm}</p>
              <p>{MoneyFormat(myProduct.cnsmrPc)}원</p>
            </div>
            <div
              className="font_23 product_edit_content"
              style={{
                marginLeft: "35px",
                width: "12%",
              }}>
              <p className="font_16 font_color_gray font300">&nbsp;</p>
              <p className="font_16 font_color_gray font300">&nbsp;</p>
              <p>변경 상품명</p>
              <p>변경 판가</p>
            </div>
            <div
              className="font_23 product_edit_content"
              style={{
                marginLeft: "10px",
                width: "20%",
              }}>
              <p className="font_16 font_color_gray font300">&nbsp;</p>
              <p className="font_16 font_color_gray font300">&nbsp;</p>
              <input
                style={{ width: "80%" }}
                className="input_search"
                type="text"
                defaultValue={myProduct.notitlePdCmNm ?? myProduct.prductNm}
                {...register("notitlePdCmNm")}
              />
              <input
                style={{ width: "80%" }}
                className="input_search"
                type="number"
                defaultValue={myProduct.notitlePdCmPrice ?? myProduct.cnsmrPc}
                min="0"
                {...register("notitlePdCmPrice")}
              />
            </div>
          </div>
          {res.data?.content.length !== 0 && (
            <div
              className="font_23 product_edit_content"
              style={{
                marginRight: "50px",
                marginTop: "40px",
              }}>
              <p>
                변경이력
                <span style={{ color: "gray", fontSize: "12px" }}>
                  *변경이력은 최대 100개까지만 보여집니다.
                </span>
              </p>
              <div>
                <table
                  className="table_sm_list work_place hp_step"
                  style={{
                    overflowY: "auto",
                    height: "300px",
                    display: "block",
                  }}>
                  <colgroup>
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "75%" }} />
                    <col style={{ width: "10%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        <span className="text_center">기간</span>
                      </th>
                      <th scope="col">
                        <span className="text_center">변경내역</span>
                      </th>
                      <th scope="col">
                        <span className="text_center">수정자</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="place_tbody">
                    {res.data?.content?.map((info, i) => (
                      <tr key={i} style={{ height: "90px" }}>
                        <td className="input_50">
                          <a herf="#" className="font_color_black font500 text_center">
                            {info.createDt}
                          </a>
                        </td>
                        <td className="input_50" style={{ textAlign: "center" }}>
                          {info.nameText ? <span>{info.nameText}</span> : ""}
                          {info.nameText === "" ? "" : <br />}
                          <span>{info.priceText}</span>
                        </td>
                        <td className="input_50">
                          <span>{info.createMberId}</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
            }}>
            <button
              onClick={() => setEditModal(false)}
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 20px",
                margin: "0px",
                background: "#6C757D",
              }}>
              취소
            </button>
            <button
              type="submit"
              className="hp_step_setting_cate_save"
              style={{
                padding: "10px 20px",
                margin: "0px",
              }}>
              확인
            </button>
          </div>
        </form>
      )}

      <Modal
        modalState={opencheckModal}
        html={checkedEditModal()}
        w={isMobile ? "60%" : "15%"}
        h="auto"
        editProduct={editProduct}
      />
    </>
  );
}
