import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import Pagination from "react-js-pagination";
import axios from "axios";
import {
  GroupInner,
  GroupListContainer,
  GroupSection,
  GroupTitle,
  NotData,
} from "./component/styled/common";
import GroupList from "./component/common/groupList";

const MyGroup = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [myGrp, setMyGrp] = useState([]);
  const [myGrpTotal, setMyGrpTotal] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const isInner = useMediaQuery({ query: "(max-width:1400px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const [mberHeight, setMberHeight] = useState(0);

  useEffect(() => {
    if (user) {
      getMyGrp();
    } else {
      navigate("/group/login");
    }
  }, []);

  useEffect(() => {
    if (user) {
      getMyGrp();
    } else {
      navigate("/group/login");
    }
  }, [currentPage]);

  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const req = getDataCond();
    const res = await axios.post(url, req);

    if (res.status === 200) {
      setMyGrp(res.data.content);
      setMyGrpTotal(res.data.totalElements);
    }
  };

  const getDataCond = () => {
    const pageSize = 8;
    return {
      mberNo: user.mberNo,
      delYn: "N",
      offset: (currentPage - 1) * pageSize,
      pageNumber: currentPage - 1,
      pageSize,
      paged: true,
    };
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 그룹 리스트 최소 높이
  const calculateMinHeight = (myGrpTotal) => {
    if (!myGrpTotal) {
      return 540;
    }
    if (isTablet) {
      return mberHeight * 4;
    }
    if (isInner) {
      return mberHeight * 2;
    }
    return 540;
  };

  return (
    <GroupSection>
      <GroupTitle className="font_48 font600 font_color_black text_center">마이 그룹</GroupTitle>
      <GroupInner>
        <GroupListContainer style={{ minHeight: calculateMinHeight(myGrpTotal) }}>
          {!myGrpTotal ? (
            <NotData>
              <span className="font_22 text_center">가입된 그룹이 존재하지 않습니다.</span>
            </NotData>
          ) : (
            <div className="members">
              <GroupList items={myGrp} setMberHeight={setMberHeight} />
            </div>
          )}
        </GroupListContainer>
        <Pagination
          activePage={currentPage}
          itemsCountPerPage={8}
          totalItemsCount={myGrpTotal}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={onPageChange}
          style={{ marginTop: 30 }}
        />
      </GroupInner>
    </GroupSection>
  );
};

export default MyGroup;
