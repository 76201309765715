import React, { useState } from "react";

import MobileTab from "./mobile/mobileTab";
import MobileOrders from "./mobile/mobileOrders";

const MobileOrderView = () => {
  const tabs = [
    { key: "orders", label: "신규 주문" },
    { key: "inProgress", label: "처리중" },
    { key: "completedOrders", label: "완료" },
  ];
  const [active, setActive] = useState("inProgress");

  return (
    <>
      <MobileTab tabs={tabs} active={active} setActive={setActive} />
      <MobileOrders tabs={tabs} active={active} setActive={setActive} />
    </>
  );
};

export default MobileOrderView;
