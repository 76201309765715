import React from "react";
import { useOutletContext } from "react-router";
import { CuteContainer } from "./styled/cuteTheme";
import Banner from "./themes/modern/banner";
import Products from "./themes/cute/products";
import Portfolio from "./themes/cute/portfolio";
import News from "./themes/cute/news";
import Introduce from "./themes/cute/introduce";

const CuteTheme = () => {
  const { cmpnyNo, homepageInfo } = useOutletContext();

  return (
    <CuteContainer>
      <Banner cmpnyNo={cmpnyNo} />
      <Introduce />
      <Products cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} />
      <Portfolio cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} />
      <News cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} />
    </CuteContainer>
  );
};

export default CuteTheme;
