import React, { useEffect, useState } from "react";
import { SalesDetailModalContainer } from "../styled/salesCheck";
import { Modal, ModalCloseButton } from "../styled/common";
import { IoClose } from "react-icons/io5";
import SalesOverview from "./salesOverview";
import SalesDetailedView from "./salesDetailedView";
import { TbArrowBackUp } from "react-icons/tb";
import axios from "axios";

const SalesDetailModal = ({ setOpenModal, detail }) => {
  const [viewType, setViewType] = useState(0);
  const transformValue = viewType * -100;
  const [data, setData] = useState([]);

  useEffect(() => {
    getSalesDaily();
  }, []);

  const handleBackButton = () => {
    switch (viewType) {
      case 0:
        setOpenModal(false);
        break;
      case 1:
        setViewType(0);
        break;

      default:
        break;
    }
  };

  const getSalesDaily = async () => {
    const url = "/api/sales-info/daily";
    const body = { date: detail.createdAt };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      if (res.status === 200 && res.data.length > 0) {
        setData(res.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SalesDetailModalContainer>
      <Modal>
        <div className="modalContent">
          <ModalCloseButton onClick={handleBackButton}>
            {viewType ? <TbArrowBackUp /> : <IoClose />}
          </ModalCloseButton>
          <div className="slideWrapper">
            <SalesOverview
              transformValue={transformValue}
              detail={detail}
              setViewType={setViewType}
            />
            <SalesDetailedView transformValue={transformValue} data={data} detail={detail} />
          </div>
        </div>
      </Modal>
    </SalesDetailModalContainer>
  );
};

export default SalesDetailModal;
