import QnaForm from "./component/qnaForm";
import NoticeBanner from "./noticeBanner";

export default function Qna() {
  return(
    <>
      <NoticeBanner />
      <QnaForm />
    </>
  )
}