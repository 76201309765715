import React from "react";
import { useSelector } from "react-redux";
import ScheduleSwiper from "./scheduleSwiper";
import { useNavigate } from "react-router";
import { LuCalendarDays } from "react-icons/lu";
import { TbLayoutDashboard } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";

import { isEventCategory } from "../../../../scheduler/component/function/scheduler";
import { ScheduleContainer } from "../../styled/dashboard";
import TodayWeather from "./todayWeather";
import { onFullscreen } from "../../../../kiosk/component/function/kiosk";
import { MdOutlineManageAccounts } from "react-icons/md";

const ScheduleViewer = ({ data, todayWeather, isWeather, location }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const calculateTotalItems = (data) => {
    if (typeof data === "object" && data !== null) {
      return Object.values(data).reduce((total, currentList) => {
        return Array.isArray(currentList) ? total + currentList.length : total;
      }, 0);
    }
    return 0;
  };

  const totalItemsCount = calculateTotalItems(data);

  const handleFullscreen = (path) => {
    onFullscreen();
    navigate(`/mypage/${path}`);
  };

  const isSungsimdangKiosk = (cmpnyNo) => {
    if (!cmpnyNo) return false;

    const kioskCompanies = [10001, 16421, 16786];
    return kioskCompanies.includes(cmpnyNo);
  };

  const isStoreKiosk = (cmpnyNo) => {
    if (!cmpnyNo) return false;

    const kioskCompanies = [10001, 16421];
    return kioskCompanies.includes(cmpnyNo);
  };

  return (
    <ScheduleContainer>
      <div className="info">
        <div className="scheduleInfo">
          <div className="userGreetingInfo">
            안녕하세요,
            <strong onClick={() => navigate("/mypage/modifyProfile")}>
              {user?.name ? ` ${user.name}` : ""}
            </strong>
            님!
            <span>
              오늘 <strong>{totalItemsCount}개</strong>의 일정이 있습니다.
            </span>
          </div>
          <button className="calendarNav" onClick={() => navigate("/mypage/scheduler")}>
            <LuCalendarDays />
          </button>
          {isSungsimdangKiosk(user?.cmpnyNo) && (
            <button className="calendarNav" onClick={() => handleFullscreen("kiosk")}>
              <TbLayoutDashboard />
            </button>
          )}
          {isStoreKiosk(user?.cmpnyNo) && (
            <button className="calendarNav" onClick={() => handleFullscreen("mutualBenefit")}>
              <MdOutlineManageAccounts />
            </button>
          )}
        </div>
        {isMobile && (
          <TodayWeather isWeather={isWeather} todayWeather={todayWeather} location={location} />
        )}
      </div>
      <div className="viewer" style={{ display: Object.keys(data).length > 0 ? "flex" : "block" }}>
        {Object.keys(data).length > 0 ? (
          Object.keys(data).map((scheduleKey) => (
            <div className="swiperContainer" key={scheduleKey}>
              <span className="tit">{`${isEventCategory(scheduleKey)}일정`}</span>
              <ScheduleSwiper list={data[scheduleKey]} />
            </div>
          ))
        ) : (
          <div className="noDataContainer">
            <span className="noDataMessageTit">일정</span>
            <div className="noDataMessage" onClick={() => navigate("/mypage/calendarEntry")}>
              일정 추가
            </div>
          </div>
        )}
      </div>
    </ScheduleContainer>
  );
};

export default ScheduleViewer;
