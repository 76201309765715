import React from "react";
import MypageBanner from "../mypageBanner";
import HomePageSettingMenu from "./homepageSettingMenu";
import RegisterTitle from "./component/registerTitle";
import RegisterMenu from "./component/registerMenu";
import RegisterForm from "./component/registerForm"
import RegisterIntroForm from "./component/registerIntroForm";
import RegisterPortForm from "./component/registerPortForm";
import RegisterNoticeForm from "./component/registerNoticeForm";
import { useSelector } from "react-redux";

export default function Register() {
  const index = useSelector((state) => state.compoIndex.register);
  
  return (
    <>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu />
          <RegisterTitle />
          <RegisterMenu 
            index={index}
          />
          <Components index={index}/>
      </div>
    </>
  )
}

function Components({index}) {
  return (
    <>
      {
        [
          <RegisterForm />, 
          <RegisterIntroForm />, 
          <RegisterPortForm />, 
          <RegisterNoticeForm />,
        ][index]
      }
    </>
  )
}
