import React from "react";
import { OnsitePaymentContainer } from "../../../styled/storage";

const ReserveCancel = ({ transformValue, selectCancel, handleBackButton, handleReserveCancel }) => {
  console.log(selectCancel);
  return (
    <OnsitePaymentContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="onsitePaymentInfo">
        <span>이름: {selectCancel.mberNm}</span>
        <span>전화번호: {selectCancel.tel}</span>
      </div>
      <div className="info">해당 예약을 취소하시겠습니까?</div>
      <div className="buttons">
        <button onClick={handleReserveCancel}>확인</button>
        <button onClick={handleBackButton}>취소</button>
      </div>
    </OnsitePaymentContainer>
  );
};

export default ReserveCancel;
