import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { useDispatch } from "react-redux";

import { GroupDetailFeedContainer } from "./component/styled/group";
import { GroupInner, GroupSection, GroupTitle, NotData } from "./component/styled/common";
import { hideLoading, showLoading } from "../../store/loading";

import GroupHambergerMenu from "./component/groupHambergerMenu";
import GroupVisualSwiper from "./component/common/groupVisualSwiper";
import Modal from "../../common/modal";
import GroupDetailFeedItem from "./component/groupDetail/groupDetailView/groupDetailHome/groupDetailFeedItem";
import GroupDetailFeedAdvertisement from "./component/groupDetail/groupDetailView/groupDetailHome/groupDetailFeedAdvertisement";
import Loading from "../../common/loading";
import ImageDetailViewer from "../../common/imageDetailViewer";

const GroupFeed = () => {
  const [feeds, setFeeds] = useState([]);
  const [myGrp, setMyGrp] = useState([]);
  const [text, setText] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState();
  const [selected, setSelected] = useState({});
  const [selectValue, setSelectValue] = useState();
  const [isBigImages, setIsBigImages] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  useEffect(() => {
    if (user) {
      getMyGrp();
    } else {
      navigate("/group");
    }
  }, []);

  useEffect(() => {
    getFeedList();
  }, [myGrp]);

  // 내 그룹
  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const req = {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, req);
    setMyGrp(res?.data?.content);
  };

  // 댓글 조회
  const getCommentList = async (feedNo) => {
    const url = "/api/feedRecomment";
    const req = {
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      delYn: "N",
      paged: false,
    };
    if (feedNo) {
      req.feedNo = feedNo;
    }
    const res = await axios.post(url, req);
    return res?.data?.content;
  };

  // 피드 조회
  const getFeedList = async () => {
    dispatch(showLoading());
    const url = "/api/grpFeedList";
    const grpNo = !isNaN(id)
      ? myGrp?.filter((grp) => grp.groupNo === parseInt(id)).map((grp) => grp.groupNo)
      : myGrp?.map((grp) => grp.groupNo);

    if (!Array.isArray(grpNo) || grpNo.length <= 0) {
      return;
    }

    const req = {
      gbn: grpNo ?? [0],
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 100,
      paged: true,
    };

    const res = await axios.post(url, req);
    const feeds = res?.data?.content;
    await mergeComment(feeds);
    await mergeAuth(feeds);
    await mergeGbnValueName(feeds);
  };

  // 그룹별 권한 병합
  const mergeAuth = async (origin) => {
    const data = origin.map((feed) => {
      const target = myGrp.find((item) => item.groupNo === parseInt(feed.gbn));
      if (target) {
        return {
          ...feed,
          auth: target.auth,
        };
      }
      return feed;
    });
    setFeeds(data);
  };

  // 댓글 병합
  const mergeComment = async (origin) => {
    const comment = await getCommentList();
    const data = await Promise.all(
      origin.map(async (feed) => {
        feed.comments = comment.filter((item) => feed.feedNo === item.feedNo);
        feed.showComments = 3;
        return feed;
      }),
    );
    setFeeds(data);
  };

  // gbn 병합 및 피드 본문 내용 말줄임
  const mergeGbnValueName = async (origin) => {
    const data = origin.map((feed) => {
      const target = myGrp.find((item) => item.groupNo === parseInt(feed.gbn));

      if (target) {
        if (feed.content.includes("\n")) {
          feed.realContent = feed.content;
          feed.content = feed.content.split("\n")[0] + "...";
        } else if (feed.content.length > 15) {
          feed.realContent = feed.content;
          feed.content = feed.content.substring(0, 25) + "...";
        }
        return {
          ...feed,
          gbnValueName: target.gbn,
          showContent: false,
        };
      }
      return feed;
    });
    setFeeds(data);
    dispatch(hideLoading());
  };

  // 댓글 추가
  const addComment = async (feedNo) => {
    if (feedNo) {
      const comment = await getCommentList(feedNo);
      const target = feeds.find((item) => item.feedNo === feedNo);
      if (target) {
        target.comments = Array.isArray(comment) ? [...comment] : [];
        setFeeds([...feeds]);
        setText({});
      }
    }
  };

  const onSubmit = async (e, feedNo) => {
    e.preventDefault();

    if (!text[feedNo].trim()) {
      return;
    }
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      mberNo: user.mberNo,
      mberNm: user.name,
      createMberId: user.userName,
      updateMberId: user.userName,
      content: text[feedNo],
      delYn: "N",
    };
    await axios.put(url, req);
    await addComment(feedNo);
  };

  // 각 댓글 시간
  function getTime(time) {
    const moment = require("moment");
    const now = moment();
    const target = moment(time);

    const years = now.diff(target, "years");
    if (years > 0) {
      return `${years}년 전`;
    }
    const months = now.diff(target, "months");
    if (months > 0) {
      return `${months}달 전`;
    }
    const days = now.diff(target, "days");
    if (days > 0) {
      return `${days}일 전`;
    }
    const hours = now.diff(target, "hours");
    if (hours > 0) {
      return `${hours}시간 전`;
    }
    const minutes = now.diff(target, "minutes");
    if (minutes > 0) {
      return `${minutes}분 전`;
    }
    return "방금 전";
  }

  // 피드 삭제
  const handleDeleteModal = (target, category) => {
    setModalOpen((modalOpen) => !modalOpen);
    setSelectValue(category);
    setSelected(target);
  };

  const onClickModalCheck = async (category) => {
    if (category === "feed") {
      await deleteFeed(selected);
    }
    if (category === "comment") {
      await deleteComment(selected);
    }
    setModalOpen(false);
    setSelected({});
    setSelectValue("");
  };

  // 피드 삭제
  const deleteFeed = async (target) => {
    const url = "/api/feedList";
    const req = {
      feedNo: target.feedNo,
      content: target.content,
      imgUrl: target.imgUrl,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`게시글이 삭제되었습니다.`);
    getFeedList();
  };

  // 피드 댓글 삭제
  const deleteComment = async (target) => {
    const url = "/api/feedRecomment";
    const req = {
      feedRecommentNo: target.feedRecommentNo,
      feedNo: target.feedNo,
      content: target.content,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`댓글이 삭제되었습니다.`);
    addComment(target.feedNo);
  };

  const deleteModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">삭제</h2>
        <button onClick={handleDeleteModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          삭제하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={() => onClickModalCheck(selectValue)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 피드 수정
  const editFeed = (target) => {
    navigate(`/group/group-feed-write/${id ? id : target.gbn}`, {
      state: {
        feed: target,
      },
    });
  };

  //  피드 댓글 더보기
  const commentShowMore = (feedNo, state) => {
    setFeeds((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo
          ? { ...item, showComments: state === "fold" ? 3 : item.showComments + 3 }
          : item,
      ),
    );
  };

  //  피드 본문내용 더보기
  const feedShowMore = (feedNo) => {
    setFeeds((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo ? { ...item, showContent: !item.showContent } : item,
      ),
    );
  };

  // gbnValue 확인
  const isGbnValue = (feed) => {
    const { groupName, gbnValue, gbnValueName } = feed;
    if (!isNaN(id)) {
      if (gbnValue && gbnValueName && !isNaN(gbnValue)) {
        return <span>{gbnValue + gbnValueName}</span>;
      }
    }
    return (
      <>
        <span
          onClick={() => navigate(`/group/${feed.gbn}`)}
          style={{ maxWidth: "40%", cursor: "pointer" }}>
          &#91;{groupName}&#93;
        </span>
        <span style={{ maxWidth: "10%" }}>
          {gbnValue && gbnValueName && !isNaN(gbnValue) ? ` ${gbnValue + gbnValueName}` : ""}
        </span>
      </>
    );
  };

  const isAuth = (feed) => {
    if (feed.auth < 2 || user.mberNo === feed.mberNo) {
      return true;
    }
    return false;
  };

  return (
    <GroupSection>
      {!myGrp.length ? (
        <GroupInner>
          <NotData>
            <span className="font_22 text_center">가입된 그룹이 존재하지 않습니다.</span>
          </NotData>
        </GroupInner>
      ) : !feeds.length ? (
        <>
          <GroupInner>
            <NotData>
              <span className="font_22 text_center">소식이 존재하지 않습니다.</span>
            </NotData>
          </GroupInner>
          {isNaN(id) && <GroupHambergerMenu refresh={getFeedList} />}
        </>
      ) : (
        <>
          {isNaN(id) && (
            <>
              <GroupTitle className="font_48 font600 font_color_black text_center">소식</GroupTitle>
              <GroupVisualSwiper />
            </>
          )}
          <GroupInner>
            <Modal
              modalState={modalOpen}
              handleModalState={handleDeleteModal}
              html={deleteModalForm()}
              w="300px"
              h="auto"
            />
            <GroupDetailFeedContainer>
              {feeds && feeds.length > 0 && (
                <ul className="feeds" style={{ margin: "60px 0" }}>
                  <GroupDetailFeedAdvertisement />
                  {feeds.map((feed) => (
                    <GroupDetailFeedItem
                      feed={feed}
                      setFeedList={setFeeds}
                      setIsBigImages={setIsBigImages}
                      text={text}
                      setText={setText}
                      active={active}
                      setActive={setActive}
                      handleDeleteModal={handleDeleteModal}
                      editFeed={editFeed}
                      onSubmit={onSubmit}
                    />
                  ))}
                </ul>
              )}
            </GroupDetailFeedContainer>
          </GroupInner>
          {isNaN(id) && <GroupHambergerMenu refresh={getFeedList} />}
          {isBigImages && isBigImages.length > 0 && (
            <ImageDetailViewer images={isBigImages} setIsDetail={setIsBigImages} />
          )}
          {isLoading && <Loading />}
        </>
      )}
    </GroupSection>
  );
};

export default GroupFeed;
