import React, { useEffect, useState } from "react";
import { Header } from "../styled/common";
import { IoRefresh, IoSearch } from "react-icons/io5";
import { IoIosRefresh, IoMdRefresh } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

const KioskHeader = ({ title, handleSearch = null, searched = "", setSearched = null }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector((state) => state?.user?.data[0]);

  const isClose = () => {
    const mberNos = [3343, 3342, 3341, 3340, 3339, 3338, 3337, 3336, 3335];
    if (mberNos.some((no) => user.mberNo === no)) {
      return false;
    }
    return true;
  };

  return (
    <Header>
      <div className="tit">으능&성심 {title}</div>
      {handleSearch && (
        <div className="searchForm">
          <input
            type="text"
            value={searched}
            onChange={(e) => setSearched(e.target.value)}
            onKeyDown={(e) => handleEnterKey(e, handleSearch)}
          />
          <button onClick={handleSearch}>
            <IoSearch />
          </button>
        </div>
      )}
      <div className="close">
        {pathname.includes("pickup") && (
          <button onClick={() => window.location.reload()}>
            <IoMdRefresh />
          </button>
        )}
        {isClose() && (
          <button onClick={() => navigate("/mypage/kiosk")}>
            <IoClose />
          </button>
        )}
      </div>
    </Header>
  );
};

export default KioskHeader;
