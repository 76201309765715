import styled from "styled-components";

export const ModernHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  font-family: "Pretendard";
  &.custom.theme {
    position: relative;
    padding: 20px 0 0;
  }
  h1.logo_wrapper {
    width: 200px;
    height: 100px;
  }
  ul {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: normal;
  }
  .gnb nav ul,
  .tnb ul {
    flex-direction: column;
  }
  .header_content {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff9f1;
    padding: 30px 30px 75px;
    border-radius: 0 0 0 100px;
    .actions ul {
      gap: 15px;
      margin-bottom: 50px;
      align-items: center;
      li a {
        font-size: 24px;
      }
    }
    .gnb nav ul {
      margin-bottom: 140px;
      gap: 70px;
      li {
        font-size: 20px;
      }
    }
    .tnb ul {
      font-size: 14px;
      gap: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    &.custom.theme {
      padding: 20px 10px 0;
    }
  }
`;
