import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

import { KioskStorage } from "./component/styled/storage";
import { Container } from "./component/styled/common";

import KioskHeader from "./component/common/kioskHeader";
import KioskFooter from "./component/common/kioskFooter";
import TabMenu from "./component/kioskStorage/tabMenu";
import ViewDetails from "./component/kioskStorage/viewDetails";
import ViewAll from "./component/kioskStorage/viewAll";
import ViewType from "./component/kioskStorage/viewType";

import UserInfoModal from "./component/kioskStorage/modal/userInfoModal";
import UserInfoForm from "./component/kioskStorage/modal/userInfoForm";
import CustResvModal from "./component/kioskStorage/modal/custResvModal";

import { printLabel, useDisableScroll, storageType } from "./component/function/kiosk";
import { useRegex } from "../../../hooks/useRegex";

const KioskStoragePage = () => {
  useDisableScroll();

  const [type, setType] = useState(0);
  const [active, setActive] = useState(0);
  const [selected, setSelected] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [origin, setOrigin] = useState([]);
  const [modalType, setModalType] = useState("");

  const { TelFormat } = useRegex();

  useEffect(() => {
    getEnableStorage();
  }, [active]);

  useEffect(() => {
    setData(makeStorage(origin));
    // makeStorage([]);
  }, [origin]);

  const getEnableStorage = async () => {
    const url = "/api/enable-storage";
    const body = {
      pickDate: moment().format("YYYYMMDD"),
      type: active,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setOrigin(res.data);
    }
  };

  const makeStorage = (specificData) => {
    const dataLength = 500;
    const config = {
      0: { baseIndex: 1 },
      1: { baseIndex: 501 },
      2: { baseIndex: 2001 },
    };

    const { baseIndex } = config[active] || { baseIndex: 1 };

    const specificDataMap = specificData.reduce((acc, item) => {
      acc[item.storageId] = item;
      return acc;
    }, {});

    return Array.from({ length: dataLength }, (_, idx) => {
      const index = baseIndex + idx;

      const isInRange =
        (index >= 351 && index <= 500) ||
        (index >= 761 && index <= 1000) ||
        (index >= 2101 && index <= 2500);
      return {
        ...specificDataMap[index],
        index,
        disable: isInRange,
      };
    });
  };

  const handleClickStorage = (type, item = null) => {
    if (item) {
      setSelected({ ...item, quantity: calcItemCount(item) });
    }
    setModalType(type);
    setOpenModal(true);
  };

  const handleExitModal = async (flag = false) => {
    if (flag) {
      await getEnableStorage();
    }
    setSelected(null);
    setModalType("");
    setOpenModal(false);
  };

  const calcItemCount = (item) => {
    return data.filter((user) => user.mberNm === item.mberNm && user.tel === item.tel).length || 0;
  };

  const getModalContent = () => {
    switch (modalType) {
      case "userInfoForm":
        return (
          <UserInfoForm
            handleExitModal={handleExitModal}
            active={active}
            getPrintElement={getPrintElement}
          />
        );
      case "custResv":
        return (
          <CustResvModal handleExitModal={handleExitModal} getPrintElement={getPrintElement} />
        );
      case "userInfo":
        return <UserInfoModal item={selected} handleExitModal={handleExitModal} />;
      default:
        return null;
    }
  };

  // 프린트 데이터 서버 전송
  const sendPrint = async (result) => {
    const url = "/etc/print";

    try {
      const res = await axios.post(url, { labelData: result });
    } catch (error) {
      console.error(error);
    }
  };

  const maskMiddle = (formatted) => {
    return formatted.replace(/(\d{3})-(\d{4})-(\d{4})/, "$1-****-$3");
  };

  // 라벨 데이터 추출
  const getPrintElement = async (items) => {
    // 직원용 데이터 처리
    await Promise.all(
      items.map(async (item) => {
        const result = {
          mberNm: item.mberNm,
          storageId: item.storageId,
          type: storageType[item.type].label,
          tel: maskMiddle(TelFormat(item.tel)),
          reserveId: item.reserveId,
          reservationTime: item.reservationStartTime,
        };

        console.log(result);
        await sendPrint(result);
        printLabel(result);
      }),
    );

    // 고객용 데이터 처리
    const customerData = {
      mberNm: items[0].mberNm,
      reserveId: items[0].reserveId,
      reservationTime: items[0].reservationStartTime,
      type: items.reduce((acc, item) => {
        acc[item.type] = acc[item.type] || [];
        acc[item.type].push(item.storageId);

        return acc;
      }, {}),
    };

    await sendPrint(customerData);
    printLabel(customerData, true);
  };

  return (
    <Container>
      <KioskStorage>
        <KioskHeader title={"보관"} />
        <TabMenu active={active} setActive={setActive} />
        <ViewType
          type={type}
          setType={setType}
          active={active}
          handleClickStorage={handleClickStorage}
        />
        {type ? (
          <ViewDetails data={data} handleClickStorage={handleClickStorage} active={active} />
        ) : (
          <ViewAll data={data} handleClickStorage={handleClickStorage} active={active} />
        )}
        <KioskFooter />
        {openModal && getModalContent()}
      </KioskStorage>
    </Container>
  );
};

export default KioskStoragePage;
