import React from "react";
import { TfiSearch } from "react-icons/tfi";
import { useMediaQuery } from "react-responsive";

const SortOptions = ({ active = "" }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div className="salesCheckBox">
      {isMobile && (
        <div className="search">
          <input type="text" placeholder="이름이나 전화번호를 입력해주세요." />
          <button>
            <TfiSearch />
          </button>
        </div>
      )}
      <div className="sortOptions">
        <button>최신순</button>
        <button>과거순</button>
      </div>
    </div>
  );
};

export default SortOptions;
