import React from "react";

const FoodItem = ({ title, options, quantity, price }) => {
  return (
    <li className="food">
      <div className="foodItem">
        <span className="tit">{title}</span>
        <ul className="foodOptions">
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <li key={index}>
                <i className="xi-subdirectory"></i>
                <span>{option}</span>
              </li>
            ))}
        </ul>
      </div>
      <span className="foodQuantity">{quantity}</span>
      <span className="foodPrice">{price}</span>
    </li>
  );
};

export default FoodItem;
