import React from "react";

const OurHistory = () => {
  const temp = Array.from({ length: 4 });
  return (
    <div className="history">
      <div className="info">OUR HISTORY</div>
      <div className="content">
        <div className="contentInfo">
          It is a long established fact that a reader will be distracted by the readable
          <br />
          content of a page when looking at its layout.
        </div>
        <div className="wrapper">
          {temp.map((_) => (
            <div className="box">
              <img src="/img/shop_network.png" alt="" />
              <span>ABOUT US</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurHistory;
