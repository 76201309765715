import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setFunctionIndex, setServiceIndex, setApplicationIndex } from "../store/compoIndex";
import { IoMdClose } from "react-icons/io";
import { SideMenuContainer } from "./styled/common";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { RiServiceLine } from "react-icons/ri";
import { MdOutlineAnnouncement, MdOutlineSettingsApplications } from "react-icons/md";
import { FaUserLock } from "react-icons/fa";
import { GrServicePlay } from "react-icons/gr";
import { LuUser } from "react-icons/lu";
import { useSelector } from "react-redux";

export default function SideMenu({ clickSideMenu, setClickSideMenu, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const menuItems = [
    user && {
      title: "내 정보",
      icon: LuUser,
      items: [{ link: "/mypage/modifyProfile", label: "정보 수정" }],
    },
    {
      title: "서비스",
      icon: RiServiceLine,
      items: serviceMenu,
      action: null,
    },
    {
      title: "기능소개",
      icon: IoMdInformationCircleOutline,
      items: sideMenu,
      action: setFunctionIndex,
    },
    {
      title: "활용사례",
      icon: GrServicePlay,
      items: serviceInfoMenu,
      action: setServiceIndex,
    },
    {
      title: "회원사",
      icon: FaUserLock,
      items: memberMenu,
      action: null,
    },
    {
      title: "서비스 소개",
      icon: MdOutlineSettingsApplications,
      items: sideMenuSub,
      action: setApplicationIndex,
    },
    {
      title: "공지/안내",
      icon: MdOutlineAnnouncement,
      items: noticeBanner,
      action: null,
    },
  ].filter(Boolean);

  const renderMenuSection = (section) => {
    const Icon = section.icon;

    const handlEChanggo = (e) => {
      e.preventDefault();

      if (!user) {
        navigate("/login");
      } else {
        openMyPage();
      }
    };

    const openMyPage = async () => {
      const url = "/api/domainSettingLoad";
      const req = {
        delYn: "N",
        cmpnyNo: user.cmpnyNo,
      };

      const res = await axios.post(url, req);
      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}`);
        user && localStorage.setItem("user", JSON.stringify(user));
      } else {
        navigate("/mypage/homepage");
      }
    };

    return (
      <li key={section.title} className="sections">
        <div>
          <div className="tit">
            <Icon />
            <span>{section.title}</span>
          </div>
        </div>
        {section.items && (
          <ul className="sideSection">
            {section.items.map((item, i) =>
              item.label === "그룹" ? (
                <li key={i}>
                  <Link
                    to={item.link}
                    target="_blank"
                    onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
                    {item.label}
                  </Link>
                </li>
              ) : item.label === "e층창고" ? (
                <li key={i}>
                  <Link onClick={(e) => handlEChanggo(e)}>{item.label}</Link>
                </li>
              ) : (
                <li key={i}>
                  <Link
                    to={item.link}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (section.action && typeof section.action === "function") {
                        dispatch(section.action(i));
                      }
                      setClickSideMenu(false);
                    }}>
                    {item.label}
                  </Link>
                </li>
              ),
            )}
          </ul>
        )}
      </li>
    );
  };

  return (
    <SideMenuContainer
      style={{ display: clickSideMenu ? "block" : "none" }}
      onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}>
      <div
        className="sideMenuInner"
        onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}>
        <div className="sideMenuWrapper">
          <div className="sideMenuContainer">
            <div className="arrowUp" />
            <div className="sideMenuHeader">
              <div className="logo">
                <img src="/img/logo_dark.png" alt="Logo" />
                <span>바로가기</span>
              </div>
              <div className="close">
                <button onClick={() => setClickSideMenu(false)}>
                  <IoMdClose />
                </button>
              </div>
            </div>
            <div className="sideMenuBody">
              <ul>{menuItems.map(renderMenuSection)}</ul>
            </div>
          </div>
        </div>
      </div>
    </SideMenuContainer>
  );
}
