import React from "react";
import { SalesOverviewContainer } from "../styled/salesCheck";
import moment from "moment";

const SalesOverview = ({ transformValue, detail, setViewType }) => {
  const calculateObj = (obj) => {
    return Object.values(obj)
      .reduce((acc, count) => acc + count, 0)
      .toLocaleString();
  };

  return (
    <SalesOverviewContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="salesDetailInfo">{moment(detail.start).format("M월 D일")} 매출액</div>
      <ul>
        <li>
          <span className="tit">결제건수</span>
          <span className="con">{calculateObj(detail.paymentTypeCount)}</span>
        </li>
        <li>
          <span className="tit">취소건수</span>
          <span className="con">{calculateObj(detail.paymentTypeCancelCount)}</span>
        </li>
        <li>
          <span className="tit">결제액</span>
          <span className="con">{calculateObj(detail.paymentTypeAmount)}</span>
        </li>
        <li>
          <span className="tit">취소액</span>
          <span className="con">{calculateObj(detail.paymentTypeCancelAmount)}</span>
        </li>
        <li>
          <span className="tit">매출액</span>
          <span className="con">{detail.totalAmount.toLocaleString()}</span>
        </li>
      </ul>
      <button onClick={() => setViewType(1)}>자세히보기</button>
    </SalesOverviewContainer>
  );
};

export default SalesOverview;
