import React, { useState } from "react";
import { FaConciergeBell } from "react-icons/fa";

const SideMenu = ({ active, setActive, tabs }) => {
  return (
    <div className="sidemenu">
      <div className="store">
        <span>상생거리 사장님</span>
        <div className="selected">
          <div className="icon">
            <FaConciergeBell />
          </div>
          <select name="" id="">
            <option value="">우리 떡볶이</option>
          </select>
        </div>
      </div>
      <div className="tabs">
        <ul>
          {Object.keys(tabs).map((key) => (
            <li
              key={key}
              onClick={() => setActive(key)}
              className={active === key ? "active" : "inactive"}>
              <div className="icon">
                <img src={`/img/mutualBenefit/salesCheck_${key}.png`} alt="" />
              </div>
              <span>{tabs[key]}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
