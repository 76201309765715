import axios from "axios";
import React, { useEffect, useState } from "react";
import { GroupContainer } from "../styled/community";
import SwiperPerView from "../common/swiperPerView";

const Group = () => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getGroups();
  }, []);

  const getGroups = async () => {
    const url = "/api/groupList";
    const req = {
      delYn: "N",
      displayYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 18,
      paged: true,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      console.log(res);
      setGroups(res.data.content);
    }
  };

  return (
    <div className="viewerContiner">
      <div className="label">
        <span>내 주변 그룹</span>
      </div>
      <SwiperPerView data={groups} type="groups" />
    </div>
  );
};

export default Group;
