import GroupRequestForm from "./component/groupRequestForm";
import NoticeBanner from "./noticeBanner";

export default function GroupRequest(){
  return(
    <>
      <NoticeBanner />
      <GroupRequestForm />
    </>
  )
}