import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { isOutsideCmpny } from "../../../../scheduler/component/function/common";

const ScheduleSwiper = ({ key, list }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });
  const user = useSelector((state) => state?.user?.data[0]);

  const displayTime = (item) => {
    const today = moment();
    const start = moment(item.startDt);
    const end = moment(item.endDt);

    const formatTime = (date) => date.format("HH:mm");

    const isFullDay =
      start.format("HH:mm:ss") === "00:00:00" && end.format("HH:mm:ss") === "23:59:59";
    const isSelectedStart = today.isSame(start, "day");
    const isSelectedEnd = today.isSame(end, "day");
    const isDuringEvent = today.isAfter(start, "day") && today.isBefore(end, "day");

    if (isFullDay || isDuringEvent) {
      return (
        <>
          <span className="text_overflow">하루종일</span>
        </>
      );
    }
    if ((isSelectedStart && isSelectedEnd) || isSelectedStart) {
      return (
        <>
          <span>{formatTime(start)}</span>
          <span className="endOfStart">시작</span>
        </>
      );
    }
    if (isSelectedEnd) {
      return (
        <>
          <span>{formatTime(end)}</span>
          <span className="endOfStart">종료</span>
        </>
      );
    }
    return "";
  };

  return (
    <>
      <Swiper
        direction={isMobile ? "horizontal" : "vertical"}
        slidesPerView={isMobile ? "auto" : 3}
        spaceBetween={isMobile ? 30 : 10}
        className="mySwiper"
        onTouchStart={(_, event) => event.stopPropagation()}
        onTouchMove={(_, event) => event.stopPropagation()}
        onTouchEnd={(_, event) => event.stopPropagation()}>
        {list.length !== 0 &&
          list.map((item, i) => (
            <SwiperSlide>
              <div className="swiper-wrapper">
                <div className="slide swiper-slide" id={i}>
                  <div className="slide_text_warp">
                    <div
                      className={`scheduleContent${isOutsideCmpny(item, user) ? " outside" : ""}`}
                      style={{ borderLeft: `10px solid #${item.color}` }}
                      onClick={() => navigate(`/mypage/detailEvent/${item.scheduleNo}`)}>
                      <div className="timeArea">{displayTime(item)}</div>
                      <div className="contentArea">
                        <span style={{ marginBottom: item.memo && 5 }}>{item.title}</span>
                        {item.memo && <span>{item.memo}</span>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default ScheduleSwiper;
