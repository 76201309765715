import React from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";

import { calculateDday } from "../../../../scheduler/component/function/scheduler";
import { DdayContainer } from "../../styled/dashboard";

const DdayViewer = ({ data }) => {
  const navigate = useNavigate();

  return (
    <DdayContainer>
      <div className="dDayInfo">D-day</div>
      {data.length > 0 ? (
        <>
          <Swiper
            direction={"horizontal"}
            slidesPerView={"auto"}
            spaceBetween={30}
            className="mySwiper">
            {data.map((item) => (
              <SwiperSlide>
                <div
                  onClick={() => {
                    navigate(`/mypage/detailEvent/${item.scheduleNo}`);
                  }}
                  className="ddayList">
                  <div className="date">
                    <span className="dday">{calculateDday(item.startDt)}</span>
                    <span>{moment(item.startDt).format("YYYY-MM-DD")}</span>
                  </div>
                  <div className="content">
                    <span>{item.title}</span>
                    <span>{item.memo}</span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      ) : (
        <div onClick={() => navigate("/mypage/ddayEntry")} className="noDataMessage">
          <div className="content">
            <span>디데이 추가</span>
          </div>
        </div>
      )}
    </DdayContainer>
  );
};

export default DdayViewer;
