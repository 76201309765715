import { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useRegex } from "../../../../hooks/useRegex";
import { CategoryList, PaginationContainer } from "../../styled/shopping";
import Loading from "../../common/ShopLoading.js";
import Pagination from "react-js-pagination";
import useQueryString from "../../../../hooks/useQueryString.jsx";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

export default function ProductListForm({ main = false }) {
  const { MoneyFormat } = useRegex();
  const { cmpnyNo, url } = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const navigater = useNavigate();

  const queryCode = useQueryString("code");
  const queryBrand = useQueryString("brand");
  const queryPage = Number(useQueryString("page"));

  const [prduct, setPrduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [bsnsTy, setBsnsTy] = useState([]);
  const [brand, setBrand] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryPage || 0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectCount, setSelectCount] = useState(0);
  const [selected, setSelected] = useState({
    codeNm: "",
    brand: "",
    code: "",
  });
  const [flag, setFlag] = useState(false);
  const [menuHeight, setMenuHeight] = useState({ category: 0, brand: 0 });
  const apiUrl = "/api/prductStock";

  useEffect(() => {
    if (cmpnyNo) {
      if (!main && !flag) {
        getCatBrand();
      } else if (main) {
        getPrductList();
      }
    } else {
      setIsLoading(false);
    }
  }, [cmpnyNo]);

  useEffect(() => {
    if (flag) {
      getPrductList();
    }
  }, [flag]);

  useEffect(() => {
    if (cmpnyNo && flag) {
      getQueryUrl();
      getPrductList(0);
    }
  }, [selectCount]);

  useEffect(() => {
    if (cmpnyNo && flag) {
      getQueryUrl();
    }
  }, [currentPage]);

  const getQueryUrl = () => {
    navigater(
      `/shop/${url}/productsList?code=${selected.code}&brand=${selected.brand}&page=${currentPage}`,
    );
  };

  const getCatBrand = () => {
    const req = {
      cmpnyNo: cmpnyNo,
      salesStatus: "S01",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: false,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        makeCategory(data.content);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPrductList = (page = queryPage || 0) => {
    setCurrentPage(page);
    const req = {
      cmpnyNo: cmpnyNo,
      salesStatus: "S01",
      notitleYn: "Y",
      offset: 12 * page,
      pageNumber: page,
      pageSize: 12,
      paged: true,
    };

    if (main) {
      req.pageSize = 8;
      req.paged = true;
    }

    if (selected.code) {
      req.bsnsTy = selected.code;
    }

    if (selected.brand) {
      req.brand = selected.brand;
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    fetch(apiUrl, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        setTotalCount(data.totalElements);
        setPrduct(data.content);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const makeCategory = (data) => {
    const category = [];
    const brand = [];
    const bsnsTy = [];

    data.map((item) => {
      if (item.codeNm) {
        const isItem = category.includes(item.codeNm);
        if (!isItem) {
          category.push(item.codeNm);
        }
      }
      if (item.brand) {
        const isItem = brand.includes(item.brand);
        if (!isItem) {
          brand.push(item.brand);
        }
      }
      if (item.bsnsTy) {
        const isItem = bsnsTy.includes(item.bsnsTy);
        if (!isItem) {
          bsnsTy.push(item.bsnsTy);
        }
      }
    });

    setCategory(category);
    setBrand(brand);
    setBsnsTy(bsnsTy);

    const idx = bsnsTy.indexOf(queryCode);
    setSelected({
      codeNm: category[idx] ?? "",
      brand: queryBrand ?? "",
      code: queryCode ?? "",
    });
    setFlag(true);
  };

  const onSelected = (text, values, code, index) => {
    if (code) {
      setSelected((prev) => ({
        ...prev,
        [values]: prev[values] === text ? "" : text,
        [code]: prev[values] === text ? "" : bsnsTy[index],
      }));
    } else {
      setSelected((prev) => ({
        ...prev,
        [values]: prev[values] === text ? "" : text,
      }));
    }
    setSelectCount((prev) => prev + 1);
  };

  const moveToPage = (prdNo) => {
    navigater(`/shop/${url}/productsView/${prdNo}`, {
      state: { selected: selected, page: currentPage },
    });
  };

  const nextPage = (num) => {
    getPrductList(num - 1);
  };

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight((data) => {
        const newHeights = {};
        for (const name in data) {
          const ulElements = document.querySelectorAll(`ul.${name}`);
          if (ulElements.length > 0 && data[name] !== 0) {
            ulElements.forEach((ulElement) => {
              ulElement.style.height = "auto";
            });
            newHeights[name] = ulElements[0].scrollHeight;
          } else {
            newHeights[name] = 0;
          }
        }
        return newHeights;
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleToggleButton = (name) => {
    const padding = isMobile ? 10 : isTablet ? 15 : 20;

    setMenuHeight((data) => {
      const ulElement = document.querySelector(`ul.${name}`);
      let newToggleState = data[name];

      if (ulElement) {
        if (newToggleState === 0) {
          ulElement.style.height = "auto";
          const height = ulElement.scrollHeight + padding * 2;
          requestAnimationFrame(() => {
            ulElement.style.height = "0px";
            requestAnimationFrame(() => {
              ulElement.style.height = `${height}px`;
            });
          });
          newToggleState = height;
        } else {
          ulElement.style.height = "0";
          newToggleState = 0;
        }
      }

      return { ...data, [name]: newToggleState };
    });
  };

  return (
    <main
      id="contProductsList"
      className="sub_page sub_page_mobile_mt"
      style={main ? { marginTop: "0" } : {}}>
      {!main && <div className="subTop_bnr subTop_bnr_01"></div>}
      <article
        className={isMobile && main ? "boundary pdb_70" : "boundary pdt_70 pdb_70"}
        style={{
          maxWidth: 1400,
          width: "100%",
          paddingRight: isMobile ? 10 : 0,
          paddingLeft: isMobile ? 10 : 0,
          paddingBottom: isMobile ? 100 : 70,
        }}>
        <h2 className="font_36 pdb_30 font600 text_center">제품소개</h2>
        {!main && (
          <section id="secBrdcrmb" className="font_14 pdb_20 text_right">
            <Link to={`/shop/${url}`}>
              <i className="xi-home-o font_20"></i>
            </Link>
            <span>제품소개</span>
          </section>
        )}
        {!main && category?.length !== 0 ? (
          <CategoryList>
            {category.length !== 0 && (
              <div className="categorys font_16">
                <div className="categoryInfo">
                  <span className="tit font700">분류</span>
                  <button className="toggleButton" onClick={() => handleToggleButton("category")}>
                    {menuHeight.category ? <IoChevronUp /> : <IoChevronDown />}
                  </button>
                </div>
                <ul
                  className={`font_color_gray category${menuHeight.category ? " active" : ""}`}
                  style={{ height: menuHeight.category }}>
                  {category.map((item, index) => (
                    <li
                      key={index}
                      className={`text_overflow ${
                        selected.codeNm === item ? "font700 font_color_black" : ""
                      }`}
                      onClick={() => onSelected(item, "codeNm", "code", index)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {brand.length !== 0 && (
              <div className="categorys font_16">
                <div className="categoryInfo">
                  <span className="tit font700">브랜드</span>
                  <button className="toggleButton" onClick={() => handleToggleButton("brand")}>
                    {menuHeight.brand ? <IoChevronUp /> : <IoChevronDown />}
                  </button>
                </div>
                <ul
                  className={`font_color_gray brand${menuHeight.brand ? " active" : ""}`}
                  style={{ height: menuHeight.brand }}>
                  {brand.map((item, index) => (
                    <li
                      key={index}
                      className={`text_overflow ${
                        selected.brand === item ? "font700 font_color_black" : ""
                      }`}
                      onClick={() => onSelected(item, "brand", null, null)}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </CategoryList>
        ) : (
          <></>
        )}
        <section id="secListTop" className="font_14">
          <div className="list_info font_color_gray_light">
            {!main ? (
              <div className="count">
                <i className="fa fa-bars"></i>
                상품
                <strong className="font_color_red">{totalCount}</strong>
                개가 등록되어 있습니다.
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <ul className="sort">
              <li><button>신상품</button></li>
              <li><button>인기상품</button></li>
              <li><button>높은가격순</button></li>
              <li>
                <select>
                  <option>개수</option>
                  <option>50</option>
                  <option>100</option>
                </select>
              </li>
            </ul> */}
        </section>
        <section
          id="secList"
          className="pdt_30"
          style={{
            justifyContent: "flex-start",
          }}>
          {prduct?.length !== 0 ? (
            <>
              {prduct.map((product) => (
                <div className="col_3" key={product.prdNo}>
                  <a onClick={() => moveToPage(product.prdNo)} style={{ cursor: "pointer" }}>
                    <div className="img">
                      <img
                        src={
                          product.extrlImgUrl
                            ? imgHandler(product.extrlImgUrl)
                            : imgHandler(product.prdImgFlpth)
                        }
                        onError={onErrorImg}
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="font_18 font600 pdt_10 pdb_20">
                        {product.notitlePdCmNm ?? product.prductNm}
                      </p>
                      {/* <p className="font_16 font_color_red">{product.description}</p> */}
                      <p className="font_16">
                        {product.notitlePdCmPrice ?? product.cnsmrPc
                          ? `${MoneyFormat(product.notitlePdCmPrice ?? product.cnsmrPc)}원`
                          : "가격 문의"}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
              {!main && (
                <PaginationContainer>
                  <Pagination
                    activePage={currentPage + 1}
                    itemsCountPerPage={12}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={nextPage}
                  />
                </PaginationContainer>
              )}
            </>
          ) : (
            main && (
              <p className="font_18 font600 pdt_10" style={{ width: "100%", textAlign: "center" }}>
                상품이 존재하지 않습니다.
              </p>
            )
          )}
          {isLoading && (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Loading />
            </div>
          )}
        </section>
      </article>
    </main>
  );
}
