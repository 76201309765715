import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  button,
  a,
  input,
  label,
  textarea,
  select,
  body {
    cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  }
  position: relative;
  &.bg {
    background: #1e1e1e;
  }
  .container {
    width: 100%;
    height: calc(100vh - 105px);
    display: flex;
  }
  .sidemenu {
    width: 375px;
    height: 100%;
    background: #f7f7f7;
  }
  .content {
    width: calc(100% - 375px);
    height: 100%;
    font-size: 26px;
    background: #f7f7f7;
    padding: 30px 40px;
    .viewer {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
      overflow: hidden;
      border-radius: 20px;
      background: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      height: calc(-50px + 100vh);
    }
  }
`;

export const Header = styled.header`
  height: 105px;
  background: #000;
  color: #fff;
  position: relative;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    background: transparent;
    color: #fff;
    border: none;
  }
  .businessButtons {
    button {
      width: 63px;
      text-align: center;
      margin-right: 60px;
      .icon {
        width: 50px;
        height: 50px;
        margin: 0 auto 7px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      span {
        font-size: 18px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .close {
    button {
      font-size: 70px;
      display: flex;
      svg {
        stroke-width: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: 50px;
    padding: 0 20px;
    .businessButtons button {
      margin-right: 30px;
      width: 42px;
      &:last-child {
        margin-right: 0;
      }
      .icon {
        width: 20px;
        height: 20px;
        margin-bottom: 5px;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .close button {
    font-size: 30px;
  }
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 15px;
    box-shadow: rgba(17, 17, 26, 0.2) 0px 7px 29px 0px;
    width: 605px;
    font-size: 26px;
    overflow: hidden;
    min-height: 300px;
    letter-spacing: -1px;
    .modalHeader {
      height: 84px;
      line-height: 84px;
      background: #255dac;
      padding: 0 40px;
      color: #fff;
      h2 {
        display: inline-block;
        width: calc(100% - 40px);
      }
      button {
        font-size: 40px;
        border: none;
        background: transparent;
        color: #fff;
      }
    }
    .modalContent {
      height: calc(100% - 84px);
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .message {
        span {
          display: block;
        }
      }
    }
    .modalActions {
      height: 70px;
      line-height: 70px;
      width: 100%;
      text-align: center;
      button {
        height: 100%;
        font-weight: 700;
      }
    }
  }
`;

export const Alert = styled.div`
  height: 300px;
  .message {
    height: calc(100% - 75px);
  }
  .modalActions {
    button {
      width: 215px;
      border-radius: 38px;
      background: #255dac;
      color: #fff;
    }
  }
`;

export const Choice = styled.div`
  height: 780px;
  .choiceOption {
    height: calc(100% - 150px);
    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      li {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 38px;
        height: calc((100% - 50px) / 6);
        position: relative;
        font-weight: 700;
        &.active {
          background: #dddddd;
        }
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .modalActions {
    button {
      width: 175px;
      background: transparent;
      border-radius: 38px;
      &:last-child {
        margin-left: 30px;
        background: #255dac;
        color: #fff;
        border: none;
      }
    }
  }
`;

export const Confirmation = styled.div`
  height: 300px;
  .message {
    height: calc(100% - 75px);
  }
  .modalActions {
    button {
      width: 50%;
      &:first-child {
        border-radius: 38px 0 0 38px;
        background: transparent;
      }
      &:last-child {
        background: #272833;
        color: #fff;
        border-radius: 0 38px 38px 0;
      }
    }
  }
`;

export const Selection = styled.div`
  height: 430px;
  .stepper {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 25px 55px;
    border: 1px solid #000;
    border-radius: 38px;
    span {
      width: calc(100% - 60px);
    }
    button {
      width: 30px;
      height: 30px;
      font-size: 30px;
      display: flex;
      border: none;
      background: transparent;
    }
  }
  .modalActions {
    button {
      width: 175px;
      background: transparent;
      border-radius: 38px;
      &:last-child {
        margin-left: 50px;
        background: #272833;
        color: #fff;
      }
    }
  }
`;

export const CustomInput = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 25px;
  width: 25px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #666;
    border-radius: 5px;
    background-color: transparent;
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      opacity: 0;
      width: 9px;
      height: 17px;
      border: solid #fff;
      border-width: 0px 3px 3px 0px;
      transition: 0.2s;
    }
  }
  input:checked ~ .checkmark {
    background: #255dac;
    border-color: #255dac;
    &:after {
      opacity: 1;
      transform: translate(-50%, -65%) rotate(45deg) scale(1);
      border-color: #fff;
    }
  }
  &:hover .checkmark {
    background-color: #efefef;
  }
`;

export const Interaction = styled.div`
  display: flex;
  gap: 15px;
  color: #bbb;
  width: 150px;
  overflow: hidden;
  font-size: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      display: block;
    }
  }
`;

export const SwiperListContainer = styled.div`
  height: calc(100% - 50px);
  .wrapper {
    height: 100%;
    .box {
      border-bottom: 1px solid #666;
      overflow: hidden;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        padding-top: 0;
      }
      .slideContent {
        width: calc(100% - 150px);
      }
      .writer {
        font-size: 16px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }
      }
      .questions {
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
      }
    }
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      &.swiper-pagination-bullet-active {
        background: #525252;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    height: calc(100% - 40px);
  }
  @media screen and (max-width: 767px) {
    .wrapper .box {
      align-items: baseline;
      flex-direction: column;
      .slideContent {
        width: 100%;
        .writer {
          margin-bottom: 15px;
          font-size: 14px;
        }
      }
    }
  }
`;

export const SwiperPerViewContainer = styled.div`
  height: calc(100% - 50px);
  .wrapper {
    height: 100%;
    .contentSlide {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      height: calc(100% - 50px);
      padding: 10px;
      .box {
        width: calc((100% - 30px) / 3);
        height: calc((100% - 15px) / 2);
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        border-radius: 20px;
        overflow: hidden;
        .image {
          width: 100%;
          height: calc(100% - 55px);
          background: #efefef;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .info {
          font-size: 20px;
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            display: inline-block;
            width: calc(100% - 85px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
          }
          button {
            width: 85px;
            height: 25px;
            font-size: 14px;
            background: #e7e7e7;
            border: none;
            border-radius: 38px;
          }
        }
      }
    }
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      &.swiper-pagination-bullet-active {
        background: #525252;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    height: calc(100% - 40px);
    .wrapper .contentSlide .box {
      width: calc((100% - 15px) / 2);
    }
  }
  @media screen and (max-width: 767px) {
    .wrapper .contentSlide {
      height: calc(100% - 30px);
      .box {
        width: 100%;
        height: 100%;
        .image {
          height: calc(100% - 45px);
        }
        .info {
          padding: 10px 20px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
`;
