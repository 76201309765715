export default function Feature () {
    return(
        <div className="section fp-auto-height-responsive" id="section2">
        <div className="text_wrap">
          <span className="font_100 font_color_white font700 pdb_40">{service.featureE}</span>
          <span className="font_24 font_color_white font400 pdb_10">
            {service.benefit}
          </span>
          <span className="font_18 font_color_white font300 pdb_20">
            {service.sCommnet2}
          </span>
          <div className="text_wrap_2">
            <span className="font_30 font_color_white font400">
              {service.mComment4}<br />{service.mComment5}<br />{service.mComment6}
            </span>
            <span className="no3">3</span>
          </div>
          <ul className="key_point">
            {functionKey.map((item, i) =>
               <li key={i}>
                  <img src={item.img} className="pdb_40" />
                  <span className="font_22 font_color_white font_600 pdb_20">{item.label}</span>
                  <span className="font_16 font_color_white font_400">{item.comment}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
}