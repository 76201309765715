import React, { useState, useRef, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { FcCalendar } from "react-icons/fc";
import { TfiSearch } from "react-icons/tfi";
import { SalesCheckInfoContainer } from "../../../styled/salesCheck";
import SalesCheckCalendar from "./salesCheckCalendar";

const SalesCheckInfo = ({ active, tabs }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [dateRange, setDateRange] = useState({
    start: moment(),
    end: moment().add(7, "days"),
  });
  const [openCalendar, setOpenCalendar] = useState(false);
  const [currentRect, setCurrentRect] = useState(null);

  const startRef = useRef(null);
  const endRef = useRef(null);

  const handleCalendarOpen = (ref) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setCurrentRect(rect);
    }
    setOpenCalendar(true);
  };

  return (
    <SalesCheckInfoContainer>
      <div className="info">
        <span>{tabs[active]}</span>
        {active !== "statistics" && !isMobile && (
          <div className="search">
            <input type="text" placeholder="이름이나 전화번호를 입력해주세요." />
            <button>
              <TfiSearch />
            </button>
          </div>
        )}
        <span className="total">총 입금금액 600,000원</span>
      </div>
      <div className="date">
        <div className="start" ref={startRef}>
          <span>{dateRange.start.format("YYYY.MM.DD")}</span>
          <button onClick={() => handleCalendarOpen(startRef)}>
            <FcCalendar />
          </button>
        </div>
        <span>~</span>
        <div className="end" ref={endRef}>
          <button onClick={() => handleCalendarOpen(endRef)}>
            <FcCalendar />
          </button>
          <span>{dateRange.end.format("YYYY.MM.DD")}</span>
        </div>
      </div>
      {openCalendar && currentRect && (
        <SalesCheckCalendar currentRect={currentRect} setOpenCalendar={setOpenCalendar} />
      )}
    </SalesCheckInfoContainer>
  );
};

export default SalesCheckInfo;
