import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleLogin(state, action) {
      if (state.data.length > 0) state.data = [];
      state.data.push(action.payload);
    },
    onClickLogout(state) {
      state.data.pop();
      sessionStorage.clear();
      localStorage.removeItem("user");
    },
    handleChangeUser(state, action) {
      const item = state.data[0];
      state.data = [{ ...item, ...action.payload }];
    },
  },
});

export const { handleLogin, onClickLogout, handleChangeUser } = user.actions;
export default user;
