import React from "react";
import { useOutletContext } from "react-router";

import { ChicConainer } from "./styled/chicTheme";

import Banner from "./themes/chic/banner";
import CompanyInfo from "./themes/chic/companyInfo";
import Products from "./themes/chic/products";
import Portfolio from "./themes/chic/portfolio";
import Notice from "./themes/chic/notice";

const ChicTheme = () => {
  const { cmpnyNo, url, homepageInfo } = useOutletContext();

  return (
    <ChicConainer>
      <Banner cmpnyNo={cmpnyNo} />
      <CompanyInfo url={url} />
      <Products homepageInfo={homepageInfo} cmpnyNo={cmpnyNo} url={url} />
      <Portfolio cmpnyNo={cmpnyNo} url={url} />
      <Notice cmpnyNo={cmpnyNo} homepageInfo={homepageInfo} url={url} />
    </ChicConainer>
  );
};

export default ChicTheme;
