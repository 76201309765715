import React from "react";
import { IoClose } from "react-icons/io5";
import { DetailsUseInquiryModal } from "../styled/resStatus";
import { useRegex } from "../../../../../hooks/useRegex";
import { Modal, ModalCloseButton } from "../styled/common";
import { formatStorageTimes, isPayType, isStorageType, resStatus } from "../function/kiosk";

const DetailsUseInquiry = ({ selected, setOpenModal }) => {
  const { TelFormat } = useRegex();
  console.log(selected);

  return (
    <DetailsUseInquiryModal>
      <Modal>
        <div className="modalContent padding_50">
          <div className="userInfo">
            <ul>
              <li>
                <span className="tit">이름: </span>
                <span className="con">{selected[0]?.mberNm}</span>
              </li>
              <li>
                <span className="tit">전화번호: </span>
                <span className="con">{TelFormat(selected[0]?.tel)}</span>
              </li>
            </ul>
          </div>
          <div className="storageInfo">
            <ul>
              {selected.map((item) => (
                <li>
                  <ul className="storage">
                    <li>
                      <span className="tit">사물함번호</span>
                      <span className="con">{item.storageId ? item.storageId : ""}</span>
                    </li>
                    <li>
                      <span className="tit">보관장소</span>
                      <span className="con">{isStorageType(item.type, "label")}</span>
                    </li>
                    <li>
                      <span className="tit">보관날짜</span>
                      <span className="con">{item.reservationDay}</span>
                    </li>
                    <li>
                      <span className="tit">보관시간</span>
                      <span className="con">{formatStorageTimes(item).start}</span>
                    </li>
                    <li>
                      <span className="tit">보관마감시간</span>
                      <span className="con">{formatStorageTimes(item).end}</span>
                    </li>
                    <li>
                      <span className="tit">가격</span>
                      <span className="con">{item.price ? item.price.toLocaleString() : 0}원</span>
                    </li>
                    <li>
                      <span className="tit">결제타입</span>
                      <span className="con">{isPayType(item.payType)}</span>
                    </li>
                    <li>
                      <span className="tit">픽업상태</span>
                      <span
                        className="con"
                        style={{
                          color: resStatus[item.reservationStatus]?.color,
                          borderColor: resStatus[item.reservationStatus]?.color,
                        }}>
                        {resStatus[item.reservationStatus]?.label}
                      </span>
                    </li>
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <ModalCloseButton onClick={() => setOpenModal(false)}>
            <IoClose />
          </ModalCloseButton>
        </div>
      </Modal>
    </DetailsUseInquiryModal>
  );
};

export default DetailsUseInquiry;
