import React from "react";

const MobileTab = ({ tabs, active, setActive }) => {
  return (
    <div className="info">
      <div className="label">주문접수</div>
      <div className="tabs">
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab.key}
              onClick={() => setActive(tab.key)}
              className={active === tab.key ? "active" : "inactive"}>
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileTab;
