export default function Company() {
  return(
    <div className="mypage_1">
      <span className="page_title">기업 추가 및 연결</span>
      <div className="float_wrap">
        <div className="mypage_btn_wrap div_width_50 homepage_wrap3">
          <span className="font_18 font600 font_color_black pdb_20">※ 메뉴 안내</span>
          <span className="font_18 font300 font_color_gray pdb_20">홈페이지 바로가기 : 생성한 홈페이지로 이동</span>
          <a href="#">기업 추가<i className="fa fa-angle-right" aria-hidden="true" /></a>
        </div>
        <div className="mypage_btn_wrap div_width_50 homepage_wrap3">
          <span className="font_18 font600 font_color_black pdb_20">※ 메뉴 안내</span>
          <span className="font_18 font300 font_color_gray pdb_20">홈페이지 바로가기 : 생성한 홈페이지로 이동</span>
          <a href="#">기업 연결 요청<i className="fa fa-angle-right" aria-hidden="true" /></a>
        </div>
      </div>
    </div>
)
}