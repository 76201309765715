import { GroupReqContents } from "./styled/notice";

export default function GroupRequestContents() {
  const handleSubmit = (e) => {
    e.preventDefault();
    alert("준비 중입니다.");
  };
  return (
    <GroupReqContents className="inner_content_wrap" id="group_application">
      <form id="form1" name="form1" onSubmit={handleSubmit}>
        {/*01.공사기본정보 시작*/}
        <div>
          {/*작성폼 시작*/}
          <div id="step1_html">
            <h5 className="sound_only">단체설명 정보 입력</h5>
            <div className="writing_ty2">
              <ul className="writing_list">
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      회사명<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal input100">
                      <input type="text" placeholder="회사명을 입력하세요." />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      담당자명<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="text" placeholder="담당자명을 입력하세요." />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      이메일<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="email" placeholder="‘@’ 를 포함하여 입력해주세요." />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      연락처<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="number" placeholder="‘-’ 없이 숫자만 입력해주세요." />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit">
                    <strong className="tit">담당자 직급/직책</strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="text" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit">
                    <strong className="tit">업종</strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="text" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit">
                    <strong className="tit">소재지</strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <input type="text" />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      사용유형<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input100">
                      <select name="search_type" id="search_type">
                        <option value="A">전체</option>
                        <option value="T">제목</option>
                        <option value="C">내용</option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="writing_tit required">
                    <strong className="tit">
                      문의내용<em className="point">필수</em>
                    </strong>
                  </div>
                  <div className="writing_cont">
                    <textarea rows={4} style={{ resize: "none", outline: "none" }} />
                  </div>
                </li>
                <li>
                  <div className="writing_cont">
                    <div className="inputxt_normal spider input50">
                      <fieldset id="captcha" className="captcha">
                        <legend>
                          <label htmlFor="captcha_key">자동등록방지</label>
                        </legend>
                      </fieldset>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="btnarea_center mt20">
              <button className="btn_normal" type="submit">
                <span>요청하기</span>
              </button>
            </div>
          </div>
        </div>
        {/*//작성폼 끝*/}
      </form>
    </GroupReqContents>
  );
}
