import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import GroupFeedSwiper from "./component/common/groupFeedSwiper";
import Loading from "../../common/loading";

import { FeedContainer } from "./component/styled/group";
import { GroupInner, GroupSection } from "./component/styled/common";

import axios from "axios";
import Modal from "../../common/modal";

import { hideLoading, showLoading } from "../../store/loading";
import uuid from "react-uuid";

const GroupFeedWrite = () => {
  const [grp, setGrp] = useState(null);
  const [myGrp, setMyGrp] = useState([]);
  const [feed, setFeed] = useState({});
  const [text, setText] = useState("");
  const [rImages, setRImages] = useState([]);
  const [images, setImages] = useState([]);
  const [selected, setSelected] = useState();
  const [status, setStatus] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      getMyGrp();
    } else {
      navigate("/group");
    }
  }, []);

  useEffect(() => {
    const selectedFeed = location.state?.feed || {};
    setFeed(selectedFeed);
    setText(selectedFeed.realContent ? selectedFeed.realContent : selectedFeed.content || "");
    const updated =
      (selectedFeed.imgUrl &&
        JSON.parse(selectedFeed.imgUrl).map((item) => ({
          path: item,
          id: uuid(),
        }))) ||
      [];
    setImages(updated);
    setGrp(selectedFeed.gbn || id);
  }, [id]);

  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const req = getDataCond();
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setMyGrp(res.data.content);
    }
  };

  const getDataCond = () => {
    return {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 1,
      pageSize: 1,
      paged: false,
    };
  };

  const handleSubmit = async () => {
    if (feed.feedNo) {
      const delCheck = await feedDelYnCheck(feed.feedNo);
      if (delCheck === "Y") {
        toast.error("게시물이 존재하지 않습니다.");
        navigate("/group/feed");
        return;
      }
    }

    if (grp === "0") {
      toast.error("그룹을 선택해주세요.");
      return;
    }
    if (!text || !text.trim() || !text.replaceAll("\n", "")) {
      toast.error("내용이 존재하지 않습니다.");
      return;
    }

    let uploadedImages;

    if (images.length > 0) {
      const serverImages = await uploadImages();
      uploadedImages = serverImages;
    }

    if (uploadedImages && uploadedImages.length > 0) {
      uploadedImages = uploadedImages.map((item) => {
        if (typeof item === "object" && item.path) {
          return item.path;
        }
        return item;
      });
    }

    dispatch(showLoading());
    const url = "/api/feedList";
    const request = {
      content: text.trim(),
      imgUrl: uploadedImages ? JSON.stringify(uploadedImages) : JSON.stringify([]),
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "N",
      gbn: grp,
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    if (feed.feedNo) {
      request.feedNo = feed.feedNo;
    }

    const res = await axios.put(url, request);

    if (res.status === 200) {
      toast.success(`게시글이 ${location.state?.feed ? "수정" : "작성"}되었습니다.`);
      dispatch(hideLoading());
      navigate("/group/feed");
    }
  };

  const changeGrpNo = (e) => {
    setGrp(e.target.value);
  };

  const changeImg = (e) => {
    const files = e.target.files;

    if (files) {
      const imgArr = [];
      let loadedCount = 0;

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event) => {
          imgArr[i] = { path: event.target.result, id: uuid() };
          loadedCount++;
          files[i].imgName = imgArr[i].path;
          files[i].id = imgArr[i].id;

          if (loadedCount === files.length) {
            setRImages([...rImages, ...files]);
            setImages([...images, ...imgArr.filter(Boolean)]);
          }
        };

        reader.readAsDataURL(files[i]);
      }
    }
  };

  const uploadImages = async () => {
    try {
      const rImagesArray = Array.from(rImages);

      const imgNamePromises = rImagesArray.map(async (item) => {
        const form = new FormData();
        form.append("file", item);
        form.append("ids", "F");
        form.append("mberNo", user.mberNo);

        const url = "/api/uploadFile";
        const res = await axios.post(url, form);
        return res.data.responseData.fileName;
      });

      const imgNames = await Promise.all(imgNamePromises);
      const imgs = images.filter((item) => !item.path.includes("data:image")).concat(imgNames);

      return imgs;
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  // 수정 완료 시 삭제여부
  const feedDelYnCheck = async (feedNo) => {
    const url = "/api/feedList";
    const request = {
      feedNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    const res = await axios.post(url, request);
    return res?.data?.content[0].delYn;
  };

  // 이미지 삭제
  const handleModalCheck = () => {
    if (status === "cancel") {
      if (location.state?.feed) {
        setFeed({});
        setText("");
        setImages([]);
      }
      navigate(-1, {
        state: { saveMessage: undefined },
      });
    }
    if (status === "del") {
      setImages(images.filter((img) => img.id !== selected.id));
      setRImages(rImages.filter((img) => img.id !== selected.id));
    }
    if (status === "save") {
      handleSubmit();
    }
    setSelected();
    setModalOpen(false);
  };

  const handleModal = (item) => {
    setModalOpen((modal) => !modal);
    if (item !== "cancel" && item !== "save") {
      setStatus("del");
      setSelected(item);
    } else {
      setStatus(item);
    }
  };

  const ModalForm = () => {
    const statusText = () => {
      if (status === "cancel") {
        return "취소";
      }
      if (status === "del") {
        return "삭제";
      }
      if (status === "save") {
        return location.state?.feed ? "수정" : "작성";
      }
    };

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">삭제</h2>
        <button onClick={handleModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          {statusText()}하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={() => handleModalCheck(status)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 클립보드
  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    const imageFilePromises = [];

    for (let item of items) {
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const mockEvent = { target: { files: [file] } };
        imageFilePromises.push(changeImg(mockEvent));
      }
    }
  };

  return (
    <GroupSection>
      <GroupInner>
        <Modal
          modalState={modalOpen}
          handleModalState={handleModal}
          html={ModalForm()}
          w="300px"
          h="auto"
        />
        <FeedContainer>
          <ul className="cardList">
            <li>
              <select
                value={grp}
                onChange={changeGrpNo}
                style={{
                  border: "1px solid #fff",
                  padding: " 10px 30px 10px 20px",
                  outline: "none",
                }}>
                <option value="0">전체선택</option>1
                {myGrp.map((item) => (
                  <option
                    key={item.groupNo}
                    value={item.groupNo}
                    selected={String(item.groupNo) === id ? true : false}>
                    {item.groupName}
                  </option>
                ))}
              </select>
              <div className="cardHeader font_18 font700">
                <div className="writerText">
                  <span>{`새소식 ${location.state?.feed ? "수정" : "작성"}`}</span>
                </div>
              </div>
              <div
                className="cardImages"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                }}>
                <GroupFeedSwiper img={images} handleModal={handleModal} />
              </div>
              <div className="cardBody">
                <div>
                  <input type="file" onChange={changeImg} multiple accept=".jpg, .png"></input>
                </div>
                <p className="font_20 text">
                  <textarea
                    style={{
                      width: "100%",
                      height: "100px",
                    }}
                    placeholder="내용을 입력해주세요"
                    onChange={(e) => setText(e.target.value)}
                    value={text}
                    onPaste={handlePaste}></textarea>
                </p>
                <hr />
              </div>
            </li>
            <div
              className="search_box"
              style={{
                textAlign: "center",
                marginTop: "3em",
              }}>
              <button
                onClick={() => handleModal("cancel")}
                style={{ background: "#f0f0f0", border: "1px solid #f0f0f0", marginRight: 10 }}>
                <span className="font_20 font300 font_color_black">취소</span>
              </button>
              <button onClick={() => handleModal("save")}>
                <span className="font_20 font300 font_color_white">등록</span>
              </button>
            </div>
          </ul>
        </FeedContainer>
      </GroupInner>
      {isLoading && <Loading />}
    </GroupSection>
  );
};

export default GroupFeedWrite;
