import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDisableScroll } from "../kiosk/component/function/kiosk";

import { PickUpContainer } from "./component/styled/pickup";
import { Container } from "./component/styled/common";

import MutualBenefitHeader from "./component/common/mutualBenefitHeader";
import DesktopOrderView from "./component/pickup/desktopOrderView";
import MobileOrderView from "./component/pickup/mobileOrderView";

const Pickup = () => {
  useDisableScroll();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const tabs = [
    { key: "orders", label: "신규 주문" },
    ...(isMobile ? [{ key: "inProgress", label: "처리중" }] : []),
    { key: "completedOrders", label: "완료" },
  ];
  const [active, setActive] = useState("inProgress");
  const [selected, setSelected] = useState({});

  return (
    <Container>
      <PickUpContainer>
        <MutualBenefitHeader />
        <div className="container">{isMobile ? <MobileOrderView /> : <DesktopOrderView />}</div>
      </PickUpContainer>
    </Container>
  );
};

export default Pickup;
