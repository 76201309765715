import ProductTop from "./productTop";
import ProductBottom from "./productBottom";
import { useMediaQuery } from 'react-responsive';
import { useRegex } from "../../../../hooks/useRegex";

export default function ProductForm ({product, setProduct}) {
  const isMobile = useMediaQuery({query: "(max-width:640px)"});
  const { MoneyFormat } = useRegex();

  return(
    <div className="hp_step_content">
      <div className="hp_step_product_step">
        <span className="font_16 font400 text_left font_color_gray" style={isMobile ? {fontSize : "18px"} : {}}>
          <i className="fa fa-cubes" aria-hidden="true" /> 
          {/* 클라우드 제품 총 <strong className="font_color_1 font400">{product.totalElements} 개</strong> */}
          내가 연동한 제품 총 <strong className="font_color_1 font400">{MoneyFormat(product.totalElements)} 개</strong>
        </span>
        <form method="post" >
          {/*연동제품 시작*/}
          <div className="hp_step_product_wrap">
            <ProductTop setProduct={setProduct}/>
            <ProductBottom product={product} setProduct={setProduct}/>
          </div>
          {/*//연동제품 끝*/}
        </form>
      </div>
    </div>
  )
}