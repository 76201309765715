import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { useDispatch } from "react-redux";
import { handleLogin } from "../../store/user";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

const ShoppingLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({ id: "", pwd: "" });
  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  useEffect(() => {
    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };
    initKakao();
  }, []);

  const getMyDomain = async (cmpnyNo) => {
    if (!cmpnyNo) return "";

    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      return res.data.domain;
    } else {
      return "";
    }
  };

  const loginWithKakao = () => {
    Kakao.Auth.login({
      success: function (authObj) {
        const token = authObj.access_token;
        axios
          .request({
            method: "POST",
            url: "https://kapi.kakao.com/v2/user/me",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
          .then(async (response) => {
            const { data, status } = response;
            if (status === 200) {
              await kakaoSignIn(data);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      },
      fail: function (err) {
        console.log("error!!!!" + JSON.stringify(err));
      },
    });
  };

  const kakaoSignIn = async (user) => {
    const url = "/api/kakaoSignIn";
    const req = {
      email: user.kakao_account.email,
      id: user.id,
      name: user.kakao_account.name,
      phoneNumber: user.kakao_account.phone_number,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      return false;
    } else {
      dispatch(handleLogin(res.data));
      const domain = await getMyDomain(res.data.cmpnyNo);
      navigate(`/shop/${domain}`);
    }
  };

  const changeLoginInfo = (e) => {
    const { name, value } = e.target;
    setLoginInfo({ ...loginInfo, [name]: value });
  };

  const handleKeyPress = (e) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key === "Enter") {
      signIn();
    }
  };

  const signIn = async () => {
    if (!loginInfo.id || !loginInfo.pwd) {
      toast.error("아이디 또는 비밀번호를 입력해주세요");
      return;
    }
    const url = "/api/signIn";
    const req = {
      id: loginInfo.id,
      pwd: loginInfo.pwd,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      toast.error("아이디 또는 비밀번호가 일치하지 않습니다");
      return false;
    } else {
      dispatch(handleLogin(res.data));
      const domain = await getMyDomain(res.data.cmpnyNo);
      navigate(`/shop/${domain}`);
    }
  };

  return (
    <div
      className="inner_content_wrap"
      style={{ marginTop: isMobile ? 70 : 240, flex: 1, padding: isMobile ? "20px" : 0 }}>
      <div className="change_pw">
        <div className="userInput">
          <input
            type="text"
            placeholder="ID"
            name="id"
            value={loginInfo.id}
            onChange={changeLoginInfo}
            onKeyDown={handleKeyPress}
          />
          <input
            type="password"
            placeholder="Password"
            name="pwd"
            value={loginInfo.pwd}
            onChange={changeLoginInfo}
            onKeyDown={handleKeyPress}
          />
          <button type="submit" className="pw_confirm pw_100 signInBtn" onClick={signIn}>
            <span>로그인</span>
          </button>
          <button
            className="pw_confirm pw_100"
            style={{
              backgroundColor: "#FEE500",
              margin: "10px 0",
            }}
            onClick={loginWithKakao}>
            <span style={{ color: "black" }}>카카오톡 로그인</span>
          </button>
        </div>
        <div className="moreService">
          <a href="#" id="leave" className="member_find">
            계정찾기
          </a>
          <a href="#" id="leave" className="member_apply">
            회원가입
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShoppingLogin;
