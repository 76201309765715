import React, { useMemo } from "react";
import styled, { keyframes, css } from "styled-components";

const SnowWrapper = styled.div`
  height: 100vh;
  background: linear-gradient(180deg, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
  overflow: hidden;
  filter: drop-shadow(0 0 10px white);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
`;

const randomRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const generateSnowflakeAnimation = (i) => {
  const randomX = Math.random() * 1000000 * 0.0001;
  const randomOffset = randomRange(-100000, 100000) * 0.0001;
  const randomXEnd = randomX + randomOffset;
  const randomXEndYoyo = randomX + randomOffset / 2;
  const randomYoyoTime = randomRange(30000, 80000) / 100000;
  const randomYoyoY = randomYoyoTime * 100;
  const randomScale = Math.random() * 10000 * 0.0001;
  const fallDuration = randomRange(10, 30);
  const fallDelay = Math.random() * 30 * -1;

  const fallAnimation = keyframes`
    ${randomYoyoTime * 100} {
      transform: translate(${randomXEnd}vw, ${randomYoyoY}vh) scale(${randomScale});
    }
    to {
      transform: translate(${randomXEndYoyo}vw, 100vh) scale(${randomScale});
    }
  `;

  return css`
    opacity: ${Math.random()};
    transform: translate(${randomX}vw, -10px) scale(${randomScale});
    animation: ${fallAnimation} ${fallDuration}s ${fallDelay}s linear infinite;
  `;
};

const Snowflake = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  ${({ animationStyle }) => animationStyle}
`;

const Snow = () => {
  const total = 200;

  const snowflakes = useMemo(() => {
    return Array.from({ length: total }, (_, i) => (
      <Snowflake key={i} animationStyle={generateSnowflakeAnimation(i)} />
    ));
  }, [total]);

  return <SnowWrapper>{snowflakes}</SnowWrapper>;
};

export default Snow;
