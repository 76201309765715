import styled, { keyframes } from "styled-components";

const dotAnimation = keyframes`
  0% {
    top: 10px;
  }
  100% {
    top: 50px;    
  }
`;

export const ChicConainer = styled.main`
  max-width: 1400px;
  min-height: 100vh;
  margin: 200px auto 100px;
  font-family: "Prompt", "Pretendard";
  .banner {
    .info {
      display: flex;
      align-items: end;
      justify-content: space-between;
      margin-bottom: 65px;
      .tit {
        font-size: 90px;
      }
      .scroll {
        display: flex;
        align-items: end;
        span {
          font-size: 24px;
        }
        button {
          margin-left: 20px;
          font-size: 30px;
          width: 40px;
          height: 70px;
          border-radius: 20px;
          background: transparent;
          border: 2px solid #000;
          position: relative;
          .dot {
            position: absolute;
            top: 10px;
            width: 10px;
            height: 10px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 10px;
            background: #000;
            animation: ${dotAnimation} 1s linear infinite 1s;
          }
        }
      }
    }
    .banners {
      display: flex;
      max-width: 1400px;
      gap: 20px;
      height: 500px;
      .box,
      .right {
        background: #efefef;
      }
      .left,
      .right {
        width: 50%;
        height: 100%;
      }
      .left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .box {
          height: calc(50% - 10px);
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .homepageInfo {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 180px;
    font-weight: 600;
    .tit {
      p {
        font-size: 130px;
        text-transform: uppercase;
      }
      span {
        font-size: 56px;
        font-weight: 700;
      }
    }
    .desc {
      margin-top: 20px;
      font-size: 20px;
      margin-bottom: 50px;
    }
    button {
      width: 570px;
      height: 100px;
      border-radius: 50px;
      font-size: 40px;
      background: transparent;
      border: 1px solid #ff4191;
      color: #ff4191;
      font-weight: 700;
    }
  }
  .productInfo {
    .info {
      margin-bottom: 60px;
      span {
        font-weight: 700;
        display: block;
        &:first-child {
          font-size: 20px;
        }
        &:nth-child(2) {
          font-size: 56px;
        }
      }
    }
    .products {
      display: flex;
      gap: 20px;
      .box {
        width: calc((100% - 40px) / 3);
        height: 350px;
        position: relative;
        transition: 0.3s;
        background: #efefef;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          pointer-events: none;
        }
        span {
          position: absolute;
          top: 60px;
          left: 40px;
          font-weight: 600;
          font-size: 32px;
          z-index: 1;
          /* color: rgba(255, 255, 255, 0.8); */
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        &:hover {
          height: 450px;
          &::after {
            background-color: transparent;
          }
          span {
            /* color: #000; */
          }
        }
      }
    }
  }
  .portfolio {
    margin: 180px 0;
    .info {
      text-transform: uppercase;
      font-size: 56px;
      font-weight: 600;
      margin-bottom: 70px;
    }
    .box {
      margin: 35px 0;
      display: flex;
      &:nth-child(1) {
        button {
          margin-right: auto;
        }
      }
      &:nth-child(2) {
        flex-direction: row-reverse;
        .info {
          text-align: right;
        }
        button {
          margin-left: auto;
        }
      }
      img {
        width: 560px;
        height: 350px;
        object-fit: contain;
        background: #efefef;
      }
      .info {
        font-size: 20px;
        padding: 50px 70px;
        .tit {
          font-size: 24px;
        }
        .desc {
          margin: 30px 0;
          span {
            display: block;
          }
        }
        button {
          border: none;
          display: flex;
          align-items: center;
          gap: 10px;
          background: transparent;
          .icon {
            display: flex;
            font-size: 25px;
          }
        }
      }
    }
  }
  .notice {
    font-weight: 600;
    .noticeInfo {
      font-size: 56px;
      margin-bottom: 100px;
    }
    .notices {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 130px;
      margin-bottom: 72px;
      li {
        width: calc((100% - 260px) / 3);
        border-top: 1px solid #000;
        padding: 30px 0 0;
        .info {
          width: 100%;
          overflow: hidden;
          font-size: 24px;
          span {
            display: block;
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            height: 38px;
          }
          .date {
            font-size: 16px;
            color: #6a6a6a;
            display: block;
            margin: 30px 0;
          }
        }
        .img {
          height: 165px;
          width: 100%;
          background: #efefef;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    button {
      width: 570px;
      height: 100px;
      border-radius: 50px;
      font-size: 40px;
      background: transparent;
      border: 1px solid rgb(255, 65, 145);
      color: rgb(255, 65, 145);
      font-weight: 700;
      margin: auto;
      display: block;
    }
  }
`;
