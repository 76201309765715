import React, { useState } from "react";

import { Container } from "./component/styled/common";
import { SalesCheckContainer } from "./component/styled/salesCheck";

import MutualBenefitHeader from "./component/common/mutualBenefitHeader";
import SideMenu from "./component/salesCheck/sideMenu";
import SalesCheckDetails from "./component/salesCheck/salesCheckDetails";

import { useDisableScroll } from "../kiosk/component/function/kiosk";

const SalesCheck = () => {
  useDisableScroll();
  const [active, setActive] = useState("salesCheck");
  const tabs = {
    salesCheck: "매출확인",
    orderHistory: "주문내역",
    statistics: "주문통계",
    settlementForm: "정산신청",
  };

  return (
    <Container>
      <SalesCheckContainer>
        <MutualBenefitHeader />
        <div className="container">
          <SideMenu active={active} setActive={setActive} tabs={tabs} />
          <SalesCheckDetails active={active} tabs={tabs} />
        </div>
      </SalesCheckContainer>
    </Container>
  );
};

export default SalesCheck;
