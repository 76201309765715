import React from "react";
import { Swiper , SwiperSlide } from "swiper/react";
import { Navigation }  from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const SectionSwiper = () => {
  
  return (
    <>
      <div className="section section_swiper" id="section0" style={{zIndex : 0}}>
        <Swiper modules={[Navigation]}
          slidesPerView={1}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          loop={true}
          speed={2000}
          autoplay=
          {
            {
              delay: 4500,
              disableOnInteraction: false
            }
          }
        >
          {swiper.map((item, i) =>
            <SwiperSlide key={i}>
              <div className="slide swiper-slide" id={item.id}>
                <div className="slide_text_warp">
                  { item.id === "slide1" ?
                    <span className="font_32 font_color_2 font400 pdb_30">{item.swiper}</span> 
                    :
                    <span className="font_32 font_color_2 font400 pdb_30">{item.swiper}<br />{item.swiper1}</span>
                  }
                  <span className="font_54 font_color_white font400 pdb_30">{common.smart}<br /><strong>{common.name}</strong>하세요.</span>
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <div className="swiper-button-prev swiper-button-white" style={{top : `calc(50% - 25px)`}}/>
        <div className="swiper-button-next swiper-button-white" />
      </div>
       </>
  );
}


export default SectionSwiper;