import React, { useEffect, useState, useRef } from "react";

import { MobileOrderItems } from "../../styled/pickup";

import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

import Progress from "../desktop/orderDetails/detailsContent/progress";
import Requests from "../desktop/orderDetails/detailsContent/requests";
import OrderMenu from "../desktop/orderDetails/detailsContent/orderMenu";

const MobileOrders = ({ tabs, active, setActive }) => {
  const temp = Array.from({ length: 7 });

  const contentRefs = useRef([]);
  const orderListRef = useRef(null);

  const [selected, setSelected] = useState(null);
  const [stepper, setStepper] = useState("15~20분");

  useEffect(() => {
    setSelected(null);
    if (orderListRef.current) {
      orderListRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [active]);

  const toggleItem = (index) => {
    if (selected === index) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  };

  const renderActions = (active) => {
    switch (active) {
      case "completedOrders":
        return null;

      case "inProgress":
        return (
          <div className="action">
            <button className="notify">음식완료 알림전송</button>
          </div>
        );

      case "orders":
        return (
          <div className="action">
            <button>
              <AiFillCaretDown />
            </button>
            <span className="stepper">{stepper}</span>
            <button>
              <AiFillCaretUp />
            </button>
          </div>
        );

      default:
        return null;
    }
  };

  const renderControls = (active) => {
    switch (active) {
      case "completedOrders":
        return null;

      case "inProgress":
        return (
          <div className="controls">
            <button>취소</button>
            <button>완료</button>
          </div>
        );

      case "orders":
        return (
          <div className="controls">
            <button>거절</button>
            <button>승낙</button>
          </div>
        );

      default:
        return null;
    }
  };

  const isSelected = (index) => {
    if (selected === index) {
      return true;
    }
    return false;
  };

  return (
    <div className="content">
      <ul className="order" ref={orderListRef}>
        {temp.map((item, index) => (
          <MobileOrderItems>
            <div className="orderItemInfo">
              <div className="icon">
                <img src="/img/mutualBenefit/pickup.png" alt="" />
              </div>
              <div className="info">
                <span className="tit">47번 주문 / 픽업</span>
                <span className="con">메뉴 1개 - 총 28,000원 (결제완료)</span>
              </div>
              {active === "inProgress" && (
                <div className="orderProgress green">
                  <div className="progressRemaining">15분</div>
                </div>
              )}
            </div>
            {renderActions(active)}
            <div
              className="toggleButton"
              style={{ marginBottom: !isSelected(item) && active === "completedOrders" ? 0 : 10 }}>
              <button onClick={() => toggleItem(index)}>
                <span>주문정보</span>
                <div className="icon">
                  {isSelected(index) ? <AiFillCaretUp /> : <AiFillCaretDown />}
                </div>
              </button>
            </div>
            <div
              className="orderItemContent"
              ref={(el) => (contentRefs.current[index] = el)}
              style={{
                maxHeight: isSelected(index)
                  ? `${contentRefs.current[index]?.scrollHeight}px`
                  : "0px",
                marginBottom: isSelected(index) ? 10 : 0,
              }}>
              <Progress />
              <Requests />
              <OrderMenu />
            </div>
            {renderControls(active)}
          </MobileOrderItems>
        ))}
      </ul>
    </div>
  );
};

export default MobileOrders;
