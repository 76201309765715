import React from "react";
import { SalesDetailedViewContainer } from "../styled/salesCheck";
import { FaReceipt } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";
import { sales } from "../function/kiosk";
import moment from "moment";

const SalesDetailedView = ({ transformValue, data, detail }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const isPay = {
    0: { type: "completed", label: "완료" },
    1: { type: "cancel", label: "취소" },
  };

  const formatCreatedAt = (created) => {
    const createArray = [...created];

    return moment({
      year: createArray[0],
      month: createArray[1] - 1,
      day: createArray[2],
      hour: createArray[3],
      minute: createArray[4],
      second: createArray[5],
    }).format("YYYY-MM-DD HH:mm:ss");
  };

  return (
    <SalesDetailedViewContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <div className="box">
        {isMobile ? (
          <ul>
            {data.map((item, index) => (
              <li key={index} className="listItem">
                <div className="contentItem">
                  <span className="tit">결제시간</span>
                  <span className="con paymentMethod">{sales[item.payType].label}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제수단</span>
                  <span className="con paymentMethod">{sales[item.payType].label}</span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제상태</span>
                  <span className="con paymentStatus">
                    <span className={isPay[item.type].type}>{isPay[item.type].label}</span>
                  </span>
                </div>
                <div className="contentItem">
                  <span className="tit">결제금액</span>
                  <span className="con paymentAmount">{item.price.toLocaleString()}원</span>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <table>
            <thead>
              <tr>
                <th>결제시간</th>
                <th>결제수단</th>
                <th>결제상태</th>
                <th>결제금액</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{formatCreatedAt(item.createdAt)}</td>
                  <td>{sales[item.payType].label}</td>
                  <td className="paymentStatus">
                    <span className={isPay[item.type].type}>{isPay[item.type].label}</span>
                  </td>
                  <td>{item.price.toLocaleString()}원</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <button className="downloadExcel" onClick={() => alert("준비 중입니다.")}>
        엑셀 다운로드
      </button>
    </SalesDetailedViewContainer>
  );
};

export default SalesDetailedView;
