import NoticeWriteForm from "../../../common/noticeWriteForm";
import QnaTitle from "./qnaTitle";

export default function QnaWriteForm () {
  return(
    <div className="inner_content">
      <QnaTitle />
      <NoticeWriteForm />
    </div>
  )
}