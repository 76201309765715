import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "./sideMenu";
import Modal from "./modal";
import { setFunctionIndex, setServiceIndex, setApplicationIndex } from "../store/compoIndex";
import { onClickLogout } from "../store/user";
import { resetSetting } from "../store/scheduler";
import { HeaderContainer } from "./styled/common";
import axios from "axios";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdMenu, MdOutlineSearch } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";

const Header = () => {
  const dispatch = useDispatch();
  const [clickSideMenu, setClickSideMenu] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, []);

  const getNotification = async () => {
    const url = "/api/notificationHistory";
    const body = {
      mberNo: user.mberNo,
    };
    const res = await axios.post(url, body);
    const data = res?.data;
    if (data && data.length > 0) {
      const filterNotification = data.filter((not) => not.readYn === "N");
      setNotification(filterNotification);
    }
  };

  const menuItems = [
    { title: "기능소개", link: "/function", items: sideMenu, action: setFunctionIndex },
    { title: "활용사례", link: "/service", items: serviceInfoMenu, action: setServiceIndex },
    { title: "회원사", link: "/member", items: memberMenu },
    { title: "서비스 소개", link: "/application", items: sideMenuSub, action: setApplicationIndex },
    { title: "공지/안내", link: "/notice", items: noticeBanner },
    { title: "서비스", link: "#", items: serviceMenu },
  ];

  const handleLogoutModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    await dispatch(resetSetting());
    setModalOpen(false);
    navigate("/login");
  };

  const LogoutModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그아웃</h2>
        <button onClick={handleLogoutModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          로그아웃 하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onClickModalCheck}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handlEChanggo = (e) => {
    e.preventDefault();

    if (!user) {
      navigate("/login");
    } else {
      openMyPage();
    }
  };

  const openMyPage = async () => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: user.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      navigate("/mypage/homepage");
    }
  };

  const Navigation = ({ menuItems }) => {
    return (
      <nav>
        <ul>
          {menuItems.map((menu, index) => (
            <li key={index} className="dropdown">
              <Link
                to={menu.link || "/"}
                onClick={() => {
                  if (menu.action && typeof menu.action === "function") {
                    dispatch(menu.action(0));
                  }
                }}>
                {menu.title}
              </Link>
              {menu.items && (
                <ul className="dropdown-menu">
                  {menu.items.map((item, i) =>
                    item.label === "그룹" ? (
                      <li key={i}>
                        <Link
                          to={item.link}
                          target="_blank"
                          onClick={() =>
                            user && localStorage.setItem("user", JSON.stringify(user))
                          }>
                          {item.label}
                        </Link>
                      </li>
                    ) : item.label === "e층창고" ? (
                      <li key={i}>
                        <Link onClick={(e) => handlEChanggo(e)}>{item.label}</Link>
                      </li>
                    ) : (
                      <li key={i}>
                        <Link
                          to={item.link}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (menu.action && typeof menu.action === "function") {
                              dispatch(menu.action(i));
                            }
                            setClickSideMenu(false);
                          }}>
                          {item.label}
                        </Link>
                      </li>
                    ),
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <HeaderContainer>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      <div className="headerInner">
        <h1>{service.welcome}</h1>
        <Link to={"/"}>
          <div>
            <img className="logo" src="/img/logo_monoti.png" />
          </div>
        </Link>
        <Navigation menuItems={menuItems} />
        <div className="headerBtnArea">
          {!user ? (
            <button className="login" onClick={() => navigate("/login")}>
              <i className="icon_login" />
              <span className="sound_only">로그인</span>
            </button>
          ) : (
            <>
              <button onClick={handleLogoutModal} className="logout">
                <i className="icon_logout"></i>
              </button>
              <button onClick={() => navigate("/mypage/search")} className="search">
                <MdOutlineSearch />
              </button>
              <button onClick={() => navigate("/mypage")} className="mypage">
                <AiOutlineUser />
              </button>
              <Link
                to="/mypage/notify"
                target="_blank"
                className="notify"
                onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
                <IoNotificationsOutline />
                {notification.length > 0 && (
                  <span>{notification.length < 99 ? notification.length : "99+"}</span>
                )}
              </Link>
            </>
          )}
          <button onClick={() => setClickSideMenu(true)} className="sideMenu">
            <MdMenu />
          </button>
        </div>
      </div>
      <SideMenu setClickSideMenu={setClickSideMenu} clickSideMenu={clickSideMenu} user={user} />
    </HeaderContainer>
  );
};
export default Header;
