import React, { useState } from "react";

import { CommunityContainer } from "./component/styled/community";

import MutualBenefitHeader from "./component/common/mutualBenefitHeader";
import SideMenu from "./component/community/sideMenu";
import { Container } from "./component/styled/common";
import WeeklyComments from "./component/community/weeklyComments";
import { useDisableScroll } from "../kiosk/component/function/kiosk";

import Group from "./component/community/group";
import ContactUs from "./component/community/contactUs";
import OwnerMessage from "./component/community/ownerMessage";
import ShareHobby from "./component/community/shareHobby";

const Community = () => {
  useDisableScroll();
  const [active, setActive] = useState("group");

  const tabs = {
    group: "그룹",
    contactUs: "문의하기",
    ownerMessage: "사장님 한마디",
    shareHobby: "취미 공유",
  };

  const renderComponent = () => {
    switch (active) {
      case "group":
        return <Group />;
      case "contactUs":
        return <ContactUs />;
      case "ownerMessage":
        return <OwnerMessage />;
      case "shareHobby":
        return <ShareHobby />;
    }
  };

  return (
    <Container>
      <CommunityContainer>
        <MutualBenefitHeader />
        <div className="container">
          <SideMenu tabs={tabs} active={active} setActive={setActive} />
          <div className="content community">
            <div className="viewer">
              <WeeklyComments />
              {renderComponent()}
            </div>
          </div>
        </div>
      </CommunityContainer>
    </Container>
  );
};

export default Community;
