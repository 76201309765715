import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

import { ProjectDetailFiles, ProjectFileBoxViewContainer } from "../common/styled/project";

import { MdMenu, MdOutlineNavigateNext } from "react-icons/md";
import { LuLayoutGrid } from "react-icons/lu";

import ProjectFileBoxItem from "./projectFileBoxItem";
import ProjectFileBoxFolders from "./projectFileBoxFolders";
import ImageDetailViewer from "../../../../../common/imageDetailViewer";
import Modal from "../../../../../common/modal";
import { hideLoading, showLoading } from "../../../../../store/loading";

const ProjectFileBoxView = ({ setShowSideMenu, showSideMenu, clickSideMenu, setClickSideMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const [folderState, setFolderState] = useState({
    gbn: "",
    orgs: "",
    sub: "",
    projects: "",
    fileType: "",
  });
  const folderOrder = ["gbn", "orgs", "sub", "projects", "fileType"];
  const [fetchedPages, setFetchedPages] = useState(new Set());
  const [current, setCurrent] = useState("gbn");
  const [folders, setFolders] = useState({
    gbn: [
      { key: "P", name: "개인" },
      { key: "C", name: "업무" },
      { key: "G", name: "그룹" },
    ],
    orgs: [],
    sub: [
      { key: "S", name: "일반" },
      { key: "P", name: "프로젝트" },
    ],
    projects: [],
    fileType: [
      { key: "I", name: "사진" },
      { key: "V", name: "동영상" },
      { key: "F", name: "파일" },
    ],
  });
  const [files, setFiles] = useState({ I: {}, V: {}, F: {} });
  const [origin, setOrigin] = useState({ I: {}, V: {}, F: {} });
  const [counts, setCounts] = useState({});
  const [isDetail, setIsDetail] = useState(false);
  const [imageOrder, setImageOrder] = useState([]);
  const [fileHeight, setFileHeight] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [totalGalleryPage, setTotalGalleryPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loadingImages, setLoadingImages] = useState({});

  useEffect(() => {
    updateImageSize();

    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".fileItem")?.offsetWidth;
    setFileHeight(previewWidth);
  };

  useEffect(() => {
    const { gbn } = folderState;
    if (!gbn) return;

    if (folderState.gbn !== "P") {
      getOrgNm(gbn);
    } else {
      getGalleryPhotoList({ gbn });
    }
  }, [folderState.gbn]);

  useEffect(() => {
    const { gbn, orgs } = folderState;
    if (!gbn || !orgs) return;

    if (folderState.gbn !== "P" && folderState.sub === "P") {
      getProjectList({ gbn, orgs });
    } else {
      getGalleryPhotoList({ gbn, orgs });
    }
  }, [folderState.sub]);

  useEffect(() => {
    if (!folderState.gbn || !folderState.projects) return;

    if (folderState.gbn !== "P" && folderState.projects) {
      getFiles(folderState.projects);
    }
  }, [folderState.projects]);

  useEffect(() => {
    if (pageNumber === 0 || pageNumber > totalGalleryPage || fetchedPages.has(pageNumber)) return;
    if (folderState.gbn !== "P" && folderState.sub === "P") return;

    mergeGalleryPhotoList(pageNumber);
  }, [pageNumber]);

  // 그룹, 회사명 조회
  const getOrgNm = async (gbn) => {
    if (!gbn) return;

    const url = "/api/orgNmList";
    const body = {
      gbn,
      mberNo: user.mberNo,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const orgs =
        res.data.length > 0
          ? res.data.map((item) => ({
              key: item.orgNo,
              name: item.orgNm,
            }))
          : [];
      setFolders((data) => ({ ...data, orgs: orgs }));
    }
  };

  // 프로젝트 목록 조회
  const getProjectList = async (org) => {
    const url = "/api/projectList";
    const body = {
      mberNo: user.mberNo,
      delYn: "N",
      gbn: org.gbn,
      orgNo: org.orgs,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const projects =
        res.data.fixedProjectList.length > 0
          ? res.data.fixedProjectList.map((item) => ({
              key: item.projectNo,
              name: item.projectNm,
            }))
          : [];
      setFolders((data) => ({ ...data, projects: projects }));
    }
  };

  // 갤러리 조회
  const getGalleryPhotoList = async (org) => {
    try {
      if (!org) return;
      dispatch(showLoading());

      const url = "/api/galleryPhotoList";
      const body = {
        gbn: org.gbn,
        mberNo: user.mberNo,
        delYn: "N",
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: false,
      };

      if (org.gbn === "G") {
        body.groupNos = [org.orgs];
      }
      if (org.gbn === "C") {
        body.cmpnyNos = [org.orgs];
      }

      const res = await axios.post(url, body);
      if (res.status === 200 && res.data.content) {
        const data = res.data.content.map((item) => ({
          ...item,
          fileType: "I",
          filePath: item.path,
        }));

        setTotalGalleryPage(res.data.totalPages);
        setCounts({ I: res.data.totalElements });
        const list = splitFiles(data.slice(0, 50));
        setOrigin(data);
        setFiles(list);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const mergeGalleryPhotoList = async (pageNumber) => {
    const start = pageNumber * 50;
    const end = start + 50;
    const data = origin.slice(start, end);

    const updatedFiles = { ...files };
    const splitData = splitFiles(data);

    for (const fileType in splitData) {
      if (splitData.hasOwnProperty(fileType)) {
        for (const date in splitData[fileType]) {
          if (splitData[fileType].hasOwnProperty(date)) {
            updatedFiles[fileType][date] = [
              ...(updatedFiles[fileType][date] || []),
              ...splitData[fileType][date],
            ];
          }
        }
      }
    }

    setFiles(updatedFiles);
    setFetchedPages((prev) => new Set(prev.add(pageNumber)));
  };

  // 파일 조회
  const getFiles = async (projectNo) => {
    try {
      const url = "/api/fileList";
      const body = {
        projectNo,
        delYn: "N",
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: false,
      };

      const res = await axios.post(url, body);
      if (res.status === 200) {
        setOrigin(res.data);

        const list = splitFiles(res.data);
        setFiles(list);

        const count = Object.keys(list).reduce(
          (acc, key) => {
            const totalLength = Object.values(list[key]).reduce((sum, arr) => sum + arr.length, 0);
            acc[key] = totalLength;
            return acc;
          },
          { F: 0, I: 0, V: 0 },
        );
        setCounts(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 선택 초기화
  const resetSelections = () => {
    setFolderState({ gbn: "", orgs: "", sub: "", projects: "", fileType: "" });
    setCurrent("gbn");
  };

  // 선택한 폴더 상태
  const isFolderState = () => {
    const path = folderOrder.reduce((acc, type) => {
      if (folderState[type]) {
        const folderItem = folders[type].find((item) => item.key === folderState[type]);
        if (folderItem) {
          acc.push({
            name: folderItem.name,
            type: type,
          });
        }
      }
      return acc;
    }, []);

    return (
      <>
        {path.length > 0 &&
          path.map((item, index) => (
            <React.Fragment key={index}>
              <span onClick={() => revertToFolder(item.type)} style={{ cursor: "pointer" }}>
                {item.name}
              </span>
              {index !== path.length - 1 && (
                <span className="icon">
                  <MdOutlineNavigateNext />
                </span>
              )}
            </React.Fragment>
          ))}
      </>
    );
  };

  // 보여줄 폴더
  const updateFolderState = (state, type, key) => {
    let newState = { ...state, [type]: key };

    if (type === "gbn" && key === "P") {
      newState.fileType = "I";
      setCurrent("fileType");
    } else if (
      (type === "orgs" && (key === "C" || key === "G") && state.sub === "S") ||
      (type === "sub" && newState.sub === "S")
    ) {
      newState.fileType = "I";
      setCurrent("fileType");
    } else if (type !== "fileType") {
      const nextType = findNextFolderType(newState);
      setCurrent(nextType || "fileType");
    } else {
      setCurrent("fileType");
    }

    return newState;
  };

  // 폴더 되돌리기
  const revertToFolder = (type) => {
    if (
      (type === "sub" && folderState.sub === "S") ||
      type === "fileType" ||
      (type === "gbn" && folderState.gbn === "P")
    ) {
    } else {
      setFolderState((prevState) => {
        const newState = { ...prevState };
        const index = folderOrder.indexOf(type);

        if (index !== -1 && index < folderOrder.length - 1) {
          folderOrder.slice(index + 1).forEach((key) => {
            newState[key] = "";
          });
        }

        return updateFolderState(newState, type, newState[type]);
      });

      if (type !== "projects") {
        setFiles({ I: {}, V: {}, F: {} });
      }

      setSelectedFiles([]);
      setPageNumber(1);
      setTotalGalleryPage(0);
    }
  };

  // 폴더 변경
  const changeFolder = async (type, key) => {
    setFolderState((state) => {
      return updateFolderState(state, type, key);
    });

    setSelectedFiles([]);
  };

  const findNextFolderType = (folderState) => {
    const next = folderOrder.find((type) => !folderState[type]);
    return next || null;
  };

  // 파일 선택/해제
  const toggleFileItem = (file) => {
    setSelectedFiles((item) => {
      if (item.includes(file)) {
        return item.filter((item) => item !== file);
      } else {
        return [...item, file];
      }
    });
  };

  // 전체 파일 선택/해제
  const handleAllCheckboxClick = (data, isChecked) => {
    const allFiles = data.flat();
    if (isChecked) {
      setSelectedFiles((prevSelectedFiles) => [...new Set([...prevSelectedFiles, ...allFiles])]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((file) => !allFiles.includes(file)),
      );
    }
  };

  // 이미지 클릭
  const handleImageClick = (item) => {
    if (folderState.fileType === "F") return;

    const splitted = splitFiles(origin);
    const fileTypeArray = [].concat(...Object.values(splitted[folderState.fileType]));
    const index = fileTypeArray.findIndex((image) => image.filePath === item.filePath);

    if (index === -1) return;

    const images = fileTypeArray.map((item) => ({
      path: item.filePath,
      createNm: item.mberNm,
      createDt: item.createDt,
    }));

    setIsDetail(true);
    const reorderedImages = [...images.slice(index), ...images.slice(0, index)];
    setImageOrder(reorderedImages);
  };

  // 이미지, 동영상, 파일 분리
  const splitFiles = (data) => {
    const splitData = { I: {}, V: {}, F: {} };

    data.forEach((file) => {
      if (splitData.hasOwnProperty(file.fileType)) {
        const date = moment(file.createDt).format("YYYY-MM-DD");
        if (!splitData[file.fileType][date]) {
          splitData[file.fileType][date] = [];
        }
        splitData[file.fileType][date].push(file);
      }
    });

    return splitData;
  };

  const handleModalOpen = (type, item = null) => {
    setModalContent({ type, item });
    setModalOpen(true);
  };

  // 모달
  const ModalForm = ({ type, item }) => {
    const modalDetails = {
      portfolio: {
        title: "포트폴리오",
        body: "포트폴리오로 전송하시겠습니까?",
        onConfirm: () => navigate("/mypage/registerPhotoUpload", { state: { images: item } }),
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => setModalOpen(false),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <ProjectFileBoxViewContainer>
      <ProjectDetailFiles>
        <div className="filesHeader">
          <div className="fileInfo">
            <div className="filesTop">
              <h3 onClick={resetSelections} style={{ cursor: "pointer" }}>
                파일함
              </h3>
              <div style={{ display: "flex", gap: 10 }}>
                {isTablet && (
                  <button className="sideMenuButton" onClick={() => setShowSideMenu(!showSideMenu)}>
                    <MdMenu />
                  </button>
                )}
                {isMobile && (
                  <button
                    className="sideMenuButton"
                    onClick={() => setClickSideMenu(!clickSideMenu)}>
                    <LuLayoutGrid />
                  </button>
                )}
              </div>
            </div>
            <div className="filePath">{isFolderState()}</div>
          </div>
          {current === "fileType" && selectedFiles.length > 0 && (
            <div className="fileButtons">
              <button onClick={() => alert("준비 중입니다.")}>내려받기</button>
              {folderState.fileType === "I" && (
                <button onClick={() => handleModalOpen("portfolio", selectedFiles)}>
                  포트폴리오 전송
                </button>
              )}
            </div>
          )}
        </div>
        <ProjectFileBoxFolders
          folders={folders}
          current={current}
          folderState={folderState}
          changeFolder={changeFolder}
          counts={counts}
        />
        {Object.values(files).some((type) =>
          Object.values(type).some((list) => list.length > 0),
        ) && (
          <ProjectFileBoxItem
            files={files}
            folderState={folderState}
            handleAllCheckboxClick={handleAllCheckboxClick}
            toggleFileItem={toggleFileItem}
            selectedFiles={selectedFiles}
            updateImageSize={updateImageSize}
            fileHeight={fileHeight}
            setPageNumber={setPageNumber}
            handleImageClick={handleImageClick}
            setLoadingImages={setLoadingImages}
            loadingImages={loadingImages}
          />
        )}
        {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={<ModalForm type={modalContent?.type} item={modalContent?.item} />}
          w="300px"
          h="auto"
        />
      </ProjectDetailFiles>
    </ProjectFileBoxViewContainer>
  );
};

export default ProjectFileBoxView;
