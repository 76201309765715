import React, { useEffect, useRef, useState } from "react";
import { LuChevronDown, LuChevronUp } from "react-icons/lu";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const NotifyReleaseNotes = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [devNotices, setDevNotices] = useState([]);
  const [totalNoticePage, setTotalNoticePage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [openNotice, setOpenNotice] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getDevNotice(1);

    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (pageNumber > totalNoticePage) return;
    getDevNotice(pageNumber);
  }, [pageNumber]);

  const getDevNotice = async (number = 1) => {
    const url = "/api/devNotice";
    const body = devNoticeCond(number);

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        console.log(res);
        setDevNotices((data) => [...data, ...res.data.content]);
        setTotalNoticePage(res.data.totalPages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const devNoticeCond = (number) => {
    const pageSize = 10;
    const pageNumber = number - 1;

    return {
      cmpnyNo: 16421,
      gbn: "DEV",
      delYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPageNumber((page) => page + 1);
    }
  };

  const isOpenNotice = (noticeNo) => {
    if (noticeNo === openNotice) {
      return true;
    }
    return false;
  };

  return (
    <div className="section">
      <ul style={{ minHeight: 800 }}>
        {devNotices.length > 0 &&
          devNotices?.map((item) => (
            <li>
              <div
                className={`devNotifyWrapper${isOpenNotice(item.noticeNo) ? " active" : ""}`}
                onClick={() => setOpenNotice(isOpenNotice(item.noticeNo) ? null : item.noticeNo)}>
                <div className="title font_16">
                  {
                    <img
                      src={item.imgPath ? imgHandler(item.imgPath) : "/img/application_price_1.png"}
                    />
                  }
                  <span className="noticeSj">{item.noticeSj}</span>
                  <span>{item.mberNm}</span>
                  <div className="date">{item.noticeDe}</div>
                  <button className="toggleCollapseButton">
                    {isOpenNotice(item.noticeNo) ? <LuChevronUp /> : <LuChevronDown />}
                  </button>
                  {user && user.cmpnyNo === 16421 && (
                    <button
                      onClick={() =>
                        navigate("/mypage/createReleaseNotes", {
                          state: { releaseNote: item },
                        })
                      }
                      className="editButton">
                      수정
                    </button>
                  )}
                </div>
                <div
                  className={`devNoticeContent font_14`}
                  dangerouslySetInnerHTML={{ __html: item.noticeCn }}></div>
              </div>
            </li>
          ))}
      </ul>
      <div id="observer" style={{ height: "10px" }}></div>
    </div>
  );
};

export default NotifyReleaseNotes;
