import React from "react";
import styled from "styled-components";

const SunnyWrapper = styled.div`
  height: 100vh;
  background: linear-gradient(345deg, rgba(97, 181, 227, 1) 0%, rgba(201, 221, 227, 1) 100%);
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  border: 0;
`;

const SunnyContainer = styled.div`
  .sun {
    position: absolute;
    margin: -20px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: #efefef;
    opacity: 0.9;
    box-shadow: 0px 0px 40px 15px #efefef;
  }
  .ray_box {
    position: absolute;
    margin: auto;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 140px;
    animation: ray_anim 120s linear infinite;
    animation: ray_anim 120s linear infinite;
  }
  .ray {
    background: #fff;
    margin-left: 10px;
    border-radius: 80% 80% 0 0;
    position: absolute;
    opacity: 0.1;
  }

  .ray1 {
    height: 340px;
    width: 60px;
    transform: rotate(180deg);
    top: -350px;
    left: 30px;
  }
  .ray2 {
    height: 200px;
    width: 16px;
    transform: rotate(220deg);
    top: -180px;
    left: 150px;
  }
  .ray3 {
    height: 340px;
    width: 100px;
    transform: rotate(250deg);
    top: -160px;
    left: 200px;
  }
  .ray4 {
    height: 240px;
    width: 28px;
    transform: rotate(305deg);
    top: 60px;
    left: 200px;
  }
  .ray5 {
    height: 280px;
    width: 60px;
    transform: rotate(-15deg);
    top: 120px;
    left: 80px;
  }
  .ray6 {
    height: 180px;
    width: 100px;
    transform: rotate(30deg);
    top: 120px;
    left: -80px;
  }
  .ray7 {
    height: 360px;
    width: 20px;
    transform: rotate(70deg);
    top: -70px;
    left: -80px;
  }
  .ray8 {
    height: 240px;
    width: 60px;
    transform: rotate(100deg);
    top: -90px;
    left: -180px;
  }
  .ray9 {
    height: 160px;
    width: 20px;
    transform: rotate(120deg);
    top: -130px;
    left: -120px;
  }
  .ray10 {
    height: 380px;
    width: 46px;
    transform: rotate(150deg);
    top: -370px;
    left: -120px;
  }

  @keyframes ray_anim {
    0% {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Sunny = () => {
  return (
    <SunnyWrapper>
      <SunnyContainer>
        <div class="sun">
          <div class="ray_box">
            <div class="ray ray1"></div>
            <div class="ray ray2"></div>
            <div class="ray ray3"></div>
            <div class="ray ray4"></div>
            <div class="ray ray5"></div>
            <div class="ray ray6"></div>
            <div class="ray ray7"></div>
            <div class="ray ray8"></div>
            <div class="ray ray9"></div>
            <div class="ray ray10"></div>
          </div>
        </div>
      </SunnyContainer>
    </SunnyWrapper>
  );
};

export default Sunny;
