import { useOutletContext, useParams } from "react-router";
import { useEffect, useState } from "react";

import ProductsViewForm from "./component/productsViewForm";


const ProductsView = () => {
  const {cmpnyNo} = useOutletContext();
  const {prdNo} = useParams(); 

  const [prduct,setPrduct] = useState([]);

  useEffect(() => {
    if(cmpnyNo){
      getCmpnyPrductList()
    }
  },[cmpnyNo])
 
  const getCmpnyPrductList = () => {
    const url = '/api/prductStock';

    const req = {
      cmpnyNo: cmpnyNo,
      prdNo,
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 1,
      paged: true,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    };

    fetch(url, options)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setPrduct(data.content);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return(
    <ProductsViewForm prduct={prduct}/>
  )
};

export default ProductsView;