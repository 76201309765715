import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const Notice = ({ cmpnyNo, homepageInfo, url }) => {
  const navigate = useNavigate();
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      noticeCl: "공지",
      offset: 0,
      pageNumber: 0,
      pageSize: 3,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      setNotices(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="notice">
      <div className="noticeInfo">{homepageInfo.cmpnyNm}의 소식을 알려드릴게요</div>
      <ul className="notices">
        {notices.map((item) => (
          <li onClick={() => navigate(`/shop/${url}/shoppingNoticeView/${item.noticeNo}`)}>
            <div className="info">
              <span>{item.noticeSj}</span>
              <span>{item.noticeCn ? item.noticeCn : ""}</span>
              <span className="date">{moment(item.createDt).format("YYYY-MM-DD")}</span>
            </div>
            <div className="img">{item.imgPath && <img src={item.imgPath} alt="" />}</div>
          </li>
        ))}
      </ul>
      <button onClick={() => navigate(`/shop/${url}/shoppingNotice?page=0`)}>
        더 많은 소식 보러가기
      </button>
    </div>
  );
};

export default Notice;
