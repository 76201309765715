import React from "react";
import { ModernHeaderContainer } from "../styled/modern";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

const ModernHeader = ({
  headerFixed,
  cmpnyNm,
  logo,
  menus,
  links,
  handleHome,
  homepageInfo,
  handleLogoutModal,
  url,
  setClickSideMenu,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const isInner = useMediaQuery({ query: "(max-width:1400px)" });

  return (
    <ModernHeaderContainer className="custom theme">
      {logo ? (
        <h1 className="logo_wrapper">
          <img className="logo logo_img" src={logo} onClick={handleHome} />
        </h1>
      ) : (
        <h1 className="logo_text cmpnyNm" onClick={handleHome}>
          {homepageInfo?.logoText ?? cmpnyNm}
        </h1>
      )}
      <div className="header_content">
        <div className="actions">
          <ul>
            <li className="tnb_btn">
              <Link to={homepageInfo ? `${url}/search?keyword=` : "search?keyword="}>
                <img src="/img/icon_search.png" alt="검색" />
              </Link>
            </li>
            <li onClick={() => setClickSideMenu(true)} className="tnb_btn">
              <div className="menuBtn" style={{ cursor: "pointer" }}>
                <img src="/img/shop_icon_menu.png" alt="전체메뉴" />
              </div>
            </li>
          </ul>
        </div>
        <div className="gnb">
          <nav>
            <ul className="font_18 font700">
              {menus.map((item) => (
                <li onClick={() => navigate(`/shop/${url}/${links[item.menuType]}`)}>
                  {item.afterMenuName ? item.afterMenuName : item.beforeMenuName}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="tnb">
          <ul className="font_14 font700">
            <li>
              {user ? (
                <span onClick={handleLogoutModal}>로그아웃</span>
              ) : (
                <Link to="/shop/login">로그인</Link>
              )}
            </li>
            {!user && (
              <li>
                <Link to="/type">회원가입</Link>
              </li>
            )}
            <li>
              <Link to={url ? `/shop/${url}/cart` : "/shop/cart"}>장바구니</Link>
            </li>
          </ul>
        </div>
      </div>
    </ModernHeaderContainer>
  );
};

export default ModernHeader;
