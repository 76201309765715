import axios from "axios";
import React, { useState, useEffect } from "react";

const TodayWeather = ({ isWeather, todayWeather, location }) => {
  return (
    <div className="location font_14">
      <div>{location}</div>
      <div>
        {isWeather(todayWeather?.weather)}
        <div className="temp font_14">
          {todayWeather?.temMin}℃/{todayWeather?.temMax}℃
        </div>
      </div>
    </div>
  );
};

export default TodayWeather;
