import styled from "styled-components";

const color = {
  refrigerated: `#2a3341`,
  roomTemperature: `#FFE072`,
  carrier: `#cdb5f5`,
};

const hoverColor = {
  refrigerated: `#4f78b8`,
  roomTemperature: `#ff8a00`,
  carrier: `#a976ff`,
};

export const KioskStorage = styled.div`
  padding: 1.0833vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  .storageTab {
    display: flex;
    align-items: center;
    li {
      width: calc(100% / 3);
      height: 9.5238vh;
      background: #d9d9d9;
      color: #4d4d4d;
      font-size: 3.8095vh;
      text-align: center;
      line-height: 9.5238vh;
      font-weight: 700;
      transition: 0.3s;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &.refrigerated {
        color: #fff;
        background: ${color.refrigerated};
      }
      &.roomTemperature {
        background: ${color.roomTemperature};
      }
      &.carrier {
        background: ${color.carrier};
      }
    }
  }
  .content {
    height: 52.6442vh;
  }
  @media screen and (max-width: 820px) {
    .storageTab li {
      height: 6.5741vh;
      line-height: 6.5741vh;
      font-size: 2.7778vh;
    }
    .content {
      height: 58.3333vh;
    }
  }
`;

export const ViewAllContainer = styled.div`
  .section {
    max-width: 80vw;
    height: 100%;
    position: relative;
    margin: auto;
  }
  .swiper-slide {
    padding: 0 10px;
  }
  .slide {
    height: 43.457vh;
    margin-bottom: 3.418vh;
    position: relative;
    .slideWrapper {
      overflow: hidden;
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      .slideContent {
        flex: 0 0 100%;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        transition: 0.5s;
        table {
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          td {
            height: 10%;
            border: 2px solid #fff;
            .index {
              width: 100%;
              height: 100%;
              position: relative;
              /* background: #424243;
              color: #fff; */
              font-weight: 700;
              border-radius: 5px;
              font-size: 12px;
              transition: 0.3s;
            }
            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            &.refrigerated .index {
              color: #fff;
              background: ${color.refrigerated};
            }
            &.roomTemperature .index {
              background: ${color.roomTemperature};
            }
            &.carrier .index {
              background: ${color.carrier};
            }
            &.empty .index {
              background: #a7a7a7;
              color: #fff;
            }
          }
        }
      }
    }
    button {
      width: 6.25vw;
      height: 6.25vw;
      max-width: 100px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;
      background: transparent;
      color: #b3b3b3;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
      &.prev {
        left: 0;
        transform: translate(-100%, -50%);
        background-image: url("/img/arrow_left.png");
      }
      &.next {
        right: 0;
        transform: translate(100%, -50%);
        background-image: url("/img/arrow_right.png");
      }
    }
  }
  .page {
    font-size: 4.8828vh;
    font-weight: 700;
  }
  @media screen and (min-width: 1400px) {
    .slide .slideWrapper .slideContent table td.hover {
      .index:hover {
        transform: scale(1.2);
        color: #fff;
        z-index: 22;
      }
      &.refrigerated {
        .index:hover {
          background: ${hoverColor.refrigerated};
        }
      }
      &.roomTemperature {
        .index:hover {
          background: ${hoverColor.roomTemperature};
        }
      }
      &.carrier {
        .index:hover {
          background: #a976ff;
        }
      }
    }
  }
  @media screen and (max-width: 820px) {
    .section {
      max-width: 85vw;
      .slide {
        height: calc(100% - 8.8828vh);
      }
    }
  }
`;

export const ViewDetailsContainer = styled.div`
  .details {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2px 2.7778vw 10px;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border: 3px solid #000;
    .refrigerated {
      color: #fff;
      background-color: ${color.refrigerated};
      .box {
        background-image: url("/img/sungsimdang/sungsimdang_bread_storage.png");
        background-position: 90% 100%;
      }
    }
    .roomTemperature {
      background-color: ${color.roomTemperature};
      .box {
        background-image: url("/img/sungsimdang/sungsimdang_roomTemperature_storage.png");
        background-position: 90% 90%;
      }
    }
    .carrier {
      background-color: ${color.carrier};
      .box {
        background-image: url("/img/sungsimdang/sungsimdang_carrier_storage.png");
        background-position: 90% 90%;
      }
    }
    .empty {
      background-color: #dedede;
      .box {
        color: #000;
        font-size: 50px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    td {
      box-sizing: border-box;
      border: 3px solid #000;
      transition: 0.3s;
      width: 10%;
      .box {
        width: 100%;
        height: calc((52.6442vh - 25px) / 4);
        font-weight: 500;
        font-size: 14px;
        padding: 12px 0 0 12px;
        background-repeat: no-repeat;
        overflow: hidden;
        background-size: 35px 35px;
        span {
          display: block;
          &:nth-child(1),
          &:nth-child(2) {
            font-weight: 700;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1401px) {
    table {
      td:not(.empty):hover {
        transform: scale(1.2);
        color: #fff;
        z-index: 22;
        border-radius: 10px;
      }
      td.refrigerated:hover {
        background: ${hoverColor.refrigerated};
      }
      td.roomTemperature:hover {
        background: ${hoverColor.roomTemperature};
      }
      td.carrier:hover {
        background: ${hoverColor.carrier};
      }
    }
  }
  @media screen and (max-width: 1400px) {
    table td {
      width: 20%;
    }
  }
  @media screen and (max-width: 820px) {
    table td {
      width: calc(100% / 3);
      .box {
        height: calc((58.3333vh - 20px) / 3);
      }
      &.empty .box {
        font-size: 30px;
      }
    }
  }
`;

export const UserInfoModalContainer = styled.div`
  position: fixed;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  .modal {
    position: absolute;
    max-width: 900px;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #a6a6a6;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 40px;
    border-radius: 50px;
    box-sizing: border-box;
    font-weight: 700;
    overflow: hidden;
    width: 100%;
    .modalContent {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 5.5556vw;
      .userInfo {
        width: 100%;
        height: 100%;
        ul {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
      }
      button {
        position: absolute;
        top: 2vw;
        right: 2vw;
        border: none;
        background: transparent;
        display: flex;
      }
    }
  }
  @media screen and (max-width: 900px) {
    .modal {
      width: 90%;
      height: auto;
      max-width: none;
      font-size: 20px;
      .modalContent {
        button {
          top: 20px;
          right: 20px;
          font-size: 30px;
        }
      }
    }
  }
`;

export const UserInfoFormContainer = styled.div`
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  .modal {
    &.confirm {
      height: 700px;
      max-width: 700px;
    }
    &.form {
      height: 700px;
      max-width: 989px;
    }
  }
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
  }
  .userInfoForm {
    font-weight: 700;
    height: 100%;
    li {
      display: flex;
      align-items: center;
      font-size: 28px;
      &:not(:last-child) {
        border-bottom: 3px solid #efefef;
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
      .tit {
        width: 160px;
        display: inline-block;
      }
      .con {
        width: calc(100% - 170px);
      }
      label {
        display: flex;
        align-items: center;
        width: 100%;
        input[type="text"],
        input[type="number"] {
          border: none;
          font-weight: 700;
          &:focus {
            box-shadow: none !important;
            border: none !important;
          }
        }
        input[type="text"] {
          width: 100%;
        }
      }
      &.quantity .con {
        display: flex;
        gap: 10px;
        align-items: center;
        .preview {
          color: #bbb;
          display: flex;
          gap: 5px;
          font-weight: 600;
        }
        button {
          border: 2px solid #efefef;
          padding: 0 15px;
          border-radius: 10px;
          font-weight: 700;
        }
      }
      &.storage {
        align-items: flex-start;
        .storageType {
          display: flex;
          gap: 10px;
          width: calc(100% - 170px);
          .box {
            width: calc((100% - 20px) / 3);
            height: 170px;
            display: flex;
            flex-direction: column;
            .bg {
              width: 100%;
              height: 120px;
              border: 3px solid #d4d4d4;
              display: inline-block;
              border-radius: 5px;
              text-align: center;
              background-repeat: no-repeat;
              background-position: 50% 20%;
              background-size: 30%;
              position: relative;
              margin-bottom: 10px;
              &.refrigerated {
                background-image: url("/img/sungsimdang/sungsimdang_refrigerated_storage.png");
              }
              &.roomTemperature {
                background-image: url("/img/sungsimdang/sungsimdang_roomTemperature_storage.png");
              }
              &.carrier {
                background-image: url("/img/sungsimdang/sungsimdang_carrier_storage.png");
              }
              span {
                position: absolute;
                font-size: 22px;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            .storageNumber {
              width: 100%;
              height: 40px;
              font-size: 20px;
              display: flex;
              align-items: center;
              border: 3px solid #d4d4d4;
              border-radius: 5px;
              font-weight: 700;
              button {
                width: 40px;
                font-size: 20px;
                border: none;
                background: transparent;
                svg {
                  display: block;
                  margin: auto;
                }
              }
              input[type="number"] {
                width: calc(100% - 80px);
                height: 100%;
                border: none !important;
                border-left: 3px solid #d4d4d4 !important;
                border-right: 3px solid #d4d4d4 !important;
                text-align: center;
                &:focus {
                  outline: none;
                  border: none !important;
                  border-left: 3px solid #d4d4d4 !important;
                  border-right: 3px solid #d4d4d4 !important;
                }
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              }
            }
          }
        }
      }
      &.paymentMethod {
        .con {
          display: flex;
          gap: 10px;
          align-items: center;
          label {
            height: 35px;
            width: 35px;
            position: relative;
            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              padding: 0;
              width: 0;
              height: 0;
            }
            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              border: 2px solid #d4d4d4;
              border-radius: 10px;
              background-color: transparent;
              transition: all 0.3s ease;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg) scale(0);
                opacity: 0;
                width: 13px;
                height: 24px;
                border: solid #fff;
                border-width: 0px 3px 3px 0px;
                transition: 0.3s;
              }
            }
            input:checked ~ .checkmark {
              background: ${color.refrigerated};
              border: 1px solid ${color.refrigerated};
              &:after {
                opacity: 1;
                transform: translate(-50%, -65%) rotate(45deg) scale(1);
                border-color: #fff;
              }
            }
            &:hover .checkmark {
              background-color: #efefef;
            }
          }
          label + span {
            margin-right: 10px;
          }
        }
      }
    }
  }
  button.submit {
    bottom: 0;
    position: absolute;
    right: 0;
    font-size: 30px;
    background: #2a3341;
    width: 200px;
    height: 50px;
    border-radius: 40px;
    color: #fff;
    z-index: 100;
    border: none;
  }

  @media screen and (max-width: 820px) {
    .modal {
      &.form {
        height: auto;
      }
      &.confirm {
        height: 450px;
        .slideContent:last-child li:last-child {
          max-height: 73px;
        }
      }
      .modalContent {
        button {
          &.submit {
            position: relative;
            bottom: 0;
            right: 0;
            margin-top: 30px;
            margin: 30px auto 0;
            display: block;
            font-size: 20px;
            width: 150px;
            height: 40px;
          }
        }
      }
      button.checkButton {
        width: 100px;
        height: 50px;
        font-size: 20px;
      }
      .userInfoForm {
        padding: 0;
        font-size: 16px;
        li {
          font-size: 16px;
          .con {
            width: calc(100% - 85px);
          }
          &:not(:last-child) {
            padding-bottom: 10px;
            margin-bottom: 10px;
          }
          &.quantity .con {
            width: calc(100% - 85px);
            justify-content: space-between;
            .inquiry {
              padding: 0px 5px;
            }
          }
          input[type="text"] {
            width: 100%;
          }
          input[type="number"] {
            width: 70px;
            margin-right: 5px;
          }
          &.storage {
            flex-wrap: wrap;
            .storageType {
              width: 100%;
              .box {
                height: 150px;
                .bg {
                  background-size: 70% 50% contain;
                }
                .storageNumber {
                  input {
                    width: 100%;
                    border: none !important;
                    margin-right: 0;
                    &:focus {
                      border: none !important;
                    }
                  }
                }
                span {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ViewTypeContainer = styled.div`
  position: relative;
  ul {
    display: flex;
    align-items: center;
    font-size: 2.7578vh;
    border: 3px solid #a0a0a0;
    border-radius: 31px;
    padding: 1.1719vh;
    background: #fff;
    width: 250px;
    margin: 0 auto;
    li {
      width: 50%;
      text-align: center;
      padding: 0.7813vh 0;
      border-radius: 15px;
      font-weight: 700;
      transition: 0.3s;
      &.active {
        color: #fff;
        background: ${color.refrigerated};
      }
    }
  }
  .buttons {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 2.7578vh;
    transform: translateY(-50%);
    font-weight: 700;
    button {
      padding: 1.9532vh 2vh;
      background: ${color.refrigerated};
      color: #fff;
      border: none;
      border-radius: 31px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    ul {
      height: auto;
      width: 154px;
      padding: 0.6481vh;
      font-size: 12px;
      margin: 0;
    }
    .buttons {
      position: relative;
      transform: translate(0);
      top: 0;
      text-align: right;
      button {
        padding: 1.4294vh;
        font-size: 12px;
      }
    }
  }
`;

export const CustResvContainer = styled.div`
  position: fixed;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  .modalContent {
    position: relative;
    width: 100%;
    height: 100%;
    .slideWrapper {
      flex-flow: row nowrap;
      display: flex;
      flex: 1;
      width: 100%;
      transition: transform 0.5s ease;
      height: 100%;
      overflow: hidden;
      margin: auto;
    }
    .slideContent {
      flex: 0 0 50%;
      height: 100%;
      position: relative;
      transform: translateX(0);
      flex-basis: 100%;
      flex-shrink: 0;
      transition: 0.3s;
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .info {
      font-size: 20px;
    }
    .confirm {
      font-size: 20px;
    }
    .close {
      top: 15px;
      right: 15px;
    }
    .reserved {
      height: calc(100% - 145px);
      > li {
        padding: 10px;
      }
    }
  }
`;

export const CustomerListContainer = styled.div`
  .search {
    width: 70%;
    position: relative;
    margin-bottom: 48px;
    input {
      width: 100%;
      border: none;
      border-bottom: 3px solid #000;
      padding: 0 40px 0 0;
      &:focus {
        border: none !important;
        box-shadow: none;
        border-bottom: 3px solid #000 !important;
      }
    }
    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      display: flex;
    }
  }
  .customers {
    height: calc(100% - 100px);
    ul {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border: 2px solid #fafafa;
      }
      &::-webkit-scrollbar-track {
        background-color: #fafafa;
        border-radius: 10px;
      }
      li {
        border-bottom: 3px solid #efefef;
        display: flex;
        font-size: 30px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 10px;
        .info {
          width: 70%;
          span {
            display: inline-block;
            &.idx {
              width: 20%;
            }
            &.name {
              width: 30%;
            }
            &.tel {
              width: 50%;
            }
          }
        }
        .buttons {
          width: 30%;
          button {
            width: 49%;
            border: none;
            font-size: 25px;
            padding: 5px 0;
            border-radius: 5px;
            font-weight: 700;
            &:last-child {
              margin-left: 2%;
              background: ${color.refrigerated};
              color: #fff;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .search {
      input {
        font-size: 16px;
        padding: 0 25px 0 0;
      }
      button {
        font-size: 25px;
      }
    }
    .customers ul li {
      display: block;
      border: 3px solid rgb(239, 239, 239);
      padding: 10px;
      border-radius: 5px;
      .info {
        width: 100%;
        margin-bottom: 5px;
        span.idx,
        span.name {
          width: auto;
          margin-right: 10px;
        }
        span.tel {
          width: auto;
        }
      }
      .buttons {
        width: 100%;
        button {
          font-size: 18px;
          width: auto;
          padding: 5px 15px;
        }
      }
    }
  }
`;

export const SaveFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .info {
    font-size: 30px;
  }
  .userInfo {
    margin: 20px 0;
    font-size: 24px;
    li {
      height: 30px;
      display: flex;
      gap: 10px;
      span {
        display: inline-block;
        height: 100%;
        &.con {
          min-width: 50%;
        }
      }
      input[type="text"] {
        border: none;
        border-bottom: 2px solid #000;
        height: calc(100% - 2px);
        width: 100%;
        font-size: 20px;
        &:focus {
          border: none !important;
          border-bottom: 2px solid #000 !important;
          box-shadow: none !important;
        }
      }
    }
  }
  .reserved {
    height: calc(100% - 295px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
    > li {
      width: 49%;
      display: inline-block;
      border: 3px solid #000;
      font-size: 20px;
      margin-bottom: 20px;
      padding: 5px;
      border-radius: 10px;
      display: inline-block;
      position: relative;
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .reserveInfo {
        width: 90%;
        > li {
          display: flex;
          gap: 10px;
          .tit {
            width: 40%;
          }
          .con {
            width: 60%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .reserved {
      > li {
        width: 100%;
        &:nth-child(odd) {
          margin-right: 0;
        }
        .reserveInfo > li {
          display: block;
          margin-bottom: 5px;
          span.tit,
          span.con {
            font-size: 16px;
          }
          span.tit {
            color: #888;
            width: 100px;
            display: inline-block;
          }
        }
      }
    }
  }
`;

export const OnsitePaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .onsitePaymentInfo {
    text-align: center;
    strong {
      color: #2a3341;
    }
    span {
      display: block;
    }
  }
  .info {
    text-align: center;
  }
  .paymentMethod {
    .con {
      display: flex;
      align-items: center;
    }
    label {
      display: inline-block;
      height: 35px;
      width: 35px;
      position: relative;
      margin-right: 10px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        padding: 0;
        width: 0;
        height: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 2px solid #d4d4d4;
        border-radius: 10px;
        background-color: transparent;
        transition: all 0.3s ease;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg) scale(0);
          opacity: 0;
          width: 13px;
          height: 24px;
          border: solid #fff;
          border-width: 0px 3px 3px 0px;
          transition: 0.3s;
        }
      }
      input:checked ~ .checkmark {
        background: ${color.refrigerated};
        border: 1px solid ${color.refrigerated};
        &:after {
          opacity: 1;
          transform: translate(-50%, -65%) rotate(45deg) scale(1);
          border-color: #fff;
        }
      }
      &:hover .checkmark {
        background-color: #efefef;
      }
    }
    label + span {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .buttons {
    button {
      width: 200px;
      padding: 10px 0;
      font-size: 35px;
      font-weight: 700;
      border-radius: 10px;
      color: #fff;
      border: none;
      &:first-child {
        margin-right: 10px;
        background: #2a3341;
      }
      &:last-child {
        background: #ccc;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .buttons {
      width: 100%;
      button {
        width: calc(50% - 5px);
      }
    }
  }
`;

export const StorageNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  .storageInfo {
    font-size: 40px;
    font-weight: 700;
    max-height: calc(100% - 120px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    .label {
      display: block;
      margin: 20px 0;
    }
  }
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
    button {
      display: block;
      position: relative;
      font-size: 30px;
      background: #2a3341;
      width: 200px;
      height: 70px;
      border-radius: 10px;
      color: #fff;
      border: none;
      &:not(.checkButton) {
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .storageInfo {
      font-size: 20px;
    }
    .buttons {
      align-items: center;
      justify-content: center;
      gap: 10px;
      button.checkButton {
        margin: 0;
        font-size: 20px;
        width: 150px;
        height: 40px;
      }
    }
  }
`;
