import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { LuLayoutGrid } from "react-icons/lu";
import { TiArrowBackOutline } from "react-icons/ti";
import { IoNotificationsOutline } from "react-icons/io5";

import { SchdulerHeader } from "../styled/scheduler";
import DashboardSideMenu from "../../../dashboard/component/dashboardPage/dailyAssistant/dashboardSideMenu";

export default function Header({ setScreenMode }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [clickSideMenu, setClickSideMenu] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const [, setCompanyInfo] = useState("");

  return (
    <SchdulerHeader id="schduler_header" className={isDarkMode}>
      <h1>모노티에 오신것을 환영합니다.</h1>
      <div className="float_wrap">
        <div className="float_left">
          <img
            className="schduler_header_img"
            src={isDarkMode ? "/img/logo_dark.png" : "/img/logo.png"}
          />
          <span className="font_22 font600 font_color_white">캘린더</span>
          <span className="font_14 font300 font_color_gray schduler_text_space">|</span>
          <span className="font_16 font300 font_color_gray">전체</span>
        </div>
        <div className="float_right">
          <button onClick={() => navigate("/mypage")} style={{ fontSize: 37 }}>
            <TiArrowBackOutline />
          </button>
          <Link
            to="/mypage/notify"
            target="_blank"
            className="notify"
            onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
            <IoNotificationsOutline />
          </Link>
          <div className="menuNav">
            <button className="navBtn" onClick={() => setClickSideMenu(!clickSideMenu)}>
              <LuLayoutGrid />
            </button>
            {clickSideMenu && !isMobile && (
              <DashboardSideMenu
                clickSideMenu={clickSideMenu}
                setClickSideMenu={setClickSideMenu}
                setScreenMode={setScreenMode}
                isMargin={false}
              />
            )}
          </div>
        </div>
      </div>
    </SchdulerHeader>
  );
}
