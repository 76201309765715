import React from "react";
import { useOutletContext } from "react-router";
import { ModernContainer } from "./styled/modernTheme";

import Banner from "./themes/modern/banner";
import Introduce from "./themes/modern/introduce";
import Products from "./themes/modern/products";
import Portfolio from "./themes/modern/portfolio";
import News from "./themes/modern/news";

const ModernTheme = () => {
  const { cmpnyNo } = useOutletContext();

  return (
    <ModernContainer>
      <Banner cmpnyNo={cmpnyNo} />
      <Introduce />
      <Products cmpnyNo={cmpnyNo} />
      <Portfolio cmpnyNo={cmpnyNo} />
      <News cmpnyNo={cmpnyNo} />
    </ModernContainer>
  );
};

export default ModernTheme;
