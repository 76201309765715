import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MypageBanner from "../mypageBanner";
import ProductAddForm from "./component/productAddForm";
import ProductBrandForm from "./component/productBrandForm";
import ProductForm from "./component/productForm";
import ProductMenu from "./component/productMenu";
import ProductSyncForm from "./component/productSyncForm";
import HomePageSettingMenu from "./homepageSettingMenu";
import { setProductIndex } from "../../../store/compoIndex";

export default function Product() {
  useEffect(() => {
    dispatch(setProductIndex(0));
  }, []);

  const dispatch = useDispatch();
  const [productSync, setProductSync] = useState([]);
  const [product, setProduct] = useState([]);
  const index = useSelector((state) => state.compoIndex.product);
  
  return(
    <>
      <MypageBanner />
      <div className="inner_content">
        <HomePageSettingMenu setProductSync={setProductSync} setProduct={setProduct}/>
        <ProductMenu index={index}/>
        <Components
          index={index} 
          productSync={productSync}
          product={product}
          setProductSync={setProductSync}
          setProduct={setProduct}
        />
      </div>
    </>
  )
};

function Components({index, productSync, setProductSync, product, setProduct}){

  return(
    <>
      {
        [<ProductForm  product={product} setProduct={setProduct} />,
        <ProductSyncForm productSync={productSync} setProductSync={setProductSync} />,
        <ProductAddForm />,
        <ProductBrandForm />][index]
      }
    </>
  )
}