import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { UserInfoFormContainer } from "../../styled/storage";

import { IoClose } from "react-icons/io5";
import ReserveForm from "./userInfoForm/reserveForm";
import StorageNumber from "./userInfoForm/storageNumber";
import { Modal, ModalCloseButton } from "../../styled/common";
import { roundMinutes } from "../../function/kiosk";

const UserInfoForm = ({ handleExitModal, active, getPrintElement }) => {
  const [formData, setFormData] = useState({
    mberNm: "",
    tel: "",
    memo: "",
    payType: "1",
  });

  const [total, setTotal] = useState(0);
  const [viewType, setViewType] = useState(0);
  const transformValue = viewType * -100;
  const [storedItems, setStoredItems] = useState([]);
  const [chosenStorage, setChosenStorage] = useState({ 0: 0, 1: 0, 2: 0 });
  const quantity = Object.values(chosenStorage).reduce((sum, current) => sum + current, 0);

  // 결제금액
  const calculateTotal = (stroage) => {
    const basePrices = { 0: 3000, 1: 2000, 2: 3000 };
    let totalBasePrice = 0;

    for (const key in stroage) {
      if (stroage.hasOwnProperty(key)) {
        const quantity = stroage[key];
        totalBasePrice += basePrices[key] * quantity;
      }
    }

    return totalBasePrice;
  };

  const handleChange = (e) => {
    const { name, value: target } = e.target;

    let value = target;
    if (name === "tel") {
      value = target.replace(/\D/g, "");
    }
    if (name === "mberNm") {
      value = target.replace(/\s/g, "");
    }

    setFormData((data) => ({ ...data, [name]: value }));
  };

  const validationCheck = () => {
    const fieldLabels = {
      mberNm: "이름",
      tel: "전화번호",
    };

    for (let key in fieldLabels) {
      if (textNotExist(formData[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return false;
      }
    }
    if (!quantity) {
      toast.error("수량을 입력해주세요.");
      return false;
    }
    if (quantity > 500) {
      toast.error("수량은 최대 500까지 가능합니다.");
      return false;
    }
    return true;
  };

  // 예약하기
  const handleReserve = async () => {
    if (!validationCheck()) return;

    const url = "/api/reserve";
    const body = [];

    Object.entries(chosenStorage).forEach(([type, quantity]) => {
      if (quantity !== 0) {
        body.push({
          mberNm: formData.mberNm,
          os: "web",
          payType: 1,
          quantity: quantity,
          reservationDay: moment().format("YYYYMMDD"),
          reservationStartTime: roundMinutes(),
          reservationTime: 180,
          tel: formData.tel,
          type: type,
        });
      }
    });

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const historyIds = res.data.map((item) => item.id);
      await handleCompleted({ historyIds, reserveId: res.data[0].reserveId });
    }
  };

  // 보관하기
  const handleCompleted = async (reserve) => {
    if (!validationCheck()) return;

    const url = "/api/reserve-completed";
    const body = { ...reserve, reservationStartTime: roundMinutes(), payType: formData.payType };

    if (formData.memo) {
      body.memo = formData.memo;
    }

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setStoredItems(res.data);
        setViewType(1);
        getPrintElement(res.data);
      }
    } catch (error) {
      const message = error?.response?.data?.message || "알 수 없는 이유로 보관할 수 없습니다.";
      toast.error(message);
    }
  };

  // 모달 나가기
  const handleExitClick = () => {
    if (viewType === 1) {
      handleExitModal(true);
    } else {
      handleExitModal(false);
    }
    setFormData({ mberNm: "", tel: "", memo: "", payType: "1" });
  };

  return (
    <UserInfoFormContainer>
      <Modal className={`modal ${viewType ? "confirm" : "form"}`}>
        <div className="modalContent">
          <div className="slideWrapper">
            <ReserveForm
              formData={formData}
              total={total}
              handleChange={handleChange}
              handleReserve={handleReserve}
              quantity={quantity}
              active={active}
              transformValue={transformValue}
              setChosenStorage={setChosenStorage}
              chosenStorage={chosenStorage}
              setTotal={setTotal}
              calculateTotal={calculateTotal}
            />
            <StorageNumber
              transformValue={transformValue}
              storedItems={storedItems}
              handleBackButton={handleExitClick}
              getPrintElement={getPrintElement}
            />
          </div>
          <ModalCloseButton className="close" onClick={handleExitClick}>
            <IoClose />
          </ModalCloseButton>
        </div>
      </Modal>
    </UserInfoFormContainer>
  );
};

export default UserInfoForm;
