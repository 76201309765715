import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const dropAnimation = keyframes`
  0% { transform: translateY(0vh); }
  75%, 100% { transform: translateY(90vh); }
`;

const stemAnimation = keyframes`
  0%, 65% { opacity: 1; }
  75%, 100% { opacity: 0; }
`;

const splatAnimation = keyframes`
  0%, 80% { opacity: 1; transform: scale(0); }
  90% { opacity: 0.5; transform: scale(1); }
  100% { opacity: 0; transform: scale(1.5); }
`;

// Styled components
const RainDrop = styled.div`
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: ${dropAnimation} 0.5s linear infinite;
  animation-delay: ${({ delay }) => delay}s;

  .stem {
    width: 1px;
    height: 60%;
    margin-left: 7px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
    animation: ${stemAnimation} 0.5s linear infinite;
    animation-delay: ${({ delay }) => delay}s;
  }

  .splat {
    width: 15px;
    height: 10px;
    border-top: 2px dotted rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
    animation: ${splatAnimation} 0.5s linear infinite;
    animation-delay: ${({ delay }) => delay}s;
    display: ${({ showSplat }) => (showSplat ? "block" : "none")};
  }
`;

const RainContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  z-index: -1;
  overflow: hidden;
`;

const Rain = () => {
  const [showSplat, setShowSplat] = useState(true);
  const [showBackRow, setShowBackRow] = useState(true);
  const [drops, setDrops] = useState([]);

  const makeItRain = () => {
    let newDrops = [];
    let increment = 0;

    while (increment < 100) {
      let randoHundo = Math.floor(Math.random() * 98) + 1;
      let randoFiver = Math.floor(Math.random() * 4) + 2;
      let delay = Math.random() * 0.5;
      increment += randoFiver;

      newDrops.push(
        <RainDrop
          key={increment}
          style={{ left: `${increment}%` }}
          showSplat={showSplat}
          delay={delay}>
          <div className="stem" />
          <div className="splat" />
        </RainDrop>,
      );
    }

    setDrops(newDrops);
  };

  useEffect(() => {
    makeItRain();
  }, [showSplat, showBackRow]);

  const Rainy = styled.div`
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(131, 131, 131, 1) 0%,
      rgba(215, 215, 215, 1) 82%,
      rgba(255, 255, 255, 1) 100%
    );
  `;

  return (
    <Rainy>
      <RainContainer className="front-row">{drops}</RainContainer>
      <RainContainer className="back-row" showBackRow={showBackRow}>
        {drops}
      </RainContainer>
      <div></div>
    </Rainy>
  );
};

export default Rain;
