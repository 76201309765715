import React from "react";
import DetailsInfo from "./orderDetails/detailsInfo";
import DetailsContent from "./orderDetails/detailsContent";

const OrderDetails = ({ selected, viewType, handleModalOpen }) => {
  return (
    <div className="content pickup">
      <DetailsInfo selected={selected} viewType={viewType} handleModalOpen={handleModalOpen} />
      <DetailsContent selected={selected} />
    </div>
  );
};

export default OrderDetails;
