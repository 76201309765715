import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";

const Introduce = () => {
  const swiperRef = useRef(null);
  const temp = Array.from({ length: 5 });
  return (
    <div className="introduce">
      <div className="label">
        <div className="dot" />
        <div className="dot" />
        <div className="dot" />
        INTRODUCE
      </div>
      <div className="introduceContent">
        <div className="bg"></div>
        <Swiper
          className="mySwiper"
          modules={[Pagination]}
          slidesPerView={1.2}
          loop={true}
          speed={2000}
          ref={swiperRef}>
          {temp.map((item) => (
            <SwiperSlide>
              <div className="box">
                <div className="viewarea">
                  <img src="/img/shop_main_bg3.jpg" alt="" />
                </div>
                <div className="content">
                  <span className="tit">여기는 회사 설명입니다</span>
                  <span className="con">
                    회사 부가적 설명이에요 회사 부가적 설명이에요회사 부가적 설명
                    <br />
                    이에요회사 부가적 설명이에요회사 부가적 설명이에요
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Introduce;
