import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";

import { useRegex } from "../../../hooks/useRegex";

import { GroupInner, GroupSection } from "../../group/component/styled/common";
import { CompanyInfoNav, CompanyPageDashboard } from "./component/styled/dashboard";

import MypageBanner from "../mypageBanner";
import CompanyInvite from "./component/companyInvite";
import { useMediaQuery } from "react-responsive";

const CompanyPage = () => {
  const [originalMembers, setOriginalMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);
  const [moblphon, setMoblphon] = useState("");
  const [searchUser, setSearchUser] = useState([]);
  const [isSearchUser, setIsSearchUser] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [searchMber, setSearchMber] = useState("");
  const [cmpnyNm, setCmpnyNm] = useState("");

  const { TelFormat } = useRegex();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = { cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpnyNm(res.data.cmpnyNm);
    }
  };

  const getCmpnyMber = async () => {
    const url = "/api/cmpnyMember";
    const body = { cmpnyNo: user.cmpnyNo };
    try {
      const res = await axios.post(url, body);
      if (res.data) {
        setOriginalMembers(res.data);
        setMembers(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCmpnyNm();
    getCmpnyMber();

    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };

    initKakao();
  }, []);

  const sendKakaoMessage = () => {
    window.Kakao.Share.sendCustom({
      templateId: 89755,
      templateArgs: {
        company: cmpnyNm,
        unique: user.cmpnyNo,
        name: user.name,
      },
    });
  };

  const handleSearch = async () => {
    const phoneRegex = /^(010\d{8}|011\d{7})$/;

    if (!phoneRegex.test(moblphon)) {
      toast.error("전화번호 양식이 일치하지 않습니다.");
      return;
    }

    const url = "/api/mberList";
    const body = {
      moblphonNo: moblphon,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setSearchUser(res.data.content);
      setIsSearchUser(true);
    }
  };

  const handleInvite = async (mberNo) => {
    if (members.some((user) => parseInt(user.mberNo) === parseInt(mberNo))) {
      toast.error("이미 가입된 멤버입니다.");
      return;
    }

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: "C",
        mberNoList: [mberNo],
        orgNo: user.cmpnyNo,
      },
    };
    const res = await axios.post(url, body);

    if (res.data.body.success) {
      toast.success("초대 알림이 발송되었습니다.");
      setIsInvite(false);
    }
  };

  const handleSearchUser = () => {
    setIsInvite(true);
    setSearchUser([]);
    setIsSearchUser(false);
    setMoblphon("");
  };

  const mberSearch = (e) => {
    e.preventDefault();

    setMembers(
      originalMembers.filter(
        (user) =>
          user.mberNm.includes(searchMber) ||
          user.username.includes(searchMber) ||
          user.moblphonNo.includes(searchMber),
      ),
    );
  };

  return (
    <div>
      <MypageBanner />
      <GroupSection>
        <GroupInner>
          <CompanyPageDashboard>
            {cmpnyNm && (
              <h2 style={{ textAlign: "center", fontSize: "20px", marginBottom: "3%" }}>
                {cmpnyNm}
              </h2>
            )}
            <div className="inviteBtnArea">
              <form onSubmit={(e) => mberSearch(e)}>
                <input
                  type="text"
                  onChange={(e) => setSearchMber(e.target.value)}
                  value={searchMber}
                />
                <button type="submit">검색</button>
              </form>
              <button onClick={handleSearchUser}>회사초대</button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>이름</th>
                  <th>아이디</th>
                  <th>휴대번호</th>
                </tr>
              </thead>
              <tbody>
                {members.map((item) => (
                  <tr key={item.mberNo}>
                    <td>{item.mberNm}</td>
                    <td>{item.username}</td>
                    <td>
                      {item.moblphonNo &&
                        TelFormat(item.moblphonNo, /(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {isInvite && (
              <CompanyInvite
                setMoblphon={setMoblphon}
                moblphon={moblphon}
                handleEnterKey={handleEnterKey}
                handleSearch={handleSearch}
                isSearchUser={isSearchUser}
                searchUser={searchUser}
                sendKakaoMessage={sendKakaoMessage}
                setIsInvite={setIsInvite}
                handleInvite={handleInvite}
              />
            )}
            <CompanyInfoNav className="btnArea">
              {user && user.cmpnyNo === 16421 && (
                <button
                  onClick={() => navigate("/mypage/createReleaseNotes")}
                  style={{ background: "#757575" }}>
                  개발자노트 등록
                </button>
              )}
            </CompanyInfoNav>
          </CompanyPageDashboard>
        </GroupInner>
      </GroupSection>
    </div>
  );
};

export default CompanyPage;
