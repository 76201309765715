import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import Modal from "../../../../common/modal";
import CustomPagination from "../../../../common/customPagination";
import "../component/styled/port.css";

export default function RegisterPortForm(){
  const [portModal, setPortModal] = useState();
  const [portfolio, setPortfolio] = useState([]);
  const navigater = useNavigate()
  const user = useSelector((state) => state?.user?.data[0]);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const isUpload = location?.state?.uploadPort;
  const isDeleted = location?.state?.deletePort;
  const isEdit = location?.state?.editPort;

  let count = 0;

  useEffect(() => {
    getToast();
    getPortfolio();
  }, [])

  const getToast = () => {
    count++;
    if(count === 1 && isUpload){
      toast.success("포트폴리오가 등록 되었습니다.");
    }
    if(count === 1 && isDeleted){
      toast.success("포트폴리오가 삭제 되었습니다.");
    }
    if(count === 1 && isEdit){
      toast.success("포트폴리오가 수정 되었습니다.");
    }
  }

  const handlePortModal = () => {
    setPortModal(!portModal)
  }

  const getPortfolio = async (page = 0) => {
    setCurrentPage(page);
    const apiUrl = '/api/portMgmtList';
    const body = {
      cmpnyNo: user?.cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 12 * page,
      pageNumber: page,
      pageSize: 12,
      paged: true
    }

    const res = await axios.post(apiUrl, body);
    const portDatas = res.data.content;

    setPortfolio(portDatas);
    setTotalPage(res.data.totalPages);
  }

  const RegisterPortmodal = () => {
    return(
      <div className="">
        <div className="">
          <h2 className="font_20 font500 font_color_white">카테고리 설정</h2>
          <button className="hp_step_setting_wrap_close" onClick={handlePortModal}><i className="fa fa-times" aria-hidden="true" /></button>
          <span className="font_18 font300 font_color_gray pdt_20 text_center">※ 원하는 카테고리를 추가 및 삭제할 수 있습니다.</span>
            <div className="hp_step_setting_wrap_input">
              <input type="text" defaultValue placeholder="카테고리를 입력하세요." />
              <button type="submit" ><i className="fa fa-plus" aria-hidden="true" />추가</button>
            </div>
          <ul className="hp_step_setting_cate_list">
            <li>
              <span className="font_18 font300 font_color_gray text_center">cate name here</span>
              <button >삭제</button>
            </li>
          </ul>
          <button className="hp_step_setting_cate_save">저장하기</button>
        </div>
      </div>  
    )
  }

  return(
    <div className="hp_step_content">
      {/* <div className="hp_step_setting">
        <span className="font_16 font300 font_color_gray">※ 사진게시판 중 카테고리가 필요한 부분은 우측 “카테고리설정에서 설정할 수 있습니다.”</span>
        <button onClick={handlePortModal} className="hp_step_setting_btn" title="클릭시 카테고리를 추가 할 수 있습니다."><i className="fa fa-cog" aria-hidden="true" /> 카테고리 설정</button>
      </div> */}
      <section className="port_wrapper">
        {portfolio?.map((item) =>
          <div key={item.portNo} className="port_img_wrapper">
            <a className="port_link" onClick={() => navigater(`/mypage/registerPortView/${item.portNo}`)}>
              <div className="port_img_aspect">
                <img className="port_img" src={imgHandler(item.imgUrl)} onError={onErrorImg}/>
              </div>
              <div className="port_title">
                <span  className="font_18 font400 font_color_black">{item.title}</span>
              </div>
            </a>
          </div>
        )}
      </section>
      <div className="hp_step_gall_wrap">
        <div className="board_paging">
          <CustomPagination totalPage={totalPage} currentPage={currentPage} setCurrentPage={setCurrentPage} getData={getPortfolio}/>
          <button onClick={() => navigater("/mypage/registerPhotoUpload")} type="button" className="btn_write" >포트폴리오 등록</button>
        </div>
      </div>
      <div className="btnarea_center pdt_40 hp_step_btn">
        <Link to={"/mypage/product"} className="btn_normal_save"><span>다음 단계</span></Link>
      </div>

      <Modal
        modalState={portModal} 
        handleModalState={handlePortModal} 
        html={RegisterPortmodal()}
        className=""
      />
    </div>
  )
}