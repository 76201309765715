import styled from "styled-components";

export const ModernContainer = styled.main`
  .info {
    font-family: "Prata", "Pretendard";
    font-size: 100px;
    margin-bottom: 60px;
    span {
      display: block;
      font-size: 30px;
      text-indent: 50px;
    }
  }
  .banner {
    width: 70vw;
    height: 555px;
    border-radius: 0 300px 0 0;
    overflow: hidden;
    .banners {
      width: 100%;
      height: 100%;
      .bannerSlide img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .introduce {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .introduceContent {
      width: 100%;
      width: 1200px;
      height: 335px;
      overflow: hidden;
      border-radius: 0 335px 335px 0;
      margin: 0 0 60px auto;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .content {
        padding: 80px;
        position: absolute;
        span {
          color: #fff;
          &.tit {
            display: block;
            font-size: 56px;
            font-weight: 700;
            margin-bottom: 30px;
          }
          &.con {
            font-size: 20px;
          }
        }
      }
      &:nth-child(2) .content {
        top: 0;
        left: 0;
      }
      &:nth-child(3) {
        margin: 0 auto 0 0;
        border-radius: 335px 0 0 335px;
        .content {
          top: 0;
          right: 0;
        }
      }
    }
  }
  .products {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .productSwiper {
      height: 400px;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .swiper-slide {
        padding: 10px;
        &:nth-child(odd) {
          .productSlide {
            padding-bottom: 60px;
          }
        }
        &:nth-child(even) {
          .productSlide {
            padding-top: 60px;
          }
        }
        .box {
          width: 100%;
          height: 100%;
          border-radius: 300px 300px 0 0;
          overflow: hidden;
          border: 2px solid #a79277;
          user-select: none;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .actions {
      position: relative;
      height: 70px;
      font-size: 30px;
      margin-top: 90px;
      .swiperAction {
        width: 200px;
        height: 70px;
        background: #a79277;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        button {
          border: none;
          background: transparent;
          svg {
            display: block;
            path {
              stroke: #fff;
            }
          }
        }
      }
      .more {
        position: absolute;
        top: 0;
        right: 0;
        height: 70px;
        width: 300px;
        background: #fff2e1;
        border-radius: 70px;
      }
    }
  }
  .portfolio {
    padding: 70px 0 200px;
    background: #fff2e1;
    .inner {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .info {
      max-width: 1400px;
      width: 100%;
      margin: 0 auto 60px;
    }
    .left {
      width: 800px;
      height: 600px;
      border-radius: 0 300px 0 300px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      text-align: right;
      button {
        font-size: 170px;
        border: none;
        background: transparent;
        margin-bottom: 200px;
        svg {
          display: block;
        }
      }
      .portfolioInfo {
        .tit {
          display: block;
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 30px;
        }
        .content {
          font-size: 20px;
        }
      }
    }
  }
  .news {
    max-width: 1400px;
    width: 100%;
    margin: 180px auto;
    .newsSwiper {
      width: 100%;
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
      .box {
        width: 380px;
        background: #ddd;
        height: 200px;
        border-radius: 100px 100px 0 0;
      }
      .newsInfo {
        span {
          display: block;
          &:first-child {
            font-weight: 700;
            font-size: 24px;
            margin: 30px 0;
          }
          &:last-child {
            font-size: 16px;
            color: #6a6a6a;
          }
        }
      }
    }
    .actions {
      margin-top: 70px;
      text-align: center;
      button {
        background: #a79277;
        font-size: 40px;
        padding: 15px 25px;
        svg {
          display: block;
          path {
            stroke: #fff;
          }
        }
        &:first-child {
          border-radius: 40px 0 0 40px;
        }
        &:last-child {
          border-radius: 0 40px 40px 0;
        }
      }
    }
  }
`;
