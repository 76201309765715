import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { weatherStates } from "../../../scheduler/component/function/dateInfo";

import { DailyAssistantContent } from "../styled/dashboard";

import ScheduleViewer from "./dailyAssistant/scheduleViewer";
import DdayViewer from "./dailyAssistant/ddayViewer";
import DashboardSideMenu from "./dailyAssistant/dashboardSideMenu";
import DashboardHeader from "./dailyAssistant/dashboardHeader";

const DailyAssistant = ({
  clickSideMenu,
  setClickSideMenu,
  location,
  schedules,
  ddays,
  todayWeather,
  weatherUI,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const { mySchedSet } = useSelector((state) => state?.scheduler);

  const [notification, setNotification] = useState([]);

  useEffect(() => {
    if (user && Object.keys(mySchedSet).length) {
      getNotification();
    }
  }, []);

  const getNotification = async () => {
    const url = "/api/notificationHistory";
    const body = {
      mberNo: user.mberNo,
    };
    const res = await axios.post(url, body);
    const data = res?.data;
    if (data && data.length > 0) {
      const filterNotification = data.filter((not) => not.readYn === "N");
      setNotification(filterNotification);
    }
  };

  const isWeather = (weather) => {
    const state = weatherStates[weather];
    if (!state) return <></>;

    const Icon = state.icon;
    return (
      <>
        <span className="weather">{state.text}</span>
        <Icon style={{ color: state.color }} />
      </>
    );
  };

  return (
    <>
      {weatherUI}
      <DailyAssistantContent>
        <DashboardHeader
          isWeather={isWeather}
          todayWeather={todayWeather}
          notification={notification}
          setClickSideMenu={setClickSideMenu}
          location={location}
        />
        <ScheduleViewer
          data={schedules}
          todayWeather={todayWeather}
          isWeather={isWeather}
          location={location}
        />
        <DdayViewer data={ddays} />
      </DailyAssistantContent>
      {clickSideMenu && (
        <DashboardSideMenu setClickSideMenu={setClickSideMenu} clickSideMenu={clickSideMenu} />
      )}
    </>
  );
};

DailyAssistant.displayName = "DailyAssistant";
export default DailyAssistant;
