import MypageBanner from "../mypageBanner";
import RegisterPortViewForm from "./component/registerPortViewForm";

export default function RegisterPortView () {
  
  return(
    <>
      <MypageBanner />
      <RegisterPortViewForm />
    </>
  )
}