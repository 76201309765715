import { useNavigate } from "react-router";

import { EndOfServiceContainer } from "../styled/dashboard";

export default function EndOfService() {
  const navigate = useNavigate();

  return (
    <EndOfServiceContainer>
      <div className="mypage_1">
        <span className="page_title">서비스 종료 일자</span>
        <button className="page_btn">연장 신청 +</button>
        <button
          className="page_btn"
          onClick={() => navigate("/mypage/modifyProfile", { state: { tab: 2 } })}>
          회원 탈퇴
        </button>
        <div className="service_end">
          <span className="font_24 font300 font_color_1 pdb_20">
            서비스 종료 일자는 2021년 5월 15일 입니다.
          </span>
          <span className="font_18 font300 font_color_gray">
            서비스가 종료되면 모노티에서 제공하는 홈페이지, 공종, 통합일정 서비스를 이용할 수
            없습니다.
          </span>
        </div>
      </div>
    </EndOfServiceContainer>
  );
}
