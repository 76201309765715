import { useLocation } from "react-router";
import MypageBanner from "../mypageBanner";
import RegisterPhotoUploadForm from "./component/registerPhotoUploadForm";

export default function RegisterPhotoUpload() {
  const location = useLocation();
  const fileBoxImages = location?.state?.images || [];

  return (
    <>
      <MypageBanner />
      <RegisterPhotoUploadForm fileBoxImages={fileBoxImages} />
    </>
  );
}
