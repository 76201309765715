import React, { useState } from "react";
import { MdMenu, MdOutlineSearch } from "react-icons/md";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { DashboardHeaderContainer } from "../../styled/dashboard";
import Modal from "../../../../../../common/modal";
import { useDispatch } from "react-redux";
import { onClickLogout } from "../../../../../../store/user";
import { resetSetting } from "../../../../../../store/scheduler";
import TodayWeather from "./todayWeather";

const DashboardHeader = ({ isWeather, todayWeather, notification, setClickSideMenu, location }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [modalOpen, setModalOpen] = useState(false);

  const LogoutModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">로그아웃</h2>
        <button onClick={handleLogoutModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">
          로그아웃 하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onClickModalCheck}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  const handleLogoutModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const onClickModalCheck = () => {
    dispatch(onClickLogout());
    dispatch(resetSetting());
    setModalOpen(false);
    navigate("/login");
  };

  return (
    <>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLogoutModal}
        html={LogoutModalForm()}
        w="300px"
        h="auto"
      />
      <DashboardHeaderContainer>
        <div className="logos" onClick={() => navigate("/")}>
          <img src="/img/logo_monoti.png" />
        </div>
        <div className="left">
          {!isMobile && todayWeather && (
            <TodayWeather isWeather={isWeather} todayWeather={todayWeather} location={location} />
          )}
          <div className="dashboardBtnArea">
            <button onClick={handleLogoutModal} className="logout">
              <i className="icon_logout"></i>
            </button>
            <button onClick={() => navigate("/mypage/search")} className="search">
              <MdOutlineSearch />
            </button>
            <Link
              to="/mypage/notify"
              target="_blank"
              className="notify"
              onClick={() => user && localStorage.setItem("user", JSON.stringify(user))}>
              <IoNotificationsOutline />
              {notification && notification.length > 0 && (
                <span>{notification.length < 99 ? notification.length : "99+"}</span>
              )}
            </Link>
            <button onClick={() => setClickSideMenu(true)} className="sideMenu">
              <MdMenu />
            </button>
          </div>
        </div>
      </DashboardHeaderContainer>
    </>
  );
};

export default DashboardHeader;
