import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useRegex } from "../../../../hooks/useRegex";
import { setPrductNm, setProductPage, setProductSelected } from "../../../../store/product";
import { resetBussiness } from "../../../../store/productCode";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import uuid from "react-uuid";
import axios from "axios";
import EditModalForm from "./modal/editModalForm";
import ReadyModalForm from "../../../../common/readyModalForm";
import Modal from "../../../../common/modal";

function ProductBottom({ product, setProduct }) {
  useEffect(() => {
    getProduct({ page: 1, selected: 10 });
    dispatch(setProductPage(1));
    dispatch(setProductSelected(10));
    dispatch(resetBussiness());

    return () => {
      dispatch(setPrductNm(""));
    };
  }, []);

  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { MoneyFormat } = useRegex();

  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [readyModal, setReadyModal] = useState(false);
  const [message, setMessage] = useState("");
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [sortIndex, setSortIndex] = useState([false, false, false]);
  const [myProduct, setMyProduct] = useState({});
  const [modalName, setModalName] = useState("");
  const numChecked = checkedSet.size;

  const page = useSelector((state) => state.product.page);
  const selected = useSelector((state) => state.product.selected);
  const prductNm = useSelector((state) => state.product.prductNm);
  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const bsnsTy = useSelector((state) => state.productCode.bussinessCode);
  const bussinessCategory = useSelector((state) => state.productCode.bussinessCategory);

  const rows = product?.content?.map((item) => ({ item }));

  const getProduct = async ({ page, selected, prductNm, bsnsTy }) => {
    const url = "/api/prductStock";
    const body = {
      offset: (page - 1) * selected,
      pageNumber: page - 1,
      pageSize: selected,
      paged: true,
      cmpnyNo,
      notitleYn: "Y",
    };

    if (prductNm) {
      body.prductNm = prductNm;
    }

    if (bsnsTy) {
      body.bsnsTy = bsnsTy;
    }

    const response = await axios.post(url, body);
    setProduct(response.data);
  };

  const handleDeleteModal = () => {
    const checkedItems = Array.from(checkedSet);
    if (checkedItems.length === 0) {
      toast.error("삭제할 제품을 선택해 주세요.");
      setOpenModal(false);
    } else if (deleteComplete) {
      setDeleteComplete(false);
      setCheckedSet(new Set());
      setOpenModal(false);
      toast.error("삭제할 제품을 선택해 주세요.");
    } else {
      setMessage("제품을 삭제 하시겠습니까?");
      setIsDeleted(!isDeleted);
      setOpenModal(!openModal);
    }
  };

  const handleEditModal = (item) => {
    setMyProduct(item);
    setEditModal(!editModal);
  };

  const handleReadyModal = () => {
    setReadyModal(!readyModal);
  };

  const deleteProduct = async () => {
    const checkedItems = Array.from(checkedSet);
    const url = "/api/stock";
    const bodyArr = [];

    checkedItems.forEach((item) => {
      const obj = {
        cmpnyNo: cmpnyNo,
        prdNo: item.prdNo,
        salesStatus: item.salesStatus,
        notitleYn: "N",
      };
      bodyArr.push(obj);
    });

    Promise.all(
      bodyArr.map(async (body) => {
        try {
          await axios.post(url, body);
        } catch (error) {
          setMessage("제품 삭제를 실패했습니다.");
        }
      }),
    ).then(() => {
      toast.success("제품이 삭제 되었습니다.");
      setOpenModal(false);
      getProduct({ page, selected, prductNm, bsnsTy });
      setIsDeleted(false);
      dispatch(setPrductNm(""));
      setDeleteComplete(true);
      setCheckedSet(new Set());
    });
  };

  useEffect(() => {
    if (deleteComplete) {
      setDeleteComplete(false);
    }
  }, [deleteComplete]);

  const handlePageChange = async (page) => {
    await getProduct({ page, selected, prductNm, bsnsTy });
    setCheckedSet(new Set());
    dispatch(setProductPage(page));
  };

  const handleSelect = async (e) => {
    await getProduct({
      page: 1,
      selected: Number(e.target.value),
      prductNm,
      bsnsTy,
    });
    dispatch(setProductPage(1));
    dispatch(setProductSelected(Number(e.target.value)));
  };

  const updateSet = (set, item) => {
    const updatedSet = new Set(set);

    if (updatedSet.has(item)) {
      updatedSet.delete(item);
    } else {
      updatedSet.add(item);
    }

    return updatedSet;
  };

  const handleCheckChange = (item) => {
    setCheckedSet((prevSet) => updateSet(prevSet, item));
  };

  const toggleAllChecked = ({ target: { checked } }) => {
    if (checked) {
      const allChecked = new Set(rows.map(({ item }) => item));
      setCheckedSet(allChecked);
    } else {
      setCheckedSet(new Set());
    }
  };

  const onLatestSort = async () => {
    await getProduct({ page: 1, selected });
    setSortIndex([true, false, false]);
    dispatch(setProductPage(1));
    dispatch(setPrductNm(""));
    dispatch(resetBussiness());
    dispatch(setProductSelected(10));
  };

  const onBrandSort = async () => {
    setSortIndex([false, true, false]);
    // await getProduct({page, selected, brandSort : "ASC", prductCl1NmSort : "", prductNm})
  };

  const onCategorySort = async () => {
    setSortIndex([false, false, true]);
    // await getProduct({page, selected, brandSort : "", prductCl1NmSort : "ASC", prductNm})
  };

  const onSearch = (e) => {
    e.preventDefault();
    getProduct({
      page: 1,
      selected,
      prductNm: e.target.form[0].value,
      bsnsTy,
    });
    dispatch(setProductPage(1));
    dispatch(setPrductNm(e.target.form[0].value));
  };

  const onClickDownloadImage = async () => {
    const checkedItems = Array.from(checkedSet);
    const bodyArr = [];

    checkedItems.forEach((item) => {
      const obj = {
        fileName: item.prdImgFlpth,
      };
      bodyArr.push(obj);
    });

    // const body = { fileName : "11131_1620362991398.gif" };
    // const url = "/api/downloadFile";
    // const res = await axios.post(url, body);
    // let test;
    // console.log(res);
    // console.log(test);
  };

  const deleteModalForm = (message) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">제품삭제</h2>
        <button onClick={handleDeleteModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{message}</span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "20px",
          }}>
          {isDeleted && (
            <>
              <button
                onClick={handleDeleteModal}
                className="hp_step_setting_cate_save"
                style={{
                  padding: "10px 15px",
                  margin: "0px",
                  background: "#6C757D",
                  fontSize: "16px",
                }}>
                취소
              </button>
              <button
                onClick={deleteProduct}
                className="hp_step_setting_cate_save"
                style={{
                  padding: "10px 15px",
                  margin: "0px",
                  fontSize: "16px",
                }}>
                확인
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      {isMobile && (
        <div className="hp_step_product_list" style={{ padding: "0" }}>
          <div className="hp_step_product_btn_wrap">
            <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "5px" }}>
              <button
                onClick={() => {
                  setReadyModal(!readyModal);
                  setModalName("엑셀파일 다운로드");
                }}
                type="button"
                className="product_upload">
                엑셀파일 다운로드
              </button>
              <button onClick={onClickDownloadImage} type="button" className="product_upload">
                이미지 다운로드
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "5px",
              }}>
              <button
                onClick={handleDeleteModal}
                type="button"
                className="product_upload"
                style={{
                  fontSize: "16px",
                  background: "rgb(51, 51, 51)",
                  color: "white",
                }}>
                삭제
              </button>
              <div className="product_upload" style={{ border: "none" }}></div>
            </div>
            <ul className="product_btn_ul">
              <li style={{ margin: "0px 5px" }} onClick={onLatestSort}>
                <Link>최신순</Link>
              </li>
              <li style={{ margin: "0px 5px" }} onClick={onBrandSort}>
                <Link>브랜드순</Link>
              </li>
              <li style={{ margin: "0px 5px" }} onClick={onCategorySort}>
                <Link>카테고리순</Link>
              </li>
              <li>
                <select key={uuid()} defaultValue={selected} onChange={handleSelect}>
                  <option value={10}>10개 보기</option>
                  <option value={30}>30개 보기</option>
                  <option value={50}>50개 보기</option>
                </select>
              </li>
            </ul>
          </div>
          <div>
            <form onSubmit="return false">
              <input
                style={{ width: "40%", margin: "10px 10px 10px 5px" }}
                className="input_search"
                type="text"
                defaultValue={prductNm}
              />
              <button onClick={onSearch} className="input_50 button_search">
                조회
              </button>
            </form>
          </div>
          <div className="member_search">
            {product?.content?.map((item, i) => (
              <div
                className="member_search_wrap product_wrap"
                style={{ minHeight: "400px" }}
                key={i}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                  }}>
                  <input
                    type="checkbox"
                    checked={checkedSet.has(item)}
                    onChange={() => handleCheckChange(item)}
                  />
                  <button
                    className="product_mobile_edit_btn"
                    onClick={() => handleEditModal(item)}
                    type="button">
                    수정하기
                  </button>
                </div>
                <div style={{ width: "100%", minHeight: "180px" }}>
                  {!item?.prdImgFlpth && !item?.extrlImgUrl ? (
                    <div style={{ width: "100%", paddingBottom: "100%" }}></div>
                  ) : (
                    <img
                      className="member_logo pdb_20 product_img"
                      src={
                        item?.prdImgFlpth
                          ? imgHandler(item?.prdImgFlpth)
                          : imgHandler(item?.extrlImgUrl)
                      }
                    />
                  )}
                </div>
                <div>
                  <div style={{ textAlign: "start", marginBottom: "10px" }}>
                    {item?.bsnsTy ? (
                      <span className="product_p">
                        {
                          bussinessCategory?.filter(
                            (category) => category?.code === item?.bsnsTy,
                          )[0]?.codeNm
                        }
                      </span>
                    ) : (
                      <span className="product_category_empty">{"\u00a0"}</span>
                    )}
                  </div>
                  <div style={{ textAlign: "start", marginBottom: "10px" }}>
                    {item?.brand ? (
                      <span className="product_p">{item?.brand}</span>
                    ) : (
                      <span className="product_category_empty">{"\u00a0"}</span>
                    )}
                  </div>
                  <span
                    className="font_28 font600 font_color_black pdb_10 text_center"
                    style={{ textAlign: "start" }}>
                    {item.prductNm}
                  </span>
                  <span
                    className="font_28 font_color_black pdb_10 text_center"
                    style={{ textAlign: "start" }}>
                    {MoneyFormat(item.cnsmrPc)}원
                  </span>
                </div>
              </div>
            ))}
            {!product?.content?.length && (
              <div className="product_empty">
                <span>연동된 제품이 없습니다.</span>
              </div>
            )}
            <div className="hp_step_product_btn_wrap" style={{ marginTop: "30px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "5px",
                }}>
                <button
                  onClick={() => {
                    setReadyModal(!readyModal);
                    setModalName("엑셀파일 다운로드");
                  }}
                  type="button"
                  className="product_upload">
                  엑셀파일 다운로드
                </button>
                <button onClick={onClickDownloadImage} type="button" className="product_upload">
                  이미지 다운로드
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "20px",
                }}>
                <button
                  onClick={handleDeleteModal}
                  type="button"
                  className="product_upload"
                  style={{
                    fontSize: "16px",
                    background: "rgb(51, 51, 51)",
                    color: "white",
                  }}>
                  삭제
                </button>
                <div className="product_upload" style={{ border: "none" }}></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className="hp_step_product_list">
          <div className="hp_step_product_btn_wrap">
            <button
              onClick={handleDeleteModal}
              type="button"
              className="product_btn product_btn_delete">
              삭제
            </button>
            <button
              onClick={() => {
                setReadyModal(!readyModal);
                setModalName("엑셀파일 다운로드");
              }}
              type="button"
              className="product_btn ">
              엑셀파일 다운로드
            </button>
            <button onClick={onClickDownloadImage} type="button" className="product_btn ">
              이미지 다운로드
            </button>
            <form onSubmit="return false" style={{ display: "inline" }}>
              <input
                style={{ width: "220px" }}
                className="input_search"
                type="text"
                defaultValue={prductNm}
              />
              <button onClick={onSearch} className="input_50 button_search">
                조회
              </button>
            </form>
            <ul className="product_btn_ul">
              <li
                style={{ textDecoration: sortIndex[0] ? "underline" : "none" }}
                onClick={onLatestSort}>
                <Link>최신순</Link>
              </li>
              <li
                style={{ textDecoration: sortIndex[1] ? "underline" : "none" }}
                onClick={onBrandSort}>
                <Link>브랜드순</Link>
              </li>
              <li
                style={{ textDecoration: sortIndex[2] ? "underline" : "none" }}
                onClick={onCategorySort}>
                <Link>카테고리순</Link>
              </li>
              <li>
                <select key={uuid()} defaultValue={selected} onChange={handleSelect}>
                  <option value={10}>10개 보기</option>
                  <option value={30}>30개 보기</option>
                  <option value={50}>50개 보기</option>
                </select>
              </li>
            </ul>
          </div>

          <div className="hp_step_product_list_wrap ">
            <table className="table_sm_list work_place hp_step">
              <colgroup>
                <col style={{ width: "5%" }} />
                <col style={{ width: "5%" }} />
                <col style={{ width: "16.5%" }} />
                <col style={{ width: "16.5%" }} />
                <col style={{ width: "8%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10.5%" }} />
                <col style={{ width: "10.5%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">
                    <input
                      type="checkbox"
                      onChange={toggleAllChecked}
                      checked={numChecked === rows?.length && product?.content?.length !== 0}
                    />
                  </th>
                  <th scope="col">
                    <span className="text_center">이미지</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">제품명</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">변경 상품명</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">브랜드</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">소비자가</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">변경판가</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">카테고리</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">수정</span>
                  </th>
                  <th scope="col">
                    <span className="text_center">상세</span>
                  </th>
                </tr>
              </thead>
              <tbody id="place_tbody">
                {product?.content?.map((item, i) => (
                  <tr key={i}>
                    <td className="input_50">
                      <input
                        type="checkbox"
                        checked={checkedSet.has(item)}
                        onChange={() => handleCheckChange(item)}
                      />
                    </td>
                    <td className="input_50">
                      <img
                        src={
                          item?.prdImgFlpth
                            ? imgHandler(item?.prdImgFlpth)
                            : item?.extrlImgUrl
                            ? imgHandler(item?.extrlImgUrl)
                            : "/img/icon_function_3.png"
                        }
                      />
                    </td>
                    <td className="input_100">
                      <a herf="#" className="font_color_black font500 text_center">
                        {item.prductNm}
                      </a>
                    </td>
                    <td className="input_100">
                      <a herf="#" className="font_color_black font500 text_center">
                        {item.notitlePdCmNm ?? "미지정"}
                      </a>
                    </td>
                    <td className="input_50">
                      <span>{item.brand}</span>
                    </td>
                    <td className="input_50">
                      <span>{MoneyFormat(item.cnsmrPc)}원</span>
                    </td>
                    <td className="input_50">
                      <span>{MoneyFormat(item.notitlePdCmPrice) ?? 0}원</span>
                    </td>
                    <td className="input_50">
                      <span>
                        {
                          bussinessCategory?.filter(
                            (category) => category?.code === item?.bsnsTy,
                          )[0]?.codeNm
                        }
                      </span>
                    </td>
                    <td className="input_50">
                      <button
                        onClick={() => handleEditModal(item)}
                        type="button"
                        className="table_btn">
                        수정
                      </button>
                    </td>
                    <td className="input_50">
                      <button
                        onClick={() => {
                          setReadyModal(!readyModal);
                          setModalName("보기");
                        }}
                        type="button"
                        className="table_btn table_btn_2">
                        보기
                      </button>
                    </td>
                  </tr>
                ))}
                {!product?.content?.length && (
                  <tr style={{ width: "100%", background: "white" }}>
                    <td
                      style={{ width: "100%", padding: "20px 0" }}
                      colSpan={8}
                      className="table_data_empty">
                      연동된 제품이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="hp_step_product_btn_wrap">
            <button
              onClick={handleDeleteModal}
              type="button"
              className="product_btn product_btn_delete">
              삭제
            </button>
            <button
              onClick={() => {
                setReadyModal(!readyModal);
                setModalName("엑셀파일 다운로드");
              }}
              type="button"
              className="product_btn">
              엑셀파일 다운로드
            </button>
            <button onClick={onClickDownloadImage} type="button" className="product_btn">
              이미지 다운로드
            </button>
          </div>
        </div>
      )}

      <Pagination
        activePage={page}
        itemsCountPerPage={selected}
        totalItemsCount={product.totalElements}
        pageRangeDisplayed={isMobile ? 5 : 10}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={handlePageChange}
      />

      <Modal
        modalState={openModal}
        handleModalState={handleDeleteModal}
        html={deleteModalForm(message)}
        w={isMobile ? "60%" : "15%"}
        h="auto"
      />

      <Modal
        modalState={editModal}
        handleModalState={handleEditModal}
        html={
          <EditModalForm
            myProduct={myProduct}
            setEditModal={setEditModal}
            handleEditModal={handleEditModal}
            bussinessCategory={bussinessCategory}
            getProduct={getProduct}
            page={page}
            selected={selected}
          />
        }
        w={isMobile ? null : "50%"}
        h={isMobile ? "100%" : "90%"}
        top={isMobile ? "55%" : null}
      />

      <Modal
        modalState={readyModal}
        handleModalState={handleReadyModal}
        html={<ReadyModalForm setReadyModal={setReadyModal} modalName={modalName} />}
        w="300px"
        h="auto"
      />
    </>
  );
}
export default ProductBottom;
