import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const News = ({ cmpnyNo, homepageInfo }) => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      noticeCl: "공지",
      offset: 0,
      pageNumber: 0,
      pageSize: 4,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      let data = res.data.content;
      console.log(data);

      while (data.length < 4) {
        data = data.concat(data.slice(0, 4 - data.length));
      }

      setNotices(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="news">
      <div className="label">
        News <div className="dot" />
        <div className="dot" />
        <div className="dot" />
      </div>
      <div className="info">
        공지사항
        <span>
          <span>{homepageInfo.cmpnyNm}의 다양한 공지사항입니다</span>
        </span>
      </div>
      <div className="newsContent">
        {notices.map((item, i) => (
          <div className="box" id={i}>
            <div className="newsInfo">
              <span>{item.noticeSj}</span>
              <span>{item.noticeCl}</span>
              <span>{moment(item.createDt).format("YYYY.MM.DD")}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default News;
