import QnaWriteForm from "./component/qnaWriteForm";
import NoticeBanner from "./noticeBanner";

export default function QnaWrite () {
  return(
    <>
      <NoticeBanner />
      <QnaWriteForm />
    </>
  )
}