import styled from "styled-components";

export const SimpleContainer = styled.main`
  min-height: 100vh;
  font-family: "Pretendard";
  .info {
    font-size: 75px;
    font-weight: 700;
    margin-bottom: 70px;
    text-transform: uppercase;
  }
  .banner {
    width: 100%;
    height: 470px;
    margin: 120px 0;
    .banners {
      width: 100%;
      height: 100%;
      .bannerSlide {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .history {
    max-width: 1400px;
    margin: 160px auto;
    .content {
      .contentInfo {
        text-align: center;
        font-size: 30px;
        margin-bottom: 100px;
      }
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 50px;
        img {
          width: 184px;
          height: 184px;
          object-fit: contain;
          background-image: url("/img/shop_union_bg.png");
          background-repeat: no-repeat;
          touch-action: none;
          user-select: none;
        }
        span {
          display: block;
          font-size: 30px;
          text-align: center;
          margin-top: 30px;
        }
      }
    }
  }
  .products {
    width: 100%;
    max-width: 1400px;
    margin: 160px auto;
    .wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .box {
        position: relative;
        width: 25%;
        height: 516px;
        .image {
          width: 100%;
          height: 100%;
          transition: 0.3s;
          background: #fff;
          img {
            transition: 0.3s;
            width: 100%;
            height: 100%;
            filter: grayscale(50%);
            opacity: 0.5;
            object-fit: cover;
          }
        }
        .prdInfo {
          position: absolute;
          left: 0;
          top: 50%;
          font-size: 28px;
          padding: 0 20px;
          span {
            display: block;
            &:first-child {
              width: 100%;
              margin-bottom: 24px;
              height: 66px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            &.price {
              font-weight: lighter;
              background: #fff;
              width: 148px;
              text-align: center;
              padding: 8px 0;
            }
          }
        }
        &:hover {
          .image {
            background: #000;
            img {
              opacity: 0.7;
              filter: grayscale(0%);
            }
          }
          .prdInfo {
            color: #fff;
            .price {
              color: #000;
            }
          }
        }
      }
    }
  }
  .portfolio {
    width: 100%;
    max-width: 1400px;
    margin: 160px auto;
    .info {
      margin-bottom: 0;
      .more {
        margin-top: 70px;
        font-size: 18px;
        font-weight: normal;
        > span {
          display: block;
        }
        button {
          display: flex;
          align-items: center;
          background: transparent;
          gap: 8px;
          margin-top: 28px;
          .line {
            display: inline-block;
            height: 1px;
            width: 82px;
            background: #102c57;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              background: #102c57;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .content {
      display: flex;
      gap: 24px;
      height: 850px;
      .box {
        width: calc((100% - 48px) / 3);
        height: 720px;
        .view {
          width: 100%;
          height: 100%;
          border: 1px solid #ccc;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .contentInfo {
          font-size: 28px;
          margin-top: 30px;
        }
        &:nth-child(odd) {
          padding-top: 130px;
        }
        &:nth-child(even) {
          padding-bottom: 130px;
        }
      }
    }
  }
  .news {
    background: #002153;
    padding: 60px 0;
    margin: 160px 0;
    .info {
      max-width: 1400px;
      margin: 0 auto 47px;
      color: #fff;
    }
    .content {
      max-width: 1400px;
      margin: 0 auto;
      display: flex;
      align-items: flex-end;
      gap: 40px;
      .more {
        button {
          display: flex;
          width: 300px;
          font-size: 40px;
          color: #fff;
          background: transparent;
          align-items: flex-end;
          text-align: left;
          gap: 40px;
          svg {
            display: block;
            path {
              stroke: #fff;
            }
          }
        }
      }
      .newsContent {
        display: flex;
        justify-content: space-between;
        width: calc(100% - 340px);
        .box {
          width: 300px;
          height: 300px;
          border-radius: 50%;
          background: #fff;
          .newsInfo {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              &:first-child {
                max-width: 80%;
                height: 58px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                font-size: 24px;
                margin: 70px 0 30px;
              }
              &:last-child {
                font-size: 16px;
                color: #6a6a6a;
              }
            }
          }
        }
      }
    }
  }
`;
