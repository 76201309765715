import { useState } from "react";
import Modal from "../../../../common/modal";
import { toast } from "react-toastify";
import { ProductAddFormInfo, ProductAddFormSubmitButton } from "./styled/productAddForm";
import axios from "axios";
import { useSelector } from "react-redux";
import { setProductIndex } from "../../../../store/compoIndex";
import { useDispatch } from "react-redux";

export default function ProductAddForm() {
  const [modalState, setModalState] = useState(false);
  const [excelModalState, setExcelModalState] = useState(false);
  const [productImage, setProductImage] = useState();
  const [productXlsx, setProductXlsx] = useState();
  const [product, setProduct] = useState({
    prductNm: "",
    brand: "",
    cnsmrPc: 0,
    repImg: "",
    extrlImgUrl: "",
  });
  const user = useSelector((state) => state?.user?.data[0]);
  const dispatch = useDispatch();

  const handleModalState = () => {
    setModalState(!modalState);
  };

  const modalForm = () => {
    return (
      <div className="">
        <div className="">
          <h2 className="font_20 font500 font_color_white">브랜드 조회</h2>
          <button onClick={handleModalState} className="hp_step_setting_wrap_close">
            <i className="fa fa-times" aria-hidden="true" />
          </button>
          <span className="font_18 font300 font_color_gray pdt_20 text_center">
            취급하는 제품의 브랜드를 선택해주세요.
          </span>
          <div className="hp_step_setting_wrap_input">
            <input type="text" defaultValue placeholder="카테고리를 입력하세요." />
            <button type="button">조회</button>
          </div>
          <table className="table_sm_list work_place hp_step">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "75%" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">
                  <span className="font_18 font300 font_color_gray text_center">선택</span>
                </th>
                <th scope="col">
                  <span className="font_18 font300 font_color_gray text_center">브랜드</span>
                </th>
              </tr>
            </thead>
            <tbody id="place_tbody">
              <tr>
                <td>
                  <input type="checkbox" />
                </td>
                <td>
                  <span className="font_18 font300 font_color_gray text_center">입력칸</span>
                </td>
              </tr>
            </tbody>
          </table>
          <button type="button" className="hp_step_setting_cate_save">
            저장하기
          </button>
        </div>
      </div>
    );
  };

  const handleDownloadExcel = () => {
    const a = document.createElement("a");
    a.href = "https://cloud.1472.ai:18443/images/cloud/download_file/notitle_excel_upload.xlsx";
    a.download = "양식.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    toast.success("엑셀 양식이 저장되었습니다.");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct((data) => ({ ...data, [name]: value }));
  };

  // 이미지 추가
  const handleAddImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      setProductImage(file);
    }
  };

  // 엑셀 추가
  const handleAddXlsx = (e) => {
    const file = e.target.files[0];

    if (file) {
      setProductXlsx(file);
      handleModalOpen();
    }
  };

  // 파일 업로드
  const uploadFile = async (item) => {
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res.data.responseData.fileName;
  };

  // 제품종합관리
  const handlePrdSynth = async (image) => {
    const url = "/api/prdSynth";
    const body = { ...product, createMberId: user.userName, updateMberId: user.userName };
    if (image) {
      body.repImg = image;
      body.extrlImgUrl = image;
    }
    const res = await axios.put(url, body);
    if (res.status === 200) {
      console.log(res);
      await handleStockSply(res.data.rtnModel);
    }
  };

  // 상품 연동
  const handleStockSply = async (item) => {
    console.log(item);
    const url = "/api/put/stockSply";
    let stockSplyList = [];
    const obj = {
      cmpnyNo: user.cmpnyNo,
      pdCmPrice: item.cnsmrPc,
      prdNo: item.prdNo,
      salesStatus: "S01",
      splySe: "SIO002",
      qy: 1,
      createMberId: user.userName,
      updateMberId: user.userName,
      gbn: "notitle",
    };

    stockSplyList.push(obj);

    const body = { stockSplyList };
    const res = await axios.post(url, body);

    if (res.data.success) {
      toast.success("상품이 등록되었습니다.");
      dispatch(setProductIndex(0));
    }
  };

  const handleSubmit = async () => {
    if (!validationCheck()) {
      return;
    }

    let image;
    if (productImage) {
      image = await uploadFile(productImage);
    }

    await handlePrdSynth(image);
  };

  const validationCheck = () => {
    const fieldLabels = {
      prductNm: "제품명",
      brand: "브랜드",
    };

    for (let key in fieldLabels) {
      if (textNotExist(product[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return false;
      }
    }

    if (!product.cnsmrPc) {
      toast.error("소비자가를 입력해주세요.");
      return false;
    }

    if (!productImage) {
      toast.error("제품 이미지를 추가해주세요.");
      return false;
    }

    return true;
  };

  const handleExcelUpload = async () => {
    const form = new FormData();

    form.append("file", productXlsx);
    form.append("cmpnyNo", user.cmpnyNo);
    form.append("mberNo", user.mberNo);
    form.append("title", productXlsx.name);
    form.append("gbn", "notitle");

    const url = "/api/prdSynthExcelUploadAndPutStock";
    const res = await axios.post(url, form);

    if (res.status === 200) {
      toast.success("엑셀이 등록되었습니다.");
      dispatch(setProductIndex(0));
    }
  };

  const handleModalOpen = () => {
    setExcelModalState((modalOpen) => !modalOpen);
  };

  const ExcelModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">엑셀 등록</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          엑셀을 등록하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setExcelModalState(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={handleExcelUpload}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="hp_step_content">
      <div className="hp_step_product_step">
        <ProductAddFormInfo>
          <span className="font_16 font400 text_left font_color_gray">
            등록할 제품의 정보를 입력해주세요.
            <strong className="font_color_1 font400">*항목은 필수값이니 꼭 입력해주세요.</strong>
          </span>
          <div className="xlsxButtons">
            <button onClick={handleDownloadExcel}>양식 내려받기</button>
            <label>
              <input type="file" onChange={handleAddXlsx} />
              엑셀 업로드
            </label>
          </div>
        </ProductAddFormInfo>
        <div>
          {/*01.제품등록 시작*/}
          <div>
            {/*작성폼 시작*/}
            <div id="step1_html">
              <h5 className="sound_only">제품 정보 입력</h5>
              <div className="writing_ty2">
                <ul className="writing_list">
                  <li>
                    <div className="writing_tit">
                      <strong className="tit">
                        제품 이미지 첨부<em className="point">필수</em>
                      </strong>
                    </div>
                    <div className="writing_cont">
                      <div className="file_import">
                        <input
                          type="text"
                          id="file_import1"
                          readOnly="readonly"
                          value={productImage?.name ? productImage.name : ""}
                        />
                        <label>
                          파일찾기
                          <input
                            type="file"
                            id="wk_file"
                            name="wk_file"
                            onChange={handleAddImage}
                          />
                        </label>
                        <button type="button" className="product_img_add">
                          <i className="fa fa-plus" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit required">
                      <strong className="tit">
                        브랜드<em className="point">필수</em>
                      </strong>
                    </div>
                    <div className="writing_cont" id="project_location">
                      <div className="inputxt_normal">
                        <input
                          type="text"
                          id="wk_name"
                          name="brand"
                          placeholder="브랜드조회를 눌러 확인해주세요."
                          onChange={handleInputChange}
                        />
                        <button
                          onClick={handleModalState}
                          type="button"
                          name="button"
                          className="btn_line gray btn_duplicate">
                          <span>브랜드 조회</span>
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="no_border">
                    <div className="writing_tit required">
                      <strong className="tit">
                        소비자가<em className="point">필수</em>
                      </strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input
                          type="number"
                          name="cnsmrPc"
                          placeholder="금액을 입력하세요"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit required">
                      <strong className="tit">
                        제품명<em className="point">필수</em>
                      </strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input
                          type="text"
                          name="prductNm"
                          placeholder="제품명을 입력하세요."
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit required">
                      <strong className="tit">카테고리</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <select className="half_select">
                          <option value>업종</option>
                        </select>
                        <select className="half_select">
                          <option value>업종</option>
                        </select>
                        <select className="half_select">
                          <option value>업종</option>
                        </select>
                        <select className="half_select">
                          <option value>업종</option>
                        </select>
                        <select className="half_select">
                          <option value>업종</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit required">
                      <strong className="tit">해시태그</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input
                          type="text"
                          placeholder="해시태그는 ,(콤마)를 사용하여 나눠주세요."
                          defaultValue
                        />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">제품번호</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">제조사 번호</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">제조국</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <select>
                          <option value>대한민국</option>
                          <option value>일본</option>
                          <option value>중국</option>
                          <option value>미국</option>
                        </select>
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit required">
                      <strong className="tit">배송유형</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit required">
                      <strong className="tit">포장수량</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">포장단위</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">규격</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li className="half_li">
                    <div className="writing_tit">
                      <strong className="tit">규격단위</strong>
                    </div>
                    <div className="writing_cont">
                      <div className="inputxt_normal spider">
                        <input type="text" defaultValue />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit required">
                      <strong className="tit">제품 설명</strong>
                    </div>
                    <div className="writing_cont">
                      <textarea
                        rows={10}
                        placeholder="제품 설명을 입력하세요."
                        style={{ resize: "none", outline: "none" }}
                        defaultValue={""}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="writing_tit">
                      <strong className="tit">상세 HTML 내용</strong>
                    </div>
                    <div className="writing_cont">
                      <textarea
                        rows={10}
                        placeholder="스마트에디터가 들어가는 자리입니다."
                        style={{ resize: "none", outline: "none" }}
                        defaultValue={""}
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/*//작성폼 끝*/}
          </div>
          <ProductAddFormSubmitButton onClick={handleSubmit}>완료</ProductAddFormSubmitButton>
          {/*//01.제품등록 끝*/}
        </div>
      </div>
      <Modal modalState={modalState} handleModalState={handleModalState} html={modalForm()} />
      <Modal
        modalState={excelModalState}
        handleModalState={handleModalOpen}
        html={ExcelModalForm()}
        h={"auto"}
        w={"300px"}
      />
    </div>
  );
}
