import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";

import { IoMdClose } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { AiOutlineHome } from "react-icons/ai";
import { PiFolderOpenFill } from "react-icons/pi";
import { GoProjectRoadmap } from "react-icons/go";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { LuMessagesSquare, LuPrinter, LuUser } from "react-icons/lu";
import { GrGroup } from "react-icons/gr";
import { RiGroupLine } from "react-icons/ri";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { FiMoon, FiSun } from "react-icons/fi";

import { GridSideMenuContainer } from "../../../../../../common/styled/common";

export default function DashboardSideMenu({
  clickSideMenu,
  setClickSideMenu,
  setScreenMode = null,
  isMargin = true,
}) {
  const user = useSelector((state) => state?.user?.data[0]);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const isInner = useMediaQuery({ query: "(max-width:1400px)" });
  const navigate = useNavigate();
  const location = useLocation();
  const [isGroup, setIsGroup] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    setIsGroup(path.includes("group"));
    setIsCalendar(path.includes("scheduler"));
  }, []);

  const menuItems = [
    user && {
      title: "내 정보",
      icon: LuUser,
      event: () => navigate("/mypage/modifyProfile"),
    },
    {
      title: "캘린더",
      icon: IoCalendarOutline,
      event: () => navigate("/mypage/scheduler"),
    },
    {
      title: "프로젝트",
      icon: GoProjectRoadmap,
      event: () => navigate("/mypage/projectList"),
    },
    {
      title: "e층창고",
      icon: AiOutlineHome,
      event: () => navigate("/mypage/homepage"),
    },
    {
      title: "그룹",
      icon: GrGroup,
      event: () => navigate("/group"),
    },
    {
      title: "업무",
      icon: HiOutlineOfficeBuilding,
      event: () => handleCmpnyNavigate(),
    },
    {
      title: "파일함",
      icon: PiFolderOpenFill,
      event: () => navigate("/mypage/projectFileBox"),
    },
  ].filter(Boolean);

  const grpMenuItems = [
    {
      title: "그룹",
      icon: GrGroup,
      event: () => navigate("/group"),
    },
    {
      title: "소식",
      icon: LuMessagesSquare,
      event: () => navigate("/group/feed"),
    },
    {
      title: "마이그룹",
      icon: RiGroupLine,
      event: () => navigate("/group/myGroup"),
    },
    {
      title: "그룹 생성",
      icon: MdOutlineCreateNewFolder,
      event: () => navigate("/group/createGroup"),
    },
  ];

  const calendarMenuItems = [
    {
      title: isDarkMode ? "라이트모드" : "다크모드",
      icon: isDarkMode ? FiSun : FiMoon,
      event: () => setScreenMode(isDarkMode ? 0 : 1),
    },
    {
      title: "인쇄",
      icon: LuPrinter,
      event: () => alert("준비 중입니다"),
    },
  ];

  const handleCmpnyNavigate = async () => {
    const url = "/api/cmpny";
    const body = { cmpnyNo: user.cmpnyNo };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const data = res.data;

      if (data.bizrno && data.useYn === "Y") {
        navigate("/mypage/company");
      } else {
        navigate("/mypage/companyInfo");
      }
    } else {
    }
  };

  const handleClickMenu = (item) => {
    if (!isGroup && item.title === "그룹") {
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
      }
      window.open(`https://1472.ai/group`);
    } else {
      setClickSideMenu(false);
      item.event();
    }
  };

  const renderMenuItems = (items) => (
    <ul>
      {items.map((item) => (
        <li
          key={item.title}
          onClick={() => {
            handleClickMenu(item);
          }}>
          <div className="icon">
            <item.icon />
          </div>
          <span>{item.title}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <GridSideMenuContainer
      style={{ display: clickSideMenu ? "block" : "none" }}
      onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}>
      <div
        className="sideMenuInner"
        onClick={(e) => e.target === e.currentTarget && setClickSideMenu(false)}
        style={{ maxWidth: !isInner && isMargin ? 1400 : "none" }}>
        <div className="sideMenuWrapper">
          <div className="sideMenuContainer">
            <div className="arrowUp" />
            <div className="sideMenuHeader">
              <div className="logo">
                <img src="/img/logo_dark.png" alt="Logo" />
                <span>바로가기</span>
              </div>
              <div className="close">
                <button onClick={() => setClickSideMenu(false)}>
                  <IoMdClose />
                </button>
              </div>
            </div>
            <div className="sideMenuBody">
              {isGroup && renderMenuItems(grpMenuItems)}
              {isCalendar && renderMenuItems(calendarMenuItems)}
              {(isCalendar || isGroup) && <div className="border"></div>}
              {renderMenuItems(menuItems)}
            </div>
          </div>
        </div>
      </div>
    </GridSideMenuContainer>
  );
}
