import styled from "styled-components";

export const PickUpContainer = styled.div`
  .content {
    padding: 0 30px;
    background: #fff;
  }
  .sidemenu {
    .tabs {
      ul li {
        width: 50%;
        height: 75px;
        line-height: 75px;
        text-align: center;
        display: inline-block;
        font-weight: 700;
        font-size: 30px;
        color: #245dab;
        &.active {
          background: #245dab;
          color: #fff;
        }
      }
    }
    .date {
      font-size: 28px;
      height: 75px;
      line-height: 75px;
      text-align: center;
      font-weight: 700;
    }
  }
  @media screen and (max-width: 1050px) {
    .sidemenu {
      width: 265px;
      .tabs {
        ul li {
          font-size: 20px;
          height: 50px;
          line-height: 50px;
        }
      }
      .date {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
      }
    }
    .container .content {
      width: calc(100% - 265px);
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .container {
      flex-direction: column;
      .content {
        height: calc(100% - 84px);
        width: 100%;
        padding: 0;
        background: transparent;
        .order {
          height: 100%;
          padding: 10px 20px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #5a5a5a;
            border-radius: 10px;
            border: 2px solid #d9d9d9;
          }
          &::-webkit-scrollbar-track {
            background-color: #d9d9d9;
            border-radius: 10px;
          }
        }
      }
    }
    .info {
      .label {
        font-size: 20px;
        padding: 0 20px;
        height: 54px;
        line-height: 54px;
        font-weight: 700;
      }
      .tabs {
        ul {
          display: flex;
          align-items: center;
          padding: 0 20px;
          li {
            width: calc(100% / 3);
            height: 30px;
            font-size: 12px;
            border-bottom: 3px solid transparent;
            text-align: center;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #9e9e9e;
            transition: 0.2s;
            &.active {
              background: none;
              color: #000;
              border-bottom: 3px solid #255dac;
            }
          }
        }
      }
    }
  }
`;

export const OrdersSection = styled.div`
  height: calc(100% - 150px);
  overflow-y: hidden;
  font-size: 22px;
  .newOrders {
    max-height: 45%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
    ul {
      li {
        color: #245dab;
        padding: 10px 15px;
        border-bottom: 1px solid #efefef;
        display: flex;
        align-items: center;
        gap: 10px;
        &:last-child {
          border-bottom: none;
        }
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        .orderInfo {
          width: calc(100% - 60px);
          span {
            display: block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.tit {
              font-size: 26px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  .inProgressOrders {
    height: 55%;
    .inProgressInfo {
      padding: 0 15px;
      height: 70px;
      line-height: 70px;
      color: #245dab;
      font-weight: 600;
    }
    ul {
      max-height: calc(100% - 70px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #5a5a5a;
        border-radius: 10px;
        border: 2px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      li {
        padding: 15px;
        background: #efefef;
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 20px;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }
        .inProgressOrderItem {
          width: calc(100% - 140px);
          span {
            display: block;
            font-size: 20px;
            &:first-child {
              font-weight: 700;
            }
          }
        }
        .orderProgress {
          width: 60px;
          height: 60px;
          background: #14110b;
          border-radius: 50%;
          position: relative;
          display: inline-block;
          &.red {
            background: #ff7878;
          }
          &.yellow {
            background: #ffcf78;
          }
          &.green {
            background: #77b171;
          }
          .progressRemaining {
            width: 50px;
            height: 50px;
            line-height: 50px;
            background: #efefef;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            text-align: center;
            font-weight: 700;
            font-size: 18px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    height: calc(100% - 100px);
    .newOrders ul li .orderInfo span {
      font-size: 18px;
      &.tit {
        font-size: 20px;
      }
    }
    .inProgressOrders ul li {
      gap: 5px;
      margin: 10px;
      .orderProgress {
        width: 40px;
        height: 40px;
        .progressRemaining {
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 12px;
        }
      }
      .inProgressOrderItem {
        width: calc(100% - 80px);
        span {
          font-size: 14px;
        }
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
`;

export const DetailsInfoContainer = styled.div`
  height: 135px;
  border-bottom: 1px solid #000;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .info {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: calc(100% - 360px);
    .infoImage {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    span {
      display: block;
      &:first-child {
        font-size: 30px;
      }
      &:last-child {
        color: #666666;
      }
    }
  }
  .actions {
    display: flex;
    gap: 10px;
    height: 75px;
    line-height: 75px;
    > button {
      border: 1px solid #666666;
      width: 95px;
      height: 100%;
      text-align: center;
      font-weight: 700;
      background: transparent;
      &:last-child {
        background: #255dac;
        color: #fff;
        border: none;
      }
    }
    .notify {
      height: 100%;
      border: 1px solid #666666;
      width: 200px;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      button {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
        font-weight: 700;
      }
    }
    .stepper {
      height: 100%;
      border: 1px solid #666666;
      width: 200px;
      overflow-y: hidden;
      display: flex;
      justify-content: center;
      button {
        border: none;
        background: transparent;
      }
      span {
        margin: 0 10px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: baseline;
    padding: 10px 0;
    .info {
      max-width: 100%;
      span {
        font-size: 20px;
        &:first-child {
          font-size: 24px;
        }
      }
    }
    .actions {
      height: 40px;
      line-height: 40px;
      font-size: 18px;
    }
  }
`;

export const DetailsContentContainer = styled.div`
  height: calc(100% - 135px);
  > div {
    min-height: 100px;
    border-bottom: 1px solid #000;
    display: flex;
    &:last-child {
      border-bottom: none;
    }
    span.label {
      font-weight: 700;
    }
  }
  .notOrder {
    align-items: center;
    justify-content: center;
    height: calc(100% - 135px);
    padding: 0;
    .bg {
      width: 100%;
      height: 340px;
      background: #dcdcdc;
      font-weight: 700;
      line-height: 340px;
      text-align: center;
    }
  }
  .pickupInfo {
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: 1050px) {
    > div {
      span.label {
        font-size: 24px;
      }
    }
    .pickupInfo {
      flex-direction: column;
      height: 120px;
      align-items: baseline;
      padding: 10px 0;
    }
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  width: 600px;
  max-width: calc(100% - 100px);
  .bg {
    width: 100%;
    height: 12px;
    background: #e8e8e8;
    border-radius: 6px;
    position: relative;
  }
  .pointer {
    font-size: 14px;
    color: #7a7a7a;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .checkpoint {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px solid #696969;
      margin: 8px auto;
    }
    &.received {
      left: 20%;
    }
    &.cooked {
      left: 50%;
    }
    &.pickedUp {
      left: 80%;
    }
  }
  .line {
    top: 50%;
    left: 2px;
    position: absolute;
    height: 8px;
    width: 22%;
    transform: translateY(-50%);
    background: #255dac;
    border-radius: 4px;
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const RequestsContainer = styled.div`
  height: 70px;
  padding: 10px 0;
  span {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgba(246, 46, 46, 0.1);
    color: #fd5959;
    font-size: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 767px) {
    height: 60px;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
    margin: 10px 0;
    span {
      font-size: 12px;
      padding-left: 10px;
    }
  }
`;

export const OrderMenuContainer = styled.div`
  width: 100%;
  height: calc(100% - 170px);
  flex-direction: column;
  overflow-y: auto;
  .foodItem {
    display: inline-block;
    width: 50%;
    .tit {
      font-weight: 700;
    }
  }
  .foodQuantity {
    display: inline-block;
    width: 10%;
    text-align: center;
  }
  .foodPrice {
    display: inline-block;
    width: 20%;
    text-align: center;
  }
  .actions {
    display: inline-block;
    width: 20%;
    text-align: right;
    height: 70px;
    button {
      width: 100%;
      height: 100%;
      border: none;
      color: #ffff;
      background: #255dac;
    }
  }
  .info {
    border-bottom: 1px solid #000;
    font-weight: 700;
    padding: 10px 0;
    line-height: 70px;
    span {
      display: inline-block;
    }
  }
  ul {
    height: calc(100% - 91px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #5a5a5a;
      border-radius: 10px;
      border: 2px solid #d9d9d9;
    }
    &::-webkit-scrollbar-track {
      background-color: #d9d9d9;
      border-radius: 10px;
    }
  }
  .food {
    width: 100%;
    padding: 30px 0;
    height: auto;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    font-size: 24px;
    .foodItem .foodOptions {
      font-size: 20px;
      li {
        margin: 10px 0 10px 20px;
      }
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media screen and (max-width: 1050px) {
    height: calc(100% - 190px);
    ul {
      height: calc(100% - 61px);
    }
    .actions {
      height: 40px;
      button {
        font-size: 16px;
      }
    }
    .info {
      font-size: 20px;
      line-height: 40px;
    }
    .food {
      padding: 10px 0;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      font-size: 14px;
      line-height: 0;
      padding: 0 0 10px;
      height: 31px;
      .label {
        font-size: 14px;
        padding: 0;
        height: 100%;
        line-height: normal;
        width: 40%;
      }
      .actions {
        width: 30%;
        height: 20px;
        button {
          font-size: 10px;
          font-weight: normal;
        }
      }
    }
    .food {
      font-size: 14px;
      .foodItem .foodOptions li {
        font-size: 12px;
        margin: 5px 0 5px 10px;
        i {
          display: none;
        }
      }
    }
  }
`;

export const MobileOrderItems = styled.li`
  min-height: 108px;
  background: #fff;
  margin-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  padding: 15px;
  font-size: 14px;
  &:last-child {
    margin-bottom: 0;
  }
  > div {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  button {
    font-weight: 700;
    border: none;
    background: transparent;
  }
  .orderItemInfo {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 44px;
    .icon {
      width: 30px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .info {
      width: calc(100% - 95px);
      span {
        display: block;
      }
      .tit {
        font-weight: 700;
      }
      .con {
        font-size: 12px;
      }
    }
    .orderProgress {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: relative;
      &.red {
        background: #ff7878;
      }
      &.yellow {
        background: #ffcf78;
      }
      &.green {
        background: #77b171;
      }
      .progressRemaining {
        width: 38px;
        height: 38px;
        line-height: 38px;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
  .action {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    font-weight: 700;
    .notify {
      width: 100%;
      height: 100%;
    }
  }
  .toggleButton {
    button {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    svg {
      display: block;
    }
  }
  .orderItemContent {
    overflow: hidden;
    transition: 0.3s;
    box-sizing: border-box;
  }
  .controls {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 30px;
    button {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      border: none;
      height: 100%;
      border-radius: 5px;
      font-weight: 700;
      &:first-child {
        flex-grow: 1;
      }
      &:last-child {
        background: #3e50da;
        color: #fff;
        flex-grow: 2;
      }
    }
  }
`;
