import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HomePageSettingMenu from "../homepageSettingMenu";
import RegisterTitle from "./registerTitle";
import RegisterMenu from "./registerMenu";
import "../component/styled/port.css";
import { setRegisterIndex } from "../../../../store/compoIndex";
import CustomModal from "../../../../common/customModal";
import Modal from "../../../../common/modal";
import { ImgWrap } from "../../../shopping/portfolio/portfolioView/styled";

export default function RegisterPortViewForm() {
  const navigater = useNavigate();
  const index = useSelector((state) => state.compoIndex.register);
  const params = useParams();
  const portNo = params.portNo;
  const [portfolio, setPortfolio] = useState([]);
  const session = JSON.parse(sessionStorage.getItem("persist:root"));
  const userData = JSON.parse(session?.user ? session?.user : null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data[0]);
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = userData?.data?.length;
    if (!isAuth || isAuth === 0) {
      return;
    }
    getPortfolio();
  }, []);

  const getPortfolio = async () => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      portNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true,
    };
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    const htmlString = res[0]?.contents;
    const contents = document.getElementById("contents");
    contents.innerHTML = htmlString;
    setPortfolio(res[0]);
  };

  const deletePortfolio = async () => {
    let url = "/api/portfolio";
    let request = {
      portNo,
      cmpnyNo: user.cmpnyNo,
      notitleYn: "Y",
      useYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    const res = await axios.put(url, request);
    if (res.data.success === true) {
      navigater("/mypage/register", { state: { deletePort: true } });
      dispatch(setRegisterIndex(2));
    }
  };

  const moveToPage = () => {
    navigater("/mypage/register");
    dispatch(setRegisterIndex(2));
  };

  const handlEChanggo = async () => {
    if (!user) {
      navigate("/login");
    } else {
      const url = "/api/domainSettingLoad";
      const req = {
        delYn: "N",
        cmpnyNo: user.cmpnyNo,
      };

      const res = await axios.post(url, req);
      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}/portfolio?page=0`);
        user && localStorage.setItem("user", JSON.stringify(user));
      } else {
        navigate("/mypage/homepage");
      }
    }
  };

  return (
    <main className="port_main">
      <div className="inner_content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <section className="port_view_wrapper">
          <p className="font_24 font600 font_color_black pdt_20 text_left">{portfolio.title}</p>
        </section>

        <section className="port_view_wrapper">
          <div style={{ width: "100%" }}>
            <div
              id="secBrdcrmb"
              className="font_14 pdb_20 text_right shop_port_menu"
              style={{ width: "100%" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <span className="author_name font_14 font_color_gray font300">
                  <span className="sound_only">작성자</span>
                  <i className="fa fa-pencil-square-o" aria-hidden="true" />{" "}
                  {portfolio?.createMberId}
                </span>
                <span className="author_date font_14 font_color_gray font300">
                  <span className="sound_only">작성일</span>
                  <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                  {portfolio?.updateDt || portfolio?.createDt}
                </span>
                <button
                  onClick={handlEChanggo}
                  style={{ border: "none", background: "transparent" }}>
                  홈페이지에서 보기
                </button>
              </div>
              <div style={{ display: "flex", gap: 15 }}>
                <Link onClick={() => navigater(`/mypage/registerPhotoUpload/${portNo}`)}>
                  <span>수정</span>
                </Link>
                <Link onClick={() => setModalOpen(true)}>
                  <span>삭제</span>
                </Link>
                <Link to={`/mypage/register`}>
                  <span style={{ cursor: "pointer" }}>전체 포트폴리오 보기</span>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="port_detail_wrapper">
          <strong className="port_content" id="contents"></strong>
        </section>

        {portfolio?.dtlList?.length !== 0 && (
          <section>
            {portfolio?.dtlList?.map((item) => (
              <ImgWrap key={item.portDtlNo}>
                <img
                  src={imgHandler(item.imgDtlUrl)}
                  style={{ width: "100%" }}
                  onError={onErrorImg}
                />
              </ImgWrap>
            ))}
          </section>
        )}
        <div className="btnarea_center mt20 port_btn_wrapper">
          <Link onClick={() => setModalOpen(true)} className="btn_normal_cancel port_btn">
            <span>삭제</span>
          </Link>
          <Link
            onClick={() => navigater(`/mypage/registerPhotoUpload/${portNo}`)}
            className="btn_normal_save port_btn">
            <span>수정</span>
          </Link>
          <Link onClick={moveToPage} className="btn_normal port_btn">
            <span>목록</span>
          </Link>
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={
            <CustomModal
              title={`포트폴리오`}
              content={`포트폴리오를 삭제 하시겠습니까?`}
              handleConfirm={deletePortfolio}
              handleCancel={() => setModalOpen(false)}
            />
          }
          w="300px"
          h="auto"
        />
      </div>
    </main>
  );
}
