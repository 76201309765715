import GroupRequestContents from "./groupRequestContents";
import GroupRequestPopup from "./groupRequestPopup";
import GroupRequestTitle from "./groupRequestTitle";

export default function GroupRequestForm () {
  return(
    <div className="inner_content">
      <GroupRequestTitle />
      <GroupRequestContents />
      <GroupRequestPopup />       
    </div>
  )
}