import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import { IoCloseSharp } from "react-icons/io5";

import { CreateGroupContainer } from "../../../styled/group";
import Modal from "../../../../../../common/modal";
import { useSelector } from "react-redux";

const GroupDetailSettingInfo = ({ grp, setGrp }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:640px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const [formData, setFormData] = useState({});
  const [isGbnChk, setIsGbnChk] = useState(grp.gbn ? true : false);
  const [modalOpen, setModalOpen] = useState(false);
  const [image, setImage] = useState();
  const [isDisplay, setIsDisplay] = useState(grp.displayYn === "Y");
  const [isApprovalUse, setIsApprovalUse] = useState(grp.approvalUseYn === "Y");

  useEffect(() => {
    setFormData({ ...grp });
  }, []);

  const changeInput = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 이미지 추가
  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((data) => ({
        ...data,
        imgUrl: imageUrl,
      }));

      setImage(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  const checkSubmitCond = () => {
    if (!formData.groupName) {
      toast.error("그룹 이름을 설정해주세요.");
      return;
    }

    if (isGbnChk && !formData.gbn) {
      toast.error("명칭을 설정해주세요.");
      return;
    }

    handleModalOpen();
  };

  // 저장
  const onSubmit = async () => {
    const url = "/api/group";
    let body = {
      ...formData,
      groupNo: formData.groupNo,
      groupName: formData.groupName,
      createMberId: user.userName,
      updateMberId: user.userName,
      mberNo: user.mberNo,
      mberNm: user.userName,
      delYn: formData.delYn,
      delDt: formData.delDt,
      gbn: isGbnChk ? formData.gbn : null,
      imgUrl: formData.imgUrl,
      displayYn: isDisplay ? "Y" : "N",
      approvalUseYn: !isDisplay || isApprovalUse ? "Y" : null,
    };

    if (image) {
      const uploaded = await uploadLogoImage(image);
      if (!uploaded) {
        setModalOpen(false);
        return;
      }
      body.imgUrl = uploaded;
    }

    const emptyValues = Object.keys(body).reduce((acc, key) => {
      acc[key] = !body[key] ? null : body[key];
      return acc;
    }, {});

    body = { ...emptyValues };

    const responses = await axios.put(url, body);
    const data = responses.data;
    if (data.success && Object.keys(data.rtnModel).length > 0) {
      toast.success("그룹 정보가 변경되었습니다.");
      handleModalOpen();
      setGrp({ ...data.rtnModel });
    }
  };

  const handleModalOpen = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const ModalForm = () => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">그룹 변경</h2>
        <button onClick={handleModalOpen} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_20 font300 font_color_gray pdt_20 text_center">
          그룹 정보를 변경하시겠습니까?
        </span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={onSubmit}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <CreateGroupContainer style={{ padding: "30px 0" }}>
        <ul style={{ maxWidth: 550 }}>
          <li>
            <div className="tit">이미지</div>
            <div className="content">
              <div className="file_import">
                <label>
                  파일찾기
                  <input
                    type="file"
                    id="wk_file"
                    name="logoImage"
                    accept="image/*"
                    onChange={addImage}
                  />
                </label>
                <div className="preview">
                  {formData.imgUrl ? (
                    <>
                      <img src={imgHandler(formData.imgUrl)} alt="" />
                      <button
                        className="deleteBtn"
                        onClick={() => {
                          setFormData((data) => ({ ...data, imgUrl: "" }));
                          setImage(null);
                        }}>
                        <IoCloseSharp />
                      </button>
                    </>
                  ) : (
                    <div className="notImage">
                      <span>이미지가 존재하지 않습니다.</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="tit">그룹 이름</div>
            <div className="content">
              <input
                type="text"
                name="groupName"
                value={formData.groupName}
                onChange={(e) => changeInput(e)}
              />
            </div>
          </li>
          <li>
            <div>
              <input
                type="checkbox"
                id="isGbn"
                checked={isGbnChk}
                onChange={(e) => setIsGbnChk(e.target.checked)}
              />
              <label htmlFor="isGbn">명칭 사용 여부</label>
            </div>
          </li>
          {isGbnChk && (
            <li>
              <div className="tit">
                명칭 <em>ex) 기, 급</em>
              </div>
              <div className="content">
                <input
                  type="text"
                  name="gbn"
                  value={formData.gbn}
                  onChange={(e) => changeInput(e)}
                />
              </div>
            </li>
          )}
          <li>
            <div className="tit">그룹 설정</div>
            <div className="radio-inputs content">
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  checked={isDisplay}
                  onChange={(e) => setIsDisplay(true)}
                />
                <span className="name">전체공개</span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  checked={!isDisplay}
                  onChange={(e) => setIsDisplay(false)}
                />
                <span className="name">비공개</span>
              </label>
            </div>
          </li>
          <li>
            <div>
              <input
                type="checkbox"
                id="isApprovalUse"
                checked={!isDisplay || isApprovalUse}
                onChange={(e) => setIsApprovalUse(e.target.checked)}
                disabled={!isDisplay}
              />
              <label htmlFor="isApprovalUse">가입승인제</label>
            </div>
          </li>
        </ul>
        <div className="btnArea">
          <button onClick={() => checkSubmitCond()}>저장</button>
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={handleModalOpen}
          html={ModalForm()}
          w={isMobile ? "70%" : "17%"}
          h="auto"
        />
      </CreateGroupContainer>
    </>
  );
};

export default GroupDetailSettingInfo;
