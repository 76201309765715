import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { handleLogin } from "../../../store/user";
import { useDispatch } from "react-redux";
import Modal from "../../../common/modal";
import { Link } from "react-router-dom";
import { PageContainer } from "./styled/common";

export default function LoginForm({
  id,
  password,
  setId,
  setPassword,
  handleSubmit,
  getSchedulerSetting,
  handleInvite,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init("b9886faecde9faa67a51166600a9d107"); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };
    initKakao();
  }, []);

  const loginWithKakao = () => {
    Kakao.Auth.login({
      success: function (authObj) {
        const token = authObj.access_token;
        axios
          .request({
            method: "POST",
            url: "https://kapi.kakao.com/v2/user/me",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
          .then(async (response) => {
            const { data, status } = response;
            if (status === 200) {
              await kakaoSignIn(data);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      },
      fail: function (err) {
        alert("error!!!!" + JSON.stringify(err));
      },
    });
  };

  const kakaoSignIn = async (user) => {
    const url = "/api/kakaoSignIn";
    const req = {
      email: user.kakao_account.email,
      id: user.id,
      name: user.kakao_account.name,
      phoneNumber: user.kakao_account.phone_number,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      return false;
    } else {
      dispatch(handleLogin(res.data));
      if (sessionStorage.getItem("params")) {
        await handleInvite(res.data.mberNo);
      }
      await getSchedulerSetting(res.data.mberNo);
    }
  };

  const handleLoginModal = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const exitModalForm = (error) => {
    return (
      <div>
        <h2 className="font_20 font500 font_color_white">가입 유형</h2>
        <button onClick={handleLoginModal} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{error}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  return (
    <PageContainer>
      <div className="sub_top" id="mypage">
        <span className="font_22 font300 pdb_20 font_color_white">로그인</span>
        <span className="font_72 font700 font_color_1 pdb_70">LOGIN</span>
      </div>
      <div className="inner_content">
        <div className="inner_title">
          <span className="font_30 font300 font_color_black">{service.loginComment}</span>
        </div>
        <div className="inner_content_wrap">
          <div className="change_pw">
            <form onSubmit={(e) => handleSubmit(e)}>
              <input
                type="text"
                placeholder="ID"
                value={id}
                onChange={(e) => setId(e.target.value)}
                id="member_id"
                name="member_id"
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                id="member_pw"
                name="member_pw"
              />
              <button
                type="submit"
                id="pwChangeBtn"
                className="pw_confirm pw_100"
                onClick={handleSubmit}>
                <span>로그인</span>
              </button>
              <button
                className="pw_confirm pw_100"
                type="button"
                style={{
                  backgroundColor: "#FEE500",
                  margin: "10px 0",
                }}
                onClick={loginWithKakao}>
                <span style={{ color: "black" }}>카카오톡 로그인</span>
              </button>
              <Link to="/memberSearch" id="leave" className="member_find">
                회원정보찾기
              </Link>
              <Link to="/type" id="leave" className="member_apply" type="button">
                회원가입
              </Link>
            </form>
          </div>
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={handleLoginModal}
        html={exitModalForm()}
        w="300px"
        h="auto"
      />
    </PageContainer>
  );
}
