import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";

const Products = ({ cmpnyNo }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getProducts(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getProducts = async (cmpnyNo) => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 8,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      console.log(res);
      setProducts(res.data.content);
    } catch (error) {
      console.log(error);
    }
  };

  const isPrdImg = (item) => {
    if (item.extrlImgUrl) return imgHandler(item.extrlImgUrl);
    if (item.prdImgFlpth) return imgHandler(item.prdImgFlpth);
    return "/img/prdct_02.png";
  };

  return (
    <div className="products">
      <div className="info">Product</div>
      <div className="wrapper">
        {products.map((item, i) => (
          <div className="box">
            <div className="image">
              <img src={isPrdImg(item)} />
            </div>
            <div className="prdInfo">
              <span>{item.prductNm}</span>
              <span className="price">{item.cnsmrPc ? item.cnsmrPc.toLocaleString() : 0}원</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
