import styled from "styled-components";

export const QnaTitleContainer = styled.div`
  .inner_title_menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      &:hover {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .inner_title_menu {
      justify-content: flex-start;
      li {
        box-sizing: border-box;
        margin-bottom: -1px;
        &:nth-child(even) {
          border-left: none;
        }
      }
    }
  }
`;

export const GroupReqContents = styled.div`
  button.btn_normal {
    border: none;
  }
`;
