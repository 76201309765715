import ProductBrandTop from "./productBrandTop";
import ProductBrandBottom from "./productBrandBottom";
import HomepageEdit from "../homepageEdit";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function ProductBrandForm(){
  useEffect(() => {
    getBrandList({page : 1});
  }, []);

  const [bussinessCode, setBussinessCode] = useState("");
  const [largeCode, setLargeCode] = useState("");
  const [middleCode, setMiddleCode] = useState("");
  const [smallCode, setSmallCode] = useState("");
  const [detailCode, setDetailCode] = useState("");
  const [check, setCheck] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const cmpnyNo = useSelector((state) => state?.user?.data[0]?.cmpnyNo);
  const [page, setPage] = useState(1);
  const [brandList, setBrandList] = useState();
  const [checkedSet, setCheckedSet] = useState(new Set());

  const [bussinessCategory, setBussinessCategory] = useState([]);
  const [largeCategory, setLargeCategory] = useState([]);
  const [middleCategory, setMiddleCategory] = useState([]);
  const [smallCategory, setSmallCategory] = useState([]);
  const [detailCategory, setDetailCategory] = useState([]);

  const getBrandList = async ({page}) => {
    const url = '/api/getBrand';
    const request = {
      delYn : "N",
      cmpnyNo ,
      offset : (page - 1) * 10,
      pageNumber : page - 1,
      pageSize: 10,
      paged: true
    };
    
    const response = await axios.post(url, request);
    setBrandList(response.data);
  };

  const handlePageChange = async (page) => {
    getBrandList({page});
    setPage(page);
    setCheckedSet(new Set());
  };

  return(
    <>
      <HomepageEdit />
      <div className="hp_step_content">
        <div className="hp_step_product_step">
          <form  method="post">
            {/*연동제품 시작*/}
            <div className="hp_step_product_wrap">
              <ProductBrandTop
                bussinessCode={bussinessCode}
                largeCode={largeCode}
                middleCode={middleCode}
                smallCode={smallCode}
                detailCode={detailCode}
                check={check}
                selectedBrand={selectedBrand}
                setBussinessCode={setBussinessCode}
                setLargeCode={setLargeCode}
                setMiddleCode={setMiddleCode}
                setSmallCode={setSmallCode}
                setDetailCode={setDetailCode}
                setCheck={setCheck}
                setSelectedBrand={setSelectedBrand}
                getBrandList={getBrandList}
                bussinessCategory={bussinessCategory}
                largeCategory={largeCategory}
                middleCategory={middleCategory}
                smallCategory={smallCategory}
                detailCategory={detailCategory}
                setBussinessCategory={setBussinessCategory}
                setLargeCategory={setLargeCategory}
                setMiddleCategory={setMiddleCategory}
                setSmallCategory={setSmallCategory}
                setDetailCategory={setDetailCategory}
              />
              <ProductBrandBottom
                brandList={brandList}
                page={page}
                handlePageChange={handlePageChange}
                getBrandList={getBrandList}
                bussinessCategory={bussinessCategory}
                largeCategory={largeCategory}
                middleCategory={middleCategory}
                smallCategory={smallCategory}
                detailCategory={detailCategory}
                setCheckedSet={setCheckedSet}
                checkedSet={checkedSet}
                selectedBrand={selectedBrand}
                bussinessCode={bussinessCode}
              />
            </div>
            {/*//연동제품 끝*/}
          </form>
        </div>
      </div>
    </>
  )
}