import axios from "axios";
import React, { useEffect, useState } from "react";

const Banner = ({ cmpnyNo }) => {
  const [banner, setBanner] = useState([]);

  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (cmpnyNo) {
      getMyBanner(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getMyBanner = async (cmpnyNo) => {
    const url = "/api/firstSettingLoad";
    const body = {
      cmpnyNo,
      delYn: "N",
    };
    try {
      const res = await axios.post(url, body);
      const images = JSON.parse(res.data.bannerImgList);

      if (images && images.length > 0) {
        setBanner(images);
      } else {
        setBanner([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="banner">
      <div className="info">
        <span className="tit">
          LOLLEM ITSUM <br /> TO ADBDFDF
        </span>
        <div className="scroll">
          <span>SCROLL</span>
          <button className="scroll-button" onClick={handleScroll}>
            <div className="dot"></div>
          </button>
        </div>
      </div>
      <div className="banners">
        <div className="left">
          {[0, 1].map((index) => (
            <div className="box" key={index}>
              {banner[index] ? (
                <img src={imgHandler(banner[index])} alt={`banner${index + 1}`} />
              ) : null}
            </div>
          ))}
        </div>
        <div className="right">
          {banner[2] && <img src={imgHandler(banner[2])} alt="banner3" />}
        </div>
      </div>
    </div>
  );
};

export default Banner;
