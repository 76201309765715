import React from "react";
import { useNavigate } from "react-router";
import { ContentNavigation } from "../../../../mypage/projectList/component/common/styled/project";
import { tabMenus } from "../../function/group";

const GroupDetailTabMenu = ({ tabChange }) => {
  return (
    <ContentNavigation>
      {tabMenus.map((item, idx) => (
        <li onClick={() => tabChange(idx)}>
          <item.icon />
          <span>{item.name}</span>
        </li>
      ))}
    </ContentNavigation>
  );
};

export default GroupDetailTabMenu;
