import { useSelector } from "react-redux";
import BoardView from "../../../../common/boardViewPage";
import FaqSubMenu from "./faqSubMenu";

export default function FaqViewForm () {
  const index = useSelector((state) => state.compoIndex.faq);
  return(
    <div className="inner_content">
      <FaqSubMenu index={index}/>
      <BoardView />
    </div>
  )
}