export default function ReadyModalForm({setReadyModal, modalName}) {
  return(
    <div>
      <h2 className="font_20 font500 font_color_white">{modalName}</h2>
      <button onClick={() =>setReadyModal(false)} className="hp_step_setting_wrap_close">
        <i className="fa fa-times" aria-hidden="true" />
      </button>
      <span className="font_18 font300 font_color_gray pdt_20 text_center">
        서비스 준비 중입니다.
      </span>
      <div style={{display : "flex", justifyContent : "center", marginTop : "20px"}}>
        <button onClick={() =>setReadyModal(false)}  className="hp_step_setting_cate_save" style={{padding : "10px 15px", margin: "0px", fontSize : "16px"}}>확인</button>
      </div>
    </div>
  )
};