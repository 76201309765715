import React from "react";
import ReactDOM from "react-dom/client";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import AppS from "./AppS";
import AppLogin from "./AppLogin";
import AppGroup from "./AppGroup";
import AppMobile from "./AppMobile";
import reportWebVitals from "./reportWebVitals";

import { initializeApp } from "firebase/app";
import reducer from "./reducer";
import { persistor } from "./store/store";
// import { AuthProvider } from './context/AuthContext ';

/* import { getMessaging, onMessage, getToken } from "firebase/messaging"; */

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(reducer);
/* 
const firebaseConfig = {
  apiKey: "AIzaSyCEYTkFAwmsXCMNIAzYEj1OXLTrlFCBsdc",
  authDomain: "toktok-358ef.firebaseapp.com",
  projectId: "toktok-358ef",
  storageBucket: "toktok-358ef.appspot.com",
  messagingSenderId: "946728950878",
  appId: "1:946728950878:web:77f10fc3c0b98283a168cf",
  measurementId: "G-VWB1XWMRTC",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const API_KEY =
  "BDTF9CMkogFUR-U1aUldUuwl1y3c6V9UuEYtcWoCBMXFJUj9bXOOJpsb249HJcUU4SM8j6RG5zwVe0hrUIzhNQU";

getToken(messaging, { vapidKey: API_KEY })
  .then((token) => {
    if (token) {
      localStorage.setItem("msgToken", token);
    } else {
      console.log("No token available");
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
  });

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  if (Notification.permission === "granted") {
    new Notification("New Message", { body: payload.notification.body });
  }
});

Notification.requestPermission().then((permission) => {
  if (permission === "granted") {
    console.log("Notifications permission granted.");
  } else if (permission === "denied") {
    console.log("Notifications permission denied.");
  } else {
    console.log("Notifications permission not specified.");
  }
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registration successful:", registration);
    })
    .catch((err) => {
      console.log("Service Worker registration failed:", err);
    });
}
 */

// root.render(

//   <React.StrictMode>
//     <Provider store={store}>
//       <AuthProvider>
//         <App />
//       </AuthProvider>
//     </Provider>
//   </React.StrictMode>

// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import Shop from "./components/shopping/shop/shopPage";
import Company from "./components/shopping/company/companyPage";
import ProductsList from "./components/shopping/productList/productsListPage";
import ShoppingNotice from "./components/shopping/notice/noticePage";
import Portfolio from "./components/shopping/portfolio/portfolioPage";
import Search from "./components/shopping/search/searchPage";
import Cart from "./components/shopping/cart/cartPage";
import ProductsView from "./components/shopping/productsView/productsViewPage";
import ShoppingLogin from "./components/shopping/shoppingLogin";
import ShoppingNoticeView from "./components/shopping/notice/noticeView/noticeViewPage";
import ShoppingPage from "./components/shopping/shopping/shoppingPage";
import PortfolioViewPage from "./components/shopping/portfolio/portfolioView/portfolioViewPage";
import OrderPage from "./components/shopping/order/orderPage";
import SuccessPage from "./components/shopping/order/successPage";
import FailPage from "./components/shopping/order/failPage";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Main from "./components/main/mainPage";
import Error from "./common/error";
import Login from "./components/login/loginPage";
import Mypage from "./components/mypage/dashboard/dashboardPage";
import Faq from "./components/mypage/faq/faqPage";
import MyQna from "./components/mypage/myQna/myQnaPage";
import Hompage from "./components/mypage/homepage/hompage";
import Function from "./components/function/functionPage";
import Policy from "./components/policy/policyPage";
import Service from "./components/service/servicePage";
import Member from "./components/member/membersPage";
import MemberBenefit from "./components/member/BenefitPage";
import UserJoinForm from "./components/login/component/userJoinForm";
import BusinessJoinForm from "./components/login/component/businessJoinForm";
import TypeJoin from "./components/login/component/typeJoin";
import MemberSearch from "./components/login/component/memberSearch";
import Notice from "./components/notice/noticePage";
import CompanyInfo from "./components/mypage/companyInfo/companyInfoPage";
import HomepageSetting from "./components/mypage/homepageSetting/homepageSettingPage";
import MenuSetting from "./components/mypage/homepageSetting/menuSettingPage";
import Product from "./components/mypage/homepageSetting/productPage";
import Promotion from "./components/mypage/homepageSetting/promotionPage";
import ProjectList from "./components/mypage/projectList/projectListPage";
import ProjectCreate from "./components/mypage/projectList/projectCreatePage";
import ProjectCreateSec from "./components/mypage/projectList/projectCreateSecPage";
import ProjectDetailPage from "./components/mypage/projectList/projectDetailPage";
import HomepageEnd from "./components/mypage/homepageSetting/homepageEndPage";
import Scheduler from "./components/mypage/scheduler/scheduler";
import ApplicationPage from "./components/application/applicationPage";
import GroupRequest from "./components/notice/groupRequestPage";
import Qna from "./components/notice/QnaPage";
import Register from "./components/mypage/homepageSetting/registerPage";
import NoticeView from "./components/notice/noticeViewPage";
import QnaView from "./components/notice/qnaViewPage";
import FaqView from "./components/mypage/faq/faqViewPage";
import MyQnaView from "./components/mypage/myQna/myQnaViewPage";
import RegisterNoticeView from "./components/mypage/homepageSetting/registerNoticeViewPage";
import RegisterNoticeWrite from "./components/mypage/homepageSetting/registerNoticeWritePage";
import RegisterPortView from "./components/mypage/homepageSetting/registerPortViewPage";
import RegisterPhotoUpload from "./components/mypage/homepageSetting/registerPhotoUploadPage";
import MyQnaWrite from "./components/mypage/myQna/myQnaWritePage";
import QnaWrite from "./components/notice/qnaWritePage";
import MakeCompanyDomain from "./components/mypage/companyInfo/component/makeCompanyDomain";
import CalendarEntry from "./components/mypage/scheduler/calendarEntry";
import DetailEvent from "./components/mypage/scheduler/detailEvent";
import DdayEntry from "./components/mypage/scheduler/ddayEntry";
import CompanyPage from "./components/mypage/dashboard/companyPage";
import CreateReleaseNotes from "./components/mypage/dashboard/createReleaseNotesPage";
import NotifyPage from "./components/mypage/dashboard/notifyPage";
import ModifyProfilePage from "./components/mypage/dashboard/modifyProfilePage";
import ProjectFileBoxPage from "./components/mypage/projectList/projectFileBoxPage";
import IntegratedSearchPage from "./components/mypage/integratedSearch/integratedSearchPage";
import KioskPage from "./components/mypage/kiosk/kioskPage";

import Group from "./components/group/group";
import GroupDetail from "./components/group/groupDetail";
import MyGroup from "./components/group/myGroup";
import CreateGroup from "./components/group/createGroup";
import GroupLogin from "./components/group/groupLogin";
import GroupFeed from "./components/group/groupFeed";
import GroupFeedWrite from "./components/group/groupFeedWrite";

import Mobile from "./components/mobile/mobile";

import KioskSalesCheckPage from "./components/mypage/kiosk/kioskSalesCheckPage";
import KioskRreservationStatusPage from "./components/mypage/kiosk/kioskRreservationStatusPage";
import KioskPickUpPage from "./components/mypage/kiosk/kioskPickUpPage";
import KioskStoragePage from "./components/mypage/kiosk/kioskStoragePage";
import MutualBenefit from "./components/mypage/mutualBenefit/mutualBenefit";
import Pickup from "./components/mypage/mutualBenefit/pickup";
import SalesCheck from "./components/mypage/mutualBenefit/salesCheck";
import Community from "./components/mypage/mutualBenefit/community";
import AppInstall from "./common/appInstall";
import SelfService from "./components/selfService/selfService";
import Storage from "./components/selfService/storage";
import Retrieve from "./components/selfService/retrieve";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Main /> },
      { path: "/policy", element: <Policy /> },
      { path: "/function", element: <Function /> },
      { path: "/service", element: <Service /> },
      { path: "/member", element: <Member /> },
      { path: "/memberBenefit", element: <MemberBenefit /> },
      { path: "/application", element: <ApplicationPage /> },
      { path: "/notice", element: <Notice /> },
      { path: "/groupRequest", element: <GroupRequest /> },
      { path: "/qna", element: <Qna /> },
      { path: "/noticeView", element: <NoticeView /> },
      { path: "/qnaView", element: <QnaView /> },
      { path: "/qnaWrite", element: <QnaWrite /> },
      { path: "/login", element: <Login /> },
      { path: "/type", element: <TypeJoin /> },
      { path: "/join", element: <UserJoinForm /> },
      { path: "/businessJoin", element: <BusinessJoinForm /> },
      { path: "/memberSearch", element: <MemberSearch /> },
      { path: "/app-install", element: <AppInstall /> },
      { path: "/self-service", element: <SelfService /> },
      { path: "/self-service/keep", element: <Storage /> },
      { path: "/self-service/find", element: <Retrieve /> },
    ],
  },
  {
    path: "/shop",
    element: <AppS />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Shop /> },
      { path: "/shop/login", element: <ShoppingLogin /> },
      { path: "/shop/cart", element: <Cart /> },
      { path: "/shop/search", element: <Search /> },
      { path: "/shop/:url", element: <ShoppingPage /> },
      { path: "/shop/:url/company", element: <Company /> },
      { path: "/shop/:url/productsList", element: <ProductsList /> },
      { path: "/shop/:url/productsView/:prdNo", element: <ProductsView /> },
      { path: "/shop/:url/shoppingNotice", element: <ShoppingNotice /> },
      { path: "/shop/:url/shoppingNoticeView/:noticeNo", element: <ShoppingNoticeView /> },
      { path: "/shop/:url/portfolio", element: <Portfolio /> },
      { path: "/shop/:url/portfolioView/:portNo", element: <PortfolioViewPage /> },
      { path: "/shop/:url/search", element: <Search /> },
      { path: "/shop/:url/cart", element: <Cart /> },
      { path: "/shop/:url/order", element: <OrderPage /> },
      { path: "/shop/:url/order/success", element: <SuccessPage /> },
      { path: "/shop/:url/order/fail", element: <FailPage /> },
    ],
  },
  {
    path: "/mypage",
    element: <AppLogin />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Mypage /> },
      { path: "modifyProfile", element: <ModifyProfilePage /> },
      { path: "homepage", element: <Hompage /> },
      { path: "faq", element: <Faq /> },
      { path: "myQna", element: <MyQna /> },
      { path: "company", element: <CompanyPage /> },
      { path: "companyInfo", element: <CompanyInfo /> },
      { path: "createReleaseNotes", element: <CreateReleaseNotes /> },
      { path: "homepageSetting", element: <HomepageSetting /> },
      { path: "menuSetting", element: <MenuSetting /> },
      { path: "register", element: <Register /> },
      { path: "product", element: <Product /> },
      { path: "promotion", element: <Promotion /> },
      { path: "homepageEnd", element: <HomepageEnd /> },
      { path: "projectList", element: <ProjectList /> },
      { path: "projectCreate", element: <ProjectCreate /> },
      { path: "projectCreateSec", element: <ProjectCreateSec /> },
      { path: "project/:projectNo", element: <ProjectDetailPage /> },
      { path: "projectFileBox", element: <ProjectFileBoxPage /> },
      { path: "scheduler", element: <Scheduler /> },
      { path: "faqView", element: <FaqView /> },
      { path: "myQnaView", element: <MyQnaView /> },
      { path: "registerNoticeView/:noticeNo", element: <RegisterNoticeView /> },
      { path: "registerNoticeWrite", element: <RegisterNoticeWrite /> },
      { path: "registerPortView/:portNo", element: <RegisterPortView /> },
      { path: "registerPhotoUpload", element: <RegisterPhotoUpload /> },
      { path: "registerPhotoUpload/:portNo", element: <RegisterPhotoUpload /> },
      { path: "myQnaWrite", element: <MyQnaWrite /> },
      { path: "makeCompanyDomain", element: <MakeCompanyDomain /> },
      { path: "calendarEntry", element: <CalendarEntry /> },
      { path: "ddayEntry", element: <DdayEntry /> },
      { path: "detailEvent/:id", element: <DetailEvent /> },
      { path: "notify", element: <NotifyPage /> },
      { path: "search", element: <IntegratedSearchPage /> },
      { path: "kiosk", element: <KioskPage /> },
      { path: "kiosk/storage", element: <KioskStoragePage /> },
      { path: "kiosk/pickup", element: <KioskPickUpPage /> },
      { path: "kiosk/salesCheck", element: <KioskSalesCheckPage /> },
      { path: "kiosk/reservationStatus", element: <KioskRreservationStatusPage /> },
      { path: "mutualBenefit", element: <MutualBenefit /> },
      { path: "mutualBenefit/pickup", element: <Pickup /> },
      { path: "mutualBenefit/salesCheck", element: <SalesCheck /> },
      { path: "mutualBenefit/community", element: <Community /> },
    ],
  },
  {
    path: "/group",
    element: <AppGroup />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Group /> },
      { path: "createGroup", element: <CreateGroup /> },
      { path: "myGroup", element: <MyGroup /> },
      { path: "/group/:id", element: <GroupDetail /> },
      { path: "/group/login", element: <GroupLogin /> },
      { path: "/group/feed", element: <GroupFeed /> },
      { path: "/group/group-feed-write/:id", element: <GroupFeedWrite /> },
    ],
  },
  {
    path: "/mobile",
    element: <AppMobile />,
    errorElement: <Error />,
    children: [{ index: true, element: <Mobile /> }],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
