import styled from "styled-components";
import CompanyForm from "./component/companyForm";
import { ToastContainer } from 'react-toastify'

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px){
    width: 100%;
  }
`;

const Company = () => {

  return(
    <>
      <CustomToast
        position='top-center'
        theme='light'
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      <CompanyForm />
    </>
  )
};

export default Company;