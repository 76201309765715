import React, { useEffect, useState } from "react";
import moment from "moment";
// import OrderList from "./orders/orderList";
// import React from "react";
import { OrdersSection } from "../../styled/pickup";
import { formatOrderId, orderType } from "../../function/mutualBenefit";

const Orders = ({ tabs, active, setActive, data, changeOrderView }) => {
  const [currentTime, setCurrentTime] = useState(moment().format("M월 DD일(ddd) A hh시 mm분"));

  useEffect(() => {
    let animationFrameId;

    const updateCurrentTime = () => {
      setCurrentTime(moment().format("M월 DD일(ddd) A hh시 mm분"));
      animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    updateCurrentTime();

    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  return (
    <div className="sidemenu">
      <div className="tabs">
        <ul>
          {tabs.map((tab) => (
            <li
              key={tab.key}
              onClick={() => setActive(tab.key)}
              className={active === tab.key ? "active" : "inactive"}>
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <OrdersSection>
        <div className="newOrders">
          <ul>
            {data.map((item, index) => (
              <li onClick={() => changeOrderView(item)}>
                <img
                  src={`/img/mutualBenefit/${orderType[item.type].imgPath}.png`}
                  alt={orderType[item.type].imgPath}
                />
                <div className="orderInfo">
                  <span className="tit">{`${orderType[item.type].label} ${formatOrderId(
                    item.orderId,
                  )}`}</span>
                  <span className="con">메뉴 1개</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="inProgressOrders">
          <div className="inProgressInfo">
            <span>진행중 1건</span>
          </div>
          <ul>
            {data.map((item, index) => (
              <li>
                <img src={`/img/mutualBenefit/${orderType[item.type].imgPath}.png`} alt="pickup" />
                <div className="inProgressOrderItem">
                  <span className="tit">{`${47 + index}번 주문 / ${
                    orderType[item.type].label
                  }`}</span>
                  <span className="con">메뉴 1개</span>
                </div>
                <div className="orderProgress green">
                  <div className="progressRemaining">15분</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </OrdersSection>
      <div className="date">{currentTime}</div>
    </div>
  );
};

export default Orders;
