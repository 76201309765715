import { IoIosList } from "react-icons/io";
import { IoCalendarOutline } from "react-icons/io5";
import { LuLayoutDashboard } from "react-icons/lu";
import { GoFile } from "react-icons/go";
import { HiOutlineDocumentReport } from "react-icons/hi";

export const projectMenus = [
  {
    name: "대시보드",
    icon: LuLayoutDashboard,
  },
  {
    name: "일정표",
    icon: IoCalendarOutline,
  },
  {
    name: "일정",
    icon: IoIosList,
  },
  { name: "파일", icon: GoFile },
  { name: "리포트", icon: HiOutlineDocumentReport },
];

export const isColor = (name) => {
  if (name.includes("fixed")) return "#FFC455";
  if (name.includes("cmpny") || name === "C") return "#0074ca";
  if (name.includes("other")) return "#ED5583";
  if (name.includes("group") || name === "G") return "#F46F02";

  return "#533b70";
};

export const isCmpnyColor = (number) => {
  const r = (number >> 16) & 0xff;
  const g = (number >> 8) & 0xff;
  const b = number & 0xff;

  // RGB 색상 문자열 반환
  return `rgba(${r}, ${g}, ${b}, 0.2)`;
};

export const isCategory = (name) => {
  if (!name) return "";

  if (name.includes("fixed")) return "즐겨찾기";
  if (name.includes("cmpny")) return "업무";
  if (name.includes("other")) return "외부업체";
  if (name.includes("group")) return "그룹";
};

// 해당 웹소켓 찾기
export const targetWebSocket = (data = [], selected) => {
  if (!selected) return;

  const extractRoomId = (url) => {
    const params = new URLSearchParams(url.split("?")[1]);
    return params.get("room");
  };

  const target = data.find((ws) => extractRoomId(ws.url) == selected.roomId);
  return target;
};
