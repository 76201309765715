import React, { useEffect, useRef, useState, useCallback, Children, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { ImageDetailViewerContainer } from "../components/mypage/scheduler/component/styled/scheduler";

import { IoMdClose } from "react-icons/io";
import { GoDownload } from "react-icons/go";

const ImageDetailViewer = ({ images: items, setIsDetail, isGroup = false }) => {
  const [fileSize, setFileSize] = useState({});

  const fileCategories = useMemo(
    () => ({
      images: [
        ".png",
        ".jpg",
        ".jpeg",
        ".gif",
        ".bmp",
        ".tiff",
        ".svg",
        ".webp",
        ".ico",
        ".psd",
        ".heic",
        ".heif",
        ".ai",
        ".eps",
        ".raw",
        ".indd",
        ".xcf",
      ],
      videos: [".mp4", ".avi", ".mov", ".wmv", ".flv", ".mkv"],
    }),
    [],
  );

  const getFileType = (path) => {
    return path.split(".").pop();
  };

  // 파일 사이즈 계산
  const handleFileSize = (e, index) => {
    const target = e.target;

    setFileSize((prevSizes) => ({
      ...prevSizes,
      [index]: `${target.videoWidth || target.naturalWidth}x${
        target.videoHeight || target.naturalHeight
      }`,
    }));
  };

  // 저장하기
  const handleDownloadFile = useCallback(async (filePath) => {
    const url = imgHandler(filePath);

    try {
      const res = await axios.post("/api/getFile", { url }, { responseType: "arraybuffer" });
      const blob = new Blob([res.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filePath.split("/").pop();
      link.click();
      toast.success(`선택하신 파일을 저장하였습니다.`);
    } catch (error) {
      console.error(`파일 다운로드 실패:`, error);
    }
  }, []);

  // 이미지 비디오 출력
  const getFileCategory = useCallback(
    (item, index) => {
      if (!item || !item.path) return null;

      const extension = item.path.match(/\.\w+$/);
      if (extension) {
        if (fileCategories.images.includes(extension[0])) {
          return (
            <img
              src={imgHandler(item.path)}
              onLoad={(e) => handleFileSize(e, index)}
              decoding="async"
            />
          );
        } else if (fileCategories.videos.includes(extension[0])) {
          return (
            <video
              src={imgHandler(item.path) || ""}
              preload="auto"
              controls
              controlsList="nodownload"
              onLoadedMetadata={(e) => handleFileSize(e, index)}></video>
          );
        }
      }
      return null;
    },
    [handleFileSize],
  );

  const handleEsc = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setIsDetail(isGroup ? 0 : false);
      }
    },
    [setIsDetail, isGroup],
  );

  useEffect(() => {
    if (items && items.length > 0) {
      document.addEventListener("keydown", handleEsc);
      return () => {
        document.removeEventListener("keydown", handleEsc);
      };
    }
  }, [handleEsc]);

  return (
    <ImageDetailViewerContainer>
      <div className="imagesContainer">
        <Swiper
          className="mySwiper"
          slidesPerView={1}
          loop={true}
          shouldSwiperUpdate={true}
          style={{ width: "100%", height: "100%" }}
          modules={[Navigation]}
          navigation={true}>
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="swiperWrapper">
                <div className="imageHeader">
                  <span>{item.createNm}</span>
                  <button className="close" onClick={() => setIsDetail(isGroup ? 0 : false)}>
                    <IoMdClose />
                  </button>
                </div>
                <div className="imageContent">
                  <div className="image">{getFileCategory(item, index)}</div>
                </div>
                <div className="imageFooter">
                  <div className="info">
                    <div className="type">
                      <span>유형</span>
                      <span>{getFileType(item.path)}</span>
                    </div>
                    {item.createDt && (
                      <div className="date">
                        <span>올린 날짜</span>
                        <span>{moment(item.createDt).format("YYYY-MM-DD HH:mm")}</span>
                      </div>
                    )}
                    {fileSize[index] && (
                      <div className="date">
                        <span>사이즈</span>
                        <span>{fileSize[index]}</span>
                      </div>
                    )}
                  </div>
                  <button className="saveButton" onClick={() => handleDownloadFile(item.path)}>
                    <GoDownload />
                    <span>저장하기</span>
                  </button>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </ImageDetailViewerContainer>
  );
};

export default ImageDetailViewer;
