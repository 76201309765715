import { useEffect } from "react";
import MemberSwiper from "./memberSwiper";
import axios from "axios";
import { useState } from "react";

export default function Member() {
  const [members, setMembers] = useState([]);
  const [totalMber, setTotalMber] = useState(0);

  useEffect(() => {
    getShopsInfo(true);
  }, []);

  // 회원사 정보 조회
  const getShopsInfo = async () => {
    const url = "/api/cmpnyIntroduce";
    const body = {
      offset: 0,
      pageNumber: 0,
      pageSize: 8,
      paged: true,
    };

    const res = await axios.post(url, body);
    if (res.data.content.length !== 0) {
      setMembers(res.data.content);
      setTotalMber(res.data.totalElements);
    }
  };

  return (
    <div className="section fp-auto-height-responsive" id="section3">
      <div className="text_wrap">
        <span className="font_100 font_color_1 font700 pdb_40">{service.memberE}</span>
        <span className="font_24 font_color_black font400 pdb_10">
          {service.mComment7} <strong>{totalMber}</strong>
          {service.mComment8}
        </span>
      </div>
      <MemberSwiper members={members} />
    </div>
  );
}
