import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { useSelector } from "react-redux";
import { MemberSwiperContainer } from "./styled/main";

const MemberSwiper = ({ members }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const images = Array.from({ length: 4 }, (_, idx) => idx + 1);

  const openNewTab = (domain) => {
    if (!domain) return;
    window.open(`https://1472.ai/shop/${domain}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <MemberSwiperContainer>
      <div className="swiper member_swiper">
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={50}
          loop={true}
          slidesPerGroup={1}
          initialSlide={2}
          scrollbar={false}
          pagination={{ clickable: true }}>
          {/* <SwiperSlide>
          <span className="font_18 font500 member_location">대전</span>
          <div className="member_img_wrap">
            <img src="/img/member_1.jpg" className="member_img" />
            <span className="font_30 font500 font_color_white member_name">Company name</span>
          </div>
          <span className="font_16 font300 font_color_black member_description">
            당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
          </span>
          <p className="font_20 font300 font_color_white member_link">go</p>
        </SwiperSlide>

        <SwiperSlide>
          <span className="font_18 font500 member_location">대전</span>
          <div className="member_img_wrap">
            <img src="/img/member_2.jpg" className="member_img" />
            <span className="font_30 font500 font_color_white member_name">Company name</span>
          </div>
          <span className="font_16 font300 font_color_black member_description">
            당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
          </span>
          <p className="font_20 font300 font_color_white member_link">go</p>
        </SwiperSlide>

        <SwiperSlide>
          <span className="font_18 font500 member_location">대전</span>
          <div className="member_img_wrap">
            <img src="/img/member_3.jpg" className="member_img" />
            <span className="font_30 font500 font_color_white member_name">Company name</span>
          </div>
          <span className="font_16 font300 font_color_black member_description">
            당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
          </span>
          <p className="font_20 font300 font_color_white member_link">go</p>
        </SwiperSlide>

        <SwiperSlide>
          <span className="font_18 font500 member_location">대전</span>
          <div className="member_img_wrap">
            <img src="/img/member_4.jpg" className="member_img" />
            <span className="font_30 font500 font_color_white member_name">Company name</span>
          </div>
          <span className="font_16 font300 font_color_black member_description">
            당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
          </span>
          <p className="font_20 font300 font_color_white member_link">go</p>
        </SwiperSlide>

        <SwiperSlide>
          <span className="font_18 font500 member_location">대전</span>
          <div className="member_img_wrap">
            <img src="/img/member_5.jpg" className="member_img" />
            <span className="font_30 font500 font_color_white member_name">Company name</span>
          </div>
          <span className="font_16 font300 font_color_black member_description">
            당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
          </span>
          <p className="font_20 font300 font_color_white member_link">go</p>
        </SwiperSlide> */}
          {members.map((item) => (
            <SwiperSlide>
              <span className="font_18 font500 member_location">
                {item.adres ? item.adres.substring(0, 2) : "대전"}
              </span>
              <div className="member_img_wrap">
                <img
                  src={`/img/member_${Math.floor(Math.random() * images.length)}.jpg`}
                  className="member_img"
                />
                <span
                  className="font_30 font500 font_color_white member_name"
                  style={{
                    maxWidth: "80%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    wordBreak: "break-all",
                    transform: "translate(-50%, -50%)",
                    left: "50%",
                  }}>
                  {item.cmpnyNm}
                </span>
              </div>
              <span className="font_16 font300 font_color_black member_description">
                당신에게만 어울리는 그 공간속의 특별함, 일상을 바꾸어드립니다.
              </span>
              <p
                className="font_20 font300 font_color_white member_link"
                onClick={() => openNewTab(item.domain)}>
                go
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </MemberSwiperContainer>
  );
};

export default MemberSwiper;
