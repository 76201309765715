import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";
import moment from "moment";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const News = ({ cmpnyNo, homepageInfo }) => {
  const [notices, setNotices] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getNotices(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getNotices = async (cmpnyNo) => {
    const url = "/api/noticeList";
    const body = {
      cmpnyNo,
      gbn: "N",
      delYn: "N",
      noticeCl: "공지",
      offset: 0,
      pageNumber: 0,
      pageSize: 3,
      paged: true,
    };
    try {
      const res = await axios.post(url, body);
      let data = res.data.content;

      setNotices(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="news">
      <div className="info">News</div>
      <div className="content">
        <div className="more">
          <button>
            <span>
              더 많은 <br />
              소식 보러가기
            </span>
            <div className="icon">
              <GrLinkNext />
            </div>
          </button>
        </div>
        <div className="newsContent">
          {notices.map((item, i) => (
            <div className="box" id={i}>
              <div className="newsInfo">
                <span>{item.noticeSj}</span>
                <span>{moment(item.createDt).format("YYYY.MM.DD")}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
