import React from "react";

import { DetailsContentContainer } from "../../../styled/pickup";

import Progress from "./detailsContent/progress";
import Requests from "./detailsContent/requests";
import OrderMenu from "./detailsContent/orderMenu";

const DetailsContent = ({ selected }) => {
  return (
    <DetailsContentContainer>
      {selected ? (
        <>
          <div className="pickupInfo">
            <span className="label">픽업정보</span>
            <Progress />
          </div>
          <Requests remark={selected.remark} />
          <OrderMenu />
        </>
      ) : (
        <div className="notOrder">
          <div className="bg">현재 들어온 주문이 없습니다.</div>
        </div>
      )}
    </DetailsContentContainer>
  );
};

export default DetailsContent;
