import BoardView from "../../../common/boardViewPage";
import QnaTitle from "./qnaTitle";

export default function QnaViewForm () {
  return (
    <div>
      <div className="inner_content">
        <QnaTitle />
        <BoardView />
      </div>
    </div>
  )
}