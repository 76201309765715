import QnaViewForm from "./component/qnaViewForm";
import NoticeBanner from "./noticeBanner";

export default function QnaView () {
  return(
    <>
      <NoticeBanner />
      <QnaViewForm />
    </>
  )
}